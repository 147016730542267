import React, { useState, useEffect } from 'react';
import { svgThumbsUp } from 'components/svg_module';
import styled from 'styled-components';
import { tableSortLabelClasses } from '@mui/material';
import { useSelector } from 'react-redux';

const ThumbContainer = styled.div`
svg, path { 
  fill: ${({ vote_up, is_white, color }) =>
    vote_up
      ? '#D18000 !important'
      : is_white
      ? `${color} !important`
      : 'rgba(255,255,255,0.6)'}
`;

export default function Thumb({
  is_white,
  block,
  isAdventure,
  total_vote_count_up,
  onThumbDown,
  onThumbUp,
  countStyle,
  thumbStyle,
}) {
  const adventureBlock = useSelector(
    (state) =>
      state.entities.itinerary.adventure_blocks?.filter(
        (e) => e.block_rid === block.block_rid,
      )[0],
  );

  const data = isAdventure ? { ...adventureBlock } : { ...block };
  const totalCount = isAdventure
    ? data.total_vote_count_up
    : total_vote_count_up;
  const voteUp = data.explorer_vote_up === true;

  //console.log(adventureBlock, block);

  return (
    <ThumbContainer
      vote_up={voteUp}
      color={thumbStyle?.color || '#82700'}
      is_white={is_white}
      className="mini-blk-btm-hierarchy-wrapper vote"
      onClick={(e) => {
        if (voteUp) {
          onThumbDown(e, data);
        } else {
          onThumbUp(e, data);
        }
      }}
      data-text={voteUp ? 'Remove Vote' : 'Vote Up'}
    >
      {svgThumbsUp(
        voteUp ? '#F7B844' : thumbStyle?.color || '#ffffff',
        voteUp ? 'mini-blk-hierarchy vote-up' : 'mini-blk-hierarchy',
        'mini-blk-hierarchy-outer',
      )}
      <span
        className={`count-spans count-spans--vote-up ${voteUp && 'vote-up'}`}
        style={countStyle}
      >
        {totalCount}
      </span>
    </ThumbContainer>
  );
}
