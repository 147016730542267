import React, { useEffect, useRef } from 'react';
import {
  copyToTripBoard,
  upsertLocationBlock,
  updateBlockLocationPoint,
} from 'actions/block_actions';
import {
  openBlockModal,
  openDeleteModal,
  openSearchModal,
  openItineraryItemModal,
  openItineraryModal,
  openCheckListModal,
  openCopyModal,
  openFolderModal,
  setLoading,
} from 'actions/modal_actions';
import { fetchLocationBlocks } from 'actions/itinerary_actions';

import { connect, useSelector } from 'react-redux';
import {
  TripLocationPopup,
  LibraryLocationPopup,
  MovedTripMarkerPopup,
  MovedLibraryMarkerPopup,
  NewTripLocationBlockPopup,
  NewLibraryLocationBlockPopup,
} from './popup_components';
const mapStateToProps = ({ entities, session, ui }) => {
  let itineraryInfo = entities.itinerary.itinerary_info
    ? entities.itinerary.itinerary_info
    : null;

  let userInfo = session.user ? session.user.userInfo[0] : null;

  let adventureBlocks = entities.itinerary.adventure_blocks
    ? entities.itinerary.adventure_blocks
    : null;
  let locationBlocks =
    entities.itinerary.locationBlocks && entities.itinerary.locationBlocks;
  let itineraryDetails = entities.itinerary.itineraryDetails
    ? entities.itinerary.itineraryDetails
    : null;
  let mapFilter = entities.itinerary.mapFilter
    ? entities.itinerary.mapFilter
    : null;
  let libraryBlocks = entities.blocks.library_blocks
    ? entities.blocks.library_blocks
    : null;
  let libraryAdventureSelected = entities.blocks.libraryAdventureSelected
    ? entities.blocks.libraryAdventureSelected
    : null;
  let user_adventure_friend = entities.social.adventureFriends
    ? entities.social.adventureFriends
    : null;
  let user_adventure_friend_rid = user_adventure_friend
    ? user_adventure_friend.filter(
        (ele) => ele.explorer_rid === userInfo.explorer_rid,
      )[0]?.adventure_friend_rid
    : null;
  let popupState = ui?.map?.displayMapPopups;
  return {
    itineraryInfo,
    userInfo,
    adventureBlocks,
    locationBlocks,
    itineraryDetails,
    mapFilter,
    libraryBlocks,
    libraryAdventureSelected,
    user_adventure_friend,
    user_adventure_friend_rid,
    popupState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openBlockModal: (
      editView,
      block_rid,
      openBLockModal,
      currentAdventure,
      tabToDisplay,
      itineraryData,
      userInfo,
    ) =>
      dispatch(
        openBlockModal(
          editView,
          block_rid,
          openBLockModal,
          currentAdventure,
          tabToDisplay,
          itineraryData,
          userInfo,
        ),
      ),
    copyToTripBoard: (data, userInfo, origin, returnActionPayload) =>
      dispatch(copyToTripBoard(data, userInfo, origin, returnActionPayload)),
    upsertLocationBlock: (data, userInfo, returnType, returnActionPayload) =>
      dispatch(
        upsertLocationBlock(data, userInfo, returnType, returnActionPayload),
      ),
    updateBlockLocationPoint: (data) =>
      dispatch(updateBlockLocationPoint(data)),
    fetchLocationBlocks: (data) => dispatch(fetchLocationBlocks(data)),
  };
};

const Popups = ({ type, ...props }) => {
  const popupStateRef = useRef(null);
  const popupState = useSelector(({ ui }) => ui?.map?.displayMapPopups);
  useEffect(() => {
    popupStateRef.current = popupState;
  }, []);
  useEffect(() => {
    if (popupState !== popupStateRef.current && props.markerData) {
      let { marker } = props.markerData;

      if (!popupState) {
        if (marker._popup.isOpen()) {
          marker.togglePopup();
        }
        popupStateRef.current = popupState;
      } else if (popupState) {
        if (!marker._popup.isOpen()) {
          marker.togglePopup();
        }
        popupStateRef.current = popupState;
      }
    }
  }, [popupState]);

  return (
    <>
      {/* {type === 'libraryLocation' && <LibraryLocationPopup {...props} />} */}
      {type === 'libraryLocation' && <></>}
      {type === 'movedLibraryMarker' && <MovedLibraryMarkerPopup {...props} />}
      {type === 'tripLocation' && <TripLocationPopup {...props} />}
      {type === 'movedTripMarker' && <MovedTripMarkerPopup {...props} />}
      {type === 'newTripMarker' && <NewTripLocationBlockPopup {...props} />}
      {type === 'newLibraryMarker' && (
        <NewLibraryLocationBlockPopup {...props} />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Popups);
