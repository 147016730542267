import ResponsiveIconButton from 'features/itinerary/components/responsive-icon-button';
import { VanIcon } from './trip-builder-page-button';

/**
 * Styled Trip Builder Button
 * Does not apply functionality
 */
function TripBuilderResponsiveButton({ sx, disabled = false, ...rest }) {
  const handleClick = (e) => {};

  return (
    <ResponsiveIconButton
      text={'Trip Planner'}
      icon={<VanIcon sx={{ width: 30, height: 30 }} />}
      disabled={disabled}
      sx={{ whiteSpace: 'nowrap', ...sx }}
      onClick={handleClick}
      {...rest}
    />
  );
}

export default TripBuilderResponsiveButton;
