export const wayPointColors = [
  // { start: "#09132B", middle: "#335D7B", end: "#6EC6ED" },
  //sef
  { start: '#273a07', middle: '#335D7B', end: '#6EC6ED' },
  //other
  // { start: '#780000 ', middle: '#B00000 ', end: '#D80000' },
  { start: '#d18000', middle: '#B00000 ', end: '#D80000' },
  { start: '#AADD00', middle: '#D1FF36', end: '#E5FF8E' },
  { start: '#218868', middle: '#41D1A4', end: '#96E5CD' },
  { start: '#808A87', middle: '#A6ADAB', end: '#CCD0CF' },
  { start: '#068481', middle: '#0BF2ED', end: '#78F9F6' },
];
