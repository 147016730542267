import { ButtonBase } from '@mui/material';
import useDonationPageButton from '../hooks/use-donation-page-button';

function DonationPageButtonBase({ children }) {
  const { goto } = useDonationPageButton();

  return <ButtonBase onClick={goto}>{children}</ButtonBase>;
}

export default DonationPageButtonBase;
