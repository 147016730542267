import React from 'react';
import { Provider } from 'react-redux';
import Popups from './popup_container';

const PopupWithStore = ({ type, store, ...props }) => {
  return (
    <Provider store={store}>
      <Popups type={type} {...props} />
    </Provider>
  );
};
export default PopupWithStore;
