import { BlockTypes } from "./constants"

function hasVotes(block) {
  const { vote_flag } = block
  return vote_flag
}

function hasComments(block) {
  const { comment_flag } = block
  return comment_flag
}

function hasFiles(block) {
  const { block_file_count } = block
  return block_file_count > 0
}

function hasLocation(block) {
  const { location_flag } = block
  return location_flag
}

function isTemplate(block) {
  const { template_flag } = block
  return template_flag
}

function isTripBoard(category) {

}

function isItinerary(category) {
  return category === 'it-item'
}

function isLibrary(category) {

}

function isFolder(block) {
  const { folder_flag } = block
  return folder_flag
}

function isActivity(block) {
  const { folder_flag, list_flag } = block
  return !folder_flag && !list_flag
}

function isList(block) {
  const { folder_flag, list_flag } = block
  return !folder_flag && list_flag
}

function blockType(block) {
  if(isFolder(block)) return BlockTypes.Folder
  if(isList(block)) return BlockTypes.List
  if(isActivity(block)) return BlockTypes.Activity
  return BlockTypes.None
}

export {
  hasVotes,
  hasComments,
  hasFiles,
  hasLocation,
  isTemplate,
  isTripBoard,
  isItinerary,
  isLibrary,
  isFolder,
  isActivity,
  isList,
  blockType,
}