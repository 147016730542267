import React, { useState, useRef, useCallback, useEffect } from 'react';
import Collapsible from 'react-collapsible';
import Slider from 'components/modules/block_slider_no_button/index';
import SlideButton from 'components/modules/block_slider_no_button/button';
import ReactTooltip from 'react-tooltip';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { makeStyles } from '@material-ui/core';
import {
  svgFolder,
  svgListBlock,
  svgActivity,
  svgAddNew,
} from 'components/svg_module';
import { DropDownSelect } from 'components/global_modal/modal_modules';
import { Box, Button, IconButton } from '@mui/material';
import { ExpandLess } from '@material-ui/icons';

const useStyles = makeStyles({
  dropdownRoot: {
    maxWidth: '50%',
    '& .react-dropdown-select-content': {
      width: '100%',
    },
    '& .react-dropdown-select-input': {
      width: '100%',
      fontSize: '12px',
      color: '#364301',
    },
    '& .react-dropdown-select': {
      padding: '4px',
      border: 'none',
      borderBottom: '1px solid #364301',
      borderRadius: '0px !important',
      backgroundColor: 'transparent !important',
      minHeight: 'unset',
    },
    '& .crs-arrow-left svg, & .crs-arrow-right svg': {
      width: '9px',
      height: '15px',
    },
  },
});

const TripBoard = ({
  displayItems,
  handleDisplayTransitions,
  handleReposition,
  currAdvDis,
  handleLevelTraverse,
  displayDetails,
  handleTraverseUp,
  adventure_rid,
  libraryBlocks,
  filterClickHandler,
  filtered,
  filter,
  itinerary_info,
  listFiltered,
  handleLevelTraverseLists,
  handleTraverseUpLists,
  displayDetailsLists,
  resetDisplayDetailsLists,
  getRenderItem,
  blockHandler,
  checkListHandler,
  addActivityFolderHandler,
  addListFolderHandler,
  ...props
}) => {
  const classes = useStyles();
  const [displayItineraryIconList, toggleItineraryIconList] = useState(false);
  const [displayAcitivityIconList, toggleAcitivityIconList] = useState(false);
  const tabBarIconListRefActivity = useRef();
  const tabBarOuterRefActivity = useRef();
  const tabBarIconListRefList = useRef();
  const tabBarOuterRefList = useRef();

  const handleClickOutSideAcitivity = useCallback((event) => {
    if (tabBarIconListRefActivity.current && tabBarOuterRefActivity.current) {
      if (
        !tabBarIconListRefActivity.current.contains(event.target) &&
        !tabBarOuterRefActivity.current.contains(event.target)
      ) {
        toggleAcitivityIconList(false);
      }
    }
  });
  const handleClickOutSideList = useCallback((event) => {
    if (tabBarIconListRefList.current && tabBarOuterRefList.current) {
      if (
        !tabBarIconListRefList.current.contains(event.target) &&
        !tabBarOuterRefList.current.contains(event.target)
      ) {
        toggleItineraryIconList(false);
      }
    }
  });

  useEffect(() => {
    window.addEventListener('click', handleClickOutSideAcitivity);

    return () => {
      window.removeEventListener('click', handleClickOutSideAcitivity);
    };
  }, [handleClickOutSideAcitivity]);
  useEffect(() => {
    window.addEventListener('click', handleClickOutSideList);

    return () => {
      window.removeEventListener('click', handleClickOutSideList);
    };
  }, [handleClickOutSideList]);

  const renderActivitiesClone = getRenderItem(filtered);
  const renderListsClone = getRenderItem(listFiltered);

  return !displayItems.tripBoard ? (
    <div
      className="itinerary-closed-buttons"
      onClick={
        !displayItems.tripBoard
          ? () => {
              handleDisplayTransitions('tripBoard');
              handleReposition('block-library');
            }
          : null
      }
    >
      <div className="hub-advSel-blocks-titles">Library</div>
    </div>
  ) : (
    <>
      <div
        className="itinerary_adventure_block_wrapper"
        id="library-page-tripboard"
      >
        <div className="header">
          {displayDetails && displayDetails.level > 1 && (
            <Box display="flex">
              <Button
                variant="contained"
                endIcon={<ExpandLess />}
                onClick={handleTraverseUp}
                sx={{ maxHeight: '30px' }}
              >
                Go Up
              </Button>
            </Box>
          )}
          {/* <div
            className="itinerary_adventure_block_bottom_icon_dropdown"
            ref={tabBarOuterRefActivity}
          > */}
          {/* <Collapsible
              trigger={
                <div
                  className="trip-board-list-collapsible-icon-plus-container"
                  data-tip
                  data-for="addNewToolTip"
                >
                  {svgAddNew(
                    '',
                    'itinerary-trip-board-svg',
                    'itinerary-trip-board-svg-outer',
                  )}
                  <ReactTooltip
                    delayShow={500}
                    id="addNewToolTip"
                    className="tool-tip-container"
                    textColor="#09132b"
                    backgroundColor="#f7b844"
                    arrowColor="#f7b844"
                    triggerModal
                  >
                    <span>{`Add New`}</span>
                  </ReactTooltip>
                </div>
              }
              contentOuterClassName="trip-board-box-menu-closed"
              contentInnerClassName="trip-board-box-menu-opened"
              open={displayAcitivityIconList}
              handleTriggerClick={() =>
                toggleAcitivityIconList(!displayAcitivityIconList)
              }
              transitionTime={10}
            >
              <div
                ref={tabBarIconListRefActivity}
                className="action-svg-wrapper"
              >
                <div
                  className="create-modal-svg-wrapper"
                  onClick={() => {
                    toggleAcitivityIconList(false);

                    blockHandler();
                  }}
                >
                  <div className="buttonify">
                    {svgActivity(
                      '',
                      'itinerary-trip-board-svg',
                      'itinerary-trip-board-svg-outer',
                    )}
                    <span className="empty-block-text-high-lighter">
                      {`NEW \nACTIVITY`}
                    </span>
                  </div>
                </div>
                <div
                  className="create-modal-svg-wrapper"
                  onClick={() => {
                    toggleItineraryIconList(false);

                    checkListHandler();
                  }}
                >
                  <div className="buttonify">
                    {svgListBlock(
                      '',
                      'itinerary-trip-board-svg',
                      'itinerary-trip-board-svg-outer',
                    )}
                    <span className="empty-block-text-high-lighter">
                      NEW <br />
                      LIST
                    </span>
                  </div>
                </div>
                <div
                  className="create-modal-svg-wrapper"
                  onClick={() => {
                    toggleAcitivityIconList(false);
                    addActivityFolderHandler();
                  }}
                >
                  <div className="buttonify">
                    {svgFolder(
                      '',
                      'itinerary-trip-board-svg',
                      'itinerary-trip-board-svg-outer',
                    )}
                    <span className="empty-block-text-high-lighter">
                      NEW <br /> FOLDER
                    </span>
                  </div>
                </div>
              </div>
            </Collapsible> */}
          {/* </div> */}
          <div className="title">
            {props.allAdventures && (
              <div
                className={[
                  'library-second-section-wrapper',
                  classes.dropdownRoot,
                ].join(' ')}
              >
                <DropDownSelect
                  values={props.selectedAdventure}
                  placeholder="Select a trip to import items into your itinerary..."
                  className="library-adventure-select-dropdown-child create_edit_block_modal_drop_down_select"
                  onChange={props.handleAdventureSelect}
                  options={props.allAdventures}
                  parentClassName="library-adventure-select-dropdown-parent create_edit_block_modal_drop_down_select_parent  library_dropdown"
                  labelField={'adventure_name'}
                  valueField={'adventure_rid'}
                  clearable
                  disableType={true}
                  displayCustomTripDates
                  displayCustomButton
                  customButton={
                    <button
                      onClick={(e) => {
                        props.location.state &&
                        props.location.state.fromItinerary
                          ? props.history.goBack()
                          : props.history.push(
                              `/hub/adventure/${encodeURIComponent(
                                props.adventureName,
                              )}/${props.maskURL(`${adventure_rid}`)}`,
                            );
                      }}
                    >
                      VIEW
                    </button>
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div className="seperator" />
        <div className="itinerary_adventure_block_bottom">
          <Slider
            adventure_rid={adventure_rid}
            handleLevelTraverse={handleLevelTraverse}
            displayDetails={displayDetails}
            currAdvDis={currAdvDis}
            itinerary_info={itinerary_info}
            handleTraverseUp={handleTraverseUp}
            libraryBlocks={libraryBlocks}
            filtered={filtered}
            filter={filter}
            customId={'library-activities'}
            renderClone={renderActivitiesClone}
          />
        </div>
      </div>
      <br />
      {false && (
        <div
          className="itinerary_adventure_block_wrapper"
          id="library-page-tripboard"
        >
          <div className="header">
            <div className="slider-buttons-fix-width">
              {displayDetailsLists && displayDetailsLists.level > 1 && (
                <SlideButton
                  type="up"
                  displayDetails={displayDetailsLists}
                  handleTraverseUp={handleTraverseUpLists}
                />
              )}
            </div>
            <div className="title">
              <div
                className="itinerary_adventure_block_bottom_icon_dropdown"
                ref={tabBarOuterRefList}
              >
                <Collapsible
                  trigger={
                    <div
                      className="trip-board-list-collapsible-icon-plus-container"
                      data-tip
                      data-for="addNewToolTip"
                    >
                      {svgAddNew(
                        '',
                        'itinerary-trip-board-svg',
                        'itinerary-trip-board-svg-outer',
                      )}
                      <ReactTooltip
                        delayShow={500}
                        id="addNewToolTip"
                        className="tool-tip-container"
                        textColor="#09132b"
                        backgroundColor="#f7b844"
                        arrowColor="#f7b844"
                        triggerModal
                      >
                        <span>{`Add New`}</span>
                      </ReactTooltip>
                    </div>
                  }
                  contentOuterClassName="trip-board-box-menu-closed"
                  contentInnerClassName="trip-board-box-menu-opened"
                  open={displayItineraryIconList}
                  handleTriggerClick={() =>
                    toggleItineraryIconList(!displayItineraryIconList)
                  }
                  transitionTime={10}
                >
                  <div
                    ref={tabBarIconListRefList}
                    className="action-svg-wrapper"
                  >
                    <div
                      className="create-modal-svg-wrapper"
                      onClick={() => {
                        toggleItineraryIconList(false);

                        checkListHandler();
                      }}
                    >
                      <div className="buttonify">
                        {svgListBlock(
                          '',
                          'itinerary-trip-board-svg',
                          'itinerary-trip-board-svg-outer',
                        )}
                        <span className="empty-block-text-high-lighter">
                          NEW <br />
                          LIST
                        </span>
                      </div>
                    </div>
                    <div
                      className="create-modal-svg-wrapper"
                      onClick={() => {
                        toggleItineraryIconList(false);
                        addListFolderHandler();
                      }}
                    >
                      <div className="buttonify">
                        {svgFolder(
                          '',
                          'itinerary-trip-board-svg',
                          'itinerary-trip-board-svg-outer',
                        )}
                        <span className="empty-block-text-high-lighter">
                          LIST <br /> FOLDER
                        </span>
                      </div>
                    </div>
                  </div>
                </Collapsible>
              </div>
              <div className="library-section-title-h3">LISTS</div>
            </div>
          </div>
          <div className="seperator" />
          <div className="itinerary_adventure_block_bottom">
            <Slider
              adventure_rid={adventure_rid}
              handleLevelTraverse={handleLevelTraverseLists}
              displayDetails={displayDetailsLists}
              currAdvDis={currAdvDis}
              itinerary_info={itinerary_info}
              handleTraverseUp={handleTraverseUpLists}
              libraryBlocks={libraryBlocks}
              filtered={listFiltered}
              filter={filter}
              customId={'library-lists'}
              renderClone={renderListsClone}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TripBoard;
