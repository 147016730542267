import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchItineraryInformation } from 'actions/adventure_actions';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';
import { ADVENTURE, ITINERARY } from 'features/query-keys';
import useUserInfo from 'features/user/hooks/use-user-info';
import adventure from 'pages/pwa_hub/adventure';
import { fetchAdventureItinerary } from 'util/api_util/adventure_api_util';
import { fetchItineraryDetail } from 'util/api_util/itinerary_api_util';

const useItineraryInfoQuery = (options?: UseQueryOptions) => {
  const { explorer_rid } = useUserInfo();
  const { adventure_rid = null } = useCurrentAdventure() || {};

  return useQuery({
    ...options,
    queryKey: [ADVENTURE, adventure_rid, ITINERARY, 'info'],
    queryFn: () =>
      fetchAdventureItinerary(adventure_rid, explorer_rid).then(
        (res) => res.data.adventureItineraries?.[0],
      ),
    enabled: Boolean(adventure_rid),
  });
};

export default useItineraryInfoQuery;
