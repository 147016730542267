import { makeStyles } from '@material-ui/core';
import { Box, Chip } from '@mui/material';
import ChipFlexLayout from 'components/chip/chip-flex-layout';
import svgLibrary from 'components/svg_module/svg_library/library/functionLibrary';
import { styleValues } from 'pages/pwa_hub/adventure/components/constants';

const useStyles = makeStyles((theme) => ({
  ...styleValues(theme),
}));
export default function ActivityCategoryLayout({ categories = [] }) {
  if (!categories) return;
  const classes = useStyles();

  return (
    <Box display="flex" gap={2}>
      <ChipFlexLayout>
        {categories.map(({ icon_name, block_category_name }, i) => {
          const icon = (
            <Box sx={{ m: 1 }}>
              {svgLibrary[icon_name](
                '',
                [
                  classes.svgSize,
                  classes.svgColor_237a07,
                  classes.cursorSvgDefault,
                ].join(' '),
                [classes.svgSize, classes.cursorSvgDefault].join(' '),
              )}
            </Box>
          );
          return (
            <Chip
              icon={icon}
              variant="outlined"
              label={block_category_name}
              key={i}
              sx={{
                fontFamily: 'Soleil Bold',
                '.MuiChip-icon': {
                  padding: '4px',
                },
                height: 45,
              }}
            />
          );
        })}
      </ChipFlexLayout>
    </Box>
  );
}
