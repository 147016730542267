import React, { useState, useEffect, useRef, forwardRef } from 'react';
import Spinner from '../spinner';
import SearchSectionHeader from '../search-section-header';

const ScrollPagination = ({ title, loadData, itemRenderer, initialData, children, ...props }) => {
  const [data, setData] = useState(initialData);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);

  const fetchData = async (pageNumber) => {
    setIsLoading(true);
    const newData = await loadData(pageNumber);

    setIsLoading(false);
    setData((prevData) => [...prevData, ...newData]);
    setPage(pageNumber + 1);
  };

  const reset = () => {
    setData([]);
    setPage(1);
    fetchData(1);
  };

  useEffect(() => {
    setData(initialData);
    reset();
  }, [initialData])

  useEffect(() => {
    fetchData(page);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = containerRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 20 && !isLoading) {
        fetchData(page);
      }
    };

    containerRef.current.addEventListener('scroll', handleScroll);
    return () => containerRef.current?.removeEventListener('scroll', handleScroll);
  }, [isLoading, page]);

  return (
    <div ref={containerRef} {...props}>
      {children}
      {data.map((item, index) => {
        return itemRenderer(item, index);
      })}
      {isLoading && <Spinner />}
    </div>
  );
};

export default ScrollPagination;
