import { useState, useEffect, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import isEqual from 'lodash.isequal';
import { ScrollContainer } from '../scroll-container/scroll-container';
import Draggable from '../draggable/draggable';
import { DragTypes, DragDirections, OverlapStatus } from '../item-types';
import { isEmpty } from '../utils';
import { useCrudList, useMouseWheel } from '../hooks';
import DragPreview from '../../../pages/pwa_hub/itineraries/drag-preview';

function DNDList({
  type,
  accept,
  direction,
  gap,
  keyField,
  clonable,
  canCombine,
  data,
  ItemRenderer,
  Placeholder,
  handlers,
}) {
  const groupId = useMemo(() => parseInt(Math.random() * 100), []);

  const { onAddItem, onRemoveItem, onReorderItem, onCombineItem } = {
    ...DNDList.defaultProps.handlers,
    ...handlers,
  };
  const initial = useMemo(() => data.map((v) => ({ ...v, groupId })), [
    data,
    groupId,
  ]);

  const [listData, setListData, listHandlers] = useCrudList(initial, {
    onAddItem,
    onRemoveItem,
    onReorderItem,
    onCombineItem,
  });
  useEffect(() => {
    setListData(initial);
  }, [initial]);
  listData.forEach((v, index) => (v.index = index));

  const [stageListData, setStageListData, stageListHandlers] = useCrudList(
    listData,
  );
  useEffect(() => {
    if (!isEqual(stageListData, listData)) {
      setStageListData(listData);
    }
  }, [listData]);

  const [hoverStatus, changeHover] = useState({ after: false });
  const {
    event,
    hoverItem,
    dragItem,
    after,
    hoverIndex,
    dragIndex,
  } = hoverStatus;

  const [{ isOver, dragType }, dropRef] = useDrop({
    accept: accept,
    drop: (droppedItem) => {
      changeHover({ after: false });
      droppedItem.data.groupId = groupId;
      droppedItem.data.transparent = false;
      switch (event) {
        case OverlapStatus.ReorderItem:
          listHandlers.reorderItem(droppedItem.data.index, hoverIndex);
          hoverItem.data.index = droppedItem.data.index;
          droppedItem.data.index = hoverIndex;
          return;
        case OverlapStatus.AddItem:
          const addIndex = hoverItem
            ? hoverItem.index + Number(after)
            : listData.length;
          droppedItem.data.index = addIndex;
          listHandlers.addItem(droppedItem, addIndex);
          if (!droppedItem.clonable) {
            droppedItem.removeItem(droppedItem.index);
          }
          return;
        case OverlapStatus.CombineItem:
          listHandlers.combineItem(droppedItem, hoverItem);
          break;
      }
      if (droppedItem.groupId !== groupId) {
        droppedItem.data.index = listData.length;
        listHandlers.addItem(droppedItem, listData.length);
        if (!droppedItem.clonable) {
          droppedItem.removeItem(droppedItem.index);
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      dragType: monitor.getItemType(),
    }),
  });

  useEffect(() => {
    switch (event) {
      case OverlapStatus.ReorderItem:
        stageListHandlers.reorderItem(dragItem.index, hoverItem.index);
        dragItem.index = hoverItem.index;
        dragItem.groupId = hoverItem.groupId;
        break;
      case OverlapStatus.AddItem:
        const _stageItems = stageListData.filter((v) => v.groupId === groupId);
        const _addedItems = stageListData
          .map((item, id) => ({ item, id }))
          .filter((v) => v.item.groupId !== groupId);

        if (isOver) {
          const startIndex = hoverItem.index + Number(after);

          if (_addedItems.length === 0 || _addedItems[0].id !== startIndex) {
            dragItem.data.transparent = true;
            setStageListData([
              ..._stageItems.slice(0, startIndex),
              dragItem.data,
              ..._stageItems.slice(startIndex),
            ]);
          }
        } else {
          if (_addedItems.length > 0) setStageListData(_stageItems);
        }
        break;
    }
  }, [isOver, dragType, event, after, hoverItem?.index, groupId]);

  const memoItems = useMemo(
    () =>
      stageListData.map((item, index) => {
        return (
          <Draggable
            index={index}
            key={item[keyField]}
            type={item.type || type}
            accept={accept}
            groupId={groupId}
            direction={direction}
            clonable={clonable}
            canCombine={canCombine}
            transparent={item.transparent}
            data={item}
            handlers={{ ...listHandlers, changeHover }}
          >
            <ItemRenderer data={item} index={index} />
          </Draggable>
        );
      }),
    [
      stageListData,
      type,
      groupId,
      direction,
      canCombine,
      listHandlers,
      changeHover,
    ],
  );

  const onWheel = useMouseWheel();

  return (
    <div ref={dropRef} style={{ width: '100%', height: '100%' }}>
      <ScrollContainer
        direction={direction}
        gap={gap}
        onWheel={onWheel}
        style={{ paddingTop: '10px', paddingBottom: '10px' }}
      >
        {isEmpty(stageListData) && <Placeholder />}
        {memoItems}
        <DragPreview />
      </ScrollContainer>
    </div>
  );
}

DNDList.defaultProps = {
  type: DragTypes.Default,
  accept: [],
  direction: DragDirections.Horizontal,
  gap: 10,
  keyField: 'id',
  clonable: false,
  canCombine: false,
  data: [],
  ItemRenderer: () => null,
  Placeholder: () => null,
  handlers: {
    onAddItem: () => {},
    onRemoveItem: () => {},
    onReorderItem: () => {},
    onCombineItem: () => {},
  },
};

export default DNDList;
