export const DragTypes = Object.freeze({
  Default: "Default",
  Day: "Day",
  Library: "Library",
  Activity: "Activity",
  Folder: "Folder",
  List: "List"
})

export const DragDirections = Object.freeze({
  Horizontal: "Horizontal",
  Vertical: "Vertical"
})

export const OverlapStatus = Object.freeze({
  ReorderItem: "ReorderItem",
  AddItem: "AddItem",
  CombineItem: "CombineItem"
})