import { makeStyles } from '@material-ui/core';
import { IconButton } from '@mui/material';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: 0,
      width: '40px',
      height: '40px',
      padding: '5px',
      borderRadius: '5px',
      backgroundColor: (props) =>
        props.selected ? '#273A07 !important' : '#F9EED8 !important',
      '& .MuiIconButton-label': {
        height: '100%',
      },
    },
    icon: {
      fontSize: '3rem',
      marginBottom: theme.spacing(1),
    },
    label: {
      marginTop: '4px',
      fontFamily: 'Soleil',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '30%',
      lineHeight: '30%',
      /* identical to box height */

      textAlign: 'center',
      textTransform: 'uppercase',
      color: (props) => (props.selected ? '#F9EED8' : '#273A07'),
    },
    labelRoot: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      '& svg': {
        width: '80%',
        height: 'calc(70% - 4px)',
        flexGrow: 1,
      },
      '& svg path': {
        fill: (props) =>
          props.selected ? '#F9EED8 !important' : '#273A07 !important',
      },
    },
  };
});

const ActivityButton = ({ title, icon, ...props }) => {
  const classes = useStyles(props);
  console.log(title, props.selected);
  return (
    <IconButton {...props} className={classes.root}>
      <span className={classes.labelRoot}>
        {icon}
        <div className={classes.label}>{title}</div>
      </span>
    </IconButton>
  );
};

export default ActivityButton;
