//  let openType = { copySelf: true };
//           return openAdventureCopyModal(current_adventure_data, openType);
import { openAdventureCopyModal } from 'actions/modal_actions';
import { useDispatch } from 'react-redux';
import useCurrentAdventure from './use-current-adventure';

const OPEN_TYPE = {
  copySelf: true,
};

const useCopyAdventureModalButton = () => {
  const dispatch = useDispatch();
  const adventure = useCurrentAdventure();

  if (!adventure)
    throw new Error(
      'useCopyAdventureModal requries entitites.adventure.currentAdventure data',
    );

  const openModal = async () => {
    dispatch(openAdventureCopyModal(adventure, OPEN_TYPE));
  };

  return { openModal };
};

export default useCopyAdventureModalButton;
