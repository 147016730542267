import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from "react-dnd-touch-backend"
import MultiBackend, {
  DndProvider,
  TouchTransition,
  MouseTransition
} from "react-dnd-multi-backend"

// import { DndProvider } from "react-dnd"

const HTML5toTouch = {
  backends: [
    {
      id: "html5",
      backend: HTML5Backend,
      transition: MouseTransition
    },
    {
      id: 'touch',
      backend: TouchBackend,
      options: {
        delayTouchStart: 200
      },
      preview: true,
      transition: TouchTransition,
    }
  ]
}

function MultiBackendProvider({ children, ...props }) {
  return (
    <DndProvider
      backend={MultiBackend}
      options={HTML5toTouch}
      {...props}
    >
      {children}
    </DndProvider>
  )
}

export default MultiBackendProvider
export {
  HTML5toTouch
}


