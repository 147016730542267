import { DragDirections, OverlapStatus } from "./item-types"

function isSameItem(a, b) {
  return (a.index === b.index && a.groupId === b.groupId)
}

function isSameGroup(a, b) {
  return a.groupId === b.groupId
}

function isNearX(a, b, width = 50) {
  return Math.abs(a.x - b.x) < 0.3 * width
}

function isNearY(a, b, height = 50) {
  return Math.abs(a.y - b.y) < 0.3 * height
}

function isEmpty(array) {
  return array === undefined || array === null || array.length === 0
}

function beforeOrAfter(rect, mouse, direction) {
  const center = {
    x: (rect.right - rect.left) / 2,
    y: (rect.bottom - rect.top) / 2
  }

  const relativeMouse = {
    x: mouse.x - rect.left,
    y: mouse.y - rect.top
  }
  
  if(direction === DragDirections.Horizontal) {
    return relativeMouse.x > center.x
  }

  if(direction === DragDirections.Vertical) {
    return relativeMouse.y > center.y
  }
}

function overlapStatus(dragItem, hoverItem, rect, mouse, direction, canCombine = false) {
  const center = {
    x: (rect.right - rect.left) / 2,
    y: (rect.bottom - rect.top) / 2
  }

  const relativeMouse = {
    x: mouse.x - rect.left,
    y: mouse.y - rect.top
  }

  // if (isSameGroup(dragItem, hoverItem) && !canCombine) {
  //   if (dragItem.index < hoverItem.index) {
  //     if(direction === DragDirections.Horizontal && relativeMouse.x < center.x) {
  //       return;
  //     }
      
  //     if(direction === DragDirections.Vertical && relativeMouse.y < center.y) {
  //       return;
  //     }
  //   }
  //   if (dragItem.index > hoverItem.index) {
  //     if(direction === DragDirections.Horizontal && relativeMouse.x > center.x) {
  //       return;
  //     }
  //     if(direction === DragDirections.Vertical && relativeMouse.y > center.y) {
  //       return;
  //     }
  //   }
  // }
  
  if (canCombine) {
    if (isNearX(center, relativeMouse, rect.width) && direction === DragDirections.Horizontal) {
      if (isSameGroup(dragItem, hoverItem)) {
        return OverlapStatus.ReorderItem
      }
      return OverlapStatus.AddItem
    }

    if (isNearY(center, relativeMouse, rect.height) && direction === DragDirections.Vertical) {
      if (isSameGroup(dragItem, hoverItem)) {
        return OverlapStatus.ReorderItem
      }
      return OverlapStatus.AddItem
    }

    return OverlapStatus.CombineItem
  } else {
    if (isSameGroup(dragItem, hoverItem)) {
      return OverlapStatus.ReorderItem
    }
    return OverlapStatus.AddItem
  }
}

export {
  isSameItem,
  isSameGroup,
  isEmpty,
  beforeOrAfter,
  overlapStatus
}