import React, { useState } from "react";

const ItineraryItemNotes = ({ itineraryItemNotes }) => {
  const [displayMore, setDisplayMore] = useState(false);
  let displayInParts =
    itineraryItemNotes && itineraryItemNotes.length > 50 ? true : false;
  let frontPart;
  let backPart;
  if (displayInParts) {
    frontPart = itineraryItemNotes.slice(0, 50);
    backPart = itineraryItemNotes.slice(50, itineraryItemNotes.length);
  }

  return (
    <div className="itinerary-section-rt-bottom">
      <span>
        {displayInParts ? (
          <>
            {`${frontPart}${displayMore ? backPart : ""} ...`}
            <span
              className="itinerary-notes-collapse-text"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDisplayMore(!displayMore);
              }}
            >
              {!displayMore ? "read more" : "read less"}
            </span>
          </>
        ) : (
          itineraryItemNotes
        )}
      </span>
    </div>
  );
};

export default ItineraryItemNotes;
