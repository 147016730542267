import { Box } from '@material-ui/core';
import Stack from '@mui/material/Stack';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import useInvalidateAdventureData from 'features/adventure/hooks/use-invalidate-adventure-data';
import { merge } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { persistItineraryItemData } from '../../../../actions/cache_actions';
import { upsertItineraryItem } from '../../../../actions/itinerary_actions';
import {
  openItineraryItemModal,
  sendTimes,
} from '../../../../actions/modal_actions';
import TextBox from '../../../common/text-box';
import HubModal from '../../modal';
// import { dateFormat } from "util/helpers/itinerary_item_moda_date_funcs";
const mapStateToProps = ({ ui, entities, dataCache, session }) => {
  let startDate = ui.modal.selectedDates
    ? ui.modal.selectedDates.startDate
    : null;
  let endDate = ui.modal.selectedDates ? ui.modal.selectedDates.endDate : null;

  let receivedTime = ui.modal.selectedTimes ? ui.modal.selectedTimes : null;
  let openType = ui.modal.itineraryItemOpenType
    ? ui.modal.itineraryItemOpenType
    : null;
  let userInfo = session.user ? session.user.userInfo[0] : null;
  let itineraryItemDataPassed = dataCache.modal.itineraryItemData
    ? dataCache.modal.itineraryItemData
    : null;
  let persistedItineraryItemModalData = dataCache.modal.savedItineraryItemData
    ? dataCache.modal.savedItineraryItemData
    : null;
  return {
    startDate,
    endDate,
    receivedTime,
    userInfo,
    openType,
    itineraryItemDataPassed,
    persistedItineraryItemModalData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    upsertItineraryItem: (data, userInfo) =>
      dispatch(upsertItineraryItem(data, userInfo)),
    openItineraryItemModal: (data, adventure_rid, isEdit) =>
      dispatch(openItineraryItemModal(data, adventure_rid, isEdit)),
    sendTimes: (data) => dispatch(sendTimes(data)),
    persistItineraryItemData: (data, persistType) =>
      dispatch(persistItineraryItemData(data, persistType)),
  };
};

dayjs.extend(utc);

const CreateItineraryItem = ({
  startDate,
  endDate,
  closeModal,
  upsertItineraryItem,
  receivedTime,
  userInfo,
  openType,
  itineraryItemDataPassed,
  persistItineraryItemData,
  persistedItineraryItemModalData,
}) => {
  const { invalidateQueries } = useInvalidateAdventureData();
  const {
    parentItineraryItemData,
    itineraryItemData,
    itineraryData,
  } = itineraryItemDataPassed;
  const [errors, setErrors] = useState(null);
  // useEffect(() => {
  //   if (persistedData) {
  //     if (receivedTime) {
  //       persistedData.item_end_date = receivedTime.endDate;
  //       persistedData.item_start_date = receivedTime.startDate;
  //     }

  //     setInputValues(persistedData);
  //   }
  // }, []);
  let dateObject;
  if (openType.isNew) dateObject = itineraryData.end_date;
  if (openType.isEdit) dateObject = itineraryItemData.item_end_date;
  let defaultNewDate = moment(dateObject)
    .utcOffset(2)
    .add(openType.isEdit ? 0 : 1, 'days')
    .format('dddd D MMM YYYY');
  let startTimeObject;
  let endTimeObject;
  if (openType.isEdit) {
    startTimeObject = itineraryItemData.item_start_date;
    endTimeObject = itineraryItemData.item_end_date;
  }
  // if (openType.isNew && !inputValues.all_day_flag) {
  //   startTimeObject = parentItineraryItemData.item_start_date;
  //   endTimeObject = parentItineraryItemData.item_end_date;
  // }
  let defaultStartTime = moment(startTimeObject)
    .utcOffset(2)
    // .add(1, "days")
    .format('dddd D MMM YYYY');
  let defaultEndTime = moment(endTimeObject)
    .utcOffset(2)
    // .add(1, "days")
    .format('dddd D MMM YYYY');
  let startTimeModified;
  let endTimeModified;
  if (openType.isEdit) {
    startTimeModified = itineraryItemData.item_start_date.split('T');
    startTimeModified.splice(1, 1, itineraryItemData.item_start_time);
    startTimeModified = startTimeModified.join('T');
    endTimeModified = itineraryItemData.item_end_date.split('T');
    endTimeModified.splice(1, 1, itineraryItemData.item_end_time);
    endTimeModified = endTimeModified.join('T');
  }
  let startTimeParsed = openType.isEdit
    ? moment(openType.isEdit ? startTimeModified : '')
      // .utcOffset(2)
      .format('hh:mm a')
    : '';
  let endTimeParsed = openType.isEdit
    ? moment(openType.isEdit ? endTimeModified : '')
      // .utcOffset(2)
      .add(1, 'days')
      .format('hh:mm a')
    : '';

  const mergeDateAndTime = (date, time) => {
    if (!time) return date;
    return (
      dayjs(dayjs.utc(date).format('YYYY-MM-DD') + 'T' + time).format(
        'YYYY-MM-DDTHH:mm:ss',
      ) + '.000Z'
    );
  };
  const initialStartTime = itineraryItemData
    ? itineraryItemData.item_start_time
    : parentItineraryItemData
      ? parentItineraryItemData.item_start_time
      : null;
  const initialEndTime = itineraryItemData
    ? itineraryItemData.item_end_time
    : parentItineraryItemData
      ? parentItineraryItemData.item_end_time
      : null;
  const initialStartDate = openType.isEdit
    ? itineraryItemData.item_start_date
    : parentItineraryItemData
      ? parentItineraryItemData.item_start_date
      : itineraryData
        ? dayjs(itineraryData.end_date).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss')
        : null;
  const initialEndDate = openType.isEdit
    ? itineraryItemData.item_end_date
    : parentItineraryItemData
      ? parentItineraryItemData.item_end_date
      : itineraryData
        ? dayjs(itineraryData.end_date).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss')
        : null;

  console.log(initialStartDate, initialEndDate);
  console.log(initialStartTime, initialEndTime);
  const [inputValues, setInputValues] = useState({
    itinerary_item_rid: openType.isEdit
      ? itineraryItemData.itinerary_item_rid
      : null,
    parent_itinerary_item_rid: itineraryItemData
      ? itineraryItemData.parent_itinerary_item_rid
      : parentItineraryItemData
        ? parentItineraryItemData.itinerary_item_rid
        : null,
    itinerary_rid: parentItineraryItemData
      ? parentItineraryItemData.itinerary_rid
      : itineraryData.itinerary_rid,
    item_name: openType.isEdit ? itineraryItemData.item_name : '',
    item_description: openType.isEdit ? itineraryItemData.item_description : '',
    item_start_date: dayjs(
      mergeDateAndTime(initialStartDate, initialStartTime),
    ),
    item_end_date: dayjs(mergeDateAndTime(initialEndDate, initialEndTime)),
    item_start_time: dayjs.utc(
      mergeDateAndTime(initialStartDate, initialStartTime),
    ),
    item_end_time: dayjs.utc(mergeDateAndTime(initialEndDate, initialEndTime)),
    all_day_flag:
      openType.isEdit || openType.isTransitioning
        ? itineraryItemData
          ? itineraryItemData.all_day_flag
          : persistedItineraryItemModalData.all_day_flag
        : parentItineraryItemData
          ? false
          : true,
  });

  // if (openType.isNew && !inputValues.all_day_flag) {
  //   startTimeObject = parentItineraryItemData.item_start_date;
  //   endTimeObject = parentItineraryItemData.item_end_date;
  //   defaultStartTime = moment(startTimeObject)
  //     .utcOffset(2)
  //     .format('dddd D MMM YYYY');
  //   defaultEndTime = moment(endTimeObject)
  //     .utcOffset(2)
  //     .format('dddd D MMM YYYY');
  // }

  useEffect(() => {
    if (openType.isTransitioning) {
      let cloned = merge({}, persistedItineraryItemModalData);
      if (!persistedItineraryItemModalData.all_day_flag) {
        delete cloned.item_start_date;
        delete cloned.item_end_date;
      }
      setInputValues({ ...inputValues, ...cloned });
    }
  }, []);

  const switchHandler = (name, value) => {
    setInputValues({ ...inputValues, [name]: !value });
  };

  const handleOnChange = useCallback((event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  });

  const onTimeChange = (v, name) => {
    console.log(v.format('YYYY-MM-DDTHH:mm:ss'));
    setInputValues({ ...inputValues, [name]: v });
  };

  const onDateChange = (v) => {
    setInputValues({
      ...inputValues,
      item_start_date: v[0],
      item_end_date: v[1],
    });
  };

  const handleSubmit = async () => {
    const { item_name } = inputValues;
    if (!inputValues.all_day_flag && !item_name) return;

    if (inputValues.all_day_flag && !openType.isEdit) {
      let itineraryStartDate = new Date(itineraryData.start_date);
      let itineraryEndDate = new Date(itineraryData.end_date);
      let receivedStartDate = new Date(inputValues.item_start_date);
      let receivedEndDate = new Date(inputValues.item_end_date);
      if (
        (receivedStartDate >= itineraryStartDate &&
          receivedStartDate <= itineraryEndDate) ||
        (receivedEndDate >= itineraryStartDate &&
          receivedEndDate <= itineraryEndDate)
      ) {
        return setErrors('Please select a non-duplicate date');
      }
    }
    let dataPassover = merge({}, inputValues);
    dataPassover.adventure_rid = itineraryData.adventure_rid;

    if (dataPassover && !dataPassover.itinerary_rid)
      dataPassover.itinerary_rid = parentItineraryItemData.itinerary_rid;
    dataPassover['explorer_rid'] = userInfo.explorer_rid;
    dataPassover.item_start_date = mergeDateAndTime(
      dataPassover.item_start_date,
      dayjs(dataPassover.item_start_time).format('HH:mm:ss'),
    );
    dataPassover.item_end_date = mergeDateAndTime(
      dataPassover.item_end_date,
      dayjs(dataPassover.item_end_time).format('HH:mm:ss'),
    );
    await upsertItineraryItem(dataPassover, userInfo);
    invalidateQueries();

    closeModal();
  };

  const triggerPersistData = () => {
    let openType = {
      isSaving: true,
    };
    let actionPayload = {
      persistedItineraryItemModalData: inputValues,
      itineraryItemBeingEdited: itineraryItemData,
    };
    persistItineraryItemData(openType, actionPayload);
  };

  console.log(inputValues, itineraryItemDataPassed, openType);

  return (
    <HubModal
      show={true}
      title={`${inputValues.itinerary_item_rid ? 'EDIT' : 'Schedule Event'} ${inputValues.itinerary_item_rid
          ? inputValues.item_name
            ? inputValues.item_name
            : (parentItineraryItemData &&
              parentItineraryItemData.item_header) ||
            (itineraryItemData && itineraryItemData.item_header) ||
            (itineraryData && itineraryData.item_header)
          : ''
        }`}
      buttonTitle="SAVE"
      onClose={closeModal}
      onApproved={handleSubmit}
    >
      <Box
        className="create-adventure-wrapper"
        style={{ paddingBottom: '10px' }}
      >
        <Stack spacing={2}>
          {!inputValues.all_day_flag && (
            <TextBox
              className="text-field required"
              name="item_name"
              label="Event name*"
              variant="outlined"
              fullWidth
              onChange={handleOnChange}
              value={inputValues.item_name}
              size="small"
            />
          )}

          {/* 
            this is where we need to put in data for start date and end date
            itineray item where the plus button was click can be accessed by itineraryItemData i.e itineraryItemData.item_start_date
          */}
          {/* {!inputValues.itinerary_item_rid && (
            <div className="modal-itinerary-item-info">
              <div className="modal-itinerary-item-info-top">
                <div className="modal-itinerary-item-info-section-left">
                  START
                </div>
                <div className="modal-itinerary-item-info-section-mid">
                  {inputValues.all_day_flag
                    ? openType.isTransitioning
                      ? dateFormat(startDate, 'UTC:dddd dd mmm yyyy')
                      : openType.isEdit
                      ? dateFormat(
                          itineraryItemData.item_start_date,
                          'UTC:dddd dd mmm yyyy',
                        )
                      : defaultNewDate
                    : openType.isTransitioning
                    ? parentItineraryItemData
                      ? dateFormat(
                          parentItineraryItemData.item_start_date,
                          'UTC:dddd dd mmm yyyy',
                        )
                      : inputValues.item_start_date
                    : openType.isEdit ||
                      (itineraryItemData &&
                        itineraryItemData.parent_itinerary_item_rid)
                    ? dateFormat(
                        itineraryItemData.item_start_date,
                        'UTC:dddd dd mmm yyyy',
                      )
                    : defaultStartTime}
                </div>
                <div className="modal-itinerary-item-info-section-right">
                  {!inputValues.all_day_flag && (
                    <>
                      {inputValues.all_day_flag === false &&
                      inputValues.item_start_date !== null ? inputValues.item_start_date
                        : startTimeParsed}
                    </>
                  )}
                </div>
              </div>
              <div className="modal-itinerary-item-info-bottom">
                <div className="modal-itinerary-item-info-section-left">
                  END
                </div>
                <div className="modal-itinerary-item-info-section-mid">
                  {inputValues.all_day_flag
                    ? openType.isTransitioning
                      ? dateFormat(endDate, 'UTC:dddd dd mmm yyyy')
                      : openType.isEdit
                      ? dateFormat(
                          itineraryItemData.item_end_date,
                          'UTC:dddd dd mmm yyyy',
                        )
                      : defaultNewDate
                    : openType.isTransitioning
                    ? parentItineraryItemData
                      ? dateFormat(
                          parentItineraryItemData.item_end_date,
                          'UTC:dddd dd mmm yyyy',
                        )
                      : inputValues.item_end_date
                    : openType.isEdit ||
                      (itineraryItemData &&
                        itineraryItemData.parent_itinerary_item_rid)
                    ? dateFormat(
                        itineraryItemData.item_end_date,
                        'UTC:dddd dd mmm yyyy',
                      )
                    : defaultEndTime}
                </div>
                <div className="modal-itinerary-item-info-section-right">
                  {!inputValues.all_day_flag && (
                    <>
                      {inputValues.all_day_flag === false &&
                      inputValues.item_end_date !== null ? inputValues.item_end_date
                        : endTimeParsed}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {(!inputValues.itinerary_item_rid && errors) && (
            <div className="error-message-wrapper">
              <span className="error-message">{errors}</span>
            </div>
          )} */}
          {!inputValues.all_day_flag ? (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  label="Start"
                  value={inputValues.item_start_time}
                  onChange={(v) => onTimeChange(v, 'item_start_time')}
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#273A07',
                          },
                          '&:hover fieldset': {
                            borderColor: '#273A07',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#273A07',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#827700',
                        },
                        '& input': {
                          color: '#827700',
                        },
                      },
                    },
                  }}
                />
                <TimePicker
                  sx={{ '& .MuiFormControl-root': { marginBottom: '20px' } }}
                  label="End"
                  value={inputValues.item_end_time}
                  onChange={(v) => onTimeChange(v, 'item_end_time')}
                  slotProps={{
                    textField: {
                      size: 'small',
                      sx: {
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#273A07',
                          },
                          '&:hover fieldset': {
                            borderColor: '#273A07',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#273A07',
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: '#827700',
                        },
                        '& input': {
                          color: '#827700',
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
              {/* <TimePicker
                spacingTop="10px"
                persistParentData={triggerPersistData}
                startDate={startDate}
                endDate={endDate}
                origin="ITINERARY_ITEM"
              /> */}
            </>
          ) : (
            !openType.isEdit && (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDateRangePicker
                    value={[
                      inputValues.item_start_date,
                      inputValues.item_end_date,
                    ]}
                    onChange={onDateChange}
                    localeText={{ start: 'Start', end: 'End' }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        sx: {
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#273A07',
                            },
                            '&:hover fieldset': {
                              borderColor: '#273A07',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#273A07',
                            },
                          },
                          '& .MuiFormLabel-root': {
                            color: '#827700',
                          },
                          '& input': {
                            color: '#827700',
                          },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
                {/* <DatePicker
                spacingTop="10px"
                persistParentData={triggerPersistData}
                startDate={startDate}
                endDate={endDate}
                origin="ITINERARY_ITEM"
                defaultDate={
                  inputValues.all_day_flag
                    ? moment(
                        openType.isNew || openType.isTransitioning
                          ? itineraryData
                            ? itineraryData.item_end_date
                            : itineraryItemData.item_end_date
                          : itineraryItemData.item_end_date,
                      )
                        .utcOffset(2)
                        .add(openType.isEdit ? 0 : 1, 'days')
                    : parentItineraryItemData.item_start_date
                }
              /> */}
              </>
            )
          )}
          {openType.isEdit && (
            <TextBox
              name="item_description"
              label="Notes & daily record"
              variant="outlined"
              multiline
              minRows={17}
              maxRows={17}
              onChange={handleOnChange}
              value={inputValues.item_description}
              size="small"
              sx={{ mt: 1 }}
            />
          )}
        </Stack>
      </Box>
    </HubModal>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateItineraryItem);
