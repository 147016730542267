import { useQuery } from '@tanstack/react-query';
import { fetchTripDonations } from 'util/api_util/user_api_util';

const useAdventureDonations = (adventureRid) => {
  return useQuery({
    queryKey: ['adventure', adventureRid, 'donations'],
    queryFn: () =>
      fetchTripDonations(adventureRid).then(
        (res) => res.data.adventurePartnerDonations,
      ),
  });
};

export default useAdventureDonations;
