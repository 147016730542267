import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
const ToggleMapStyles = ({ mapStyle, setMapStyle, map }) => {
  const styleObject = {
    mapStyleDefault: 'mapbox://styles/lodestarhubdev/cksaxb7zxcgzj17lyzl0spl1a',
    mapStyleSatellite:
      'mapbox://styles/lodestarhubdev/cktdem0rq19fy18luf27zwigj',
    mapStyleDirections:
      'mapbox://styles/lodestarhubdev/cktdekbuf19dg18pclo506154',
  };
  const handleToggleSelect = useCallback((event) => {
    const { value } = event.target;
    if (mapStyle !== value) {
      setMapStyle(value);
    }
  });
  return (
    <div className="map-box-toggle">
      <Item>
        <RadioButton
          type="radio"
          name="radio"
          value="mapStyleDefault"
          checked={mapStyle === 'mapStyleDefault'}
          onChange={handleToggleSelect}
        />
        <RadioButtonLabel />
        <span className="toggle-span">Default</span>
      </Item>
      <Item>
        <RadioButton
          type="radio"
          name="radio"
          value="mapStyleSatellite"
          checked={mapStyle === 'mapStyleSatellite'}
          onChange={handleToggleSelect}
        />
        <RadioButtonLabel />
        <span className="toggle-span">Satellite</span>
      </Item>
      <Item>
        <RadioButton
          type="radio"
          name="radio"
          value="mapStyleDirections"
          checked={mapStyle === 'mapStyleDirections'}
          onChange={handleToggleSelect}
        />
        <RadioButtonLabel />
        <span className="toggle-span">Directions</span>
      </Item>
    </div>
  );
};
export default ToggleMapStyles;
const Item = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  position: relative;
  flex-direction: row;
`;
const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;

const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  &:hover ~ ${RadioButtonLabel} {
    background: #bebebe;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 9px;
      height: 9px;
      margin: 6px;
      background: #eeeeee;
    }
  }
  ${(props) =>
    props.checked &&
    `
  &:checked + ${RadioButtonLabel} {
    background: #09132b;
    border: 1px solid #09132b;
    &::after{
      content: "";
      display: block;
      border-radius: 50%;
      width: 9px;
      height: 9px;
      margin: 6px;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
      background: white;
    }
  }
  `}
`;
