import React, { ReactNode } from 'react';
import { Toolbar, IconButton, Typography, Box, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TripPlannerActionMenuBase from 'features/trip-planner/components/trip-planner-action-menu-base';
import { MoreVert } from '@material-ui/icons';

interface DrawerTopBarProps {
  onClose: () => void;
  title: string;
  children?: ReactNode;
}

const DrawerTopBar: React.FC<DrawerTopBarProps> = ({
  onClose,
  title,
  children,
}) => {
  return (
    <Toolbar
      variant="dense"
      sx={{
        height: 10,
        minHeight: 35,
        background: 'rgb(39 58 7)',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
        position: 'sticky',
        justifyContent: 'center', // Centers the content
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          flexGrow={1}
          display="flex"
          justifyContent={'center'}
          alignItems="center"
        >
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '1rem', textAlign: 'center' }}
            color="white"
          >
            {title}
          </Typography>
          {children}
        </Box>
        <Tooltip title={'Close'}>
          <IconButton
            edge="end"
            color="inherit"
            variant="contrast"
            onClick={onClose}
            size="small"
            sx={{ borderRadius: '50%' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Toolbar>
  );
};

export default DrawerTopBar;
