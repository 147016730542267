// TODO: remove this list and use server errors
const noRoute = "A route could not be created for this trip"

export const MapboxErrors = {
  NoRoute: {
    message: `${noRoute}: Check all points are routable.`,
    severity: 'warning'
  },
  InvalidInput: {
    message: `${noRoute}: Check all points are valid and distance is not too long.`,
    severity: 'warning'
  },
  NoSegment: {
    message: `${noRoute}: Check all points are routable.`,
    severity: 'warning'
  },
  NoMatch: {
    message: `${noRoute}`,
    severity: 'warning'
  },
  default: {
    message: 'An error has occurred with the map',
    severity: 'error'
  }
}

// Response body code	HTTP status code	Descriptions for MapBox
// Ok	200	Normal case
// NoMatch	200	The input did not produce any matches, or the waypoints requested were not found in the resulting match. features will be an empty array.
// NoSegment	200	No road segment could be matched for one or more coordinates within the supplied radiuses. Check for coordinates that are too far away from a road.
// Not Authorized - No Token	401	No token was used in the query.
// Not Authorized - Invalid Token	401	Check the access token you used in the query.
// Forbidden	403	There may be an issue with your account. Check your Account page for more details.

// In some cases, using an access tokens with URL restrictions can also result in a 403 error. For more information, see our Token management guide.
// TooManyCoordinates	422	There are more than 100 points in the regular request, or more than 50 points for OpenLR input.
// ProfileNotFound	404	Needs to be a valid profile (mapbox/driving, mapbox/driving-traffic, mapbox/walking, or mapbox/cycling).
// InvalidInput	422	message will hold an explanation of the invalid input.