import { useEffect, useRef, useCallback, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import isEqual from 'lodash.isequal';
import { DragDirections, DragTypes, OverlapStatus } from '../item-types';
import { isSameItem, beforeOrAfter, overlapStatus } from '../utils';
import { Box, LinearProgress } from '@mui/material';
import useHoldTimer from 'hooks/use-hold-timer';
import useHoldToDrag from 'hooks/use-hold-to-drag';
import TimedCircularProgress from 'components/timed-circular-progress';
import TimedLinearProgress from 'components/timed-linear-progress';
import { useLongPress } from '@uidotdev/usehooks';

var style = {
  cursor: 'grab',
  position: 'relative',
};

// this is a singleton that helps track only one draggable item (the first clicked)
let clickedRef = null;

function Draggable({
  index,
  type,
  accept,
  groupId,
  direction,
  transparent,
  canCombine,
  clonable,
  data,
  handlers,
  children,
  holdDuration = 300,
}) {
  const [canDrag, setCanDrag] = useState(false);
  const [isPressing, setIsPressing] = useState(false);
  const ref = useRef(null);

  // helps with the ux
  const PRESS_AND_HOLD_DELAY_DURATION = 150; //ms
  const timeoutRef = useRef(null);

  const attrs = useLongPress(
    () => {
      if (clickedRef === ref) {
        setCanDrag(true);
      }
    },
    {
      onStart: (event) => {
        if (clickedRef) return;
        clickedRef = ref;
        // @ts-ignore
        timeoutRef.current = setTimeout(() => {
          setIsPressing(true);
        }, PRESS_AND_HOLD_DELAY_DURATION);
      },
      onFinish: (event) => {
        if (timeoutRef.current !== null) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        setCanDrag(false);
        setIsPressing(false);
        clickedRef = null;
      },
      onCancel: (event) => {
        if (timeoutRef.current !== null) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        setCanDrag(false);
        setIsPressing(false);
        clickedRef = null;
      },
      threshold: holdDuration + PRESS_AND_HOLD_DELAY_DURATION,
    },
  );

  const { addItem, removeItem, reorderItem, combineItem, changeHover } = {
    ...Draggable.defaultProps.handlers,
    ...handlers,
  };

  const handleDrop = useCallback(
    (dragItem, monitor, isDragging = false) => {
      setCanDrag(false);
      setIsPressing(false);
      if (!ref.current) {
        return;
      }

      const hoverItem = { index, groupId, clonable, data };
      if (isSameItem(dragItem, hoverItem) || isEqual(data, dragItem.data)) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Determine mouse position
      const mousePosition = monitor.getClientOffset();

      const overlap = overlapStatus(
        dragItem,
        hoverItem,
        hoverBoundingRect,
        mousePosition,
        direction,
        canCombine,
      );
      const { addItem, combineItem, reorderItem } = handlers;

      switch (overlap) {
        case OverlapStatus.AddItem:
          const after = beforeOrAfter(
            hoverBoundingRect,
            mousePosition,
            direction,
          );
          changeHover({
            event: overlap,
            hoverItem,
            dragItem,
            after,
            hoverIndex: index,
            dragIndex: dragItem.index,
          });
          break;
        case OverlapStatus.CombineItem:
          changeHover({
            event: overlap,
            hoverItem,
            dragItem,
            after: false,
            hoverIndex: index,
            dragIndex: dragItem.index,
          });
          break;
        case OverlapStatus.ReorderItem:
          changeHover({
            event: overlap,
            hoverItem,
            dragItem,
            after: false,
            hoverIndex: index,
            dragIndex: dragItem.index,
          });
          break;
      }
    },
    [
      index,
      groupId,
      clonable,
      direction,
      data,
      canCombine,
      changeHover,
      handlers,
      ref,
    ],
  );

  const [{ handlerId }, drop] = useDrop({
    accept: accept,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(dragItem, monitor) {
      handleDrop(dragItem, monitor, true);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    canDrag: () => canDrag, // Use state to control when dragging is allowed
    item: () => ({
      index,
      groupId,
      clonable,
      data,
      addItem,
      removeItem,
      reorderItem,
      combineItem,
    }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging || transparent ? 0 : 1;

  drag(drop(ref));

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <>
      <div
        ref={ref}
        style={{ ...style, opacity }}
        data-handler-id={handlerId}
        {...attrs}
      >
        {children}
        <Box position="absolute" top={0} left={0} width={1}>
          <TimedLinearProgress seconds={holdDuration} isLoading={isPressing} />
        </Box>
      </div>
    </>
  );
}

Draggable.defaultProps = {
  index: -1,
  type: DragTypes.Default,
  accept: [],
  groupId: -1,
  direction: DragDirections.Horizontal,
  canCombine: false,
  clonable: false,
  transparent: false,
  data: {},
  holdDuration: 500, // Default hold duration in milliseconds
  handlers: {
    addItem: () => {},
    removeItem: () => {},
    reorderItem: () => {},
    combineItem: () => {},
    changeHover: () => {},
  },
};

export default Draggable;
