import React, { useCallback } from 'react';
import SlideButton from './button';
import ItemStandAlone from './item_stand_alone';
import { Droppable } from 'react-beautiful-dnd';
import MiniBlocksClone from '../blocks/blocks_clone';

const RenderBlockSlider = ({
  libraryBlocks,
  adventure_rid,
  handleLevelTraverse,
  displayDetails,
  currAdvDis,
  itinerary_info,
  handleTraverseUp,
  filtered,
  provided,
  filter,
  customId,
  maxZIndex,
  renderClone,
}) => {
  const onWheel = useCallback((e) => {
    e.stopPropagation();
    const container = document.getElementById(
      customId ? customId : 'tripboard-library',
    );
    const containerScrollPosition = document.getElementById(
      customId ? customId : 'tripboard-library',
    ).scrollLeft;
    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
      behaviour: 'smooth',
      passive: false,
    });
  });
  return libraryBlocks || filtered ? (
    <Droppable
      direction="horizontal"
      droppableId={customId ? customId : 'itinerary_adventure_blocks'}
      isCombineEnabled
      type="blocks"
      // mode="virtual"
      // renderClone={renderClone}
    >
      {(provided) => {
        return (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="no-button-slider-blocks-wrapper"
            id={customId ? customId : 'tripboard-library'}
            onWheel={onWheel}
            style={{ zIndex: maxZIndex }}
          >
            {filter === 'Template Lists' || filter === 'Trip Lists'
              ? filtered.map((ele, idx) => {
                  ele = ele.item;
                  return (
                    <ItemStandAlone
                      block={ele}
                      key={ele.block_rid}
                      blockIndex={idx}
                      adventure_rid={adventure_rid}
                      handleLevelTraverse={handleLevelTraverse}
                      displayDetails={displayDetails}
                      currAdvDis={currAdvDis}
                      provided={provided}
                      itinerary_info={itinerary_info}
                      handleTraverseUp={handleTraverseUp}
                    ></ItemStandAlone>
                  );
                })
              : filtered.map((ele, idx) => {
                  ele = ele.item;
                  return (
                    <ItemStandAlone
                      block={ele}
                      key={ele.block_rid}
                      blockIndex={idx}
                      adventure_rid={adventure_rid}
                      handleLevelTraverse={handleLevelTraverse}
                      displayDetails={displayDetails}
                      currAdvDis={currAdvDis}
                      provided={provided}
                      itinerary_info={itinerary_info}
                      handleTraverseUp={handleTraverseUp}
                    ></ItemStandAlone>
                  );
                })}
          </div>
        );
      }}
    </Droppable>
  ) : (
    <div />
  );
};

export default RenderBlockSlider;
