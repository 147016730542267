import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { merge } from 'lodash';
import Button from '@mui/material/Button';
import {
  svgCopyToTripBoard,
  svgEdit,
  svgInviteAccepted,
  LocationIcon,
} from 'components/svg_module';
import { setMapUiDetailShow } from '../../../../../actions/ui_actions';
import svgLibrary from 'components/svg_module/svg_library/library/functionLibrary';
import * as BlockApiUtil from '../../../../../util/api_util/block_api_util';
import {
  isMediaFile,
  isVideoFile,
  isImageFile,
} from '../../../../../util/helpers/file-helper';
import { convertToGeolocationString } from '../../../../../util/helpers/geolocation-helper';
import PopupContainer from './PopupContainer';
import Carousel, { CarouselCard } from '../../../../carousel';
import HubButton from '../../../../hub-button';

const useStyles = makeStyles((theme) => ({
  spanSizeWithOutFont: {
    fontFamily: 'Soleil',
    fontStyle: 'normal',
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  crsRoot: {
    width: '100%',
    height: '80px',
    '& .crs-body': {
      height: '100%',
    },
    '& .crs-item': {
      height: '100%',
    },
    '& .crs-dot': {
      width: '4px',
      height: '4px',
    },
    '& .crs-arrow-left svg, & .crs-arrow-right svg': {
      width: '6px',
      height: '10px',
    },
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  blockName: {
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#273A07',
    marginTop: '5px',
  },
  blockDescription: {
    fontWeight: '400',
    fontSize: '12px',
    color: '#273A07',
  },
  blockItemInfo: {
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#827700',
    width: '95%',
    overflowWrap: 'break-word',
  },
  iconRoot: {
    minWidth: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blockTypeIcon: {
    width: '15px',
    height: '15px',
    color: '#273A07',
    fill: '#273A07',
  },
  iconText: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  btnEdit: {
    color: 'white',
    fontSize: '10px',
    background: '#B34104',
    borderRadius: '2px',
    borderWidth: '0px',
    padding: '2px',
    minWidth: '50px',
    width: 'fit-content',
    '&:hover, &:focus': {
      background: '#B34104',
      borderWidth: '0px',
    },
  },
  editBtnIcon: {
    width: '10px',
    height: '10px',
    color: 'white',
    fill: 'white',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ShowLibraryLocationPoint = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    userInfo,
    libraryBlocks,
    openBlockModal,
    markerData,
    copyToTripBoard,
    itineraryInfo,
    locationBlocks,
    fetchLocationBlocks,

    libraryAdventureSelected,
  } = props;
  const { marker, coords, block, store, idx } = markerData;

  const [fullViewMode, setFullViewMode] = useState(false);
  const { account_rid } = userInfo;
  const [currentBlock, setCurrentBlock] = useState(null);
  const [blockFiles, setBlockFiles] = useState([]);

  useEffect(() => {
    const blockRid = block.block_rid;
    if (!blockRid) return;

    BlockApiUtil.fetchBlockFiles(blockRid).then((res) => {
      const files = res.data.blockFiles;
      const mediaFiles = files.filter((file) =>
        isMediaFile(file.file_location),
      );
      setBlockFiles(mediaFiles);
    });
  }, [block]);

  useEffect(() => {
    if (block && libraryBlocks) {
      const filterFirst = (block_rid) =>
        new Promise((resolve, reject) => {
          let filtered = libraryBlocks.filter((ele, idx) => {
            ele['idx'] = idx;
            return ele.block_rid === block_rid;
          });
          setCurrentBlock(filtered[0]);
          if (filtered.length === 1) {
            resolve('success');
          } else {
            resolve('fail');
          }
        });

      axios
        .get(
          `/api/b/block-details/${block.block_rid}/${libraryAdventureSelected}`,
        )
        .then((res) => {
          if (res.data.blockDetails.length === 0) marker.remove();
          setCurrentBlock(res.data.blockDetails[0]);
        });
    }
  }, [libraryBlocks]);

  const callBackAfterInsertingBlock = async (marker) => {
    await fetchLocationBlocks({
      account_rid: userInfo.account_rid,
      adventure_rid: null,
      itinerary_rid: null,
    });
    let popup = marker.getPopup();
    let domElementPlaceHolder = document.createElement('div');
    ReactDOM.render(
      <ShowLibraryLocationPoint
        type="libraryLocation"
        block={block}
        {...props}
      />,
      domElementPlaceHolder,
    );
    popup.setDOMContent(domElementPlaceHolder);
  };

  const editHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    let openType = {
      isEditAndView: true,
    };

    block['idx'] = 0;
    let newLocations = marker.getLngLat();

    const { lat, lng } = newLocations;
    currentBlock.location_point = { lat, lng };
    currentBlock.adventure_rid_exist_flag = libraryAdventureSelected;
    let uiUpdatePayload = () => callBackAfterInsertingBlock(marker);
    openBlockModal(openType, '15a', uiUpdatePayload, currentBlock, userInfo);
  });

  const onClose = () => {
    marker.togglePopup();
  };

  const isMediaExist = blockFiles.length > 0;
  const getSvgIconFromLibrary = svgLibrary[currentBlock?.ui_image_location];
  const shouldGenerateSvgBlockTypeIcon =
    typeof getSvgIconFromLibrary === 'function';
  const SvgBlockTypeIcon = shouldGenerateSvgBlockTypeIcon
    ? getSvgIconFromLibrary('#273A07', '', classes.blockTypeIcon)
    : null;
  const locationPoint = currentBlock?.location_point || { lat: 0, lng: 0 };
  return (
    <div
      className={classes.blockName}
      style={{
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        cursor: 'pointer',
      }}
      onClick={() => {
        dispatch(setMapUiDetailShow(currentBlock));
      }}
    >
      {currentBlock?.block_name}
    </div>
  );
  return null;
};
export default ShowLibraryLocationPoint;
