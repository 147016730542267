import {
  DRAWERS,
  useDrawerContext,
} from 'features/adventure/hooks/use-drawer-context';

function useGlobalLibraryDrawerButton() {
  const { toggleDrawer: toggleGlobalDrawer, openDrawer } = useDrawerContext();

  const toggleDrawer = () => {
    console.log('toggling');
    toggleGlobalDrawer(DRAWERS.LIBRARY);
  };

  const isOpen = openDrawer === DRAWERS.LIBRARY;
  console.log(isOpen);

  return { toggleDrawer, isOpen };
}

export default useGlobalLibraryDrawerButton;
