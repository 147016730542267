import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { convertTime } from '../../../util/helpers/time_funcs';

const ItinerariesSingleItemPreview = ({ item }) => {
  const {
    item_level,
    item_header,
    item_start_date,
    item_start_time,
    item_end_time,
    itinerary_item_rid,
    item_description,
  } = item;

  return (
    <div className="itinerary-day">
      <div className="header">
        <div className="info">
          <div className="hr-bar" />
          <div className="day-title">
            <div className="itineraryBottomDropdown">
              <div className="itinerary_adventure_block_bottom_icon_dropdown">
                <div
                  className="trip-board-list-collapsible-icon-plus-container"
                  data-tip
                  data-for="dailySetup"
                >
                  <EditIcon />
                </div>
              </div>
            </div>
            <span className="text">{`${item_header} - `}</span>
            <span className="date-time">
              {item_level === 1
                ? `${moment(item_start_date)
                    .utcOffset(2)
                    .format('ddd D, MMM YYYY')}`
                : `${convertTime(item_start_time)} - ${convertTime(
                    item_end_time,
                  )}`}
            </span>
          </div>
          <div className="hr-bar" />
        </div>
      </div>
    </div>
  );
};

export default ItinerariesSingleItemPreview;
