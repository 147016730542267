import { useSelector } from 'react-redux';

/**
 * @typedef {Object} ItineraryInfo
 * @property {number} adventure_rid - The unique identifier for the adventure.
 * @property {string} carbon_produced - The amount of carbon produced (in units) for this itinerary.
 * @property {string} duration - The duration of the adventure, e.g., "4 Days".
 * @property {string} end_date - The ISO 8601 formatted end date of the itinerary.
 * @property {?number} engagement - Engagement metric, if available (null if not applicable).
 * @property {?number} explorer_vote_down - The number of explorer votes down (null if not applicable).
 * @property {?number} explorer_vote_rid - The unique identifier for the explorer's vote (null if not applicable).
 * @property {?number} explorer_vote_up - The number of explorer votes up (null if not applicable).
 * @property {?string} itinerary_description - A description of the itinerary (null if not provided).
 * @property {string} itinerary_distance - The distance covered in the itinerary, in units (e.g., kilometers).
 * @property {number} itinerary_duration - The duration of the itinerary in days.
 * @property {number} itinerary_rid - The unique identifier for the itinerary.
 * @property {Object} itinerary_route - The geographical route of the itinerary.
 * @property {string} itinerary_route.type - The type of the route, e.g., "LineString".
 * @property {Array<Array<number>>} itinerary_route.coordinates - The coordinates of the route as an array of latitude/longitude pairs.
 * @property {string} itinerary_title - The title of the itinerary.
 * @property {string} northern_hemisphere_season - The season in the northern hemisphere during this itinerary.
 * @property {boolean} selected_flag - Indicates if the itinerary is selected.
 * @property {string} southern_hemisphere_season - The season in the southern hemisphere during this itinerary.
 * @property {string} start_date - The ISO 8601 formatted start date of the itinerary.
 * @property {string} status - The current status of the itinerary, e.g., "past".
 * @property {string} status_detail - Additional details about the status, e.g., "completed".
 * @property {number} status_sort - A numerical representation of the status for sorting purposes.
 * @property {number} transpotation_type_rid - The unique identifier for the type of transportation.
 * @property {?number} vote_count_down - The total number of downvotes for the itinerary (null if not applicable).
 * @property {?number} vote_count_total - The total number of votes for the itinerary (null if not applicable).
 * @property {?number} vote_count_up - The total number of upvotes for the itinerary (null if not applicable).
 * @property {?boolean} vote_flag - Indicates if the itinerary has been voted on (null if not applicable).
 * @property {?number} vote_percent_down - The percentage of downvotes (null if not applicable).
 * @property {?number} vote_percent_up - The percentage of upvotes (null if not applicable).
 */

/**
 * @typedef {Object} ItineraryStore
 * @prop {Object} itineraryDetails
 * @prop {ItineraryInfo} itinerary_info
 * @prop {Object} locationBlocks
 * @prop {Object} adventure_blocks
 */

/**
 *
 * @returns {ItineraryStore}
 */
const useCurrentItinerary = () => {
  return useSelector((state) => state.entities?.itinerary ?? null);
};

export default useCurrentItinerary;
