import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';
import useCurrentItinerary from 'features/itinerary/hooks/use-current-itinerary';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useMaskingHooks from 'util/custom_hooks/maskUrl';

const useTripPlannerButton = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { maskURL } = useMaskingHooks();

  const { itinerary_info: { itinerary_rid } = {} } = useCurrentItinerary();
  const { adventure_rid } = useCurrentAdventure();

  const handleClick = (e) => {
    setLoading(true); // prevent button spam
    e.preventDefault();
    e.stopPropagation();
    history.push(
      `/hub/itinerary/${maskURL(`${adventure_rid}`)}/${maskURL(
        `${itinerary_rid}`,
      )}`,
    );
  };
  return { handleClick, loading };
};

export default useTripPlannerButton;
