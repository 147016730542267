import React from 'react';
import useCurrentAdventure from './use-current-adventure';
import useAdventureFriends from 'features/friends/hooks/use-adventure-friends';
import useAdventureAttendees from './use-adventure-attendees';
import useUserInfo from 'features/user/hooks/use-user-info';

export const TRIP_ROLES = {
  HOST: 3,
  GUEST: 2,
  VIEWER: 1,
};

const LOADING_ROLES_VAL = -1;

function useAdventurePermissions() {
  // const adventure = useCurrent();
  const { data: attendees, isLoading } = useAdventureAttendees();
  const { explorer_rid } = useUserInfo();

  const getRole = () => {
    console.log('ATTENDEES', attendees, explorer_rid);
    return (
      attendees?.find((attendee) => attendee.explorer_rid == explorer_rid)
        .friend_role_rid || null
    );
  };

  const role = isLoading ? LOADING_ROLES_VAL : getRole();
  if (!role) throw new Error('failed to get role');

  // create roles
  const canEditTrip = role === TRIP_ROLES.HOST;
  const canCopyTrip = role === TRIP_ROLES.HOST || role === TRIP_ROLES.GUEST;

  return { canEditTrip };
}

export default useAdventurePermissions;
