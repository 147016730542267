import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { merge } from 'lodash';
import {
  fetchItineraryDetails,
  moveItineraryBlock,
  moveItineraryBlockBetweenItems,
  deleteItineraryBlockItem,
  editItineraryItem,
  editItinerary,
  deleteItinerary,
  upsertItineraryItem,
  deleteItineraryItem,
  fetchItineraryVotes,
  fetchItineraryComments,
  upsertItineraryVotes,
  deleteItineraryVotes,
  flushItineraryData,
} from '../../../actions/itinerary_actions';
import {
  fetchAllBlocksItinerary,
  moveToAdventureBlock,
  upsertBlock,
  fetchBlocksDynamic,
  nestBlock,
  unnestBlock,
  fetchBoardBlocks,
  drillDownIntoAndOutOfItineraryBlock,
  copyToTripBoard,
  updateAdventureBlock,
} from '../../../actions/block_actions';

function useReorderItineraryItem() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.session.user?.userInfo[0]);

  return useCallback((from, to) => {
    const item = merge({}, from);
    const fromStartTime = from.item_start_time;
    const fromEndTime = from.item_end_time;
    const toStartDay = to.item_start_date.split('T')[0];
    const toEndDay = to.item_end_date.split('T')[0];
    const newStartTime = `${toStartDay} ${fromStartTime}`;
    const newEndTime = `${toEndDay} ${fromEndTime}`;

    item.item_start_date = newStartTime;
    item.item_end_date = newEndTime;
    if (from.parent_itinerary_item_rid) {
      const newParentId = to.parent_itinerary_item_rid || to.itinerary_item_rid;
      item.parent_itinerary_item_rid = newParentId;
    }
    const actionType = { moveItineraryItemOnly: true };

    return dispatch(upsertItineraryItem(item, userInfo, actionType));
  }, []);
}

function useReorderBlock() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.session.user?.account_rid);
  const explorerId = useSelector((state) => state.session.user?.explorer_rid);
  const itinerary_rid = useSelector(
    (state) => state.entities?.itinerary.itinerary_info?.itinerary_rid,
  );

  return useCallback((from, to, fromIndex, toIndex) => {
    console.log(from, `from`);
    const itItemId = from.itinerary_item_rid;
    const toSortOrder = toIndex+1;

    return dispatch(
      moveItineraryBlockBetweenItems(
        itItemId,
        from,
        accountId,
        explorerId,
        toSortOrder,
      ),
    );
  }, []);
}

function setBlockSortOrder() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.session.user?.account_rid);
  const explorerId = useSelector((state) => state.session.user?.explorer_rid);
  const itinerary_rid = useSelector(
    (state) => state.entities?.itinerary.itinerary_info?.itinerary_rid,
  );
  return useCallback((item, order) => {
    const itItemId = item.itinerary_item_rid;

    return dispatch(
      moveItineraryBlockBetweenItems(
        itItemId,
        item,
        accountId,
        explorerId,
        order,
      ),
    );
  }, []);
}

function useRefreshItinerary() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.session.user?.account_rid);
  const explorerId = useSelector((state) => state.session.user?.explorer_rid);
  const itinerary_rid = useSelector(
    (state) => state.entities?.itinerary.itinerary_info?.itinerary_rid,
  );
  return useCallback(() => {
    return dispatch(
      fetchItineraryDetails(
        itinerary_rid,
        accountId,
        explorerId
      ),
    );
  }, []);
}

function useMoveBlockAroundItems() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.session.user?.account_rid);
  const explorerId = useSelector((state) => state.session.user?.explorer_rid);
  const itinerary_rid = useSelector(
    (state) => state.entities?.itinerary.itinerary_info?.itinerary_rid,
  );
  return useCallback((item, itItemId, sortOrder) => {
    return dispatch(
      moveItineraryBlockBetweenItems(
        itItemId,
        item,
        accountId,
        explorerId,
        sortOrder,
      ),
    );
  }, []);
}

function useAddBlockFromBoard() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.session.user?.account_rid);
  const explorerId = useSelector((state) => state.session.user?.explorer_rid);
  const itinerary_rid = useSelector(
    (state) => state.entities.itinerary.itinerary_info.itinerary_rid,
  );

  return useCallback((item, itItemId, sortOrder) => {
    return dispatch(
      moveItineraryBlock(
        itItemId,
        item.block_rid,
        accountId,
        explorerId,
        sortOrder,
        itinerary_rid,
      ),
    );
  }, []);
}

function useCombineBlock() {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.session.user?.account_rid);
  const explorerId = useSelector((state) => state.session.user?.explorer_rid);

  return useCallback((from, to) => {
    const item = merge({}, from);
    item.parent_block_rid = to.block_rid;
    item.explorer_rid = explorerId;
    item.ui_color = to.ui_color;

    return dispatch(nestBlock(accountId, item));
  }, []);
}

export {
  useReorderItineraryItem,
  useReorderBlock,
  useMoveBlockAroundItems,
  useAddBlockFromBoard,
  useCombineBlock,
  setBlockSortOrder,
  useRefreshItinerary,
};
