import styled from "styled-components"
import withScrolling, {
  createVerticalStrength,
  createHorizontalStrength
} from "react-dnd-scrolling"
import { DragDirections } from "../item-types"

const Container = styled(withScrolling('div'))`
  display: flex;
  flex-direction: ${({ direction }) => direction === DragDirections.Horizontal ? 'row' : 'column'};
  gap: ${({ gap }) => `${gap}px` || '10px'};
  align-items:${({ direction }) => direction === DragDirections.Horizontal ? 'flex-end' : ''};
  overflow-x: ${({ direction }) => direction === DragDirections.Horizontal ? 'auto' : 'hidden'};
  overflow-y: ${({ direction }) => direction === DragDirections.Vertical ? 'auto' : 'hidden'};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  user-select: none;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #8C867C55;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #8C867C99;
  }
`

const vStrength = createVerticalStrength(80);
const hStrength = createHorizontalStrength(80);

function ScrollContainer({ children, ...props }) {
  return (
    <Container
      verticalStrength={vStrength}
      horizontalStrength={hStrength}
      {...props}
    >
      {children}
    </Container>
  )
}

export {
  ScrollContainer
}