import { useEffect, useState } from 'react';
import { fetchBlockDetails } from '../api_util/block_api_util';

export const useBlockDetails = (block_rid) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [blockDetails, setBlockDetails] = useState();

  useEffect(() => {
    setLoading(true);
    fetchBlockDetails(block_rid)
      .then((res) => {
        if (res.status != 200)
          throw new Error(`Fetching Block Data Error: ${res.status}`);
        if (!res.data?.blockDetails) throw new Error('No Block Data');
        if (res.data.length === 0) throw new Error('No Block Data');
        setBlockDetails(res.data.blockDetails[0]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErrors((errors) => [...errors, 'Error fetching block details']);
      });
  }, [block_rid]);

  return { loading, blockDetails, errors };
};
