import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  svgCopyToTripBoard,
  svgEdit,
  svgInviteAccepted,
} from 'components/svg_module';
import { merge } from 'lodash';
import axios from 'axios';
import ReactDOM from 'react-dom';

const ShowTripLocationPoint = (props) => {
  const {
    userInfo,
    adventureBlocks,
    openBlockModal,
    copyToTripBoard,
    itineraryInfo,
    locationBlocks,
    markerData,
    mapFilter,
    fetchLocationBlocks,
    user_adventure_friend_rid,
  } = props;
  const { marker, coords, block, store, idx } = markerData;
  const [copiedFlag, setCopiedFlag] = useState(false);
  const [fullViewMode, setFullViewMode] = useState(false);
  const { account_rid } = userInfo;
  let ownFlag = block.account_rid === account_rid ? true : false;
  const [currentBlock, setCurrentBlock] = useState(null);
  const filterRef = useRef();
  useEffect(() => {
    if (block) {
      axios.get(`/api/b/block-details/${block.block_rid}/null`).then((res) => {
        if (res.data.blockDetails.length === 0) marker.remove();
        let blockProfileImage = block.ui_image_location;
        let blockMerged = merge(block, res.data.blockDetails[0]);
        blockMerged.profile_image_location = blockProfileImage;
        setCurrentBlock(blockMerged);
      });
    }
  }, [adventureBlocks, locationBlocks]);
  const callBackAfterInsertingBlock = async (marker) => {
    let returnedData;
    if (mapFilter === 'itineraryLocationBlocks') {
      await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: itineraryInfo.itinerary_rid,
      });
    } else if (mapFilter === 'allTripBlocks') {
      await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: null,
      });
    } else if (mapFilter === 'myRoutes') {
      fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: itineraryInfo.itinerary_rid,
        direction_flag: true,
        my_routes: true,
        adventure_friend_rid: user_adventure_friend_rid,
      });
    } else {
      await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: itineraryInfo.itinerary_rid,
        direction_flag: true,
      });
    }
    let popup = marker.getPopup();
    let domElementPlaceHolder = document.createElement('div');
    ReactDOM.render(
      <ShowTripLocationPoint type="tripLocation" block={block} {...props} />,
      domElementPlaceHolder,
    );
    popup.setDOMContent(domElementPlaceHolder);
  };
  const editHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    let openType = {
      isEditAndView: true,
    };

    block['idx'] = 0;
    let newLocations = marker.getLngLat();

    const { lat, lng } = newLocations;
    // actionPayload.location_point = { lat, lng };

    currentBlock.location_point = { lat, lng };
    let uiUpdatePayload = () => callBackAfterInsertingBlock(marker);
    openBlockModal(openType, '17a', uiUpdatePayload, currentBlock, userInfo);
  });
  const renderBlockInformation = () => {
    if (mapFilter === 'allTripBlocks') {
      return (
        <span className="trip-location-block-pop-up--block-name">
          {currentBlock.block_name}
        </span>
      );
    } else {
      return (
        <>
          <span className="trip-location-block-pop-up--itinerary-item-header">
            {currentBlock.itinerary_item_header}
          </span>
          <span className="trip-location-block-pop-up--block-name">
            {currentBlock.block_name}
          </span>
        </>
      );
    }
  };
  return (
    <div
      className={`existing-trip-location-popup-wrapper existing-trip-location-popup-wrapper--regular ${fullViewMode && 'existing-trip-location-popup-wrapper--full'
        }`}
      onClick={(e) => {
        setFullViewMode(!fullViewMode);
      }}
    >
      <div className="existing-trip-location-popup existing-trip-location-popup--top">
        <button className="edit-location-block-button" onClick={editHandler}>
          {svgEdit(
            '',
            'existing-location-popup',
            'existing-location-popup-outer',
          )}
        </button>
      </div>
      <div className="existing-trip-location-popup existing-trip-location-popup--middle">
        {/* <span>{block</span> */}
        {/*  this is where the the popup has to conditionally show different information depending on the fitlers */}
        {currentBlock && renderBlockInformation()}
      </div>

      <div className="existing-trip-location-popup existing-trip-location-popup--down">
        {!ownFlag && (
          <button
            className="copy-to-library-button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let passOver = merge({}, currentBlock);
              let returnType = {
                isLibraryBlock: true,
                isNestedLibraryBlock:
                  currentBlock.block_level > 1 ? true : false,
              };
              // passOver.account_rid = userInfo.account_rid;
              passOver.parent_block_rid = null;
              let _actionPayload = {
                adventureRid: null,
                parentBlockRid:
                  currentBlock.block_level > 1
                    ? currentBlock.parent_block_rid
                    : null,
              };
              passOver.copy_to_account_rid = userInfo.account_rid;
              copyToTripBoard(userInfo, passOver, returnType, _actionPayload);
              setCopiedFlag(true);
            }}
            disabled={copiedFlag}
          >
            {!copiedFlag
              ? svgCopyToTripBoard(
                '',
                'existing-location-popup',
                'existing-location-popup-outer',
              )
              : svgInviteAccepted(
                '',
                'mini-blk-hierarchy',
                'mini-blk-hierarchy-outer adventure-included-svg-outer',
              )}
          </button>
        )}
        {!ownFlag && currentBlock && (
          <img
            src={currentBlock.profile_image_location}
            className="existing-profile-location-profile-iamge"
          />
        )}
      </div>
    </div>
  );
};

export default ShowTripLocationPoint;
