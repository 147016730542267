import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@mui/material';
const theme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        color: '#273A07',
        backgroundColor: '#ffa500',
      },
    },
  },
});
const HubTooltip = (props) => {
  const { children } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <Tooltip title={props.title} placement={props.placement}>
        <IconButton size="small">{children}</IconButton>
      </Tooltip>
    </MuiThemeProvider>
  );
};

export default HubTooltip;
