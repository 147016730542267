import { openBlockModal } from 'actions/modal_actions';
import {
  deleteBlock,
  copyBlock,
  voteOnBlock,
  removeVote,
  fetchBlocksDynamic,
  deleteBlockFile,
  fetchBlockList,
  fetchBoardBlocks,
  drillDownIntoAndOutOfItineraryBlock,
  upsertBlock,
  voteOnAdventureBlock,
  removeAdventureBlockVote,
  copyToTripBoard,
  fetchTemplateBlocks,
  copyTemplateBlock,
} from 'actions/block_actions';
import { fetchLocationBlocks } from 'actions/itinerary_actions';
import { deleteAdventureBlock } from 'actions/adventure_actions';
import {
  openBlockFileModal,
  openDeleteModal,
  openCheckListModal,
  openFolderModal,
} from 'actions/modal_actions';

export const mapDispatchToProps = (dispatch) => {
  return {
    openBlockModal: (
      openType,
      activityModalTypeRid,
      uiUpdatePayload,
      blockPayload,
      userInfo,
    ) =>
      dispatch(
        openBlockModal(
          openType,
          activityModalTypeRid,
          uiUpdatePayload,
          blockPayload,
          userInfo,
        ),
      ),
    openFolderModal: (
      editView,
      block_rid,
      openBLockModal,
      currentAdventure,
      tabToDisplay,
      itineraryData,
      userInfo,
    ) =>
      dispatch(
        openFolderModal(
          editView,
          block_rid,
          openBLockModal,
          currentAdventure,
          tabToDisplay,
          itineraryData,
          userInfo,
        ),
      ),
    deleteBlock: (data, userInfo, blockType, returnActionPayload) =>
      dispatch(deleteBlock(data, userInfo, blockType, returnActionPayload)),
    copyBlock: (isAdventure, adventureRid, accountRid, block, userInfo) =>
      dispatch(
        copyBlock(isAdventure, adventureRid, accountRid, block, userInfo),
      ),
    voteOnBlock: (
      data,
      itineraryRid,
      accountRid,
      explorerRid,
      adventure_info,
    ) =>
      dispatch(
        voteOnBlock(
          data,
          itineraryRid,
          accountRid,
          explorerRid,
          adventure_info,
        ),
      ),
    fetchLocationBlocks: (data) => dispatch(fetchLocationBlocks(data)),
    fetchBlocksDynamic: (account_rid, data) =>
      dispatch(fetchBlocksDynamic(account_rid, data)),
    removeVote: (
      explorerVoteRid,
      explorerRid,
      itineraryBlockRid,
      itineraryRid,
      accountRid,
      adventure_info,
    ) =>
      dispatch(
        removeVote(
          explorerVoteRid,
          explorerRid,
          itineraryBlockRid,
          itineraryRid,
          accountRid,
          adventure_info,
        ),
      ),
    deleteBlockFile: (blockFileRid, blockRid) =>
      dispatch(deleteBlockFile(blockFileRid, blockRid)),
    openBlockFileModal: (editView, data) =>
      dispatch(openBlockFileModal(editView, data)),

    deleteAdventureBlock: (adventure_block_rid, adventure_rid, account_rid) =>
      dispatch(
        deleteAdventureBlock(adventure_block_rid, adventure_rid, account_rid),
      ),
    openDeleteModal: (data, func) => dispatch(openDeleteModal(data, func)),
    fetchBlockList: (block_rid, adventure_block_rid, userInfo) =>
      dispatch(fetchBlockList(block_rid, adventure_block_rid, userInfo)),
    fetchBoardBlocks: (userInfo, data, origin, isNested) =>
      dispatch(fetchBoardBlocks(userInfo, data, origin, isNested)),
    drillDownIntoAndOutOfItineraryBlock: (data, userInfo, destination) =>
      dispatch(
        drillDownIntoAndOutOfItineraryBlock(data, userInfo, destination),
      ),
    voteOnAdventureBlock: (
      data,
      userInfo,
      origin,
      returnActionPayload,
      itinerary_rid,
    ) =>
      dispatch(
        voteOnAdventureBlock(
          data,
          userInfo,
          origin,
          returnActionPayload,
          itinerary_rid,
        ),
      ),
    removeAdventureBlockVote: (
      adventure_block_rid,
      adventure_block_vote_rid,
      userInfo,
      origin,
      returnActionPayload,
      itinerary_rid,
    ) =>
      dispatch(
        removeAdventureBlockVote(
          adventure_block_rid,
          adventure_block_vote_rid,
          userInfo,
          origin,
          returnActionPayload,
          itinerary_rid,
        ),
      ),
    openCheckListModal: (openType, blockType, actionPayload, data, userInfo) =>
      dispatch(
        openCheckListModal(openType, blockType, actionPayload, data, userInfo),
      ),
    upsertBlock: (userInfo, data, blockType, returnActionPayload) =>
      dispatch(upsertBlock(userInfo, data, blockType, returnActionPayload)),
    copyToTripBoard: (data, userInfo, origin, returnActionPayload) =>
      dispatch(copyToTripBoard(data, userInfo, origin, returnActionPayload)),
    fetchTemplateBlocks: (parentBlockRid, type) =>
      dispatch(fetchTemplateBlocks(parentBlockRid, type)),
    copyTemplateBlock: (data, userInfo) =>
      dispatch(copyTemplateBlock(data, userInfo)),
  };
};

export const mapStateToProps = (state) => {
  let accountRid = state.session.user.account_rid
    ? state.session.user.account_rid
    : null;
  let userInfo = state.session.user ? state.session.user.userInfo[0] : null;
  let adventureFriends = state.entities.social.adventureFriends
    ? state.entities.social.adventureFriends
    : null;

  return {
    accountRid,
    userInfo,
    adventureFriends,
  };
};
