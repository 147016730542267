import { LicenseInfo } from '@mui/x-license-pro';
import dotenv from 'dotenv';
import React, { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Route, Switch, useHistory } from 'react-router-dom';
import ErrorBoundary from './pages/pwa_hub/error_bounday/error_boundary';
import { AuthRoute, ProtectedRoute } from './util/api_util/route_util';
// import PlaceHolder from "./placeholder"

//modal

import SignUpSuccess from 'pages/public_home_page/sign-up-success';
import Modal from './components/global_modal';
import ModalV2 from './components/global_modal/index.v2';
import Header from './pages/public_home_page/header';
import {
  AccountCancelConfirmed,
  AccountCancelSubscription,
  AccountCancelSubscriptionReason,
  AccountChangePassword,
  AccountCheckoutSubscription,
  AccountInfo,
  AccountManageSubscription,
  AccountMembers,
  AccountMenu,
  AccountMyImpact,
  AccountPauseConfirmed,
  AccountPauseOptions,
  AccountPaymentSuccessful,
  AccountProfile,
  AccountSubscription,
} from './pages/pwa_hub/account';
import Adventure from './pages/pwa_hub/adventure';
import DashBoardTesting from './pages/pwa_hub/dashboard';
// import SingUpPage from 'pages/public_home_page/sign-up';
import VerifyEmail from 'pages/public_home_page/email-verified';
import ResetPassword from 'pages/public_home_page/enter-password-reset';
import LogIn from 'pages/public_home_page/log-in';
import EmailResetPassword from 'pages/public_home_page/request-password-reset';
import ResendEmail from 'pages/public_home_page/request-verification-email';
import SharedSignUp from './pages/pwa_hub/share_via_qr';
import SharedSignUpFree from './pages/pwa_hub/share_via_qr/free_promo';
// email stuff ------------
import Unsubscribed from 'pages/public_home_page/unsubscribe';
import BottomNav from './components/modules/bottom-nav';
//
// notification stuff
import NotificationBadgesWrapper from 'components/global_modules/notification_badges';
//
import NoneFound from './pages/404';

//-----------
import { CircularProgress } from '@mui/material';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query/build/legacy';
import { reloadLazy, withTracker } from 'components/global_modules';
import SignUp from './pages/pwa_hub/sign_up';
import PlannersSpeedDialer from 'features/planners-speed-dialer';
dotenv.config();
LicenseInfo.setLicenseKey(
  'a8bd18a411ce1dc63589604ace87c1daTz02NjA3NCxFPTE3MTUyNjQxMzk4NjAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);
//react
// const Modal = React.lazy(() => import('./global_modal'));
// const HubLogIn = React.lazy(() =>
//   reloadLazy(() => import('./pages/pwa_hub/log-in')),
// );
// const HubHome = React.lazy(() =>
//   reloadLazy(() => import('./pages/pwa_hub/home')),
// );
const HubAdventures = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/my_adventures')),
);
// const AdventureSelected = React.lazy(() =>
//   reloadLazy(() => import('./pages/pwa_hub/adventure_selected')),
// );
const FriendsPage = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/friends')),
);
// const ConnectPage = React.lazy(() =>
//   reloadLazy(() => import('./pages/pwa_hub/connect')),
// );
const InvitedSignUp = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/invited_sign_up')),
);
const HubSignUp = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/sign_up')),
);
const HubItinerary = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/itineraries')),
);
const HubItineraryShared = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/itineraries_shared')),
);
const ComingSoon = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/coming_soon')),
);
const GettingStartedTextMenu = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/getting_started/text_menu')),
);
const GettingStartedVideoPlayer = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/getting_started/video_player')),
);
const HubPlaceholder = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/place_holder')),
);
const HubLibraryV2 = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/library_v2')),
);
const HubImpact = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/impact')),
);
const HubCommunity = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/community')),
);
const HubAmbassadorCodes = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/ambassador_codes')),
);
const HubPartnerPromotionCodes = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/partner_codes')),
);

const HubLinkedApps = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/linked_apps')),
);
const HubDownloads = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/downloads')),
);
const HubInvitations = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/invitations')),
);
const HubAdventureShared = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/adventure_shared')),
);
const HubUserProfile = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/user_profile')),
);
const HubPayPalCheckout = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/paypal_checkout')),
);
const HubImpactSelect = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/impact_select_partner')),
);
const HubViewPartner = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/view_partner')),
);
const HubCalculateImpact = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/impact_amount_calculator')),
);

const MyImpactSummary = React.lazy(() =>
  reloadLazy(() => import('./pages/pwa_hub/impact_summary')),
);

// const TripCard = React.lazy(() =>
//   reloadLazy(() => import('./pages/pwa_hub/new-feature')),
// );

const cookieStorage = {
  getItem: (key) => {
    const cookies = document.cookie
      .split(';')
      .map((cookie) => cookie.split('='))
      .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
    return cookies[key];
  },
  setItem: (key, value) => {
    document.cookie = `${key}=${value}`;
  },
};
const storageType = cookieStorage;
const consentPropertyName = 'jdc_consent';

// const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
const saveToStorage = () => storageType.setItem(consentPropertyName, true);
const loading = (
  <div className="suspense-wrapper">
    {/* <img src="/hub_svg/lodestar_logo.png" className="modal-loader-icon" /> */}
    <div className="page-loading-wrapper">
      <CircularProgress />
    </div>
  </div>
);

// Create a client
const queryClient = new QueryClient();

const App = () => {
  const history = useHistory();
  const userPageHistory = [];
  const ref = useRef(0);

  useEffect(() => {
    function onRouteChange() {
      userPageHistory.push(history.location);
      window.scrollTo(0, 1);
    }
    const unlisten = history.listen(onRouteChange);

    // stop the listener when component unmounts
    return unlisten;
  }, [history]);

  useEffect(() => {
    const calculateVH = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    window.addEventListener('resize', calculateVH);

    calculateVH();

    return () => window.removeEventListener('resize', calculateVH);
  }, []);

  function clearPageHistory() {
    userPageHistory.splice(0, userPageHistory.length);
  }

  // return <>{loading}</>;

  return (
    <ErrorBoundary
      userPageHistory={userPageHistory}
      clearPageHistory={clearPageHistory}
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <React.Suspense fallback={loading}>
          <div
            id="app-div"
            className="app-div app-div-itinerary ms-Overlay"
            ref={ref}
          >
            {/* <PreferencePopup /> */}
            {!isMobile && (
              <Route path="/hub/account/" component={AccountMenu} />
            )}
            {/* <Modal path="/hub" /> */}
            {/* <ModalV2 path="/hub" /> */}
            <Route path="/hub" component={Header} />
            <Route path="/hub" component={Modal} />
            <Route path="/hub" component={ModalV2} />
            <Route path="/hub" component={BottomNav} />
            <Route path="/hub" component={PlannersSpeedDialer} />
            <Switch>
              {/* <Route exact path="/log-in">
              <Redirect to="/" />
            </Route> */}

              <AuthRoute
                exact
                path="/shared/sign-up/ambassador-code/:type"
                component={withTracker(SignUp)}
              />
              <AuthRoute
                exact
                path="/partner/sign-up/:type/:partnerCode"
                component={withTracker(SignUp)}
              />

              <AuthRoute
                exact
                path="/invited/sign-up/:type/:rand"
                component={withTracker(SignUp)}
              />
              <AuthRoute
                exact
                path="/promo/sign-up/:type/:promoCode"
                component={withTracker(SignUp)}
              />
              <AuthRoute
                exact
                path="/sign-up/:type"
                component={withTracker(HubSignUp)}
              />

              <Route
                exact
                path="/sign-up-success"
                component={withTracker(SignUpSuccess)}
              />
              <Route
                exact
                path="/resend-email"
                component={withTracker(ResendEmail)}
              />
              <AuthRoute exact path="/" component={withTracker(LogIn)} />
              <Route
                exact
                path="/verify-email/:id/:email"
                component={withTracker(VerifyEmail)}
              />
              <Route
                exact
                path="/email-reset-password"
                component={withTracker(EmailResetPassword)}
              />
              <Route
                exact
                path="/unsubscribe/:email_front/:email_back/:email_last"
                component={withTracker(Unsubscribed)}
              />
              <Route
                exact
                path="/reset-password/:subscription_rid/:email_front/:email_back/:email_last"
                component={withTracker(ResetPassword)}
              />
              {/* hub ------ */}

              {/* onboarding  */}
              <AuthRoute
                exact
                path="/auth/invited-sign-up/:token"
                component={withTracker(InvitedSignUp)}
              />
              <AuthRoute
                exact
                path="/sign-up/checkout-subscription/:plan_rid"
                component={withTracker(AccountCheckoutSubscription)}
              />

              <ProtectedRoute
                exact
                path="/hub/getting-started"
                component={withTracker(GettingStartedTextMenu)}
              />
              <ProtectedRoute
                exact
                path="hub/getting-strated-video/:getting_started_rid"
                component={withTracker(GettingStartedVideoPlayer)}
              />
              <ProtectedRoute
                exact
                path="/hub/itinerary/:adventure_rid/:itinerary_rid"
                component={withTracker(HubItinerary)}
              />
              <ProtectedRoute
                exact
                path="/hub/itinerary_shared/:adventure_rid/:itinerary_rid/:account_rid/:explorer_rid"
                component={withTracker(HubItineraryShared)}
              />
              <ProtectedRoute
                exact
                path="/hub/my_adventures"
                component={withTracker(HubAdventures)}
              />
              <ProtectedRoute
                exact
                path="/hub/adventure/:adventure/:adventure_rid/:token"
                component={withTracker(Adventure, {
                  name: 'My Adventure',
                  pageName: 'My Adventure',
                  title: 'My Adventure',
                  pathname: 'adventureSelected',
                })}
              />
              <ProtectedRoute
                exact
                path="/hub/adventure/:adventure/:adventure_rid"
                component={withTracker(Adventure)}
                pathname={'adventureSelected'}
                name="My Adventure"
                pageName="My Adventure"
                title="My Adventure"
              />
              {/* user profile page */}
              <ProtectedRoute
                exact
                path="/hub/profile/:account_rid/:explorer_rid"
                component={withTracker(HubUserProfile)}
              />
              <ProtectedRoute
                exact
                path="/hub/adventure_shared/:account_rid/:adventure_name/:adventure_rid/:explorer_rid"
                component={withTracker(HubAdventureShared)}
              />
              <ProtectedRoute
                exact
                path="/hub/friends"
                component={withTracker(FriendsPage)}
              />

              <ProtectedRoute
                exact
                path="/hub/connect"
                component={withTracker(ComingSoon)}
                title="Connect page"
              />

              <ProtectedRoute
                exact
                path="/hub/dashboard"
                component={withTracker(DashBoardTesting)}
              />
              <ProtectedRoute
                exact
                path="/hub/shareqr"
                component={withTracker(SharedSignUp)}
              />
              <ProtectedRoute
                exact
                path="/hub/shareqr-free"
                component={withTracker(SharedSignUpFree)}
              />
              <ProtectedRoute
                exact
                path="/hub/library"
                component={withTracker(HubLibraryV2)}
              />

              <ProtectedRoute
                exact
                path="/hub/impact"
                component={withTracker(HubImpact)}
              />
              <ProtectedRoute
                exact
                path="/hub/checkout-calculate"
                component={withTracker(HubCalculateImpact)}
              />
              <ProtectedRoute
                exact
                path="/hub/view-partner/:partner_rid"
                component={withTracker(HubViewPartner)}
              />
              <ProtectedRoute
                exact
                path="/hub/checkout/:page_type"
                component={withTracker(HubPayPalCheckout)}
              />
              <ProtectedRoute
                exact
                path="/hub/community"
                component={withTracker(HubCommunity)}
              />

              <ProtectedRoute
                exact
                path="/hub/invitations"
                component={withTracker(ComingSoon)}
                title="Invitations page"
              />
              {isMobile && (
                <ProtectedRoute
                  exact
                  path="/hub/account"
                  component={withTracker(AccountMenu)}
                  title="Accounts page"
                />
              )}
              <ProtectedRoute
                exact
                path="/hub/account/profile"
                component={withTracker(AccountProfile)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/change-password"
                component={withTracker(AccountChangePassword)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/account-profile"
                component={withTracker(AccountInfo)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/members"
                component={withTracker(AccountMembers)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/subscription"
                component={withTracker(AccountSubscription)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/checkout-subscription"
                component={withTracker(AccountCheckoutSubscription)}
              />
              <ProtectedRoute
                exact
                path="/hub/my-impact-summary"
                component={withTracker(MyImpactSummary)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/my-impact"
                component={withTracker(AccountMyImpact)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/manage-subscription"
                component={withTracker(AccountManageSubscription)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/cancel-subscription"
                component={withTracker(AccountCancelSubscription)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/cancel-subscription-reason"
                component={withTracker(AccountCancelSubscriptionReason)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/cancel-confirmed"
                component={withTracker(AccountCancelConfirmed)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/pause-subscription-options"
                component={withTracker(AccountPauseOptions)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/pause-subscription-confirmed"
                component={withTracker(AccountPauseConfirmed)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/payment-succesful"
                component={withTracker(AccountPaymentSuccessful)}
              />
              <ProtectedRoute
                exact
                path="/hub/account/messages"
                component={withTracker(ComingSoon)}
                title="messages"
              />
              <ProtectedRoute
                exact
                path="/hub/linkedapps"
                component={withTracker(HubLinkedApps)}
              />

              <ProtectedRoute
                exact
                path="/hub/download"
                component={withTracker(HubDownloads)}
              />

              <ProtectedRoute
                exact
                path="/hub/notifications"
                component={withTracker(ComingSoon)}
                title="Notifications"
              />
              <ProtectedRoute
                exact
                path="/hub/ambassador_codes"
                component={withTracker(HubAmbassadorCodes)}
              />
              <ProtectedRoute
                exact
                path="/hub/partner_codes"
                component={withTracker(HubPartnerPromotionCodes)}
              />
              <ProtectedRoute
                exact
                path="/hub/impact_partner/:type"
                component={withTracker(HubImpactSelect)}
              />
              {/* <ProtectedRoute
              exact
              path="/hub/trips/new"
              component={withTracker(TripCard)}
            /> */}
              {/* 404 hanlder */}
              <Route path="*" component={NoneFound} />
            </Switch>
            <ProtectedRoute path="/hub" component={NotificationBadgesWrapper} />
          </div>
        </React.Suspense>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};
export default App;

//https://lodestar-vpc-host-nonprod.wl.r.appspot.com

//https://storage.googleapis.com/lodestar-vpc-host-nonprod.appspot.com/bottom.png\
//https://storage.googleapis.com/lodestar-vpc-host-nonprod.appspot.com/lodestar_guide_banner.png
