import { openBlockModal } from 'actions/modal_actions';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';
import { useDispatch, useSelector } from 'react-redux';

interface CreateAdventureActivityCtx {
  parentBlockRid?: boolean;
}

const useCreateAdventureActivityModalButton = (
  ctx?: CreateAdventureActivityCtx,
) => {
  const userInfo = useSelector((state) => state?.session?.user);
  const { itinerary_info, itineraryDetails } = useSelector(
    (state) => state?.entities?.itinerary,
  );
  const { adventure_rid } = useCurrentAdventure();

  const dispatch = useDispatch();
  const openModal = async () => {
    let openType = { isNew: true };
    let activityModalTypeRid = ctx?.parentBlockRid ? '8a' : '2a';
    let uiUpdatePayload = {
      account_rid: userInfo.account_rid,
      adventure_rid: adventure_rid,
      adventure_rid_exist_flag: true,
      inspiration_rid: null,
      itinerary_item_rid: null,
      parent_block_rid: ctx?.parentBlockRid || null,
      explorer_rid: userInfo.explorer_rid,
      itineraryInfo: itinerary_info,
    };
    let blockPayload = {
      adventure_rid: adventure_rid,
      parent_block_rid: ctx?.parentBlockRid || null,
      itineraryDetails: itineraryDetails,
    };
    dispatch(
      openBlockModal(
        openType,
        activityModalTypeRid,
        uiUpdatePayload,
        blockPayload,
        userInfo,
      ),
    );
    return;
  };

  return { openModal };
};

export default useCreateAdventureActivityModalButton;
