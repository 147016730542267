import React, { useState, useEffect, useCallback, createElement } from 'react';
import {
  translateToGeoJson,
  translateAsDataOnly,
} from '../util/geoJsonTranslator';
import ReactDOM from 'react-dom';
import { Popup } from '../components/index';
import { useStore, useSelector } from 'react-redux';
import drawCustomMarkerHooks from './render_markers_hooks';
import { setMapUiDetailShow } from '../../../../actions/ui_actions';
import { createRoot } from 'react-dom/client';
const CustomMarkerHooks = (filters, userInfo) => {
  const store = useStore();
  // let markersOnScreen = {};

  const markers = {};
  let markersOnScreen = {};
  const { createLodestarSvg } = drawCustomMarkerHooks(filters, userInfo);

  const renderCollaboraterStartingPoint = () => { };
  //–––––––––––––––––––––––––––––––––––––––––––––––––––––––––*******breakpoint******–––––––––––––––––––––––––––––––––––––––––––––––––––––––––

  const updateSourceAndDraw = (
    map,
    mapboxgl,
    sourceId,
    data,
    userInfo,
    usecase,
    // markersOnScreen
  ) => {
    let translatedData = translateAsDataOnly(data);
    // handler for marker dragends
    const markerDragEndHandler = ({ event, target }, markers) => {
      let block_id = target.getElement().id;
      let markerData = markers[block_id];
      let popup = target.getPopup();
      let domElementPlaceHolder = document.createElement('div');
      const root = createRoot(domElementPlaceHolder);
      root.render(
        <Popup
          type={
            usecase === 'itinerary' ? 'movedTripMarker' : 'movedLibraryMarker'
          }
          store={store}
          markerData={markerData}
        />
      );
      popup.setDOMContent(domElementPlaceHolder);
      // target.addPopup(popup).togglePopup();
    };

    const loadDataAndLayer = () =>
      new Promise((resolve, reject) => {
        try {
          map.getSource(sourceId).setData(translatedData);

          resolve('success');
        } catch (err) {
          reject(err);
        }
      });

    const updateUpdatedLocationPointMarkers = (
      markersOnScreen, // this is for usering 'sourcedata' listener and passing the fetched source
    ) =>
      // const updateLocationPointMarkers = () => // this when 'sourcedata' is not used
      new Promise((resolve, reject) => {
        // const newMarkers = {};
        const newMarkers = {};
        const markers = {};
        // useEffect()
        // let markersOnScreen = {};
        const popups = {};
        let popupsOnScreen = {};
        try {
          const features = map.querySourceFeatures(sourceId); //this is for when the feature isnt passed in
          for (const feature of features) {
            const coords = feature.geometry.coordinates;
            const props = feature.properties;
            const { id, block, name, accountRid } = props;
            let markerObject = markers[id];
            let existingMarkerObject = markersOnScreen[id];
            let popup = popups[id];
            if (!markerObject) {
              if (existingMarkerObject) {
                markers[id] = existingMarkerObject;
                newMarkers[id] = existingMarkerObject;
              } else {
                const el = createLodestarSvg(props);
                el.addEventListener('click', (event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  console.log('unkown Event');
                });
                markerObject = markers[id] = {
                  marker: new mapboxgl.Marker({
                    element: el,
                    draggable:
                      userInfo.account_rid === accountRid ? true : false,
                    scale: 0.2,
                    id,
                  }).setLngLat(coords),
                  // .on("dragstart", (event) => markerDragStartHandler(event)),
                  type:
                    usecase === 'itinerary'
                      ? 'tripLocation'
                      : 'libraryLocation',
                  block,
                  coords,
                  store,
                };
                // setMarkers({ ...markers, [id]: markerObject });
                newMarkers[id] = markerObject;
                let domElementPlaceHolder = document.createElement('div');
                const root = createRoot(domElementPlaceHolder)
                root.render(
                  <Popup
                    type={
                      usecase === 'itinerary'
                        ? 'tripLocation'
                        : 'libraryLocation'
                    }
                    store={store}
                    block={block}
                    markerData={markers[id]}
                  />
                );

                popup = popups[id] = new mapboxgl.Popup({
                  offset: 25,
                  closeButton: false,
                  isOpen: true,
                  closeOnClick: false,
                  className: 'lodestarhub-custom-popups',
                }).setDOMContent(domElementPlaceHolder);
                markers[id].marker
                  .setPopup(popup)
                  .on('dragend', (event) =>
                    markerDragEndHandler(event, markers),
                  );
              }
            }
            // setNewMarkers({ ...newMarkers, [id]: markerObject });

            if (!markersOnScreen[id] && map) {
              markerObject?.marker?.addTo(map);
              if (
                !markerObject.marker._popup.isOpen() &&
                store.getState().ui.map.displayMapPopups
              ) {
                markerObject.marker.togglePopup(); // toggle must be toggle after it gets added to the map
              }
              if (
                markerObject.marker._popup.isOpen() &&
                !store.getState().ui.map.displayMapPopups
              ) {
                markerObject.marker.togglePopup();
              }
            }
          }
          // for every marker we've added previously, remove those that are no longer visible
          for (const id in markersOnScreen) {
            if (!markers[id]) {
              markersOnScreen[id].marker.remove();
            }
          }
          // markersOnScreen = newMarkers;
          // setMarkersOnscreen(newMarkers);
          // Object.assign(markersOnScreen, newMarkers);
          markersOnScreen = markers;
          resolve(markers);
          // let markersOnScreenPlaceHolder = newMarkers;
          // setMarkersOnscreen(newMarkers);
          // resolve(markersOnScreenPlaceHolder);
        } catch (err) {
          console.error(err);

          reject(err);
        }
      });

    return loadDataAndLayer().then((res) => {
      if (res === 'success') {
        return {
          updateUpdatedLocationPointMarkers,
          // isSourceLoaded,
        };
      } else {
        return 'error';
      }
    });
  };

  //this is renderd on map rend
  //–––––––––––––––––––––––––––––––––––––––––––––––––––––––––*******breakpoint******–––––––––––––––––––––––––––––––––––––––––––––––––––––––––

  const drawLocationPoints = (
    map,
    mapboxgl,
    sourceId,
    data,
    userInfo,
    usecase,
    mapStyle,
  ) => {
    let translatedData = translateToGeoJson(data);
    // let markersOnScreen = {};
    const popups = {};
    let popupsOnScreen = {};
    const colors = [
      '#da4431',
      '#feb24c',
      '#fd8d3c',
      '#fc4e2a',
      '#e31a1c',
      'transparent',
    ];

    const markerDragEndHandler = ({ event, target }) => {
      let block_id = target.getElement().getElementsByTagName('svg')[0].id;
      let markerData = markers[block_id];
      let popup = target.getPopup();
      let domElementPlaceHolder = document.createElement('div');
      const root = createRoot(domElementPlaceHolder)
      root.render(
        <Popup
          type={
            usecase === 'itinerary' ? 'movedTripMarker' : 'movedLibraryMarker'
          }
          store={store}
          markerData={markerData}
        />
      );
      popup.setDOMContent(domElementPlaceHolder);
      // target.addPopup(popup).togglePopup();
    };

    const loadDataAndLayer = () =>
      new Promise((resolve, reject) => {
        let source = map.getSource(sourceId);

        if (!source) map.addSource(sourceId, translatedData);
        try {
          map.getLayer('lodestar-location-point-cluster-circle-label') &&
            map.removeLayer('lodestar-location-point-cluster-circle-label');
        } catch (_) { }
        try {
          map.getLayer('location_block_circles') &&
            map.removeLayer('location_block_circles');
        } catch (_) { }

        map.addLayer({
          id: `lodestar-location-point-cluster-circle-label`,
          type: 'symbol',
          source: sourceId,
          filter: ['==', 'cluster', true],
          layout: {
            'text-field': [
              'number-format',
              ['get', 'locationBlockCount'],
              { 'min-fraction-digits': 0, 'max-fraction-digits': 0 },
            ],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 10,
          },
          paint: {
            'text-color': 'white',
          },
        });
        map.addLayer(
          {
            id: `location_block_circles`,
            type: 'circle',
            source: sourceId,
            paint: {
              'circle-color': [
                'case',
                // ["==", ["get", "locationBlockCount"], 2],
                ['==', ['get', 'cluster'], false],
                'rgba(255,255,255,0.0)',
                ['>=', ['get', 'locationBlockCount'], 2],
                colors[0],
                colors[5],
              ],
              'circle-opacity': [
                'case',
                ['==', ['get', 'cluster'], true],
                0.6,
                ['==', ['get', 'cluster'], false],
                0.0,
                0.0,
              ],
              'circle-radius': [
                'case',
                ['==', ['get', 'cluster'], true],
                12,
                ['==', ['get', 'cluster'], false],
                0,
                0,
              ],
            },
          },
          'lodestar-location-point-cluster-circle-label',
        );

        resolve('success');
      });

    // for loggin necessary data for markers
    // for every cluster on the screen, create an HTML markert for it
    const updateLocationPointMarkers = () => {
      const newMarkers = {};
      const features = map.querySourceFeatures(sourceId);
      for (const feature of features) {
        const props = feature.properties;
        const geometry = feature.geometry;
        if (!geometry) return;

        const coords = geometry.coordinates;
        const { id, block, name, accountRid } = props;
        let markerObject;
        if (props.cluster) {
          // continue;
        } else if (!props.cluster) {
          markerObject = markers[id];
          // this is not rendering as markers if the point is a cluster
          let popup;
          if (!markerObject) {
            try {
              map.getBounds();
            } catch (_) {
              return;
            }
            let iterate = map.getBounds().contains(coords);
            const el = createLodestarSvg(props);
            el.addEventListener('click', (event) => {
              event.stopPropagation();
              event.preventDefault();
              store.dispatch(
                setMapUiDetailShow(JSON.parse(feature.properties.block)),
              );
            });
            markerObject = markers[id] = newMarkers[id] = {
              marker: new mapboxgl.Marker({
                element: el,
                // draggable: userInfo.account_rid === accountRid ? true : false,
                draggable: false,
                scale: 1,
                anchor: 'center',
                // offset: [0, -16],
                offset: [0, iterate ? -300 : -16],
                id,
              }).setLngLat(coords),

              type:
                usecase === 'itinerary' ? 'tripLocation' : 'libraryLocation',
              block: JSON.parse(block),
              coords,
              store,
            };

            let domElementPlaceHolder = document.createElement('div');
            let markerData = markers[id];
            const root = createRoot(domElementPlaceHolder)
            root.render(
              <Popup
                type={
                  usecase === 'itinerary' ? 'tripLocation' : 'libraryLocation'
                }
                store={store}
                block={block}
                markerData={markerData}
              />
            );

            popup = popups[id] = new mapboxgl.Popup({
              offset: 25,
              closeButton: false,
              isOpen: true,
              closeOnClick: false,
              className: 'lodestarhub-custom-popups',
            }).setDOMContent(domElementPlaceHolder);
            if (map) {
              markerObject?.marker
                .addTo(map)
                .on('dragend', (event) => markerDragEndHandler(event))
                .setPopup(popup);
            }
            if (store.getState().ui.map.displayMapPopups) {
              markerObject.marker.togglePopup();
            }

            function animateMarker(timestamp) {
              let { x, y } = markerObject.marker.getOffset();

              if (y < -16) {
                markerObject.marker.setOffset([x, y + 5]);

                // markerObject.marker.addTo(map);

                requestAnimationFrame(animateMarker);
              }
            }

            if (iterate) {
              requestAnimationFrame(animateMarker);
            }
          } else {
            newMarkers[id] = markerObject;
            if (!markersOnScreen[id] && map) markerObject?.marker?.addTo(map);
            if (store.getState().ui.map.displayMapPopups) {
              markerObject.marker.togglePopup();
            }
            // .togglePopup();
          }
        }
      }

      for (const id in markersOnScreen) {
        if (!newMarkers[id]) markersOnScreen[id].marker.remove();
      }
      markersOnScreen = newMarkers;
    };
    // );
    loadDataAndLayer();
    return { updateLocationPointMarkers };
  };
  //–––––––––––––––––––––––––––––––––––––––––––––––––––––––––*******breakpoint******–––––––––––––––––––––––––––––––––––––––––––––––––––––––––
  const drawDataAsPieChart = (
    map,
    mapboxgl,
    sourceId,
    data, // can be URL or object(i think?)
  ) => {
    const newMarkers = {};
    const mag1 = ['<', ['get', 'mag'], 2]; // get magnitude that is less thantwo
    const mag2 = ['all', ['>=', ['get', 'mag'], 2], ['<', ['get', 'mag'], 3]]; // get all mags that are equal or greater than two, and that is less than three
    const mag3 = ['all', ['>=', ['get', 'mag'], 3], ['<', ['get', 'mag'], 4]]; // get all mags that are equal to or grether than three and less than four
    const mag4 = ['all', ['>=', ['get', 'mag'], 4], ['<', ['get', 'mag'], 5]]; // get all mags that are equal to or greater than four and less than five
    const mag5 = ['>=', ['get', 'mag'], 5]; // get all mags that are equal to or greater than 5
    const colors = ['#fed976', '#feb24c', '#fd8d3c', '#fc4e2a', '#e31a1c'];
    map.addSource(sourceId, {
      type: 'geojson',
      data: data,
      cluster: true,
      clusterRadius: 80,
      clusterProperties: {
        // keep separate counts for each magnitude category in a cluster
        mag1: ['+', ['case', mag1, 1, 0]],
        mag2: ['+', ['case', mag2, 1, 0]],
        mag3: ['+', ['case', mag3, 1, 0]],
        mag4: ['+', ['case', mag4, 1, 0]],
        mag5: ['+', ['case', mag5, 1, 0]],
      },
    });
    // circle and symbol layers for rendering individual earthquakes (unclustered points)
    map.addLayer({
      id: 'cluster_circle',
      type: 'circle',
      source: sourceId,
      filter: ['!=', 'cluster', true],
      paint: {
        'circle-color': [
          'case',
          mag1,
          colors[0],
          mag2,
          colors[1],
          mag3,
          colors[2],
          mag4,
          colors[3],
          colors[4],
        ],
        'circle-opacity': 0.6,
        'circle-radius': 12,
      },
    });
    map.addLayer({
      id: 'cluster_label',
      type: 'symbol',
      source: sourceId,
      filter: ['!=', 'cluster', true],
      layout: {
        'text-field': [
          'number-format',
          ['get', 'mag'],
          { 'min-fraction-digits': 1, 'max-fraction-digits': 1 },
        ],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        'text-size': 10,
      },
      paint: {
        'text-color': ['case', ['<', ['get', 'mag'], 3], 'black', 'white'],
      },
    });

    // objects for caching and keeping track of HTML marker objects (for performance)
    const markers = {};
    let markersOnScreenPie = {};
    // code for creating an SVG donut chart from feature properties
    function createDonutChart(props) {
      const offsets = [];
      const counts = [
        props.mag1,
        props.mag2,
        props.mag3,
        props.mag4,
        props.mag5,
      ];
      let total = 0;
      for (const count of counts) {
        offsets.push(total);
        total += count;
      }
      const fontSize =
        total >= 1000 ? 22 : total >= 100 ? 20 : total >= 10 ? 18 : 16;
      const r = total >= 1000 ? 50 : total >= 100 ? 32 : total >= 10 ? 24 : 18;
      const r0 = Math.round(r * 0.6);
      const w = r * 2;

      let html = `<div>
<svg width="${w}" height="${w}" viewbox="0 0 ${w} ${w}" text-anchor="middle" style="font: ${fontSize}px sans-serif; display: block">`;

      for (let i = 0; i < counts.length; i++) {
        html += donutSegment(
          offsets[i] / total,
          (offsets[i] + counts[i]) / total,
          r,
          r0,
          colors[i],
        );
      }
      html += `<circle cx="${r}" cy="${r}" r="${r0}" fill="white" />
<text dominant-baseline="central" transform="translate(${r}, ${r})">
${total.toLocaleString()}
</text>
</svg>
</div>`;

      const el = document.createElement('div');
      el.innerHTML = html;
      return el.firstChild;
    }

    function donutSegment(start, end, r, r0, color) {
      if (end - start === 1) end -= 0.00001;
      const a0 = 2 * Math.PI * (start - 0.25);
      const a1 = 2 * Math.PI * (end - 0.25);
      const x0 = Math.cos(a0),
        y0 = Math.sin(a0);
      const x1 = Math.cos(a1),
        y1 = Math.sin(a1);
      const largeArc = end - start > 0.5 ? 1 : 0;

      // draw an SVG path
      return `<path d="M ${r + r0 * x0} ${r + r0 * y0} L ${r + r * x0} ${r + r * y0
        } A ${r} ${r} 0 ${largeArc} 1 ${r + r * x1} ${r + r * y1} L ${r + r0 * x1
        } ${r + r0 * y1} A ${r0} ${r0} 0 ${largeArc} 0 ${r + r0 * x0} ${r + r0 * y0
        }" fill="${color}" />`;
    }
    function updateMarkers() {
      const features = map.querySourceFeatures(sourceId);
      // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
      // and add it to the map if it's not there already
      for (const feature of features) {
        const coords = feature.geometry.coordinates;
        const props = feature.properties;
        if (!props.cluster) continue;
        const id = props.cluster_id;

        let marker = markers[id];
        if (!marker) {
          const el = createDonutChart(props);
          marker = markers[id] = new mapboxgl.Marker({
            element: el,
          }).setLngLat(coords);
        }
        newMarkers[id] = marker;

        if (!markersOnScreenPie[id] && map) marker?.addTo(map);
      }
      // for every marker we've added previously, remove those that are no longer visible
      for (const id in markersOnScreenPie) {
        if (!newMarkers[id]) markersOnScreenPie[id].remove();
      }
      markersOnScreenPie = newMarkers;
    }
    return { updateMarkers };
  };

  return {
    drawDataAsPieChart,
    drawLocationPoints,
    updateSourceAndDraw,
  };
};

export default CustomMarkerHooks;
