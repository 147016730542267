import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useUserInfo from 'features/user/hooks/use-user-info';
import useCurrentAdventure from './use-current-adventure';

/**
 * @typedef {Object} ActivityCategory
 * @property {string} block_category_name - The name of the block category.
 * @property {number} block_category_rid - The unique identifier for the block category.
 * @property {string} icon_name - The name of the icon associated with the block category.
 * @property {number} icon_rid - The unique identifier for the icon.
 */

/**
 *
 * @returns
 */
const useAdventureActivityIcons = () => {
  const { explorer_rid } = useUserInfo();
  const { adventure_rid } = useCurrentAdventure();
  if (!adventure_rid) {
    throw new Error(
      'useAdventureAttendees must be used with selectedAdventure state',
    );
  }

  /**
   * @returns {Promise<ActivityCategory[]>} Array of activity category objects.
   */
  const queryFn = () =>
    axios
      .get(`/api/ui/adventure-activity-icons/${adventure_rid}`)
      .then((res) => res.data.icons);

  return useQuery({
    queryKey: [explorer_rid, 'adventure', adventure_rid, 'activities'],
    queryFn,
  });
};

export default useAdventureActivityIcons;
