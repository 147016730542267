import { alpha, Box, ButtonBase, Drawer, Portal, styled } from '@mui/material';
import HubItinerary from '../../../pages/pwa_hub/itineraries';
import React, { useEffect, useState } from 'react';
import useGlobalLibraryDrawerButton from '../hooks/use-global-library-drawer-button';
import useInvalidateAdventureData from 'features/adventure/hooks/use-invalidate-adventure-data';
import ReactDOM from 'react-dom';
import Library from './library';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';

interface LibraryDrawerButtonProps {
  children: React.ReactNode;
}

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '90%',
    height: '93%',
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    zIndex: 50000,
    // opacity: 0.95,
    left: '2.5%',
    '& .hub-page-wrapper': {
      height: '100% !important',
      width: '100%',
    },
    '& .hub-page-container': {
      height: '100% !important',
    },
  },
}));

function LibraryDrawerButtonBase({ children }: LibraryDrawerButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const currentAdventure = useCurrentAdventure();
  const { invalidateQueries } = useInvalidateAdventureData();

  const toggleDrawer = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsOpen(false);
    invalidateQueries();
  };

  return (
    <>
      <ButtonBase onClick={toggleDrawer} sx={{ width: 1 }}>
        {children}
      </ButtonBase>
      {ReactDOM.createPortal(
        <CustomDrawer
          BackdropProps={{ style: { backgroundColor: alpha('#fff', 0.5) } }}
          anchor="bottom"
          open={isOpen}
          onClose={handleClose}
          container={document.body}
        >
          <Box width={1} height={1} position="relative">
            {/* Drawer content */}
            <Library sourceAdventureRid={currentAdventure.adventure_rid} />
          </Box>
        </CustomDrawer>,
        document.body,
      )}
    </>
  );
}

export default LibraryDrawerButtonBase;
