import styled from "@emotion/styled"
import chroma from "chroma-js"

const Container = styled.div`
  position: relative;
  width: 110px;
  height: 105px;
  background: ${({ startColor }) => startColor};
  box-sizing: border-box;
  padding: 8px;
  border-radius: 10px;
  // box-shadow: 0 1.7px 1.1px -10px rgba(19,10,43,.093),
  //             0 4.3px 3px -10px rgba(19,10,43,.133),
  //             0 8.9px 6.6px -10px rgba(19,10,43,.167),
  //             0 12.3px 14.4px -10px rgba(19,10,43,.207),
  //             0 11px 17px -10px rgba(19,10,43,.3);
  &::before {
    content: "";
    position: absolute;
    top: -14px;
    right: 0;
    border-left: 0px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid ${({ endColor }) => endColor};
    display: none;
  }
  &::after {
    content: "";
    position: absolute;
    top: -14px;
    left: 0px;
    background: ${({ startColor }) => startColor};
    width: calc(100% - 15px);
    height: 15px;
    border-top-left-radius: 10px;
    display: none;
  }
`

function Activity({ color, children, ...props }) {
  const startColor = color || Activity.defaultProps.color
  const endColor = chroma(startColor).darken(1.2).hex()
  const mainColor = chroma(startColor).darken(1.5).hex()

  return (
    <Container color={mainColor} startColor={startColor} endColor={endColor} {...props}>
      {children}
    </Container>
  )
}

Activity.defaultProps = {
  color: '#ffd480',
}

export default Activity