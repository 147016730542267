import { LibraryMusicOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { ButtonBase, ButtonProps, Divider, MenuItem } from '@mui/material';
import { ActivityIcon } from 'features/activity/components/create-activity-modal-button';
import { FolderIcon } from 'features/folder/components/create-trip-folder-modal-button';
import { CalendarIcon } from 'features/itinerary/components/edit-itinerary-modal-button';
import { ChecklistIcon } from 'features/list/components/create-list-modal-button';
import { StyledMenu } from 'features/list/components/list-action-menu';
import { useState } from 'react';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import LibraryDrawerButtonBase from 'features/library/components/library-drawer-button-base';
import useGlobalLibraryDrawerButton from 'features/library/hooks/use-global-library-drawer-button';
import LibraryDrawer from 'features/library/components/library-drawer';
import useToggle from 'hooks/use-toggle';

interface TripPlannerActionMenuProps {
  children: React.ReactNode;
  openCreateFolder: Function;
  openCreateActivity: Function;
  openCreateList: Function;
  openEditDates: Function;
  buttonProps?: ButtonProps;
}

const id = 'trip-planner-action-menu';

function TripPlannerActionMenuBase({
  openCreateFolder,
  openCreateActivity,
  openCreateList,
  openEditDates,
  buttonProps,
  children,
}: TripPlannerActionMenuProps) {
  const [libraryDrawerIsOpen, toggleLibraryDrawer] = useToggle(false);

  const { adventure_rid } = useCurrentAdventure();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActivity = () => {
    openCreateActivity();
    handleClose();
  };

  const handleList = () => {
    openCreateList();
    handleClose();
  };

  const handleFolder = () => {
    openCreateFolder();
    handleClose();
  };

  const handleLibrary = () => {
    toggleLibraryDrawer();
    handleClose();
  };

  const handleDates = () => {
    openEditDates();
    handleClose();
  };
  return (
    <>
      <ButtonBase onClick={handleClick} {...buttonProps}>
        {children}
      </ButtonBase>
      <StyledMenu
        id={id}
        MenuListProps={{
          'aria-labelledby': id,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleActivity}>
          <ActivityIcon />
          Create Activity
        </MenuItem>
        <MenuItem onClick={handleList}>
          <ChecklistIcon />
          Create List
        </MenuItem>
        <MenuItem onClick={handleFolder}>
          <FolderIcon />
          Create Folder
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLibrary} sx={{ color: 'text.primary' }}>
          <CollectionsBookmarkIcon />
          Import Items From Library
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDates}>
          <CalendarIcon />
          Edit Start Date
        </MenuItem>
      </StyledMenu>

      <LibraryDrawer
        isOpen={libraryDrawerIsOpen}
        handleClose={() => {
          toggleLibraryDrawer();
        }}
      />
    </>
  );
}

export default TripPlannerActionMenuBase;
