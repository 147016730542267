import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { styleValues } from '../../../pages/pwa_hub/adventure/components/constants';

const useStyles = makeStyles((theme) => ({
  ...styleValues(theme),
  tanBarContainer: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      // maxWidth: '700px',
      height: '131px',
      padding: '8px 0px',
      borderRadius: '20px',
      position: 'relative',
      margin: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '92px',
      borderRadius: '5px',
      position: 'relative',
      margin: 'auto',
    },
  },
  tanBarContent: {
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 90px)',
      marginRight: '90px',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 40px)',
      marginRight: '40px',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
  },
  tanBarHeader: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      width: '90px',
      height: '100%',
      borderTopRightRadius: '20px',
      borderBottomRightRadius: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      width: '40px',
      height: '100%',
      borderTopRightRadius: '5px',
      borderBottomRightRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
  },
}));

const TanBar = ({ headerContent, bodyContent, colorMode }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.tanBarContainer}
      style={{ background: colorMode === 'light' ? '#F9EED8' : '#DDE9EC' }}
    >
      <Box className={classes.tanBarContent}>{bodyContent}</Box>
      <Box
        className={[
          classes.tanBarHeader,
          classes.contentFontSize,
          classes.svgColor_237a07,
        ].join(' ')}
        style={{
          backgroundColor: colorMode === 'light' ? '#E0D5C3' : '#B0CDC9',
        }}
      >
        {headerContent}
      </Box>
    </Box>
  );
};

export default TanBar;
