import { SvgIcon } from '@mui/material';
import useEditItineraryModalButton from '../hooks/use-edit-itinerary-modal-button';
import ResponsiveIconButton from './responsive-icon-button';

const EditItneraryModalButton = ({ sx, ...rest }) => {
  const { openModal } = useEditItineraryModalButton();

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openModal();
  };

  return (
    <ResponsiveIconButton
      onClick={handleClick}
      icon={<CalendarIcon sx={{ width: 25, height: 25 }} />}
      variant="contained"
      sx={{ whiteSpace: 'nowrap', ...sx }}
      {...rest}
      text={'Edit Dates'}
    />
  );
};

export function CalendarIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 31 30">
      <path d="M22.3897 6.18557C23.3276 6.18557 24.0882 5.42377 24.0882 4.48454V1.70103C24.0882 0.761797 23.3276 0 22.3897 0C21.4519 0 20.6912 0.761797 20.6912 1.70103V4.48454C20.6912 5.42377 21.4519 6.18557 22.3897 6.18557ZM8.49265 6.18557C9.4305 6.18557 10.1912 5.42377 10.1912 4.48454V1.70103C10.1912 0.761797 9.4305 0 8.49265 0C7.55479 0 6.79412 0.761797 6.79412 1.70103V4.48454C6.79412 5.42377 7.55479 6.18557 8.49265 6.18557ZM29.3382 2.78351H25.0147V4.79381C25.0147 6.24561 23.8394 7.42268 22.3897 7.42268C20.94 7.42268 19.7647 6.24561 19.7647 4.79381V2.78351H11.1176V4.79381C11.1176 6.24561 9.94231 7.42268 8.49265 7.42268C7.04299 7.42268 5.86765 6.24561 5.86765 4.79381V2.78351H1.54412C0.691059 2.78351 0 3.47558 0 4.3299V28.4536C0 29.3079 0.691059 30 1.54412 30H29.3382C30.1913 30 30.8824 29.3079 30.8824 28.4536V4.3299C30.8824 3.47558 30.1913 2.78351 29.3382 2.78351ZM10.5 25.0515H5.25V19.7938H10.5V25.0515ZM10.5 17.9381H5.25V12.6804H10.5V17.9381ZM18.2206 25.0515H12.9706V19.7938H18.2206V25.0515ZM18.2206 17.9381H12.9706V12.6804H18.2206V17.9381ZM25.9412 25.0515H20.6912V19.7938H25.9412V25.0515ZM25.9412 17.9381H20.6912V12.6804H25.9412V17.9381Z" />
    </SvgIcon>
  );
}
export default EditItneraryModalButton;
