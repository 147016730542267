import { useCallback } from 'react';
import { MAP_OPTION_ITINERARY_ONLY, MAP_OPTION_TRIP_BOARD, MAP_OPTION_MY_ROUTE } from '../../../../constants';
const FilterHooks = (props) => {
  const {
    filters,
    setFilters,
    selectedItinerary,
    fetchLocationBlocks,
    fetchTripBoardLocationBlocks,
    sendItineraryMapFilters,
    userInfo,
    map,
    user_adventure_friend,
    user_adventure_friend_rid,
  } = props;

  const handleFilterClick = useCallback((value) => {
    if (filters === value) {
      return;
    } else {
      if (value === MAP_OPTION_TRIP_BOARD) {
        fetchTripBoardLocationBlocks({
          account_rid: userInfo.account_rid,
          adventure_rid: selectedItinerary.adventure_rid,
          itinerary_rid: null,
        });
      } else if (value === MAP_OPTION_MY_ROUTE) {
        fetchLocationBlocks({
          account_rid: userInfo.account_rid,
          adventure_rid: selectedItinerary.adventure_rid,
          itinerary_rid: selectedItinerary.itinerary_rid,
        });
      } else if (value === MAP_OPTION_ITINERARY_ONLY) {
        fetchLocationBlocks({
          account_rid: userInfo.account_rid,
          adventure_rid: selectedItinerary.adventure_rid,
          itinerary_rid: selectedItinerary.itinerary_rid,
          direction_flag: true,
          my_routes: true,
          adventure_friend_rid: user_adventure_friend_rid,
        });
      } else {
        fetchLocationBlocks({
          account_rid: userInfo.account_rid,
          adventure_rid: selectedItinerary.adventure_rid,
          itinerary_rid: selectedItinerary.itinerary_rid,
          direction_flag: true,
        });
      }

      setFilters(value);
      sendItineraryMapFilters(value);
    }
  });

  return {
    handleFilterClick,
  };
};

export default FilterHooks;
