import React, { useState, useEffect, useCallback } from 'react';
import { Filters, TogglePopUps, MapStyleToggle } from './tool_components';
const MapBoxControls = (props) => {
  return (
    <div
      className="map-box-module-toolbar-wrapper"
      id="map-box-module-filters-wrapper-mobile"
    >
      <Filters {...props} />
    </div>
  );
};

export default MapBoxControls;
