const DrawCampgrounds = (map, data, sourceId) => {
  if(data.length === 0) return;
  console.log(sourceId);
  const features = map.current.querySourceFeatures(sourceId);

  // Iterate over the features and extract the location points
  const locationPoints = features.map(feature => feature.geometry.coordinates);

    // Create a GeoJSON source for special markers
  const campgroundMarkersGeoJSON = {
    type: 'FeatureCollection',
    features: [],
  };
  // Add special markers to the GeoJSON source
  data.forEach((point) => {
    const { facility_coordinates } = point;
    campgroundMarkersGeoJSON.features.push({
      type: 'Feature',
      properties: {
        ...point
      },
      geometry: {
        type: 'Point',
        coordinates: [facility_coordinates.lng, facility_coordinates.lat],
      },
    });
  });
  map.current.loadImage('/campground_poi.png', (error, image) => {
    if (error) {
      console.error('Failed to load custom marker image:', error);
      return;
    }
    if(map.current.hasImage('campground-poi')){}
    else
      map.current.addImage('campground-poi', image);
      map.current.addSource("rec-campgrounds", {
      type: "geojson",
      // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
      // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
      data: campgroundMarkersGeoJSON,
      cluster: true,
      clusterRadius: 40 // Radius of each cluster when clustering points (defaults to 50)
    });
    if(map.current.getLayer("unclustered-points")){
    } else {
      initializeCampGrounds(map, locationPoints);
    }
  });
};

const initializeCampGrounds = (map, locationPoints) => {
  // Use the earthquakes source to create five layers:
    // One for unclustered points, three for each cluster category,
    // and one for cluster labels
    
    map.current.addLayer({
      "id": "unclustered-points",
      "type": "symbol",
      "source": "rec-campgrounds",
      "filter": ["!has", "point_count"],
      "layout": {
          "icon-image": "campground-poi",
          "icon-size": 1,
          'text-field': ['get', 'facility_name'],
          'text-size': 12,
          'text-anchor': 'top',
          'text-offset': [0, 1],
      },
    });

    const filter = [
      'any',
      // Create an 'all' expression for each specific location
      // Checks if the point's coordinates match the specific location
      ...locationPoints.map(location => ['all', ['==', ['get', 'lng'], location[0]], ['==', ['get', 'lat'], location[1]]])
    ];
  
    // Set the opacity for the selected locations
    map.current.setPaintProperty('unclustered-points', 'icon-opacity', [
      'case',
      filter, // Filter for specific locations
      0.5, // Opacity value for specific locations
      1 // Default opacity value for other locations
    ]);
    
// Display the earthquake data in three layers, each filtered to a range of
  // count values. Each range gets a different fill color.
  var layers = [
      [30, '#74952F'],
      [10, '#74952F'],
      [0, '#74952F']
  ];

  layers.forEach(function (layer, i) {
    
      map.current.addLayer({
          "id": "cluster-" + i,
          "type": "circle",
          "source": "rec-campgrounds",
          "paint": {
              "circle-color": layer[1],
              "circle-radius": 10,
              "circle-opacity": 1
          },
          "filter": i === 0 ?
              [">=", "point_count", layer[0]] :
              ["all",
                  [">=", "point_count", layer[0]],
                  ["<", "point_count", layers[i - 1][0]]]
      });
  });

  // Add a layer for the clusters' count labels
  map.current.addLayer({
      "id": "cluster-count",
      "type": "symbol",
      "source": "rec-campgrounds",
      "layout": {
          "text-field": "{point_count}",
          "text-font": [
              "DIN Offc Pro Medium",
              "Arial Unicode MS Bold"
          ],
          "text-size": 8,
      },
      "paint": {
        "text-color": 'white',
      }
  });
}

export default DrawCampgrounds;

