import { openItineraryModal } from 'actions/modal_actions';
import { useDispatch, useSelector } from 'react-redux';

// Hook to handle opening
const useEditItineraryModalButton = () => {
  const itinerary_info = useSelector(
    (state) => state.entities?.itinerary?.itinerary_info,
  );
  const dispatch = useDispatch();

  const openModal = () => {
    if (!itinerary_info)
      throw new Error('Edit Itinerary Modal requires itinerary_info');

    dispatch(openItineraryModal(itinerary_info, { isEditAndView: true }, null));
  };

  return { openModal };
};

export default useEditItineraryModalButton;
