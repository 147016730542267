import React from 'react';
import { svgUnnestInverted, SvgUpArrow } from 'components/svg_module';
const SlideButton = ({
  onClick,
  type,
  isHoverEnabled,
  displayDetails,
  handleTraverseUp,
  customClassName,
}) => {
  const delayedActionUp = () => {
    setTimeout(handleTraverseUp, 750);
  };

  const delayedActionClick = () => {
    setTimeout(onClick, 750);
  };
  if (type === 'up') {
    return (
      <div
        className={`slide-button ${
          customClassName && `${customClassName}`
        } slide-button--${type}--up`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleTraverseUp();
        }}
        onMouseOver={isHoverEnabled && delayedActionUp}
      >
        {SvgUpArrow()}
      </div>
    );
  }

  return displayDetails && displayDetails.level > 1 ? (
    <div className="slider-button-group">
      <div
        className={`slide-button ${
          customClassName && `${customClassName}`
        } slide-button--${type}--up`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleTraverseUp();
        }}
        onMouseOver={isHoverEnabled && delayedActionUp}
      >
        <span>{svgUnnestInverted('', 'unnest-svg', 'unnest-svg-outer')}</span>
      </div>
    </div>
  ) : (
    <button
      className={`slide-button ${
        customClassName && `${customClassName}`
      } slide-button--${type}`}
      onClick={onClick}
      onMouseOver={() => isHoverEnabled && delayedActionClick()}
    >
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          className="itinerary-svg-up-outer"
          height="27.922"
          viewBox="0 0 50 27.922"
        >
          <path
            className="itinerary-svg-up"
            id="Polygon_9"
            data-name="Polygon 9"
            d="M25,0,50,27.922H0Z"
          />
        </svg>
      </span>
    </button>
  );
};
export default SlideButton;
