import { Box, makeStyles } from '@material-ui/core';
import { Grid } from '@mui/material';
import {
  fetchPartners,
  selectPartner,
  setCalculatedCarbon,
} from 'actions/ui_actions';
import SectionHeader from 'components/section/header';
import fetchRecommendedPartners from 'features/adventure/api/fetchRecommendedPartners';
import useAdventureDonations from 'features/adventure/hooks/use-adventure-donations';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TanBar from '../../../../../components/common/tan-bar';
import TanBarItem from '../../../../../components/common/tan-bar/tan-bar-item';
import HubButton from '../../../../../components/hub-button';
import svgLibrary from '../../../../../components/svg_module/svg_library/library/functionLibrary';
import { styleValues } from '../constants';
import { GreenEarthIcon, HandShakeIcon } from './svgs';
import useItineraryInfoQuery from 'features/itinerary/hooks/use-itinerary-info-query';
import { fmtNumber } from 'features/adventure/components/adventure-overview';

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPartners: (data, statusData, adventure_rid) =>
      dispatch(fetchPartners(data, statusData, adventure_rid)),
    setCalculatedCarbon: (data) => dispatch(setCalculatedCarbon(data)),
    selectPartner: (data) => dispatch(selectPartner(data)),
  };
};
const mapStateToProps = ({ session, entities }) => {
  let userInfo = session.user?.userInfo ? session.user.userInfo[0] : null;
  let current_adventure_data = entities?.adventure?.selectedAdventure;

  return {
    userInfo,
    current_adventure_data,
  };
};
const useStyles = makeStyles((theme) => ({
  ...styleValues(theme),
}));

const FootPrint = ({ carbonData, ...props }) => {
  const { adventure_rid, default_transportation_icon } = useCurrentAdventure();
  const history = useHistory();
  const classes = useStyles();

  const {
    data: itineraryInfo,
    isLoading: itineraryInfoLoading,
  } = useItineraryInfoQuery();

  const { data: tripDonations, isLoading, isError } = useAdventureDonations(
    adventure_rid,
  );

  const genThreePartners = (partners) => {
    if (partners.length <= 3) {
      return partners;
    }
    let recommended = partners.filter((partner) => partner.recommended);
    // debugger;
    if (recommended.length >= 3) {
      return recommended.slice(0, 3);
    } else {
      let diff = 3 - recommended.length;
      for (let i = partners.length - 1; diff > 0 && i > -1; i--) {
        let partner = partners[i];
        if (!partner.recommended) {
          recommended.push(partner);
          diff -= 1;
        }
      }
      return recommended;
    }
  };

  const handleWipeOutCO2 = () => {
    props.fetchPartners(41, 3, adventure_rid).then(({ data }) => {
      props.selectPartner(data[0]);
      props.setCalculatedCarbon(carbonData?.carbonTon);
      history.push('/hub/checkout-calculate');
    });
  };

  const handleDonate = () => {
    fetchRecommendedPartners(adventure_rid).then((res) => {
      let selectedPartners = genThreePartners(res.data);
      props.selectPartner(selectedPartners);
      history.push('/hub/checkout-calculate');
    });
  };

  if (isLoading) return;
  if (isError) return;

  return (
    <Box className={classes.cardContainer}>
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12} lg={12}>
          <Box>
            <SectionHeader>IMPACT</SectionHeader>
          </Box>
          <Box
            className={[classes.font, classes.contentFontSize].join(' ')}
            style={{ marginTop: '6px' }}
          >
            Make your footprint count — donate to a nonprofit partner and make
            your trip climate positive
          </Box>
        </Grid>
        <Box style={{ width: '100%', maxWidth: '700px', margin: 'auto' }}>
          <Grid
            container
            className={classes.gridContainer}
            justifyContent="center"
            alignItems="center"
          >
            {/* Don't show C02 wipeout if no carbonData*/}
            {Boolean(carbonData?.carbonTon) && (
              <Grid
                item
                md={6}
                sm={6}
                xs={12}
                lg={6}
                className={classes.svgClass}
              >
                <Box style={{ width: '80%', margin: 'auto' }}>
                  <HubButton
                    mode="trans-divider"
                    className={classes.spanSizeWithOutFont}
                    icon={GreenEarthIcon}
                    bold={true}
                    title="WIPE OUT CO<sub>2</sub>"
                    width="100%"
                    align="center"
                    height="fit-content"
                    onClick={() => handleWipeOutCO2()}
                  />
                </Box>
              </Grid>
            )}
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              lg={6}
              className={classes.svgClass}
            >
              <Box style={{ width: '80%', margin: 'auto' }}>
                <HubButton
                  className={classes.spanSizeWithOutFont}
                  bold={true}
                  mode="trans-divider"
                  icon={HandShakeIcon}
                  title="DONATE"
                  width="100%"
                  height="fit-content"
                  onClick={() => handleDonate()}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box style={{ margin: '24px', width: '100%' }}>
          <TanBar
            headerContent={
              <Box className={classes.svgClass}>
                {svgLibrary &&
                  default_transportation_icon &&
                  svgLibrary[default_transportation_icon]('#273A07')}
              </Box>
            }
            bodyContent={
              <Box className={classes.subContentFont}>
                {itineraryInfoLoading
                  ? '0.0 tons'
                  : `${
                      fmtNumber(itineraryInfo?.carbon_produced) || '0.0'
                    } tons`}
                of CO
                <sub>2</sub>
              </Box>
            }
            colorMode="light"
          />
        </Box>
        {tripDonations.map((donation, index) => (
          <Box style={{ margin: '12px', width: '100%' }} key={index}>
            <TanBar
              headerContent={donation.donation_amount}
              bodyContent={
                <TanBarItem
                  icon={
                    donation.partner_donation_type_rid === 1
                      ? HandShakeIcon
                      : GreenEarthIcon
                  }
                  title={donation.explorer_name}
                  content={`${donation.donation_date} - ${donation.partner_name}`}
                />
              }
              colorMode="dark"
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FootPrint);
