import EditIcon from '@mui/icons-material/Edit';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Divider, IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import useDeleteBlockModalButton from 'features/blocks/hooks/use-delete-block-modal-button';
import { DeleteIcon } from 'features/itinerary/components/delete-itinerary-day-modal-button';
import { StyledMenu } from 'features/list/components/list-action-menu';
import { useState } from 'react';
import useEditActivityModalButton from '../hooks/use-edit-activity-modal-button';
import LikeMenuItem from 'components/modules/blocks/like-menu-item';
import useBlockVote from 'features/blocks/hooks/use-block-vote';

/**
 * @param {object} props
 * @param {import('features/blocks/constants').ActivityBlock} props.activityBlock
 */
const ActivityActionMenu = ({ activityBlock }) => {
  const { openModal: openDeleteModal } = useDeleteBlockModalButton({
    block_rid: activityBlock.block_rid,
    name: activityBlock.block_name,
  });

  const { openModal: openEditModal } = useEditActivityModalButton();

  const { onThumbUp, onThumbDown } = useBlockVote({ isAdventure: true });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handleClose();
    openDeleteModal();
  };

  const handleEdit = () => {
    handleClose();
    openEditModal(activityBlock);
  };

  const id = `activity-action-menu-${activityBlock.block_rid}`;
  return (
    <>
      <IconButton
        onClick={handleClick}
        id={id}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <MoreHorizIcon />
      </IconButton>
      <StyledMenu
        id={id}
        MenuListProps={{
          'aria-labelledby': id,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit} disableRipple>
          <EditIcon />
          Edit Activity
        </MenuItem>
        <LikeMenuItem
          block={activityBlock}
          is_white={false}
          isAdventure={false}
          total_vote_count_up={activityBlock.total_vote_count_up}
          onThumbDown={onThumbDown}
          onThumbUp={onThumbUp}
          onClick={handleClose}
        />
        <Divider />
        <MenuItem onClick={handleDelete} disableRipple>
          <DeleteIcon />
          Delete Activity
        </MenuItem>
      </StyledMenu>
    </>
  );
};

export default ActivityActionMenu;
