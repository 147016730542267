import React, { useCallback } from 'react';
import HubButton from '../../../../hub-button';
/*
add to trip = done through modal
cancel =removes it self

*/

const NewLocationBlockPopup = ({
  marker,
  itineraryInfo,
  openBlockModal,
  fetchLocationBlocks,
  userInfo,
  mapFilter,
  itineraryDetails,
  libraryAdventureSelected,
  ...props
}) => {
  const handleCancel = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    marker.remove();
  });
  const callBackAfterInsertingBlock = async (marker) => {
    await fetchLocationBlocks({
      account_rid: userInfo.account_rid,
      adventure_rid: null,
      itinerary_rid: null,
    });

    marker.remove();
  };
  const handleNew = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    let openType = { isNew: true, isLibraryBlock: true };
    let location_point = marker.getLngLat();
    let blockPayload = {
      location_point: location_point,
      location_flag: true,
    };
    let uiUpdatePayload = () => callBackAfterInsertingBlock(marker);
    blockPayload['adventureRid'] = libraryAdventureSelected;
    openBlockModal(openType, '3a', uiUpdatePayload, blockPayload, userInfo);
  });
  return (
    <div className="new-location-block-wrapper">
      <HubButton style={{fontSize: '12px', fontWeight: '700'}} mode="red" title="Create POI" onClick={handleNew}/>
      <HubButton style={{fontSize: '12px', fontWeight: '700'}} mode="yellow" title="Cancel" onClick={handleCancel}/>
      {/* <button
        onClick={handleNew}
        className="new-location-block-pop-button new-location-block-pop-button--new"
      >
        
      </button> */}
      {/* <button
        onClick={handleCancel}
        className="new-location-block-pop-button new-location-block-pop-button--cancel"
      >
        Cancel
      </button> */}
    </div>
  );
};

export default NewLocationBlockPopup;
