import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { merge } from 'lodash';
import MultiBackendProvider from 'components/drag-drop/multi-backend-provider/multi-backend-provider';
import ScrollIndicator from 'components/modules/scroll_indicators';
import ItinerariesSingleItemClone from './itineraries_singleitem_clone';
import DNDList from 'components/drag-drop/dnd-list/dnd-list';
import {
  DragDirections,
  DragTypes,
} from '../../../components/drag-drop/item-types';
import { useReorderItineraryItem } from './utils';

const Container = styled.div`
  width: 100%;
  height: calc(100% - 188px);
  box-sizing: border-box;
`;

const Itineraries = ({
  pageData,
  openBlockModal,
  itinerary_info,
  openDeleteModal,
  deleteItineraryItem,
  adventure_rid,
  explorer_rid,
  openItineraryItemModal,
  drillDownIntoAndOutOfItineraryBlock,
  userInfo,
  isHoverEnabled,
  addDayHandler,
}) => {
  const [drilledDownItineraries, setDrilledDownItineraries] = useState({});

  const itineraryBlockTraverseUpHanlder = (itinerary_item_rid) => {
    const {
      level,
      prevLevel,
      lastBlockRid,
      prevItineraryBlockRid,
    } = drilledDownItineraries[itinerary_item_rid];
    if (level === 2) {
      let copiedDrilledDownData = merge({}, drilledDownItineraries);
      delete copiedDrilledDownData[itinerary_item_rid];
      setDrilledDownItineraries(copiedDrilledDownData);
      drillDownIntoAndOutOfItineraryBlock({ itinerary_item_rid }, userInfo, {
        itinerary_item_rid,
      });
    } else {
      let copiedPrevItineraryBlockRid = merge([], prevItineraryBlockRid);
      let copiedLastBlockRid = merge([], lastBlockRid);
      copiedLastBlockRid.splice(lastBlockRid.length - 1, 1);
      copiedPrevItineraryBlockRid.splice(prevItineraryBlockRid.length - 1);
      let nextLevelDetails = {
        [itinerary_item_rid]: {
          level: level - 1,
          prevLevel: prevLevel - 1,
          currentBlockRid: lastBlockRid[lastBlockRid.length - 1],
          currentItineraryBlockRid:
            prevItineraryBlockRid[prevItineraryBlockRid.length - 1],
          prevItineraryBlockRid: copiedPrevItineraryBlockRid,
          lastBlockRid: copiedLastBlockRid,
        },
      };

      setDrilledDownItineraries({
        ...drilledDownItineraries,
        ...nextLevelDetails,
      });
      drillDownIntoAndOutOfItineraryBlock(
        { parent_block_rid: lastBlockRid[lastBlockRid.length - 1] },
        userInfo,
        {
          itinerary_item_rid,
        },
      );
    }
  };

  const onWheel = useCallback((e, divId) => {
    e.stopPropagation();
    const container = document.getElementById(divId);
    const containerScrollPosition = document.getElementById(divId).scrollLeft;
    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
      behaviour: 'smooth',
      passive: false,
    });
  });

  const DayRenderer = useCallback(
    ({ data, index }) => {
      return (
        <ItinerariesSingleItemClone
          key={data.itinerary_item_rid}
          addDayHandler={addDayHandler}
          itineraryBlockTraverseUpHanlder={itineraryBlockTraverseUpHanlder}
          drilledDownItineraries={drilledDownItineraries}
          setDrilledDownItineraries={setDrilledDownItineraries}
          ele={data}
          idx={index}
          pageData={pageData}
          itinerary_info={itinerary_info}
          openDeleteModal={openDeleteModal}
          deleteItineraryItem={deleteItineraryItem}
          adventure_rid={adventure_rid}
          explorer_rid={explorer_rid}
          openItineraryItemModal={openItineraryItemModal}
          onWheel={onWheel}
          isHoverEnabled={isHoverEnabled}
        ></ItinerariesSingleItemClone>
      );
    },
    [
      addDayHandler,
      itineraryBlockTraverseUpHanlder,
      drilledDownItineraries,
      setDrilledDownItineraries,
      pageData,
      itinerary_info,
      openDeleteModal,
      deleteItineraryItem,
      adventure_rid,
      openItineraryItemModal,
      onWheel,
      isHoverEnabled,
    ],
  );

  const DropPlaceholder = useCallback(() => {
    return <div></div>;
  }, []);

  const reorderItineraryItem = useReorderItineraryItem();

  const dayReorder = useCallback(
    (a, b) => {
      const from = pageData.itineraryItems[a];
      const to = pageData.itineraryItems[b];
      reorderItineraryItem(from, to);
    },
    [pageData.itineraryItems],
  );

  return (
    <Container>
      <MultiBackendProvider>
        <DNDList
          type={DragTypes.Day}
          accept={[DragTypes.Day]}
          direction={DragDirections.Vertical}
          gap={0}
          keyField="itinerary_item_rid"
          data={pageData.itineraryItems}
          ItemRenderer={DayRenderer}
          Placeholder={DropPlaceholder}
          handlers={{ onReorderItem: dayReorder }}
        />
      </MultiBackendProvider>
    </Container>
  );
};

export default Itineraries;
