import { useState } from 'react';
import {
  useCampgroundData,
  useCampsiteData,
} from '../../../../../../util/custom_hooks/recreationGov';
import { useBlockDetails } from '../../../../../../util/custom_hooks/blocks';

const CAMPGROUND = 'campground';
const CAMPSITE = 'campsite';

const locations = {
  CAMPGROUND,
  CAMPSITE,
};



//TODO: REMOVE Hooks from inside hooks, just fetch all requried data for component directly 
export const useLibraryLocationBlockCard = (block_rid, blockMeta) => {
  const blockDetailsHook = useBlockDetails(block_rid);
  let blockLocationMetaHook;
  if (blockMeta && blockMeta != 'no results' && blockMeta.facility_id) {
    blockLocationMetaHook = useBlockWithLocationMeta(blockMeta);
  }

  const loading = (blockDetailsHook.loading)  || (!!blockLocationMetaHook?.loading);
  const errors = [
    ...blockDetailsHook.errors,
    ...(blockLocationMetaHook?.errors || []),
  ];

  // should parse all data here to remove logic from dumb component
  const data = {
    blockDetails: blockDetailsHook.blockDetails,
    locationDetails: blockLocationMetaHook?.data,
  };
  return { loading, errors, data };
};

const useBlockWithLocationMeta = (blockMeta) => {
  const { campsite_id, facility_id } = blockMeta;
  const isCampsite = campsite_id && facility_id;
  let locationDetailsHook;
  let locationType;
  if (isCampsite) {
    locationDetailsHook = useCampsiteData(facility_id, campsite_id);
    locationType = CAMPSITE;
  } else {
    locationDetailsHook = useCampgroundData(facility_id);
    locationType = CAMPGROUND;
  }
  const loading = locationDetailsHook.loading;
  const errors = [...locationDetailsHook.errors];
  const data = {
    locationType: locationType,
    data: locationDetailsHook.data,
    // pull images top level for easier access and prevent parsing location data in component
    images: getLocationImages(locationType, locationDetailsHook.data),
  };

  return { loading, errors, data };
};

const parseCampsiteImages = (data) => {
  return data.campsite_media.media.map((val) => val.URL);
};

const parseCampgroundImages = (data) => {
  return data.facility?.media.map((val) => val.URL);
};

const getLocationImages = (locationType, data) => {
  // return early if still loading data;
  if (!data) return [];
  if (locationType === CAMPGROUND) {
    return parseCampgroundImages(data);
  } else if (locationType === CAMPSITE) {
    return parseCampsiteImages(data);
  } else {
    // no valid location supplied
    return [];
  }
};
