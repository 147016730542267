import React from 'react';
import moment from 'moment';
import chroma from 'chroma-js';
import HubTooltip from '../../common/tooltip';
import {
  svgEdit,
  svgCopy,
  svgDelete,
  svgComment,
  svgClip,
  svgThumbsDown,
  svgThumbsUp,
  svgInviteAccepted,
  svgListBlock,
  svgUnnest,
  svgCopyToTripBoard,
  svgCompass,
} from 'components/svg_module';
import svgLibrary from 'components/svg_module/svg_library/library/functionLibrary';
const MiniBlocks = ({
  category,
  type,
  selected_flag,
  itinerary_rid,
  adventure_rid,
  name,
  start_date,
  end_date,
  duration,
  backgroundColor,
  vote_flag,
  openBlockModal,
  block,
  deleteBlock,
  accountRid,
  history,
  isAdventure,
  copyBlock,
  itineraryItemRid,
  voteOnBlock, //function to vote
  removeVote,
  fetchBlocksDynamic,
  handleLevelTraverse,
  currentLevelDisplayDetails,
  blockFile,
  deleteBlockFile,
  currentBlock,
  openBlockFileModal,
  userInfo,
  openDeleteModal,
  fetchBlockList,
  fetchBoardBlocks,
  drillDownIntoAndOutOfItineraryBlock,
  drilledDownItineraries,
  setDrilledDownItineraries,
  voteOnAdventureBlock,
  removeAdventureBlockVote,
  idx,
  itinerary_info,
  openCheckListModal,
  handleTraverseUp,
  upsertBlock,
  styleActive,
  copyToTripBoard,
  ...props
}) => {
  console.log(vote_flag, `mini blocks`);
  let startingColor;
  let endingColor;
  let style;
  if (type === 'block') {
    startingColor = backgroundColor !== 'ffff' ? backgroundColor : '#8bbdd3';
    if (backgroundColor === null) {
      startingColor = '#8bbdd3';
    }
    endingColor = chroma(startingColor).darken(3).hex();
    style = {
      backgroundColor: `linear-gradient(-125deg, ${startingColor}, ${endingColor}),`,
    };
  } else {
    startingColor = '#8bbdd3';
    endingColor = chroma(startingColor).darken(3).hex();
    style = {
      backgroundColor: `linear-gradient(-125deg, ${startingColor}, ${endingColor}),`,
    };
  }

  let blockFileCount = parseInt(block.block_file_count);
  let checkListCount = parseInt(block.child_count);
  return (
    <>
      {type === 'block' && block.folder_flag && (
        <div className="folder-top-wrapper">
          <div
            className="ffolder"
            style={
              styleActive
                ? {
                    background: `linear-gradient(to left, ${startingColor}, ${endingColor})`,
                  }
                : {
                    background: `linear-gradient(to left, ${startingColor}, ${endingColor})`,
                  }
            }
          ></div>
        </div>
      )}
      <div
        className={`mini-block-container
          ${
            ((type === 'block' && block.child_count > 0) ||
              block.family_count > 0) &&
            !block.list_flag &&
            'mini-block-container--no-top-left-radius'
          }
       
        `}
        style={
          styleActive
            ? {
                background: `linear-gradient(-125deg, ${startingColor}, ${endingColor})`,
              }
            : {
                background: `linear-gradient(-125deg, ${startingColor}, ${endingColor})`,
              }
        }
      >
        <div
          className={
            type === 'itineries' ? 'itinerary-block-top' : 'mini-block-top'
          }
        >
          <div
            className={`mini-block-left-button-top min-blk-btns tooltip--top--left ${
              ((type === 'block' && block.child_count > 0) ||
                block.family_count > 0) &&
              !block.list_flag
                ? 'tooltip--top--left-stacked'
                : null
            }`}
          >
            {svgEdit('color', 'min-blk-svg', 'min-blk-svg-outer')}
          </div>
          <div
            className={`mini-block-center-top min-blk-btns tooltip--top--left ${
              ((type === 'block' && block.child_count > 0) ||
                block.family_count > 0) &&
              !block.list_flag
                ? 'tooltip--top--left-stacked'
                : null
            }`}
          >
            {svgCopy('color', 'min-blk-svg', 'min-blk-svg-outer')}
          </div>

          {currentLevelDisplayDetails &&
            currentLevelDisplayDetails.level > 1 &&
            category !== 'it-item' && (
              <div
                className={`mini-block-right-button-top min-blk-btns  tooltip--top--left ${
                  ((type === 'block' && block.child_count > 0) ||
                    block.family_count > 0) &&
                  !block.list_flag
                    ? 'tooltip--top--left-stacked'
                    : null
                }`}
              >
                <div className="min-blk-btns-stcker">
                  {svgUnnest('color', 'min-blk-svg', 'min-blk-svg-outer')}
                </div>
              </div>
            )}
          <div
            className={`mini-block-right-button-top min-blk-btns tooltip--top--left ${
              ((type === 'block' && block.child_count > 0) ||
                block.family_count > 0) &&
              !block.list_flag
                ? 'tooltip--top--left-stacked'
                : null
            }`}
          >
            <div className="min-blk-btns-stcker">
              {svgDelete('color', 'min-blk-svg', 'min-blk-svg-outer')}
            </div>
          </div>
        </div>
        <div className="mini-block-center">
          {type === 'block' ? (
            <div className="mini-block-center-top">
              <div>
                <span className="it-b-sp">{name}</span>
              </div>
            </div>
          ) : (
            <div className="itinerary-title-wrapper">
              <div className="it-t-tp">
                <span>{name}</span>
              </div>
              <div className="it-t-bt">
                <span>
                  {`${moment(start_date).format('Do MMM YYYY')} - ${moment(
                    end_date,
                  ).format('Do MMM YYYY')}`}
                  ({duration})
                </span>
              </div>
            </div>
          )}

          <div
            className={`mini-blk-cnt-wm-wrapper ${
              block.list_flag && 'list-block-svg-container'
            }`}
          >
            {block &&
              block.ui_image_location !== null &&
              typeof svgLibrary[block.ui_image_location] === 'function' &&
              svgLibrary[block.ui_image_location](
                '',
                'watermark-svg',
                'watermark-svg-outer',
              )}
            {block &&
              block.list_flag &&
              svgListBlock(
                '',
                'watermark-svg',
                'watermark-svg-outer check-list-svg-outer',
              )}
          </div>
        </div>

        <div className="mini-block-bottom">
          {vote_flag && (category === 'it-item' || isAdventure) && (
            <div
              className={`mini-blk-btm-hierarchy-wrapper ${
                block.explorer_vote_up &&
                'mini-block-bottom-button--highlighted'
              } tooltip--bottom--left ${
                ((type === 'block' && block.child_count > 0) ||
                  block.family_count > 0) &&
                !block.list_flag
                  ? 'tooltip--bottom--left-stacked'
                  : null
              }`}
            >
              {block.total_vote_count_up}
              {svgThumbsUp(
                '',
                'mini-blk-hierarchy',
                'mini-blk-hierarchy-outer',
              )}
            </div>
          )}
          {vote_flag && (category === 'it-item' || isAdventure) && (
            <div
              className={`mini-blk-btm-hierarchy-wrapper ${
                block.explorer_vote_down &&
                'mini-block-bottom-button--highlighted'
              } tooltip--bottom--left ${
                ((type === 'block' && block.child_count > 0) ||
                  block.family_count > 0) &&
                !block.list_flag
                  ? 'tooltip--bottom--left-stacked'
                  : null
              }`}
            >
              {block.total_vote_count_down}
              {svgThumbsDown(
                '',
                'mini-blk-hierarchy',
                'mini-blk-hierarchy-outer',
              )}
            </div>
          )}
          {(category === 'it-item' || isAdventure) && block.comment_flag && (
            <div
              className={`mini-blk-btm-hierarchy-wrapper tooltip--bottom--left ${
                ((type === 'block' && block.child_count > 0) ||
                  block.family_count > 0) &&
                !block.list_flag
                  ? 'tooltip--bottom--left-stacked'
                  : null
              }`}
            >
              {block.comment_count}
              {svgComment('', 'mini-blk-hierarchy', 'mini-blk-hierarchy-outer')}
            </div>
          )}
          {!isAdventure &&
          block.adventure_block_exist_flag &&
          !block.adventure_block_rid ? (
            <div
              className={`mini-blk-btm-hierarchy-wrapper tooltip--bottom--left ${
                ((type === 'block' && block.child_count > 0) ||
                  block.family_count > 0) &&
                !block.list_flag
                  ? 'tooltip--bottom--left-stacked'
                  : null
              }`}
              style={{ paddingTop: '10px' }}
            >
              {svgInviteAccepted(
                '',
                'mini-blk-hierarchy',
                'mini-blk-hierarchy-outer adventure-included-svg-outer',
              )}
            </div>
          ) : (
            !block.adventure_block_rid && (
              <div
                className={`mini-blk-btm-hierarchy-wrapper min-blk-btns custom-bottom-button tooltip--bottom--left ${
                  ((type === 'block' && block.child_count > 0) ||
                    block.family_count > 0) &&
                  !block.list_flag
                    ? 'tooltip--bottom--left-stacked'
                    : null
                }`}
              >
                {svgCopyToTripBoard(
                  'color',
                  'min-blk-svg',
                  'min-blk-svg-outer',
                )}
              </div>
            )
          )}

          {blockFileCount > 0 && (
            <div
              className={`mini-blk-btm-hierarchy-wrapper left-margin-remover tooltip--bottom--left ${
                ((type === 'block' && block.child_count > 0) ||
                  block.family_count > 0) &&
                !block.list_flag
                  ? 'tooltip--bottom--left-stacked'
                  : null
              }`}
            >
              {blockFileCount}
              {svgClip('', 'mini-blk-hierarchy', 'mini-blk-hierarchy-outer')}
            </div>
          )}

          {block.location_flag && (
            <div
              className={`mini-blk-btm-hierarchy-wrapper left-margin-remover tooltip--bottom--left ${
                ((type === 'block' && block.child_count > 0) ||
                  block.family_count > 0) &&
                !block.list_flag
                  ? 'tooltip--bottom--left-stacked'
                  : null
              }`}
            >
              <div />
              {svgCompass('', 'mini-blk-hierarchy', 'mini-blk-hierarchy-outer')}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default MiniBlocks;
