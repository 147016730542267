import { borderRadius } from '@atlaskit/theme';
import { alpha } from '@mui/material';

const muiButtonStyles = {
  MuiButton: {
    styleOverrides: {
      defaultProps: {
        sx: {
          color: 'white',
        },
      },
      root: ({ theme }) => ({
        color: 'text.contrast',
        borderRadius: theme.shape.borderRadius / 2,
      }),
      text: ({ theme }) => ({
        color: theme.palette.primary.main,
      }),
      contained: ({ theme }) => ({
        transition: 'background-color 0.3s ease', // Transition for background-color
        position: 'relative', // Needed for pseudo-element
        boxShadow: 'none',
        '&:hover': {
          color: theme.palette.text.contrast,
          backgroundColor: theme.palette.primary.main, // Lighten color on hover
          boxShadow: 'none',
        },
        '&:active': {
          // override styles for when using component "a" it doesn't inherit a tag styles
          color: theme.palette.text.contrast,
          backgroundColor: theme.palette.primary.main, // Lighten color on hover
          boxShadow: 'none',
        },
        '&:focus': {
          color: theme.palette.text.contrast,
          backgroundColor: theme.palette.primary.main, // Lighten color on hover
          boxShadow: 'none',
        },
        '&::before': {
          content: '""',
          borderRadius: 'inherit',
          position: 'absolute',
          left: 0,
          top: 0,
          width: '0%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          transition: 'width 0.2s ease',
        },
        '&:hover::before': {
          width: '100%',
        },
        '&.MuiButton-sizeSmall': {
          height: '36px',
        },
        '&.MuiButton-sizeMedium': {
          height: '45px',
        },
        '&.MuiButton-sizeLarge': {
          height: '64px',
        },
      }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&:hover .MuiSvgIcon-root': {
          color: theme.palette.primary.main,
        },
        borderRadius: 100,
      }),
    },
    variants: [
      {
        props: { variant: 'contrast' },
        style: ({ theme }) => ({
          color: theme.palette.text.contrast,
          '&:hover': {
            //hover colors not working currently
            color: theme.palette.text.contrast,
            visibility: 'visible',
          },
          '&:focus': {
            color: theme.palette.text.contrast,
          },
        }),
      },
      {
        props: { variant: 'primary' },
        style: ({ theme }) => ({
          color: theme.palette.text.primary,
          '&:hover': {
            // hover and focus colors not working currently
            color: theme.palette.text.primary,
            visibility: 'visible',
          },
          '&:focus': {
            color: theme.palette.text.primary,
          },
        }),
      },
      {
        props: { variant: 'contained' },
        style: ({ theme }) => ({
          backgroundColor: alpha('#000', 0.05),
          borderRadius: theme.shape.borderRadius / 2,
          '&:hover': {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
          },
          '&:focus': {
            borderColor: theme.palette.primary.main,
          },
          '&:disabled': {
            color: theme.palette.action.disabled,
            borderColor: theme.palette.action.disabledBackground,
          },
        }),
      },
    ],
  },
};

export default muiButtonStyles;
