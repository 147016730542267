import { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchRecGovCampGroundsMapData } from '../../../../util/api_util/linked_app_api_util';
import { receivePersistRecGovCampGroundsData } from '../../../../actions/cache_actions';

const useGetFacilities = () => {
  const updatedAt = useSelector(state => state.dataCache.recGov?.recGovCampGrounds?.updated_at);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let today = new Date().toDateString();
    if(updatedAt === undefined || updatedAt !== today) {
      fetchRecGovCampGroundsMapData().then((res) => {
        let tempData = res.data.campgrounds.map(e => ({facility_id: e.facility_id, facility_coordinates: e.facility_coordinates, facility_name: e.facility_name}));
        dispatch(receivePersistRecGovCampGroundsData(tempData))
        //console.log(res.data.campgrounds);
      }).finally(() => setLoading(false))
    } else {
      setTimeout(() => setLoading(false), 2000);
    }
  }, [])

  return {loading};
}

export default useGetFacilities;