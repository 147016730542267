import { openAdventureModal } from 'actions/modal_actions';
import { useDispatch } from 'react-redux';
import useCurrentAdventure from './use-current-adventure';

const useEditAdventureModal = () => {
  const dispatch = useDispatch();

  const adventure = useCurrentAdventure();

  if (!adventure)
    throw new Error(
      'useEditAdventureModal requries entitites.adventure.currentAdventure data',
    );

  const openModal = async () => {
    dispatch(openAdventureModal(false, adventure, true));
  };

  return { openModal };
};

export default useEditAdventureModal;
