import { Button, ButtonBase, Tooltip } from '@mui/material';
import React from 'react';
import useAdventurePermissions from '../hooks/use-adventure-permissions';
import useEditAdventureModal from '../hooks/use-edit-adventure-modal';
import useCopyAdventureModalButton from '../hooks/use-copy-adventure-modal-button';

interface DuplicateAdventureButtonBaseProps {
  children: React.ReactElement<typeof Button>;
}

function DuplicateAdventureButtonBase({
  children,
}: DuplicateAdventureButtonBaseProps) {
  const { canEditTrip } = useAdventurePermissions();

  const { openModal: openCopyAdventureModal } = useCopyAdventureModalButton();

  const handleDuplicate = async () => {
    await openCopyAdventureModal();
  };

  const buttonBase = (
    <span>
      <ButtonBase
        disabled={!canEditTrip}
        onClick={handleDuplicate}
        sx={{ width: 1 }}
      >
        {React.Children.map(children, (child) =>
          React.isValidElement(child)
            ? React.cloneElement(child, { disabled: !canEditTrip })
            : child,
        )}
      </ButtonBase>
    </span>
  );

  return canEditTrip ? (
    buttonBase
  ) : (
    <Tooltip title="You don't have permission to do this">{buttonBase}</Tooltip>
  );
}

export default DuplicateAdventureButtonBase;
