import { ButtonBase } from '@mui/material';
import useWipeoutCarbonButton from '../hooks/use-wipeout-carbon-button';

function WipeoutCarbonPageButtonBase({ children }) {
  const { goto } = useWipeoutCarbonButton();

  return <ButtonBase onClick={goto}>{children}</ButtonBase>;
}

export default WipeoutCarbonPageButtonBase;
