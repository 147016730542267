import { useQuery } from '@tanstack/react-query';
import useUserInfo from 'features/user/hooks/use-user-info';
import { fetchAdventureFriend } from 'util/api_util/social_api_util';
import useCurrentAdventure from './use-current-adventure';
/**
 * @typedef {Object} AdventureFriend
 * @property {number} adventure_friend_rid
 * @property {number} explorer_rid
 * @property {number} adventure_rid
 * @property {string} adventure_name
 * @property {number} friend_rid
 * @property {string} friend_name
 * @property {number} friend_role_rid
 * @property {string} friend_role_name
 * @property {number} invite_response_rid
 * @property {string} invite_response
 * @property {string} ui_image_location
 * @property {string} city
 * @property {string} state_provence
 */

/**
 *
 * @returns
 */
const useAdventureAttendees = () => {
  const { explorer_rid } = useUserInfo();
  const { adventure_rid } = useCurrentAdventure();
  if (!adventure_rid) {
    throw new Error(
      'useAdventureAttendees must be used with selectedAdventure state',
    );
  }

  /**
   * Fetches the list of adventure friends.
   * @returns {Promise<AdventureFriend[]>} The list of adventure friends.
   */
  const queryFn = () =>
    fetchAdventureFriend(adventure_rid, 1, 100).then(
      (res) => res.data.userAdventureFriends,
    );

  return useQuery({
    queryKey: [explorer_rid, 'adventure', adventure_rid, 'attendees'],
    queryFn,
  });
};

export default useAdventureAttendees;
