import { useState, useCallback } from 'react';

type UseToggleReturn = [boolean, () => void];
const useToggle = (initialValue = false): UseToggleReturn => {
  const [state, setState] = useState(initialValue);

  const toggle = useCallback(() => {
    setState((prev) => !prev);
  }, []);

  return [state, toggle];
};

export default useToggle;
