// CardContainer.js
import { Box } from '@mui/material';

const ChipFlexLayout = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
      }}
    >
      {children}
    </Box>
  );
};

export default ChipFlexLayout;
