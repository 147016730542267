import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { setMapUiDetailShow } from '../../../../../actions/ui_actions';
import { useSelector, useDispatch } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import LibraryLocationBlockCard from './components/library-location-block-card';
import { openBlockModal } from '../../../../../actions/modal_actions';
import CampGround from '../../../../global_modal/hub_modal_pages/campground';
import CampSite from '../../../../global_modal/hub_modal_pages/campsite';
import HubButton from '../../../../hub-button';
import { fetchRecGovMetaData } from '../../../../../util/api_util/linked_app_api_util';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute ! important',
    '& .MuiModal-backdrop': {
      display: 'none',
    },
    '& .MuiDrawer-paper': {
      position: 'absolute ! important',
      width: '250px',
    },
  },
  btnShowDrawer: {
    position: 'absolute',
    left: '50%',
    top: '10px',
    zIndex: 50,
    transform: 'translateX(-50%)',
  },
  btnEdit: {
    fontSize: '10px',
    margin: 'inherit',
  },
  btnBack: {
    justifyContent: 'left !important',
    padding: '0 !important',
  },
  blockTypeIcon: {
    width: '15px',
    height: '15px',
    color: '#273A07',
    fill: '#273A07',
    '&.book-icon': {
      width: '10px',
      height: '10px',
    },
  },
}));

export default function DetailedPopup({
  onGotoMap,
  onViewCampsite,
  usecase,
  onAddCampground,
  onAddCampsite,
  selectedAdventure,
  callBackAfterInsertingBlockForTrip,
  callBackAfterInsertingBlock,
}) {
  const displayMapDetailPopups = useSelector(
    (state) => state?.ui?.map?.displayMapDetailPopups,
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [blockMeta, setBlockMeta] = useState('no results');
  const [loadingMeta, setLoadingMeta] = useState(true);
  const userInfo = useSelector((state) =>
    state.session.user ? state.session.user.userInfo[0] : null,
  );
  const btnAddTitle =
    usecase === 'itinerary'
      ? 'Add to itinerary'
      : selectedAdventure
      ? 'Add to trip'
      : 'Add to library';

  useEffect(() => {
    if (displayMapDetailPopups?.block_rid) {
      setLoadingMeta(true);
      //fetch recgov check...
      fetchRecGovMetaData(displayMapDetailPopups?.block_rid).then((res) => {
        setBlockMeta(res.data.result);
        setLoadingMeta(false);
      });
    }
  }, [displayMapDetailPopups]);

  useEffect(() => {
    return () => dispatch(setMapUiDetailShow(null));
  }, []);

  const editLibraryLocationBlock = (block) => {
    let openType = {
      isEditAndView: true,
    };
    if (displayMapDetailPopups?.location_type === 'libraryLocationBlock') {
      let uiUpdatePayload = () => callBackAfterInsertingBlock(block);
      dispatch(
        openBlockModal(openType, '15a', uiUpdatePayload, block, userInfo),
      );
    } else {
      let uiUpdatePayload = () => callBackAfterInsertingBlockForTrip(block);
      dispatch(
        openBlockModal(openType, '17a', uiUpdatePayload, block, userInfo),
      );
    }
  };

  return (
    <>
      {(displayMapDetailPopups?.location_type === 'libraryLocationBlock' ||
        displayMapDetailPopups?.location_type === 'allTripItineraryBlocks' ||
        displayMapDetailPopups?.location_type === 'tripRoutes') && (
        <Drawer
          className={classes.root}
          open={!!displayMapDetailPopups}
          onClose={() => dispatch(setMapUiDetailShow(null))}
          container={document.getElementById('block-detail-container')}
        >
          <div style={{ padding: '4px' }}>
            <IconButton
              className={classes.btnBack}
              onClick={() => dispatch(setMapUiDetailShow(null))}
            >
              <ArrowBackIcon
                className={classes.blockTypeIcon}
                fontSize="small"
                color="#273A07"
              />
            </IconButton>
          </div>
          {loadingMeta ? (
            <></>
          ) : (
            <>
              <LibraryLocationBlockCard
                block={displayMapDetailPopups}
                onEdit={(block) => editLibraryLocationBlock(block)}
                blockMeta={blockMeta}
              />
              <div style={{ height: 'fit-content' }}>
                {blockMeta?.campsite_id && blockMeta?.facility_id ? (
                  <CampSite
                    id={blockMeta.campsite_id}
                    campgroundId={blockMeta.facility_id}
                    onClose={() => dispatch(setMapUiDetailShow(null))}
                    onGotoMap={onGotoMap}
                    usecase={usecase}
                    addButton={<></>}
                    noBack
                  />
                ) : blockMeta?.facility_id ? (
                  <CampGround
                    id={blockMeta.facility_id}
                    onClose={() => dispatch(setMapUiDetailShow(null))}
                    onGotoMap={onGotoMap}
                    usecase={usecase}
                    onViewCampsite={onViewCampsite}
                    addButton={<></>}
                    noBack
                  />
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </Drawer>
      )}
      {displayMapDetailPopups?.location_type === 'campground' && (
        <Drawer
          className={classes.root}
          open={!!displayMapDetailPopups}
          onClose={() => dispatch(setMapUiDetailShow(null))}
          container={document.getElementById('campground-container')}
        >
          <CampGround
            id={displayMapDetailPopups?.facility_id}
            onClose={() => dispatch(setMapUiDetailShow(null))}
            onGotoMap={onGotoMap}
            usecase={usecase}
            onViewCampsite={onViewCampsite}
            addButton={
              <HubButton
                className={classes.btnEdit}
                onClick={() =>
                  onAddCampground(displayMapDetailPopups?.facility_id)
                }
                title={btnAddTitle}
                mode="red"
              />
            }
          />
        </Drawer>
      )}
      {displayMapDetailPopups?.location_type === 'campsite' && (
        <Drawer
          className={classes.root}
          open={!!displayMapDetailPopups}
          container={document.getElementById('campsite-container')}
        >
          <CampSite
            id={displayMapDetailPopups?.campsite_id}
            campgroundId={displayMapDetailPopups?.facility_id}
            onClose={() => dispatch(setMapUiDetailShow(null))}
            onGotoMap={onGotoMap}
            usecase={usecase}
            addButton={
              <HubButton
                className={classes.btnEdit}
                onClick={() =>
                  onAddCampsite(
                    displayMapDetailPopups?.facility_id,
                    displayMapDetailPopups?.campsite_id,
                  )
                }
                title={btnAddTitle}
                mode="red"
              />
            }
          />
        </Drawer>
      )}
    </>
  );
}
