export const setUiState = (name, data) => {
  let dataStringified =  JSON.stringify(data)
  localStorage.setItem(name, dataStringified)
}

export const persistUiState = (name) => {
  let dataFetched = localStorage.getItem(name)
  let dataParsed = JSON.parse(dataFetched)
  return dataParsed
}

