import styled from 'styled-components';

export const CarouselContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: ${({ width }) => width || '100%'};
  ${({ height }) => height && `height: 200px`};
  border-radius: ${({ radius }) => radius || '0px'};
  ${({ aspectRatio }) => aspectRatio && `aspect-ratio: ${aspectRatio}`}
`;
export const CarouselBody = styled.div`
  transform: ${({ curIndex }) => 'translateX(-' + curIndex * 100 + '%)'};
  white-space: nowrap;
  transition: transform 0.3s;
  width: 100%;
  height: fit-content;
`;

export const CarouselItem = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: ${({ width }) => width || '100%'};
  ${({ height }) => height && `height: 200px`};
  ${({ ratio }) => ratio && `aspect-ratio: ${ratio}`};
`;

export const LeftIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: fit-content;
  height: 100%;
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  & svg path {
    fill: #EBEBEB;
  }
`;

export const RightIndicator = styled.div`
  position: absolute;
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  height: 100%;
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  & svg path {
    fill: #EBEBEB;
  }
`;

export const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  z-index: 8;
  width: 100%;
`;
