import React, { useCallback } from 'react';
import {
  svgListBlock,
} from 'components/svg_module';
import svgLibrary from 'components/svg_module/svg_library/library/functionLibrary';
import Activity from "./activity"
import Folder from './folder';
import List from './list';
import { blockType } from "./utils"
import { BlockTypes } from './constants';
import { IconWrapper, TitleWrapper } from './index';

const BlockPreview = ({ block }) => {
  const {
    block_name,
    ui_color,
    ui_image_location
  } = block

  const isWhite = ui_color === '#F9FBFF'

  const Container = useCallback(({ color, children, ...props }) => {
    const type = blockType(block)
    switch(type) {
      case BlockTypes.Folder:
        return <Folder color={color} {...props}>{children}</Folder>
      case BlockTypes.Activity:
        return <Activity color={color} {...props}>{children}</Activity>
      case BlockTypes.List:
        return <List color={color} {...props}>{children}</List>
    }

    return null
  }, [block])

  const Icon = useCallback(() => {
    const SvgIcon = svgLibrary[ui_image_location] || (() => null)

    switch(type) {
      case BlockTypes.Folder:
        return <IconWrapper isWhite={isWhite}>{SvgIcon('', 'watermark-svg', 'watermark-svg-outer')}</IconWrapper>
      case BlockTypes.Activity:
        return <IconWrapper isWhite={isWhite}>{SvgIcon('', 'watermark-svg', 'watermark-svg-outer')}</IconWrapper>
      case BlockTypes.List:
        return <IconWrapper isWhite={isWhite}>{svgListBlock('', 'watermark-svg', 'watermark-svg-outer check-list-svg-outer')}</IconWrapper>
    }

    return null
  }, [block, isWhite])

  const type = blockType(block)

  return (
    <Container className="block" color={ui_color}>
      <div className="wrapper">
        <div style={{height: '20px'}}></div>
        <div className="center-content">
          <div className="title-wrapper">
            <TitleWrapper className="title" isWhite={isWhite}>
              {block_name}
            </TitleWrapper>
          </div>
          <div className="icon">
            <Icon />
          </div>
        </div>
        <div style={{height: '15px'}}></div>
      </div>
    </Container>
  );
}

export default BlockPreview
