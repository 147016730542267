import React, { useCallback, useMemo } from 'react';
import MiniBlocks from '../blocks';
import { merge } from 'lodash';
import DragItem from '../../../pages/pwa_hub/itineraries/drag-item';

const Item = ({
  block,
  blockIndex,
  adventure_rid,
  handleLevelTraverse,
  displayDetails,
  currAdvDis,
  itinerary_item,
  itinerary_info,
  drilledDownItineraries,
  setDrilledDownItineraries,
  handleTraverseUp,
  type,
}) => {
  const ItineraryBlock = useCallback(() => {
    let modifiedBlock = merge({}, block);
    modifiedBlock['itinerary_item_rid'] = itinerary_item.itinerary_item_rid;
    return (
      <div className="itinerary-adventure-block-children">
        <MiniBlocks
          category="it-item"
          vote_flag={block.vote_flag}
          type="block"
          name={block.block_name}
          description={block.block_description}
          backgroundColor={block.ui_color}
          grabber={`grabber-${blockIndex}`}
          block_rid={block.block_rid}
          block={block.itinerary_item_rid ? block : modifiedBlock}
          itineraryItemRid={itinerary_item.itinerary_item_rid}
          itinerary_rid={itinerary_info.itinerary_rid}
          setDrilledDownItineraries={setDrilledDownItineraries}
          drilledDownItineraries={drilledDownItineraries}
          adventure_rid={adventure_rid}
          idx={blockIndex}
          itinerary_info={itinerary_info}
          handleTraverseUp={handleTraverseUp}
        />
      </div>
    );
  }, [
    block,
    blockIndex,
    adventure_rid,
    handleLevelTraverse,
    displayDetails,
    currAdvDis,
    itinerary_item,
    itinerary_info,
    drilledDownItineraries,
    setDrilledDownItineraries,
    handleTraverseUp,
    type,
  ]);

  const OtherBlock = useCallback(() => {
    return (
      <div className="itinerary-adventure-block-children">
        <MiniBlocks
          category="block_cache"
          type="block"
          name={block.block_name}
          description={block.block_description}
          backgroundColor={block.ui_color}
          grabber={`grabber-${blockIndex}`}
          adventure_rid={adventure_rid}
          vote_flag={block.vote_flag}
          block_rid={block.block_rid}
          block={block}
          itinerary_info={itinerary_info}
          isAdventure={currAdvDis === 'Adv-Blocks'}
          handleLevelTraverse={handleLevelTraverse}
          currentLevelDisplayDetails={displayDetails}
          adventureBlock={currAdvDis === 'Adv-Blocks' ? true : false}
          idx={blockIndex}
          handleTraverseUp={handleTraverseUp}
        />
      </div>
    );
  }, [
    block,
    blockIndex,
    adventure_rid,
    handleLevelTraverse,
    displayDetails,
    currAdvDis,
    itinerary_item,
    itinerary_info,
    drilledDownItineraries,
    setDrilledDownItineraries,
    handleTraverseUp,
    type,
  ]);

  return useMemo(
    () =>
      type === 'itinerary-item-block' ? <ItineraryBlock /> : <OtherBlock />,
    [type],
  );
};

export default Item;
