import { Box, Typography } from '@mui/material';

function SectionHeader({ children }) {
  return (
    <Box marginY={1}>
      <Typography variant="h4" color="text.primary">
        {children}
      </Typography>
    </Box>
  );
}

export default SectionHeader;
