import React, { useCallback } from 'react';
const NewLocationBlockPopup = ({
  marker,
  itineraryInfo,
  openBlockModal,
  fetchLocationBlocks,
  userInfo,
  mapFilter,
  itineraryDetails,
  ...props
}) => {
  const handleCancel = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    marker.remove();
  });
  const callBackAfterInsertingBlock = async (marker) => {
    let returnedData;
    if (mapFilter === 'itineraryLocationBlocks') {
      returnedData = await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: itineraryInfo.itinerary_rid,
      });
    } else if (mapFilter === 'allTripBlocks') {
      returnedData = await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: null,
      });
    } else {
      returnedData = await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: itineraryInfo.itinerary_rid,
        direction_flag: true,
      });
    }
    marker.remove();
  };
  const handleNew = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    let openType = { isNew: true };
    let location_point = marker.getLngLat();
    let blockPayload = {
      location_point: location_point,
      location_flag: true,
      itineraryDetails: itineraryDetails,
      adventure_rid: itineraryInfo?.adventure_rid,
    };
    let uiUpdatePayload = {
      uiUpdateFunction: () => callBackAfterInsertingBlock(marker),
      itineraryInfo,
      explorer_rid: userInfo.explorer_rid,
    };
    blockPayload['adventureRid'] = itineraryInfo?.adventure_rid;
    openBlockModal(openType, '4a', uiUpdatePayload, blockPayload, userInfo);
  });
  return (
    <div className="new-location-block-wrapper">
      <button
        onClick={handleNew}
        className="new-location-block-pop-button new-location-block-pop-button--new"
      >
        Add new location
      </button>
      <button
        onClick={handleCancel}
        className="new-location-block-pop-button new-location-block-pop-button--cancel"
      >
        Cancel
      </button>
    </div>
  );
};

export default NewLocationBlockPopup;
