import axios from 'axios';

export const translateToGeoJson = (data, accountRid) => {
  if (data) {
    let dataTransformed = data.map((ele) => {
      if (!ele.location_point) {
        return;
      }
      return {
        type: 'Feature',
        properties: {
          name: ele.block_name,
          id: ele.block_rid,
          accountRid: ele.account_rid,
          block: ele,
          count: 1,
        },
        geometry: {
          type: 'Point',
          coordinates: [ele.location_point.lng, ele.location_point.lat],
        },
      };
    });
    const baseData = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [...dataTransformed],
      },
      cluster: true,
      clusterRadius: 60,
      clusterProperties: {
        locationBlockCount: ['+', ['get', 'count']],
        // blockRids: ["concat", ],
        blockRids: ['concat', ['get', 'id']],
      },
    };
    return baseData;
  }
};

export const translateAsDataOnly = (data, accountRid) => {
  let dataTransformed = data.map((ele) => ({
    type: 'Feature',
    properties: {
      name: ele.block_name,
      id: ele.block_rid,
      accountRid: ele.account_rid,
      block: ele,
      count: 1,
    },
    geometry: {
      type: 'Point',
      coordinates: [ele.location_point.lng, ele.location_point.lat],
    },
  }));
  const baseData = {
    type: 'FeatureCollection',
    features: [...dataTransformed],
  };
  return baseData;
};

export const translateIntoRoutes = (data) =>
  new Promise((resolve, reject) => {
    try {
      let dataTransformed = data.map(
        (ele) =>
          `${Number.parseFloat(ele.location_point.lng)},${Number.parseFloat(
            ele.location_point.lat,
          )}`,
      );
      let coordinates = dataTransformed.join(';');
      let radius = dataTransformed.map(() => 'unlimited');
      let radiuses = radius.join(';');
      axios
        .get(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${dataTransformed.join(
            ';',
          )}?access_token=pk.eyJ1IjoibG9kZXN0YXJodWJkZXYiLCJhIjoiY2tzYXd3aHNuMDE1ZDMwcDRjZmVrMDRrcyJ9.EccewqzpbeugVzO2ARAAiA&geometries=geojson&steps=true&radiuses=${radiuses}&overview=full`,
        )

        .then(({ data, ...rest }) => {
          if (data.code !== 'Ok') {
            reject(data.code)
          }
          let sourceData = {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: data.routes[0].geometry,
            },
          };
          let userRoutesMetrics;
          resolve({ sourceData, userRoutesMetrics });
        })
        .catch(() => {
          reject('error at mapbox api');
        });
    } catch (err) {
      reject('error at mapbox api');
    }
  });

// this is to take myroutes data and create a route accounting for flights;
export const translateIntoRoutesWithFlight = (data) =>
  new Promise(async (resolve, reject) => {
    let dat = data;

    try {
      /*
      in order to account for data with flights, what needs to happend is the following:
      from a set of location blocks a flight will cut off a route and began a new route from the end point of that the flight
    */
      let returnedLocationCoordintes = []; // this will be an array that contains object structured likethis:
      /* {
        type: "default"(car) || flight, 
        sourceData: required source data to draw the map either flight or direction, 
        layerId:myRoutes-{idxOfItemInRetunedLocationCoordaintesArray}-{type==default ? numberOfCoordinates : flightOrigingName+'to'+flightDestinationName}}
      */
      let locationPointGroups = [];
      let newStartingPoint;
      let lastBlockType = undefined; // this is so that when a location point goest from a -> b where b is the flight data,\
      // b's starting point needs to be end of a, and vicer versa,
      // this variable will either be flight or car
      for (let i = 0; i < data.length; i++) {
        let block = data[i];
        if (block.location_point.flight_data) {
          /*
            flight data can come in following instances:
              trip beginning with flight,
              flight after driving,
              flight after flight
              
            flight as the last part of the trip will be automatically calculated 
          */
          if (lastBlockType === undefined) {
            // this is when the trip starts as a flight
            returnedLocationCoordintes.push({
              type: 'flight',
              sourceData: transformFlightIntoMapData(block),
              layerId: generateLayerId(
                'flight',
                returnedLocationCoordintes,
                block,
              ),
            });
          }
          if (lastBlockType === 'flight') {
            // this is when a trip connects from flight to flight

            locationPointGroups.push(newStartingPoint); // inputs last destination point to be used as the new starting point
            locationPointGroups.push(
              translateFlightDataToLocationPoint('origin', block),
            ); // inputs current location starting point to use as new destination

            let coordinates = locationPointGroups.join(';');
            let radius = locationPointGroups.map(() => 'unlimited');
            let radiuses = radius.join(';');
            await axios
              .get(
                `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?access_token=pk.eyJ1IjoibG9kZXN0YXJodWJkZXYiLCJhIjoiY2tzYXd3aHNuMDE1ZDMwcDRjZmVrMDRrcyJ9.EccewqzpbeugVzO2ARAAiA&geometries=geojson&steps=true&radiuses=${radiuses}&overview=full`,
              ).catch(error => {
                console.error("Mapbox directions error", error.response.data);
                reject(error.response.data.code);
              })
              .then(({ data, ...rest }) => {
                if (data.code !== 'Ok') {
                  console.error("Mapbox could not supply a route for:", coordinates);
                  reject(data.code)
                }
                // fetching driving routes from mapbox
                let sourceData = {
                  type: 'geojson',
                  data: {
                    type: 'Feature',
                    properties: {},
                    geometry: data.routes[0].geometry,
                  },
                };
                let userRoutesMetrics;
                // push the trasnformed result of the driving between flight to flight in to array that will be returned
                returnedLocationCoordintes.push({
                  type: 'default',
                  sourceData,
                  layerId: generateLayerId(
                    'car',
                    returnedLocationCoordintes,
                    locationPointGroups,
                  ),
                });
                //empty the locationPointGroups;
                locationPointGroups = [];
                // transform flight data and push into array being returned from this function
                returnedLocationCoordintes.push({
                  type: 'flight',
                  sourceData: transformFlightIntoMapData(block),
                  layerId: generateLayerId(
                    'flight',
                    returnedLocationCoordintes,
                    block,
                  ),
                });
                return;
              });
          }

          if (lastBlockType === 'car') {
            // this is when the last transporation was a car
            //first push the origin of the flight data into the locationPointGroups that has been getting populated
            locationPointGroups.push(
              translateFlightDataToLocationPoint('origin', block),
            );
            // take all of the location points inside the locationPointGroups into array of coordinates
            let coordinates = locationPointGroups.join(';');
            let radius = locationPointGroups.map(() => 'unlimited');
            let radiuses = radius.join(';');
            await axios
              .get(
                `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?access_token=pk.eyJ1IjoibG9kZXN0YXJodWJkZXYiLCJhIjoiY2tzYXd3aHNuMDE1ZDMwcDRjZmVrMDRrcyJ9.EccewqzpbeugVzO2ARAAiA&geometries=geojson&steps=true&radiuses=${radiuses}&overview=full`,
              ).catch(error => {
                console.error("Mapbox directions error", error.response.data);
                reject(error.response.data.code);
              })
              .then(({ data, ...rest }) => {
                if (data.code !== 'Ok') {
                  console.error("Mapbox could not supply a route for:", data);
                  reject(data.code);
                }
                // fetching driving routes from mapbox
                let sourceData = {
                  type: 'geojson',
                  data: {
                    type: 'Feature',
                    properties: {},
                    geometry: data.routes[0].geometry,
                  },
                };
                let userRoutesMetrics;
                // push the trasnformed result of the driving between flight to flight in to array that will be returned
                returnedLocationCoordintes.push({
                  type: 'default',
                  sourceData,
                  layerId: generateLayerId(
                    'car',
                    returnedLocationCoordintes,
                    locationPointGroups,
                  ),
                });
                //empty the locationPointGroups;
                locationPointGroups = [];
                // transform flight data and push into array being returned from this function
                returnedLocationCoordintes.push({
                  type: 'flight',
                  sourceData: transformFlightIntoMapData(block),
                  layerId: generateLayerId(
                    'flight',
                    returnedLocationCoordintes,
                    block,
                  ),
                });
                return;
              });
          }

          // at the end of iteration set the new starting point  to be used in the next iteration and update lastBlockType
          newStartingPoint = translateFlightDataToLocationPoint(
            'destination',
            block,
          );

          lastBlockType = 'flight';
        } else {
          /*
            car travels need to account for these factors:
              when last travel was a flight since locationPointGroups will be resetted
              when car travel is the last method since then no other inputs will be added in to the location PointGroup
          */
          if (lastBlockType === 'flight') {
            //when last travel type was a flight first add the new starting point set by  flight block
            locationPointGroups.push(newStartingPoint);
          }
          // will always add its locatin points
          locationPointGroups.push(
            `${Number.parseFloat(block.location_point.lng)},${Number.parseFloat(
              block.location_point.lat,
            )}`,
          );
          if (i === data.length - 1) {
            let coordinates = locationPointGroups.join(';');
            let radius = locationPointGroups.map(() => 'unlimited');
            let radiuses = radius.join(';');
            await axios
              .get(
                `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?access_token=pk.eyJ1IjoibG9kZXN0YXJodWJkZXYiLCJhIjoiY2tzYXd3aHNuMDE1ZDMwcDRjZmVrMDRrcyJ9.EccewqzpbeugVzO2ARAAiA&geometries=geojson&steps=true&radiuses=${radiuses}&overview=full`,
              ).catch(error => {
                console.error("Mapbox directions error", error.response);
                reject(error.response.data.code);
              })
              .then(({ data, ...rest }) => {
                // fetching driving routes from mapbox
                if (data.code !== 'Ok') {
                  console.error("Mapbox could not supply a route for:", coordinates);
                  reject(data.code);
                }
                let sourceData = {
                  type: 'geojson',
                  data: {
                    type: 'Feature',
                    properties: {},
                    geometry: data.routes[0].geometry,
                  },
                };
                let userRoutesMetrics;
                // push the trasnformed result of the driving between flight to flight in to array that will be returned
                returnedLocationCoordintes.push({
                  type: 'default',
                  sourceData,
                  layerId: generateLayerId(
                    'car',
                    returnedLocationCoordintes,
                    locationPointGroups,
                  ),
                });
                //empty the locationPointGroups;
                locationPointGroups = [];
                // transform flight data and push into array being returned from this function
              });
          }

          //always switch the transportation type at the end
          lastBlockType = 'car';
        }
        if (i === data.length - 1) {
          return resolve(returnedLocationCoordintes);
        }
      }
    } catch (err) {
      console.error("translate route error:", err);
      reject('There was an error creating a route with the given coordinates');
    }
  });

function translateFlightDataToLocationPoint(type, block) {
  if (type === 'destination') {
    let coordinate = `${Number.parseFloat(
      block.location_point.destination_airport_location_point.lng,
    )},${Number.parseFloat(
      block.location_point.destination_airport_location_point.lat,
    )}`;

    return coordinate;
  }
  if (type === 'origin') {
    let coordinate = `${Number.parseFloat(
      block.location_point.origin_airport_location_point.lng,
    )},${Number.parseFloat(
      block.location_point.origin_airport_location_point.lat,
    )}`;

    return coordinate;
  }
}

function transformFlightIntoMapData(block) {
  const { lng, lat } = block.location_point.origin_airport_location_point;
  let origin = [lng, lat];
  let destination = [
    block.location_point.destination_airport_location_point.lng,
    block.location_point.destination_airport_location_point.lat,
  ];

  const route = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [origin, destination],
        },
      },
    ],
  };

  const point = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: origin,
        },
      },
    ],
  };
  return { route, point };
}

function generateLayerId(type, returningArray, switchData) {
  //switch data will either be the block || coordinates groups
  if (type === 'flight') {
    const {
      destination_airport_location_id,
      origin_airport_location_id,
    } = switchData.location_point;
    return `myRoutes-${returningArray.length}-${origin_airport_location_id}to${destination_airport_location_id}`;
  }
  if (type === 'car') {
    return `myRoutes-${returningArray.length}-${switchData.length}`;
  }
}
//
/* idea to add onto impact piece
returns
{
    jointDirection: [], // single be a single 1D array for single direction, or 2D array of array of
    userDirections: [] /* available if user triggers the type into 'multiple', an array of 
    objects with the value {username: 'stuff', account_rid:'stuff, directionsCoordianates: []} 
    where directions coordinates equate to to direction from user starting point to a meetup destination ,
}

*/

/* this will require couple the following:
PSQL: 
  fetch_user_starting_points(:adventure_rid)
  upsert_usert_starting_points(:adventure_rid, :explorer_rid or :account_rid, :location_points)
UI: 
  if the user start clicks render each starting point,
  it will trigger a modal where it will say:
    for collaboratores:"The owner of the user has requested to fetch your starting location for impact calculation, click allow " 
    for trip owner: "Click allow to input your starting location for the trip"

  when the user clicks allow, the upsert_user_starting_points will be ran
  the itinerary page will run fetch_user_starting_point to get user starting points
  the fetched data will feed into RenderCollaboraterStartingPoint hook in custom_marker.js in custom_hooks folder to 
  add source and draw markers on the map then combined with the starting location of the trip 
  fetched from return block maps function to be fed into DrawUserStartingDirections hook in directions_hooks under custom_hooks folder
  which will feed translateMeetUpPointsDirections to then insert source and draw layers for 
  directions from user starting location to trip starting location

*/
function combineMatchGeometries(points) {
  let coordinates = points.flatMap((ele) => ele.geometry.coordinates);
  return {
    type: 'LineString',
    coordinates,
  };
}
function returnFeatureCollections(points) {
  return points.map((ele) => ({ type: 'Feature', geometry: ele.geometry }));
}
export const translateMeetUpPointDirections = (data, ...userMeetUpPoints) => { };
