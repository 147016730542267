import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Button, Box /*, Rating */ } from '@mui/material';
import { makeStyles } from '@material-ui/core';
// import { isMediaFile, isVideoFile, isImageFile } from '../../../util/helpers/file-helper';
// import Carousel, { CarouselCard } from '../../carousel';
// import { openCampgroundModal } from '../../../actions/modal_actions';
import MediaSliderWithPreview from '../media-slider-with-preview/media-slider-with-preview';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
    width: '100%',
    boxSizing: 'border-box',
    border: '1px solid #E9EBEB',
  },
  information: {
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    flexGrow: 1,
  },
  box: {
    width: '95%',
    overflowWrap: 'break-word'
  },
  imageCard: {
    height: '100%',
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  crsRoot: {
    width: '100%',
    aspectRatio: '24 / 15',
    '& .crs-body': {
      height: '100%',
    },
    '& .crs-item': {
      height: '100%',
    },
    '& .crs-dot': {
      width: '4px',
      height: '4px',
    },
    '& .crs-arrow-left svg, & .crs-arrow-right svg': {
      width: '6px',
      height: '10px',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '14px !important',
    lineHeight: '20px !important',
    color: '#273A07 !important',
  },
  description: {
    fontWeight: 300,
    fontSize: '12px !important',
    lineHeight: '15px !important',
    color: '#827700 !important',
  },
  btnViewDetail: {
    fontWeight: 700,
    fontSize: '12px !important',
    lineHeight: '15px !important',
    color: '#B34104 !important',
    padding: '0px !important',
  },
  btnContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
});

const CampGroundCard = ({
  images,
  title,
  description,
  sites,
  facilityId,
  onViewDetail,
  addButton,
  uniqueKey,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const openDetail = () => {
    onViewDetail(facilityId);
  };

  const isMediaExist = images.length > 0;

  return (
    <Box className={classes.container}>
      {isMediaExist && (
        <MediaSliderWithPreview
          images={images.slice(0, 5)}
          width={'100%'}
          className={'block-image-carousel'}
          uniqueKey={uniqueKey}
        />
      )}
      <Box className={classes.information}>
        <Box className={classes.box}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.description}>{description}</Typography>
          <Typography className={classes.description}>{sites}</Typography>
          <Box>
            <Button
              onClick={openDetail}
              variant="text"
              className={classes.btnViewDetail}
            >
              View Detail
            </Button>
          </Box>
        </Box>
        <Box className={classes.btnContainer}>{addButton}</Box>
      </Box>
    </Box>
  );
};

CampGroundCard.defaultProps = {
  onViewDetail: () => {},
};

export default CampGroundCard;
