import { useState, MouseEvent, useCallback } from 'react';

interface UseMenuControllerReturn {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleClick: (event: MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
}

function useMenuController(): UseMenuControllerReturn {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    console.debug(event.currentTarget);
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);

  return { anchorEl, open, handleClick, handleClose };
}

export default useMenuController;
