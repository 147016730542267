import { useState } from 'react';
import styled from 'styled-components';
import '../../stylesheets/modals/_howto_modal.scss';
import Checkbox from '../checkbox';
import HubCircularProgress from '../common/circular-progress';
import HubButton from '../hub-button';
import { ReactComponent as CloseButton } from './close-btn.svg';
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ThemeProvider,
} from '@mui/material';
import Theme from 'components/themes/time_picker/time_picker_theme';
import muiTheme from 'stylesheets/mui/themes/defaultTheme';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1300;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  position: relative;
  background-color: ${({ mode }) =>
    (mode === 'dark' ? '#F9EED8' : mode) || '#fff'};
  color: #273a07;
  border-radius: 20px;
  ${({ width }) => width && `width: ${width}px`}
  ${({ height }) =>
    height && `width: ${height}px`}
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 95%;
  max-height: 95%;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 24px 0 24px;
  height: 30px;
`;

const ModalTitle = styled.div`
  flex-grow: 1;
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  text-align: center;
  color: #273a07;
`;

const ModalBody = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const ModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
`;

const HubModal = (props) => {
  if (!props.show) return <></>;
  const [checked, setChecked] = useState(false);
  return (
    <Dialog
      maxWidth="sm"
      open={props.show}
      onClose={props.onClose}
      BackdropProps={{ style: { backgroundColor: alpha('#fff', 0.5) } }}
      PaperProps={{
        sx: {
          overflow: 'hidden',
          borderRadius: 5,
          p: 2,
          m: 0,
        },
      }}
    >
      {(props.loading || '') !== '' && (
        <div className="modal-loading-wrapper">
          {props.loading === 'Uploading' ? (
            <HubCircularProgress value={props.loadingProcess} />
          ) : (
            <div className="animated-ring">
              <span className="ring-light"></span>
              {/* <div className="component-loading-image-container"> */}
              <img
                src="/logos_v2/lodestar-mark.svg"
                layout="fill"
                // objectfit="contain"
                alt="lodestar-mark"
                className="loading-image-container"
              />
              {/* </div> */}
            </div>
          )}
          <div className="component-loading-message-container animate-text-grow-size-whole">
            {/* {message.split("").map((char, idx) => (
                    <span key="laoding-message-char">{char}</span>
                  ))} */}
            <span>{props.loading ? `${props.loading}...` : 'Loading...'}</span>
          </div>
        </div>
      )}
      <ModalHeader>
        {props.title && <ModalTitle>{props.title}</ModalTitle>}
        {!props.hiddenHeaderButton && (
          <HubButton
            mode="icon"
            width="25px"
            height="25px"
            align="center"
            icon={<CloseButton />}
            onClick={() => props.onClose()}
          ></HubButton>
        )}
      </ModalHeader>
      <ModalBody>{props.children}</ModalBody>
      {props.footer && <ModalFooter>{props.footer}</ModalFooter>}
      {!props.hideFooter && !props.footer && (
        <DialogActions sx={{ alignItems: 'center', justifyContent: 'center' }}>
          {props.submitButton ? (
            props.submitButton
          ) : (
            <ThemeProvider theme={muiTheme}>
              <Button
                variant="contained"
                sx={{
                  minWidth: 100,
                  fontSize: '1.1rem', // Adjust the size
                  fontWeight: 'bold',
                }}
                onClick={() =>
                  props.onApproved({
                    checked,
                    hasCheckBox: props.showCheckBox,
                  })
                }
              >
                {props.buttonTitle || 'Close'}
              </Button>
            </ThemeProvider>
            // <HubButton
            //   title={props.buttonTitle || 'Close'}
            //   mode={'red'}
            //   onClick={() =>
            //     props.onApproved({
            //       checked,
            //       hasCheckBox: props.showCheckBox,
            //     })
            //   }
            // />
          )}
          {props.showCheckBox && (
            <Checkbox
              title="Don't show me again"
              value={checked}
              onChange={(value) => setChecked(value)}
            />
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

HubModal.defaultProps = {
  onClose: () => {},
  onApproved: () => {},
};

export default HubModal;
