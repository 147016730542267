import {
  fetchItineraryDetails,
  moveItineraryBlock,
  moveItineraryBlockBetweenItems,
  deleteItineraryBlockItem,
  editItineraryItem,
  editItinerary,
  deleteItinerary,
  upsertItineraryItem,
  deleteItineraryItem,
  fetchItineraryVotes,
  fetchItineraryComments,
  upsertItineraryVotes,
  deleteItineraryVotes,
  flushItineraryData,
} from '../../../actions/itinerary_actions';
import {
  fetchAllBlocksItinerary,
  moveToAdventureBlock,
  upsertBlock,
  fetchBlocksDynamic,
  nestBlock,
  fetchBoardBlocks,
  drillDownIntoAndOutOfItineraryBlock,
  copyToTripBoard,
  updateAdventureBlock,
} from '../../../actions/block_actions';
import {
  fetchAdventureBlocks,
  postAdventureBlock,
  fetchItineraryInformation,
  fetchUserAdventures,
  flushAdventures,
} from '../../../actions/adventure_actions';
import {
  openBlockModal,
  openDeleteModal,
  openSearchModal,
  openItineraryItemModal,
  openItineraryModal,
  openCheckListModal,
  openCopyModal,
  openFolderModal,
  setLoading,
} from '../../../actions/modal_actions';
import { fetchAdventureFriend } from '../../../actions/social_actions';
export const mapStateToProps = (state) => {
  let itineraryDetails = state.entities.itinerary.itineraryDetails
    ? state.entities.itinerary.itineraryDetails
    : null;
  let account_rid = state.session.user.account_rid
    ? state.session.user.account_rid
    : null;
  let adventureBlocks = state.entities.itinerary.adventure_blocks
    ? state.entities.itinerary.adventure_blocks
    : null;
  let itBlocks = state.entities.itBlocks.item_blocks
    ? state.entities.itBlocks.item_blocks
    : null;
  let user_block_categories = state.entities.blocks.user_block_categories
    ? state.entities.blocks.user_block_categories
    : null;
  let user_block_activites = state.entities.blocks.user_block_activities
    ? state.entities.blocks.user_block_activities
    : null;
  let user_block_activity_details = state.entities.blocks
    .user_block_activity_details
    ? state.entities.blocks.user_block_activity_details
    : null;
  let itinerary_info = state.entities.itinerary.itinerary_info
    ? state.entities.itinerary.itinerary_info
    : null;
  let allAdventures = state.entities.adventure.user_adventures
    ? state.entities.adventure.user_adventures
    : null;
  let current_adventure =
    allAdventures && itinerary_info
      ? allAdventures.filter(
          (e) => itinerary_info.adventure_rid === e.adventure_rid,
        )[0]
      : null;
  let explorer_rid = state.session.user.explorer_rid
    ? state.session.user.explorer_rid
    : null;
  let userInfo = state.session.user ? state.session.user.userInfo[0] : null;
  let libraryBlocks = state.entities.blocks.library_blocks
    ? state.entities.blocks.library_blocks
    : null;
  let subNavData = null;
  if (allAdventures && itinerary_info) {
    let adventure = allAdventures.filter((ele) => {
      return ele.adventure_rid === itinerary_info.adventure_rid;
    });
    if (adventure[0]) {
      subNavData = {
        inspiration_name: adventure[0].inspiration_name,
        adventure_name: adventure[0].adventure_name,
      };
    }
  }
  let itineraryComments = state.entities.itinerary.itineraryComments
    ? state.entities.itinerary.itineraryComments
    : null;
  let itineraryVotes = state.entities.itinerary.itineraryVotes
    ? state.entities.itinerary.itineraryVotes
    : null;
  return {
    account_rid,
    itineraryDetails,
    adventureBlocks,
    itBlocks,
    user_block_categories,
    user_block_activites,
    user_block_activity_details,
    itinerary_info,
    current_adventure,
    explorer_rid,
    subNavData,
    userInfo,
    libraryBlocks,
    itineraryComments,
    itineraryVotes,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    fetchItineraryDetail: (itinerary_rid, account_rid, explorer_rid) =>
      dispatch(fetchItineraryDetails(itinerary_rid, account_rid, explorer_rid)),
    fetchAdventureBlocks: (account_rid, adventure_rid) =>
      dispatch(fetchAdventureBlocks(account_rid, adventure_rid)),
    moveItineraryBlock: (
      itinerary_item_rid,
      block_rid,
      account_rid,
      explorer_rid,
      index,
    ) =>
      dispatch(
        moveItineraryBlock(
          itinerary_item_rid,
          block_rid,
          account_rid,
          explorer_rid,
          index,
        ),
      ),
    moveItineraryBlockBeetweenItems: (
      itinerary_item_rid,
      block_rid,
      account_rid,
      itinerary_block_rid,
      explorer_rid,
      sort_order,
    ) =>
      dispatch(
        moveItineraryBlockBetweenItems(
          itinerary_item_rid,
          block_rid,
          account_rid,
          itinerary_block_rid,
          explorer_rid,
          sort_order,
        ),
      ),
    deleteItineraryBlockItem: (
      itinerary_block_rid,
      itinerary_item_rid,
      explorer_rid,
    ) =>
      dispatch(
        deleteItineraryBlockItem(
          itinerary_block_rid,
          itinerary_item_rid,
          explorer_rid,
        ),
      ),
    postAdventureBlock: (account_rid, adventure_rid, data) =>
      dispatch(postAdventureBlock(account_rid, adventure_rid, data)),
    editItineraryItem: (itinerary_item_rid, data) =>
      dispatch(editItineraryItem(itinerary_item_rid, data)),
    fetchItineraryInformation: (adventure_rid, itinerary_rid, userInfo) =>
      dispatch(
        fetchItineraryInformation(adventure_rid, itinerary_rid, userInfo),
      ),
    fetchUserAdventures: (
      account_rid,
      inspiration_rid,
      userInfo,
      image_position_type_rid,
    ) =>
      dispatch(
        fetchUserAdventures(
          account_rid,
          inspiration_rid,
          userInfo,
          image_position_type_rid,
        ),
      ),
    fetchAllBlocksItinerary: (account_rid) =>
      dispatch(fetchAllBlocksItinerary(account_rid)),
    editItinerary: (data) => dispatch(editItinerary(data)),
    moveToAdventureBlock: (data) => dispatch(moveToAdventureBlock(data)),
    upsertBlock: (account_rid, data) =>
      dispatch(upsertBlock(account_rid, data)),
    fetchBlocksDynamic: (account_rid, data) =>
      dispatch(fetchBlocksDynamic(account_rid, data)),
    deleteItinerary: (advenutre_rid, itinerary_rid, userInfo) =>
      dispatch(deleteItinerary(advenutre_rid, itinerary_rid, userInfo)),
    openDeleteModal: (data, func) => dispatch(openDeleteModal(data, func)),
    openBlockModal: (
      openType,
      activityModalTypeRid,
      uiUpdatePayload,
      blockPayload,
      userInfo,
    ) =>
      dispatch(
        openBlockModal(
          openType,
          activityModalTypeRid,
          uiUpdatePayload,
          blockPayload,
          userInfo,
        ),
      ),
    openCheckListModal: (openType, blockType, actionPayload, data, userInfo) =>
      dispatch(
        openCheckListModal(openType, blockType, actionPayload, data, userInfo),
      ),
    openSearchModal: () => dispatch(openSearchModal()),
    openItineraryItemModal: (data, adventure_rid, isEdit) =>
      dispatch(openItineraryItemModal(data, adventure_rid, isEdit)),
    deleteItineraryItem: (
      adventure_rid,
      explorer_rid,
      itinerary_item_rid,
      itinerary_rid,
    ) =>
      dispatch(
        deleteItineraryItem(
          adventure_rid,
          explorer_rid,
          itinerary_item_rid,
          itinerary_rid,
        ),
      ),
    openDeleteModal: (data, func) => dispatch(openDeleteModal(data, func)),
    openItineraryModal: (data, editView) =>
      dispatch(openItineraryModal(data, editView)),
    upsertItineraryItem: (data, userInfo, actionType) =>
      dispatch(upsertItineraryItem(data, userInfo, actionType)),
    nestBlock: (account_rid, data) => dispatch(nestBlock(account_rid, data)),
    unnestBlock: (account_rid, data) => dispatch(nestBlock(account_rid, data)),
    fetchBoardBlocks: (userInfo, data, origin, isNested) =>
      dispatch(fetchBoardBlocks(userInfo, data, origin, isNested)),
    drillDownIntoAndOutOfItineraryBlock: (data, userInfo, destination) =>
      dispatch(
        drillDownIntoAndOutOfItineraryBlock(data, userInfo, destination),
      ),
    fetchItineraryComments: (itineraryRid) =>
      dispatch(fetchItineraryComments(itineraryRid)),
    fetchItineraryVotes: (itineraryRid, userInfo) =>
      dispatch(fetchItineraryVotes(itineraryRid, userInfo)),
    upsertItineraryVotes: (data) => dispatch(upsertItineraryVotes(data)),
    deleteItineraryVotes: (itineraryVoteRid, userInfo, itineraryRid) =>
      dispatch(deleteItineraryVotes(itineraryVoteRid, userInfo, itineraryRid)),
    copyToTripBoard: (
      userInfo,
      data,
      origin,
      returnActionPayload,
      placeToUpdate,
    ) =>
      dispatch(
        copyToTripBoard(
          userInfo,
          data,
          origin,
          returnActionPayload,
          placeToUpdate,
        ),
      ),
    openCopyModal: (actionPayload, type) =>
      dispatch(openCopyModal(actionPayload, type)),
    setLoading: (boolean) => dispatch(setLoading(boolean)),
    openFolderModal: (
      editView,
      block_rid,
      openBLockModal,
      currentAdventure,
      tabToDisplay,
      itineraryData,
      userInfo,
      uiUpdatePayload,
    ) =>
      dispatch(
        openFolderModal(
          editView,
          block_rid,
          openBLockModal,
          currentAdventure,
          tabToDisplay,
          itineraryData,
          userInfo,
          uiUpdatePayload,
        ),
      ),
    flushItineraryData: () => dispatch(flushItineraryData()),
    flushAdventures: () => dispatch(flushAdventures()),
    updateAdventureBlock: (uiUpdatePayload) =>
      dispatch(updateAdventureBlock(uiUpdatePayload)),
    fetchAdventureFriend: (adventure_rid) =>
      dispatch(fetchAdventureFriend(adventure_rid)),
  };
};
