export const fetchLibraryBlocks = async (
  adventureRidFlag,
  userInfo,
  displayDetails,
  displayDetailsLists,
  //TODO: remove fetchBoardBlocks dispatch action reference
  fetchBoardBlocks,
) => {
  if (displayDetails.level === 1 && displayDetailsLists.level === 1) {
    await fetchBoardBlocks(
      userInfo,
      {
        adventure_rid_exist_flag: adventureRidFlag,
        // parent_block_rid:
      },
      { isLibrary: true, isLibraryAndList: true },
      false,
    );
  } else {
    await fetchBoardBlocks(
      userInfo,
      {
        adventure_rid_exist_flag: adventureRidFlag,
        parent_block_rid:
          displayDetails.level > 1 ? displayDetails.currentBlockRid : null,
      },
      { isLibrary: true, isLibraryAndList: true },
      displayDetailsLists.level > 1,
    );
    // await Promise.all([
    //   await fetchBoardBlocks(
    //     userInfo,
    //     {
    //       adventure_rid_exist_flag: adventureRidFlag,
    //       parent_block_rid:
    //         displayDetails.level > 1 ? displayDetails.currentBlockRid : null,
    //     },
    //     { isLibraryBlockOnly: true },
    //     displayDetails.level > 1,
    //   ),
    //   await fetchBoardBlocks(
    //     userInfo,
    //     {
    //       adventure_rid_exist_flag: adventureRidFlag,
    //       parent_block_rid:
    //         displayDetailsLists.level > 1
    //           ? displayDetailsLists.currentBlockRid
    //           : null,
    //     },
    //     { isLibraryListOnly: true },
    //     displayDetailsLists.level > 1,
    //   ),
    // ]);
  }
};
