import { Box } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import SwiperCarousel from '../../../../../components/swiper-carousel/swiper-carousel';
import { styleValues } from '../constants';
import ImageCard from './image-card';
import TripFsLightBox from './trip-fslightbox';
import './trip-fslightbox.scss';
import VideoCard from './video-card';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  ...styleValues(theme),
}));

const TripOverview = ({ data }) => {
  const classes = useStyles();
  const [advActivityIcons, setAdvActivityIcons] = useState([]);
  const [advSeasons, setAdvSeasons] = useState([]);
  const [caption, setCaption] = useState(undefined);
  const [firstRenderFlag, setFirstRenderFlag] = useState(true);
  const [fullScreen, setFullScreen] = useState(-1);
  const [activeIndex, setActiveIndex] = useState(-1);

  const modalEvent = useSelector((state) =>
    state?.ui?.modal?.action ? state?.ui?.modal?.action : null,
  );

  const modalFlag =
    modalEvent === 'OPEN_CREATE_ADVENTURE' ||
    modalEvent === 'OPEN_DELETE_MODAL' ||
    modalEvent === 'RECEIVE_OPEN_ADVENTURE_COPY_MODAL';

  const curAdv = useSelector(
    (state) => state.entities?.adventure?.selectedAdventure || null,
  );

  const tmp = curAdv.adventure_file;
  const images = Array.isArray(tmp) ? tmp : [tmp];

  useEffect(() => {
    const handleClick = (event) => {
      // Handle the click event here
      setFirstRenderFlag(false);
    };

    document.addEventListener('click', handleClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    const tImages = Array.isArray(tmp) ? tmp : [tmp];
    const firstImage = tImages[0];
    setCaption(firstImage['file_note']);

    async function loadData() {
      axios
        .get(`/api/ui/adventure-activity-icons/${curAdv.adventure_rid}`)
        .then((res) => {
          setAdvActivityIcons(res?.data?.icons || []);
        });
    }
    loadData();
  }, []);

  const handleShowFullScreen = (index) => {
    setFullScreen(index);
  };

  return (
    <>
      {ReactDOM.createPortal(
        fullScreen >= 0 ? (
          <TripFsLightBox
            onClose={() => setFullScreen(-1)}
            classes={classes}
            images={images}
            activeIndex={fullScreen}
          />
        ) : (
          <></>
        ),
        document.getElementById('root'),
      )}
      <Box>
        <Box style={{ margin: '16px 0px' }}>
          <SwiperCarousel
            className={'image-carousel'}
            prev={'swiper-image-prev'}
            next={'swiper-image-next'}
            navigation={{
              prevEl: '.swiper-image-prev',
              nextEl: '.swiper-image-next',
            }}
            onActiveIndexChange={(swiper) => {
              let image = images[swiper.activeIndex];
              setActiveIndex(swiper.activeIndex);
              setCaption(image['file_note']);
            }}
          >
            {images.map((image, index) => {
              return (
                <React.Fragment key={`trip-overview ${index}`}>
                  {image['media_type'] === 'video' ? (
                    <VideoCard
                      video={image['file_location']}
                      caption={image['file_note']}
                      onClick={() => {
                        handleShowFullScreen(index);
                      }}
                      autoPlay={
                        activeIndex === index && fullScreen === -1 && !modalFlag
                      }
                      firstRenderFlag={firstRenderFlag}
                    />
                  ) : (
                    <ImageCard
                      image={
                        image['file_location'] ? image['file_location'] : image
                      }
                      caption={image['file_note']}
                      onClick={() => handleShowFullScreen(index)}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </SwiperCarousel>
          {caption &&
          <Box
            className={[classes.font, classes.contentFontSize].join(' ')}
            sx={{
              marginTop: '8px',
              minHeight: '40px',
              padding: '0px 16px',
              width: 'calc(100% - 32px)',
              height: 'fit-content',
              fontWeight: '400',
            }}
          >
            {caption || ''}
          </Box>
          }
        </Box>
        {/* <Box className={classes.cardContainer} position="relative">
          <Box position="absolute" sx={{ right: 0, top: 0 }} pt={2} pr={3}>
            <TripPlannerButton variant="contained" />
          </Box>
          <Grid container spacing={2}>
            <Grid
              item
              md={
                advActivityIcons.length === 0
                  ? 2
                  : advActivityIcons.length === 1
                  ? 3
                  : 6
              }
              sm={
                advActivityIcons.length === 0
                  ? 3
                  : advActivityIcons.length === 1
                  ? 3
                  : 6
              }
              xs={12}
              lg={
                advActivityIcons.length === 0
                  ? 2
                  : advActivityIcons.length === 1
                  ? 2
                  : 4
              }
            >
              <Box>
                <SectionHeader>ACTIVITIES</SectionHeader>

                <Grid container>
                  {advActivityIcons.map((e, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        md={advActivityIcons.length === 1 ? 12 : 6}
                        sm={advActivityIcons.length === 1 ? 12 : 6}
                        xs={advActivityIcons.length === 1 ? 12 : 6}
                        lg={advActivityIcons.length === 1 ? 12 : 6}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'left',
                            alignItems: 'center',
                            gap: '10px',
                          }}
                        >
                          <Box>
                            {typeof svgLibrary[e.icon_name] === 'function' &&
                              svgLibrary[e.icon_name](
                                '',
                                [
                                  classes.svgSize,
                                  classes.svgColor_237a07,
                                  classes.cursorSvgDefault,
                                ].join(' '),
                                [
                                  classes.svgSize,
                                  classes.cursorSvgDefault,
                                ].join(' '),
                              )}
                          </Box>
                          <Box
                            className={[
                              classes.font,
                              classes.contentFontSize,
                            ].join(' ')}
                          >
                            {e.block_category_name}
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Grid>
            {advSeasons.length > 0 ? (
              <Grid item md={3} sm={6} xs={12} lg={2}>
                <SectionHeader>SEASONS</SectionHeader>
                <Grid container>
                  {advSeasons.map((e, index) => (
                    <Grid key={index} item md={12} sm={12} xs={12}>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'left',
                          alignItems: 'center',
                        }}
                      >
                        <HubButton align="none" mode="icon" icon={GearIcon} />
                        <Box
                          className={[
                            classes.font,
                            classes.contentFontSize,
                          ].join(' ')}
                          style={{ marginLeft: '10px' }}
                        >
                          HIKING
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null}
            {data?.budget_range?.from && data?.budget_range?.to ? (
              <Grid item md={3} sm={6} xs={12} lg={2}>
                <SectionHeader>BUDGET</SectionHeader>

                <Typography
                  className={[classes.font, classes.contentFontSize].join(' ')}
                >
                  {`$${data?.budget_range?.from}-$${data?.budget_range?.to} `}
                </Typography>
              </Grid>
            ) : null}
            <Grid item md={3} sm={6} xs={12} lg={2}>
              <SectionHeader>DISTANCE</SectionHeader>
              <Typography
                className={[classes.font, classes.contentFontSize].join(' ')}
              >
                {`${data.total_distance ?? 0} miles`}
              </Typography>
            </Grid>
            <Grid item md={3} sm={6} xs={12} lg={2}>
              <SectionHeader>DURATION</SectionHeader>

              <Typography
                className={[classes.font, classes.contentFontSize].join(' ')}
              >
                {data?.duration || '0 days'}
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12} lg={12}>
              {data?.advenutre_description && (
                <>
                  <SectionHeader>OVERVIEW</SectionHeader>

                  <Typography
                    className={[classes.font, classes.contentFontSize].join(
                      ' ',
                    )}
                  >
                    {data?.adventure_description || ''}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Box> */}
      </Box>
    </>
  );
};

export default TripOverview;
