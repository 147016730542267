import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../../components/card-carousel/card-carousel.scss';
import svgLibrary from '../../../../../components/svg_module/svg_library/library/functionLibrary';
import SwiperCarousel from '../../../../../components/swiper-carousel/swiper-carousel';
import { styleValues } from '../constants';
const MapBox = lazy(() => import('../../../../../components/modules/map_box'));
//import { ImageSource } from 'mapbox-gl';
import { alpha, makeStyles } from '@material-ui/core';
import { useResize } from 'components/carousel/card-carousel';
import PaperSection from 'components/section/paper-section';
import CreateActivityModalButton from 'features/activity/components/create-activity-modal-button';
import useEditActivityModalButton from 'features/activity/hooks/use-edit-activity-modal-button';
import {
  CenteredSectionHeader,
  SkeletonListBlockCard,
} from 'features/adventure/components/adventure-lists';
import BlockActionMenu from 'features/blocks/components/block-action-menu';
import ItineraryActionMenu from 'features/itinerary/components/itinerary-action-menu';
import ItineraryItemActionMenuBase from 'features/itinerary/components/itinerary-item-action-menu-base';
import JournalEntryPreview from 'features/itinerary/components/journal-entry-preview';
import useItineraryItems from 'features/itinerary/hooks/use-itinerary-items';
import React, { lazy, Suspense } from 'react';
import {
  CompassCalibration,
  Explore,
  LocationCity,
  LocationOn,
  ThumbUpAlt,
} from '@material-ui/icons';
import blocks from 'components/modules/blocks';
import Thumb from 'components/modules/blocks/thumb';
import { getBlockType } from 'features/blocks/util';
import { BLOCK } from 'features/blocks/constants';
import { Circle } from 'rc-progress';

const useStyles = makeStyles((theme) => styleValues(theme));

const Block = ({ block }) => {
  const theme = useTheme();
  const classes = useStyles();
  const {
    openModal: openEditActivityModalButton,
  } = useEditActivityModalButton();

  const handleClickActivity = (e, block) => {
    e.preventDefault();
    e.stopPropagation();
    openEditActivityModalButton(block);
  };

  const type = getBlockType(block);

  return (
    <Box
      sx={{
        position: 'relative', // Enable absolute positioning for child elements
        display: 'flex',
        cursor: 'pointer',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        borderRadius: 1,
        wordBreak: 'break-word',
        boxSizing: 'border-box',
        p: 1,
        width: 1,
        backgroundColor: alpha('#fff', 0.7),
      }}
    >
      {/* Delete Button Positioned Absolutely */}
      <Box
        sx={{
          position: 'absolute',
          top: 8, // Adjusts the vertical offset from the top
          right: 8, // Adjusts the horizontal offset from the right
        }}
      >
        <BlockActionMenu block={block} />
      </Box>

      {/* Title aligned to top-left, with some margin to prevent overlap */}
      <Box
        onClick={(e) => handleClickActivity(e, block)}
        width={0.8}
        textAlign={'left'}
        mr={3}
        sx={{
          '&:hover': {
            '& .block-name': {
              textDecoration: 'underline',
            },
          },
        }}
      >
        <Typography variant="h6" gutterBottom={false}>
          {block.block_name}
        </Typography>

        {/* Icon and Category Name */}
        <Stack>
          <Box
            className={[classes.flexLeft, classes.staticSvgSize_30_18].join(
              ' ',
            )}
            sx={{
              alignItems: 'left',
              gap: '4px',
            }}
          >
            {svgLibrary &&
              block.ui_image_location !== null &&
              typeof svgLibrary[block.ui_image_location] === 'function' &&
              svgLibrary[block.ui_image_location]('#fff')}
            <Typography
              sx={{
                margin: 0,
                fontWeight: 'bold',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                pt: 0.5,
              }}
            >
              {block.block_category_name || ''}
            </Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box display="flex" color="primary">
            <Stack direction="row" gap={0}>
              {block.location_flag && <LocationOn fontSize="small" />}
              {
                <Thumb
                  block={block}
                  is_white={true}
                  isAdventure={false}
                  total_vote_count_up={block.total_vote_count_up}
                  onThumbDown={() => {}}
                  onThumbUp={() => {}}
                  countStyle={{ color: '#000' }}
                  thumbStyle={{
                    color: theme.palette.text.primary,
                  }}
                />
              }
              {type === BLOCK.TYPE.LIST && (
                <Stack
                  direction="row"
                  width={1}
                  height={'18px'}
                  pt={0.25}
                  gap={0.2}
                  display={'flex'}
                  justifyContent={'bottom'}
                >
                  <Circle
                    percent={Math.round(
                      (block.completed_checklist_total /
                        (block.child_count || 1)) *
                        100,
                    )}
                    trailColor={'#999'}
                    strokeColor="#D18000"
                    strokeWidth={15}
                    trailWidth={15}
                    className="mini-blk-hierarchy-outer"
                    gapPosition="bottom"
                  />
                  <span
                    className="count-spans count-spans--vote-up false"
                    style={{ fontSize: '11px', marginLeft: 0 }}
                  >
                    {`${block.completed_checklist_total}/${block.child_count}`}
                  </span>
                </Stack>
              )}
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
const ItineraryDay = ({ data, numDays }) => {
  const blocks = data?.itinerary_blocks?.length
    ? data.itinerary_blocks
        .filter(Boolean)
        .sort((a, b) => a.sort_order - b.sort_order)
    : [];
  const hasBlocks = Boolean(blocks.length);
  const hasJournalEntry = Boolean(data.item_description);

  return (
    <div className="contentBox">
      <div className="cardBox">
        <div className="fontBox">
          <Box position="relative">
            <Box pt={1} mr={4}>
              <CenteredSectionHeader
                title={data.item_header}
                subtitle={`${moment
                  .utc(data.item_start_date)
                  .format('ddd, MMM D, YYYY')}`}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 1,
              }}
            >
              <ItineraryItemActionMenuBase
                itineraryItemData={data}
                hideDelete={Boolean(numDays <= 1)}
              >
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              </ItineraryItemActionMenuBase>
            </Box>
          </Box>
          <Divider sx={{ my: 1 }} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              width: '100%',
              gap: 4,
            }}
          >
            {blocks.map((block) => (
              <Block block={block} />
            ))}
            {!hasBlocks && (
              <Box py={4}>
                <Typography color="text.secondary">
                  No activities planned for this day.
                </Typography>
              </Box>
            )}
            {hasJournalEntry && (
              <>
                <Divider sx={{ my: 1 }} />
                <JournalEntryPreview journalEntry={data?.item_description} />
              </>
            )}
            <div className="fader-day fader-left"></div>
            <div className="fader-day fader-right"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ItineraryDayCarousel = function ItineraryDayCarousel() {
  const curItineraries = useSelector(
    (state) =>
      state.entities?.adventure?.current_adventure?.adventure_itineraries,
  );

  const curItinerary = curItineraries ? curItineraries[0] : false;

  const { data, isLoading, isError } = useItineraryItems(
    curItinerary.itinerary_rid,
  );

  const { deviceWidth } = useResize();
  let perViewCounts = 4;
  if (deviceWidth < 450) {
    perViewCounts = 1;
  } else if (deviceWidth < 600) {
    perViewCounts = 1;
  } else if (deviceWidth < 750) {
    perViewCounts = 1;
  } else if (deviceWidth < 900) {
    perViewCounts = 2;
  } else if (deviceWidth < 1050) {
    perViewCounts = 3;
  } else if (deviceWidth < 1200) {
    perViewCounts = 3;
  } else if (deviceWidth < 1500) {
    perViewCounts = 4;
  } else if (deviceWidth < 1900) {
    perViewCounts = 5;
  } else {
    perViewCounts = 6;
  }

  if (isLoading) {
    return (
      <Paper
        elevation={0}
        sx={{
          mt: 3,
          background: 'white',
          '& .swiper-slide': {
            background: 'transparent',
          },
        }}
      >
        <PaperSection>
          <Box my={'30px'}>
            <SwiperCarousel
              className={`card-carousel checklist`}
              prev={'swiper-checklist-prev'}
              next={'swiper-checklist-next'}
              navigation={{
                prevEl: '.swiper-checklist-prev',
                nextEl: '.swiper-checklist-next',
              }}
              spaceBetween={10}
              slidesPerView={perViewCounts}
            >
              {/* Render Skeleton cards for loading state */}
              {Array.from({ length: perViewCounts }).map((_, index) => (
                <SkeletonListBlockCard key={index} />
              ))}
            </SwiperCarousel>
          </Box>
        </PaperSection>
      </Paper>
    );
  }
  if (isError) {
    console.error('Error loading adventure itinerary items');
    return;
  }
  if (!data) return;

  return (
    <SwiperCarousel
      className={`card-carousel ${data?.length === 1 && 'left-adjusted'}`}
      prev={'swiper-itinerary-prev'}
      next={'swiper-itinerary-next'}
      navigation={{
        prevEl: '.swiper-itinerary-prev',
        nextEl: '.swiper-itinerary-next',
      }}
      spaceBetween={10}
      slidesPerView={perViewCounts}
    >
      {data.map((item, index) => (
        <ItineraryDay
          key={`itinerary-details ${index}`}
          data={item}
          numDays={data.length}
        />
      ))}
    </SwiperCarousel>
  );
};

const ItineraryCard = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const curItineraries = useSelector(
    (state) =>
      state.entities?.adventure?.current_adventure?.adventure_itineraries,
  );

  const curItinerary = curItineraries ? curItineraries[0] : false;
  return (
    <Box className={classes.cardContainer}>
      <Grid container spacing={2} mt={0.5}>
        <Grid item md={12} sm={12} xs={12} lg={12}>
          <Box
            display="flex"
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'left'}
          >
            <Box flexGrow={1} display="flex" flexDirection={'row'}>
              <Box
                display="flex"
                flexGrow={2}
                py={0.5}
                justifyContent="left"
                alignItems="center"
              >
                <Typography color="text.primary" variant="h4" gutterBottom>
                  ITINERARY
                </Typography>
              </Box>
              <Stack direction="row" spacing={1}>
                <CreateActivityModalButton
                  variant={'contained'}
                  sx={{ height: '40px !important' }}
                />
                <ItineraryActionMenu>
                  <IconButton variant="contained">
                    <MoreVertIcon />
                  </IconButton>
                </ItineraryActionMenu>
              </Stack>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} sm={12} xs={12} lg={12} sx={{ pb: 4 }}>
          <ItineraryDayCarousel />
        </Grid>
        <Grid item md={12} sm={12} xs={12} lg={12}>
          <div
            className={['itinerary-page-map-wrapper'].join(' ')}
            style={{
              width: '100%',
            }}
          >
            <Suspense fallback={<Skeleton width={'100%'} height={'100%'} />}>
              <MapBox
                usecase="itinerary"
                selectedItinerary={curItinerary}
                onBack={() => null}
                displayTools
                tools={{
                  filters: true,
                  mapStyleToggle: true,
                  togglePopups: true,
                  backButton: false,
                }}
                interactive={true}
              />
            </Suspense>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(ItineraryCard);
