import React, { useCallback } from 'react';
import Popup from './ShowTripLocationPointPopup';
import ReactDOM from 'react-dom';
import { merge } from 'lodash';
import axios from 'axios';

/*
update location point only
update details
cancel
update location points only: update location point only needs to trigger a refetch of the location blocks so it will update the blocks accordingly, 
additional case might be when the user moves multiple blocks, if we were to accomodate this, we need to make it so that an update 
to the moved trip marker needs to go into the data and update the data
update details: same logic applies as update location points only but the logical operations must be passed as a callback passed to opening the modal
cancle: straight forward:
*/
const MovedLocationBlockPopup = (props) => {
  const {
    markerData,
    upsertLocationBlock,
    updateBlockLocationPoint,
    itineraryInfo,
    userInfo,
    mapFilter,
    fetchLocationBlocks,
    openBlockModal,
    user_adventure_friend_rid,
  } = props;

  const { marker, coords, block, store, idx } = markerData;

  const handleCancel = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    let popup = marker.getPopup();

    let domElementPlaceHolder = document.createElement('div');
    ReactDOM.render(
      <Popup type="tripLocation" block={block} {...props} />,
      domElementPlaceHolder,
    );
    popup.setDOMContent(domElementPlaceHolder);
    marker.setLngLat(coords);
  });

  const updateCoordinatesHandler = useCallback(async (event) => {
    let newLocations = marker.getLngLat();
    const { lat, lng } = newLocations;
    let blockToUpdate = {
      block_rid: block.block_rid,
      location_point: { lat: `${lat}`, lng: `${lng}` },
    };

    updateBlockLocationPoint(blockToUpdate);
    let returnedData;
    if (mapFilter === 'itineraryLocationBlocks') {
      returnedData = await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: itineraryInfo.itinerary_rid,
      });
    } else if (mapFilter === 'allTripBlocks') {
      returnedData = await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: null,
      });
    } else if (mapFilter === 'myRoutes') {
      fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: itineraryInfo.itinerary_rid,
        direction_flag: true,
        my_routes: true,
        adventure_friend_rid: user_adventure_friend_rid,
      });
    } else {
      await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: itineraryInfo.itinerary_rid,
        direction_flag: true,
      });
    }
    let popup = marker.getPopup();
    let domElementPlaceHolder = document.createElement('div');
    ReactDOM.render(
      <Popup type="tripLocation" block={block} {...props} />,
      domElementPlaceHolder,
    );
    popup.setDOMContent(domElementPlaceHolder);
  });

  const callBackAfterInsertingBlock = async (marker) => {
    let returnedData;
    if (mapFilter === 'itineraryLocationBlocks') {
      returnedData = await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: itineraryInfo.itinerary_rid,
      });
    } else if (mapFilter === 'allTripBlocks') {
      returnedData = await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: null,
      });
    } else if (mapFilter === 'myRoutes') {
      fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: itineraryInfo.itinerary_rid,
        direction_flag: true,
        my_routes: true,
        adventure_friend_rid: user_adventure_friend_rid,
      });
    } else {
      await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfo.adventure_rid,
        itinerary_rid: itineraryInfo.itinerary_rid,
        direction_flag: true,
      });
    }
    let popup = marker.getPopup();
    let domElementPlaceHolder = document.createElement('div');
    ReactDOM.render(
      <Popup type="tripLocation" block={block} {...props} />,
      domElementPlaceHolder,
    );
    popup.setDOMContent(domElementPlaceHolder);
  };

  const editHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    let openType = {
      isEditAndView: true,
    };
    let blockType = {
      isAdventureBlock: true,
      isLocationBlock: true,
      itineraryInfo: itineraryInfo,
    };
    let actionPayload = {
      adventureRid: itineraryInfo.adventure_rid,
      itineraryInfo: itineraryInfo,
      func: () => callBackAfterInsertingBlock(marker),
    };

    block['idx'] = 0;
    let passOver;
    axios.get(`/api/b/block-details/${block.block_rid}`).then((res) => {
      passOver = res.data.blockDetails[0];
      let newLocations = marker.getLngLat();
      const { lat, lng } = newLocations;
      actionPayload.location_point = { lat, lng };

      passOver.location_point = { lat: `${lat}`, lng: `${lng}` };
      openBlockModal(openType, blockType, actionPayload, passOver, userInfo);
    });
    // let popup = marker.getPopup();
    // let domElementPlaceHolder = document.createElement("div");
    // ReactDOM.render(
    //   <Popup type="tripLocation" block={block} {...props} />,
    //   domElementPlaceHolder
    // );
    // popup.setDOMContent(domElementPlaceHolder);
  });
  return (
    <div className="moved-location-block-wrapper">
      <button
        className="moved-location-block-button moved-location-block-button--update"
        onClick={updateCoordinatesHandler}
      >
        Update coordinates only
      </button>
      <button
        onClick={editHandler}
        className="moved-location-block-button moved-location-block-button--edit"
      >
        Edit details
      </button>
      <button
        onClick={handleCancel}
        className="moved-location-block-button moved-location-block-button--cancel"
      >
        Cancel
      </button>
    </div>
  );
};

export default MovedLocationBlockPopup;
