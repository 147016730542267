import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, LinearProgress } from '@mui/material';

const TimedLinearProgress = ({
  seconds,
  isLoading,
}: {
  seconds: number;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [progress, setProgress] = useState(0);
  if (!isLoading && progress != 0) setProgress(0);

  useEffect(() => {
    if (!isLoading) return;
    const startTime = Date.now();
    const animateProgress = () => {
      const elapsedTime = Date.now() - startTime;
      const newProgress = Math.min(
        Math.floor((elapsedTime / seconds) * 100),
        100,
      );
      setProgress(newProgress);

      if (newProgress < 100) {
        requestAnimationFrame(animateProgress);
      }
    };

    animateProgress();
  }, [seconds, isLoading]);

  console.log(progress);
  return (
    <>
      {isLoading && (
        <LinearProgress
          variant="determinate"
          color={progress >= 100 ? 'success' : 'gray'}
          value={progress}
          sx={{
            '& .MuiLinearProgress-bar': {
              transition: 'none', // Disables the animation transition of the circle
            },
          }}
          // style={{ animationDuration: `${seconds}s` }} // Set the animation duration
        />
      )}
    </>
  );
};

export default TimedLinearProgress;
