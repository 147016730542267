import { ButtonBase, Divider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { FolderIcon } from 'features/folder/components/create-trip-folder-modal-button';
import useCreateTripFolderModalButton from 'features/folder/hooks/use-create-trip-folder-modal-button';
import { ChecklistIcon } from 'features/list/components/create-list-modal-button';
import { StyledMenu } from 'features/list/components/list-action-menu';
import useCreateListModalButton from 'features/list/hooks/use-create-list-modal-button';
import { useState } from 'react';
import TripPlannerDrawerButtonBase from '../../trip-planner/components/trip-planner-drawer-button-base';
import useTripPlannerButton from 'features/trip-planner/hooks/use-trip-planner-button';
import { VanIcon } from 'features/trip-planner/components/trip-builder-page-button';

const AdventureListsActionMenu = ({ children }) => {
  const { openModal: openCreateListModal } = useCreateListModalButton();
  const { openModal: openCreateFolderModal } = useCreateTripFolderModalButton();
  const {
    handleClick: handleTripBuilderButtonClick,
    loading,
  } = useTripPlannerButton();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateList = () => {
    openCreateListModal();
    handleClose();
  };

  const handleCreateFolder = () => {
    openCreateFolderModal();
    handleClose();
  };

  const handleTripPlanner = (e) => {
    // handleTripBuilderButtonClick(e);
    handleClose();
  };

  const id = 'adventure-lists-action-menu';
  return (
    <>
      <ButtonBase
        onClick={handleClick}
        id={id}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          height: 'min-content',
        }}
      >
        {children}
      </ButtonBase>
      <StyledMenu
        id={id}
        MenuListProps={{
          'aria-labelledby': id,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCreateList} disableRipple>
          <ChecklistIcon />
          Create New List
        </MenuItem>
        <MenuItem onClick={handleCreateFolder} disableRipple>
          <FolderIcon />
          Create New Folder
        </MenuItem>

        <Divider />
        <TripPlannerDrawerButtonBase>
          <MenuItem onClick={handleTripPlanner} disableRipple sx={{ width: 1 }}>
            <VanIcon />
            Open Trip Planner
          </MenuItem>
        </TripPlannerDrawerButtonBase>
      </StyledMenu>
    </>
  );
};

export default AdventureListsActionMenu;
