import { useRef, useEffect, useState } from 'react';
import { fetchRecGovCampsitesMapData } from '../../../../util/api_util/linked_app_api_util';
import { useDispatch, useSelector } from 'react-redux';
import { receivePersistRecGovCampSitesData } from '../../../../actions/cache_actions';

const useGetCampSites = () => {
  const dispatch = useDispatch();
  const updatedAt = useSelector(state => state.dataCache.recGov?.recGovCampSites?.updated_at);
  const [campsitesLoading, setCampsitesLoading] = useState(true);
  useEffect(() => {
    let today = new Date().toDateString();
    console.log(updatedAt, today, 'res');
    if(updatedAt === undefined || updatedAt !== today) {
      fetchRecGovCampsitesMapData().then((res) => {
        dispatch(receivePersistRecGovCampSitesData(res.data.campsites))
      }).finally(() => setCampsitesLoading(false))
    } else {
      setTimeout(() => setCampsitesLoading(false), 2000); 
    }
  }, [])

  return {campsitesLoading};
}

export default useGetCampSites;