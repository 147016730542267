import React, { useState, useEffect, useCallback, useRef } from 'react';
import { merge } from 'lodash';
import { connect } from 'react-redux';
import {
  mapStateToProps,
  mapDispatchToProps,
} from '../../../pages/pwa_hub/library_v2/container';
import Fuse from 'fuse.js';
import { withRouter, useLocation } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import { DropDownSelect } from 'components/global_modal/modal_modules';
import MapBox from 'components/modules/map_box';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import MiniBlocksClone from 'components/modules/blocks/blocks_clone';
import useMaskingHooks from '../../../util/custom_hooks/maskUrl';
import { fetchLibraryBlocks } from '../../../util/api_util/library_api_util';
import TripBoard from 'pages/pwa_hub/library_v2/trip_board';
import DrawerTopBar from 'features/adventure/components/drawer-top-bar';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@material-ui/icons';
import useInvalidateAdventureData from 'features/adventure/hooks/use-invalidate-adventure-data';
import useToggle from 'hooks/use-toggle';
import useMenuController from 'hooks/use-menu-controller';
import { StyledMenu } from 'features/list/components/list-action-menu';
import { ChecklistIcon } from 'features/list/components/create-list-modal-button';
import { FolderIcon } from 'features/folder/components/create-trip-folder-modal-button';
import { ActivityIcon } from 'features/activity/components/create-activity-modal-button';

const Library = (props) => {
  const { maskURL } = useMaskingHooks();
  const { invalidateQueries } = useInvalidateAdventureData();

  const {
    itinerary_info,
    explorer_rid,
    openBlockModal,
    match,
    userInfo,
    upsertItineraryItem,
    unnestBlock,
    adventureBlocks,
    fetchBoardBlocks,
    upsertItineraryVotes,
    deleteItineraryVotes,
    sourceAdventureRid,
    openCheckListModal,
    copyToTripBoard,
    allAdventures,
    setLoading,
    closeDrawer,
    openFolderModal,
    libraryLists,
    sendAdventureSelected,
    flushAdventures,
    fetchLocationBlocks,
  } = props;

  const [adventureName, setAdventureName] = useState(null);
  const [selectedAdventure, setSelectedAdventure] = useState([]);
  const [adventure_rid, setAdventureRid] = useState(sourceAdventureRid || null);
  const [currAdvDis, setCurrAdvDis] = useState('Library');
  const [itineraryTab, setItineraryTab] = useState('Itinerary');
  const [isHoverEnabled, setIsHoverEnabled] = useState(false);

  const [displayItems, setDisplayItems] = useState({
    summary: false,
    tripBoard: true,
    itinerary: true,
    friends: false,
  });

  const {
    open: menuOpen,
    anchorEl: menuAnchor,
    handleClick: handleMenuClick,
    handleClose: handleMenuClose,
  } = useMenuController();

  const [logCoordinateFlag, setLogCoordinateFlag] = useState(true);
  const [mapLocationBlock, setMapLocationBlock] = useState(null);
  const [displayAnchors, setDisplayAnchors] = useState({
    reposition: false,
    currentRepositionAnchor: null,
  });

  const [draggingItSection, setDraggingItSection] = useState('');
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });

  useEffect(() => {
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'library' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
    // return () => flushAdventures();
  }, []);

  useEffect(() => {
    console.debug('SOURCE', sourceAdventureRid);
    if (
      (props.location?.state && props.location?.state?.fromItinerary) |
      sourceAdventureRid
    ) {
      setAdventureRid(
        props.location?.state?.adventureRid || sourceAdventureRid,
      );
      sendAdventureSelected(
        props.location?.state?.adventureRid || sourceAdventureRid,
      );
    }
  }, []);

  const handleReposition = (name) => {
    setDisplayAnchors({
      ...displayAnchors,
      currentRepositionAnchor: name,
    });
    const element = document.getElementById(name);
    setTimeout(() => {
      if (element) {
        window.scrollTo({
          behavior: element ? 'smooth' : 'auto',
          top: element ? element.offsetTop - 60 : 0,
        });
      }
    }, 300);
  };

  const [pageData, setPageData] = useState({
    libraryBlocks: props.adventureBlocks ? props.adventureBlocks : [],
    itineraryItems: props.itineraryDetails ? props.itineraryDetails : [],
    itineraryItemBlocks: props.itBlocks ? props.itBlocks : {},
    libraryLists: libraryLists ? libraryLists : null,
  });

  const handleAdventureSelect = useCallback((event) => {
    if (event.length > 0) {
      const { adventure_rid, adventure_name } = event[0];
      setAdventureRid(adventure_rid);
      sendAdventureSelected(adventure_rid);
      setLoading(true);
      fetchLibraryBlocks(
        adventure_rid,
        userInfo,
        displayDetails,
        displayDetailsLists,
        fetchBoardBlocks,
      ).then(() => setLoading(false));
      setAdventureName(adventure_name);
    } else {
      setAdventureRid(null);
      sendAdventureSelected(null);
      setLoading(true);
      fetchLibraryBlocks(
        null,
        userInfo,
        displayDetails,
        displayDetailsLists,
        fetchBoardBlocks,
      ).then(() => setLoading(false));
      setAdventureName(null);
      setLoading(false);
    }
  });

  useEffect(() => {
    function fetchAll() {
      setLoading(true);
      let adventureQuery = {
        account_rid: userInfo.account_rid,
        inspiration_rid: null,
        page_number: 1,
        page_size: 100,
        image_position_type_rid: 1,
      };
      Promise.all([
        fetchBoardBlocks(
          userInfo,
          {
            adventure_rid_exist_flag:
              props.location.state && props.location.state.fromItinerary
                ? props.location.state.adventureRid
                : null,
          },
          {
            isLibrary: true,
            isLibraryAndList: true,
          },
          false,
        ),
        props.fetchUserAdventures(adventureQuery),
      ]).then(() => {
        setLoading(false);
      });
    }
    fetchAll();
  }, []);

  useEffect(() => {
    if (allAdventures && adventure_rid) {
      setSelectedAdventure(
        allAdventures.filter(
          (ele) => parseInt(ele.adventure_rid) === parseInt(adventure_rid),
        ),
      );
    }
  }, [allAdventures, adventure_rid]);

  useEffect(() => {
    if (props.libraryBlocks && props.libraryBlocks.length > 0 && libraryLists) {
      let firstBlock = props.libraryBlocks[0];
      const { adventure_block_rid, block_level } = firstBlock;
      setPageData({
        ...pageData,
        libraryBlocks:
          currAdvDis === 'Adv-Blocks'
            ? props.adventureBlocks
            : props.libraryBlocks,
        libraryLists: libraryLists ? libraryLists : props.libraryBlocks,
      });
    } else if (props.libraryBlocks && props.libraryBlocks.length === 0) {
      setPageData({
        ...pageData,
        libraryBlocks:
          currAdvDis === 'Adv-Blocks'
            ? props.adventureBlocks
            : props.libraryBlocks,
        libraryLists: libraryLists ? libraryLists : props.libraryBlocks,
      });
    }
  }, [props.libraryBlocks, libraryLists]);

  const [filter, setFilter] = useState('Template Blocks');
  const filterClickHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.currentTarget;
    if (value !== filter) {
      resetDisplayDetails();
      if (
        value === 'Template Blocks' ||
        value === 'Template Lists' ||
        e.currentTarget.innerText === 'add them from your library'
      ) {
        setCurrAdvDis('All-Blocks');
        fetchBoardBlocks(
          userInfo,
          { adventure_rid_exist_flag: adventure_rid },
          { isLibrary: true },
          false,
        );
      } else if (value === 'Trip Blocks' || value === 'Trip Lists') {
        setCurrAdvDis('Adv-Blocks');
        fetchBoardBlocks(
          userInfo,
          { adventure_rid: adventure_rid },
          { isAdventure: true },
          false,
        );
      }
      setFilter(
        e.currentTarget.innerText === 'add them from your library'
          ? 'Template Blocks'
          : value,
      );
    }
  });
  const [displayDetails, setDisplayDetails] = useState({
    level: 1,
    prevLevel: null,
    currentBlockRid: null,
    lastBlockRid: [],
  });
  const [displayDetailsLists, setDisplayDetailsLists] = useState({
    level: 1,
    prevLevel: null,
    currentBlockRid: null,
    lastBlockRid: [],
    list: true,
  });
  const options = { keys: ['list_flag', 'block_level'] };
  let filtered;
  let listFiltered;
  let filterActivated;
  let listFilterActivated;
  let current_adventure;
  if (pageData.libraryBlocks && pageData.libraryLists) {
    filterActivated = new Fuse(pageData.libraryBlocks, options);
    listFilterActivated = new Fuse(pageData.libraryLists, options);
    listFiltered = listFilterActivated.search('true');
    filtered = filterActivated.search({
      $and: [
        // { list_flag: 'false' },
        { block_level: `${displayDetails.level}` },
      ],
    });
  }

  const resetDisplayDetails = () =>
    setDisplayDetails({
      level: 1,
      prevLevel: null,
      currentBlockRid: null,
      lastBlockRid: [],
    });

  const resetDisplayDetailsLists = () => {
    setDisplayDetailsLists({
      level: 1,
      prevLevel: null,
      currentBlockRid: null,
      lastBlockRid: [],
    });
  };

  const handleLevelTraverse = (data) => {
    setDisplayDetails({ ...displayDetails, ...data });
  };
  const handleLevelTraverseLists = (data) => {
    setDisplayDetailsLists({ ...displayDetailsLists, ...data });
  };
  const handleVote = async (voteType, vote) => {
    let voteObject = {
      itinerary_vote_rid: null,
      itinerary_rid: itinerary_info.itinerary_rid,
      vote_rid: voteType === 'up' ? 1 : 3,
      explorer_rid: userInfo.explorer_rid,
      adventure_rid: match.params.adventure_rid,
    };
    if (vote) {
      if (
        itinerary_info.explorer_vote_down ||
        itinerary_info.explorer_vote_up
      ) {
        if (itinerary_info.explorer_vote_down && voteType === 'up') {
          await deleteItineraryVotes(
            itinerary_info.explorer_vote_rid,
            userInfo,
            itinerary_info,
          );
        } else if (itinerary_info.explorer_vote_up && voteType === 'down') {
          await deleteItineraryVotes(
            itinerary_info.explorer_vote_rid,
            userInfo,
            itinerary_info,
          );
        } else if (itinerary_info.explorer_vote_up && voteType === 'up') {
          await deleteItineraryVotes(
            itinerary_info.explorer_vote_rid,
            userInfo,
            itinerary_info,
          );
          return;
        } else if (itinerary_info.explorer_vote_down && voteType === 'down') {
          await deleteItineraryVotes(
            itinerary_info.explorer_vote_rid,
            userInfo,
            itinerary_info,
          );
          return;
        }
        return upsertItineraryVotes(voteObject);
      }
    }

    upsertItineraryVotes(voteObject);
  };

  const handleTraverseUp = () => {
    // debugger;

    if (displayDetails.level === 2) {
      if (currAdvDis === 'Adv-Blocks') {
        props.fetchBoardBlocks(
          userInfo,
          {
            adventure_rid: adventure_rid,
          },
          { isAdventure: true },
          true,
        );
        setDisplayDetails({
          level: 1,
          prevLevel: null,
          currentBlockRid: null,
          lastBlockRid: [],
        });
      } else {
        props.fetchBoardBlocks(
          userInfo,
          { adventure_rid_exist_flag: adventure_rid },
          { isLibrary: true },
          true,
        );
        setDisplayDetails({
          level: 1,
          prevLevel: null,
          currentBlockRid: null,
          lastBlockRid: [],
        });
      }
    } else {
      let lastParentBlockRid =
        displayDetails.lastBlockRid[displayDetails.lastBlockRid.length - 1];

      if (currAdvDis === 'Adv-Blocks') {
        props.fetchBoardBlocks(
          userInfo,
          {
            parent_block_rid: lastParentBlockRid,
            adventure_rid: adventure_rid,
          },
          { isAdventure: true },
          true,
        );
        setDisplayDetails({
          level: displayDetails.level - 1,
          prevLevel: displayDetails.prevLevel - 1,
          currentBlockRid: lastParentBlockRid,
          lastBlockRid: displayDetails.lastBlockRid.slice(
            0,
            displayDetails.lastBlockRid.length - 1,
          ),
        });
      } else {
        props.fetchBoardBlocks(
          userInfo,
          {
            parent_block_rid: lastParentBlockRid,
            adventure_rid_exist_flag: adventure_rid,
          },
          { isLibrary: true },
          true,
        );
        setDisplayDetails({
          level: displayDetails.level - 1,
          prevLevel: displayDetails.prevLevel - 1,
          currentBlockRid: lastParentBlockRid,
          lastBlockRid: displayDetails.lastBlockRid.slice(
            0,
            displayDetails.lastBlockRid.length - 1,
          ),
        });
      }
    }
  };

  const handleTraverseUpLists = () => {
    // debugger;

    if (displayDetailsLists.level === 2) {
      // debugger;

      props.fetchBoardBlocks(
        userInfo,
        { adventure_rid_exist_flag: adventure_rid },
        { isLibrary: true, isLibraryListOnly: true },
        true,
      );
      setDisplayDetailsLists({
        level: 1,
        prevLevel: null,
        currentBlockRid: null,
        lastBlockRid: [],
        list: true,
      });
    } else {
      // debugger;

      let lastParentBlockRid =
        displayDetailsLists.lastBlockRid[
          displayDetailsLists.lastBlockRid.length - 1
        ];

      props.fetchBoardBlocks(
        userInfo,
        {
          parent_block_rid: lastParentBlockRid,
          adventure_rid_exist_flag: adventure_rid,
        },
        { isLibrary: true, isLibraryListOnly: true },
        true,
      );
      setDisplayDetailsLists({
        level: displayDetailsLists.level - 1,
        prevLevel: displayDetailsLists.prevLevel - 1,
        currentBlockRid: lastParentBlockRid,
        lastBlockRid: displayDetailsLists.lastBlockRid.slice(
          0,
          displayDetailsLists.lastBlockRid.length - 1,
        ),
      });
    }
  };

  const callBackAfterInsertingBlock = async () => {
    await fetchLocationBlocks({
      account_rid: userInfo.account_rid,
      adventure_rid: null,
      itinerary_rid: null,
    });
  };

  const onDragEnd = useCallback(
    (value, allBlocks, allItineraryItems, pageData, filtered, listFiltered) => {
      localStorage.removeItem('blockClone');
      if (value.reason === 'CANCEL') return;
      setIsHoverEnabled(false);
      if (
        value.destination &&
        value.destination.droppableId === 'itinerary-map-dropzone'
      ) {
        setLogCoordinateFlag(false);
        return;
      }
      if (
        value.type === 'blocks' &&
        value.destination &&
        value.source.droppableId !== 'itinerary_adventure_blocks' &&
        value.destination.droppableId === 'itinerary_adventure_blocks'
      )
        return;

      if (
        value.destination &&
        value.source.droppableId === value.destination.droppableId &&
        value.destination.droppableId !== 'it-item-dropzone'
      )
        return;
      if (value.type === 'itinerary-item') {
        let origin = merge(allItineraryItems[value.source.index], {});
        let destination = allItineraryItems[value.destination.index];
        let endTimeSplit = destination.item_end_date.split('T');
        let startTimeSplit = destination.item_start_date.split('T');
        let endTime = `${endTimeSplit[0]} ${origin.item_end_time}`;
        let startTime = `${startTimeSplit[0]} ${origin.item_start_time}`;
        origin.item_start_date = startTime;
        origin.item_end_date = endTime;
        let actionType = { moveItineraryItemOnly: true };
        origin.parent_itinerary_item_rid =
          origin.parent_itinerary_rid && !destination.parent_itinerary_rid
            ? destination.itinerary_item_rid
            : destination.parent_itinerary_rid;
        upsertItineraryItem(origin, userInfo, actionType);
      } else {
        if (value.destination) {
          if (value.destination.droppableId === 'draggable-up-traverser') {
            let draggedBlockParsed = value.draggableId.split('-');
            let draggedBlockRid = draggedBlockParsed[1];

            let filtered = allBlocks.filter((ele) => {
              return ele.block_rid === parseInt(draggedBlockRid);
            });
            let blockSelected = merge({}, filtered[0]);
            blockSelected.parent_block_rid = parseInt(
              displayDetails.lastBlockRid[
                displayDetails.lastBlockRid.length - 1
              ],
            );
            if (currAdvDis === 'Adv-Blocks') {
              blockSelected['adventure_rid'] = adventure_rid;
            }
            unnestBlock(props.account_rid, blockSelected);
          } else {
            let itinerary_item_rid = value.destination.droppableId;
            let block_rid = value.draggableId;
            let parser1 = value.destination.droppableId.split('-');
            let parser2 = value.draggableId.split('-');
            let combined = [...parser1, ...parser2];
            let validInclude = combined.includes('adventureBlock') && true;
            if (validInclude) {
              let copiedBlockRid;
              let originBlock = allBlocks[value.source.index];

              if (parser2.includes('All')) {
                let passOver = merge({}, originBlock);
                passOver.adventure_rid = adventure_rid;

                let returnActionPayload = { itinerary_item_rid };
                let placeToUpdate = {
                  itinerary_item: true,
                };
                delete passOver.parent_block_rid;
                copyToTripBoard(
                  userInfo,
                  passOver,
                  null,
                  returnActionPayload,
                  placeToUpdate,
                ).then((res) => {
                  copiedBlockRid =
                    res.data.userBlocks[0].func_copy_return_new_block;

                  let block_rid = parser2[1];
                  props.moveItineraryBlock(
                    itinerary_item_rid,
                    copiedBlockRid ? copiedBlockRid : block_rid,
                    props.account_rid,
                    explorer_rid,
                    value.destination.index,
                  );

                  filtered[
                    value.source.index
                  ].item.adventure_block_exist_flag = true;

                  // debugger;
                  pageData.itineraryItemBlocks[
                    value.destination.droppableId
                  ].splice(value.destination.index, 0, originBlock);

                  setPageData(pageData);
                });
              } else {
                let block_rid = parser2[1];
                props.moveItineraryBlock(
                  itinerary_item_rid,
                  copiedBlockRid ? copiedBlockRid : block_rid,
                  props.account_rid,
                  explorer_rid,
                  value.destination.index,
                );

                filtered[
                  value.source.index
                ].item.adventure_block_exist_flag = true;

                // debugger;

                pageData.itineraryItemBlocks[
                  value.destination.droppableId
                ].splice(value.destination.index, 0, originBlock);

                setPageData(pageData);
              }
            } else {
              let ogBlockInfo = value.draggableId.split('-');
              let originaItemRid = ogBlockInfo[1];
              block_rid = ogBlockInfo[2];
              let originalItineraryRid = ogBlockInfo[0];
              let destinationRid = value.destination.droppableId;
              let removed = pageData.itineraryItemBlocks[ogBlockInfo[0]].splice(
                value.source.index,
                1,
              );
              pageData.itineraryItemBlocks[
                value.destination.droppableId
              ].splice(value.destination.index, 0, removed[0]);

              props.moveItineraryBlockBeetweenItems(
                destinationRid,
                block_rid,
                props.account_rid,
                originaItemRid,
                originalItineraryRid,
                explorer_rid,
              );
              setPageData(pageData);
            }
          }
        }
        if (value.combine) {
          let draggedBlockParsed = value.draggableId.split('-');
          let draggedBlockRid = draggedBlockParsed[1];
          let destinationBlockParsed = value.combine.draggableId.split('-');
          let destinationBlockRid = destinationBlockParsed[1];
          if (destinationBlockParsed.includes('isFolder')) {
            let filtered;
            filtered = destinationBlockParsed.includes('isFolderList')
              ? listFiltered.filter((ele) => {
                  if (ele.item.block_rid === parseInt(draggedBlockRid))
                    return ele.item;
                })
              : allBlocks.filter((ele) => {
                  return ele.block_rid === parseInt(draggedBlockRid);
                });
            let blockSelected = merge(
              {},
              destinationBlockParsed.includes('isFolderList')
                ? filtered[0].item
                : filtered[0],
            );
            blockSelected.adventure_block_exist_flag = adventure_rid;
            blockSelected.parent_block_rid = parseInt(destinationBlockRid);
            blockSelected.explorer_rid = userInfo.explorer_rid;
            // blockSelected.adventure_rid = adventure_rid
            // debugger
            props.nestBlock(props.account_rid, blockSelected);
            props.fetchBoardBlocks(
              userInfo,
              { adventure_rid: adventure_rid },
              { isAdventure: true },
              false,
            );
            props.fetchBoardBlocks(
              userInfo,
              { adventure_rid_exist_flag: adventure_rid },
              { isLibrary: true },
              false,
            );
            let libraryBlocksCloned;
            let index;
            if (destinationBlockParsed.includes('isFolderList')) {
              libraryBlocksCloned = ([], pageData.libraryLists);
              libraryBlocksCloned.forEach((ele, idx) => {
                if (ele.block_rid === parseInt(destinationBlockRid))
                  ele.child_count += 1;
                if (ele.block_rid === blockSelected.block_rid) index = idx;
              });
            } else {
              libraryBlocksCloned = ([], pageData.libraryBlocks);
              libraryBlocksCloned.forEach((ele, idx) => {
                if (ele.block_rid === parseInt(destinationBlockRid))
                  ele.child_count += 1;
                if (ele.block_rid === blockSelected.block_rid) index = idx;
              });
            }

            libraryBlocksCloned.splice(index, 1);
            setPageData({
              ...pageData,
              libraryBlocks: libraryBlocksCloned,
            });
            // debugger
            // super simple: just removing the dragging item
          }
        }
        // the only one that is required
      }
    },
    [],
  );

  const onDragUpdate = useCallback((value) => {});

  const onDragStart = useCallback(
    (value, allBlocks, allItineraryItems, pageData, filtered) => {
      let draggedBlockParsed = value.draggableId.split('-');
      let draggedBlockRid = draggedBlockParsed[1];

      let filteredBlock = allBlocks.filter((ele) => {
        return ele.block_rid === parseInt(draggedBlockRid);
      });
      setMapLocationBlock(filteredBlock[0]);
      if (!logCoordinateFlag) setLogCoordinateFlag(true);
      setIsHoverEnabled(true);
      if (value.type === 'itinerary-item') {
        setDraggingItSection(value.draggableId);
      }
    },
  );
  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;
  const getRenderItem = (items) => (provided, snapshot, rubric) => {
    let ele;
    ele = items[rubric.source.index].item
      ? items[rubric.source.index].item
      : items[rubric.source.index];
    if (ele.block_name)
      return (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <MiniBlocksClone
            category="block_cache"
            styleActive
            type="block"
            name={ele.block_name}
            description={ele.block_description}
            backgroundColor={ele.ui_color}
            grabber={`grabber-${rubric.source.index}`}
            adventure_rid={adventure_rid}
            vote_flag={ele.vote_flag}
            block_rid={ele.block_rid}
            block={ele}
            isAdventure={currAdvDis === 'Adv-Blocks'}
            handleLevelTraverse={handleLevelTraverse}
            currentLevelDisplayDetails={displayDetails}
            adventureBlock={currAdvDis === 'Adv-Blocks' ? true : false}
          />
        </div>
      );
  };

  const handleCreateList = () => {
    openCheckListModal(
      { isNew: true, isFromMenu: true },
      {
        isAdventureBlock: currAdvDis === 'Adv-Blocks' ? true : false,
        isNestedAdventureBlock:
          currAdvDis === 'Adv-Blocks' && displayDetailsLists.level > 1
            ? true
            : false,
        isLibraryBlock: currAdvDis !== 'Adv-Blocks' ? true : false,
        isNestedLibraryBlock:
          currAdvDis !== 'Adv-Blocks' && displayDetailsLists.level > 1
            ? true
            : false,
      },
      {
        adventureRid:
          currAdvDis === 'Adv-Blocks' ? current_adventure.adventure_rid : null,
        nestedLevel: displayDetailsLists.level,
        parentBlockRid:
          displayDetailsLists.level > 1
            ? displayDetailsLists.currentBlockRid
            : null,
      },
      null,
      userInfo,
    );
    handleMenuClose();
  };

  const handleCreateActivity = () => {
    let openType = { isNew: true, isLibraryBlock: true };
    let activityModalTypeRid = displayDetails.level === 1 ? '1a' : '7a';
    let uiUpdatePayload = {
      uiUpdateFunction: () => callBackAfterInsertingBlock(),
      account_rid: userInfo.account_rid,
      adventure_rid: null,
      adventure_rid_exist_flag: adventure_rid ? adventure_rid : null,
      inspiration_rid: null,
      itinerary_item_rid: null,
      parent_block_rid:
        displayDetails.level === 1 ? null : displayDetails.currentBlockRid,
      explorer_rid: userInfo.explorer_rid,
    };
    let blockPayload =
      displayDetails.level === 1
        ? {}
        : {
            parent_block_rid: displayDetails.currentBlockRid,
          };
    openBlockModal(
      openType,
      activityModalTypeRid,
      uiUpdatePayload,
      blockPayload,
      userInfo,
    );
    handleMenuClose();
  };

  const handleCreateFolder = () => {
    openFolderModal(
      {
        isNew: true,
        isFolder: true,
        isListFolder: false,
        isLibraryFolder: true,
      },
      {
        isAdventureBlock: false,
        isNestedAdventureBlock: false,
        isLibraryBlock: true,
        isNestedLibraryBlock:
          currAdvDis !== 'Adv-Blocks' && displayDetails.level > 1
            ? true
            : false,
      },
      {
        adventureRid:
          currAdvDis === 'Adv-Blocks' ? current_adventure.adventure_rid : null,
        nestedLevel: displayDetails.level,
        parentBlockRid:
          displayDetails.level > 1 ? displayDetails.currentBlockRid : null,
      },
    );
    handleMenuClose();
  };

  const getParentBlockLevel = () => {
    if (displayDetails.level > 1) {
    }
    if (displayDetailsLists.level > 1) {
    }
  };
  return pageData.libraryBlocks &&
    pageData.libraryLists &&
    pageData.libraryBlocks.length >= 0 &&
    pageData.libraryLists.length >= 0 ? (
    <div className="hub-page-wrapper" id="library-page-mobile">
      <DrawerTopBar
        onClose={() => {
          closeDrawer();
          invalidateQueries();
        }}
        title={'Library'}
      >
        <IconButton variant="contrast" onClick={handleMenuClick}>
          <MoreVert />
        </IconButton>
        {/* <Button disabled={pageIsLoading}>OPEN</Button> */}
        <StyledMenu
          open={menuOpen}
          onClose={handleMenuClose}
          anchorEl={menuAnchor}
        >
          <MenuItem onClick={handleCreateActivity}>
            <ActivityIcon />
            Create Activity
          </MenuItem>
          <MenuItem onClick={handleCreateList}>
            <ChecklistIcon />
            Create List
          </MenuItem>
          <MenuItem onClick={handleCreateFolder}>
            <FolderIcon />
            Create Folder
          </MenuItem>
        </StyledMenu>
      </DrawerTopBar>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{ backgroundImage: `url(${ui_image_location})` }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div className="hub-page-container">
        <div
          className="library-page-contents--wrapper"
          id="library-page-contents--wrapper--mobile"
        >
          <DragDropContext
            onDragEnd={(value) => {
              let modified = filtered.map((ele) => ele.item);
              onDragEnd(
                value,
                modified,
                props.itineraryDetails,
                pageData,
                filtered,
                listFiltered,
              );
            }}
          >
            {props.libraryBlocks && pageData.libraryBlocks && (
              <TripBoard
                location={props.location}
                history={props.history}
                maskURL={maskURL}
                adventureName={adventureName}
                selectedAdventure={selectedAdventure}
                allAdventures={allAdventures}
                handleAdventureSelect={handleAdventureSelect}
                displayItems={displayItems}
                handleReposition={handleReposition}
                setCurrAdvDis={setCurrAdvDis}
                currAdvDis={currAdvDis}
                fetchBoardBlocks={fetchBoardBlocks}
                pageData={pageData}
                adventureBlocks={adventureBlocks}
                libraryBlocks={props.libraryBlocks}
                userInfo={userInfo}
                adventure_rid={adventure_rid}
                isHoverEnabled={isHoverEnabled}
                filtered={filtered}
                filterClickHandler={filterClickHandler}
                listFiltered={listFiltered}
                filter={filter}
                itinerary_info={itinerary_info}
                itineraryTab={itineraryTab}
                openBlockModal={openBlockModal}
                current_adventure={current_adventure}
                handleLevelTraverse={handleLevelTraverse}
                displayDetails={displayDetails}
                handleTraverseUp={handleTraverseUp}
                resetDisplayDetails={resetDisplayDetails}
                handleLevelTraverseLists={handleLevelTraverseLists}
                handleTraverseUpLists={handleTraverseUpLists}
                displayDetailsLists={displayDetailsLists}
                resetDisplayDetailsLists={resetDisplayDetailsLists}
                getRenderItem={getRenderItem}
                checkListHandler={() => handleCreateList()}
                blockHandler={() => handleCreateActivity()}
                addListFolderHandler={() =>
                  openFolderModal(
                    {
                      isNew: true,
                      isFolder: true,
                      isListFolder: true,
                      isLibraryFolder: true,
                    },
                    {
                      isAdventureBlock: false,
                      isNestedAdventureBlock: false,
                      isLibraryBlock: true,
                      isNestedLibraryBlock:
                        currAdvDis !== 'Adv-Blocks' &&
                        displayDetailsLists.level > 1
                          ? true
                          : false,
                    },
                    {
                      adventureRid:
                        currAdvDis === 'Adv-Blocks'
                          ? current_adventure.adventure_rid
                          : null,
                      nestedLevel: displayDetailsLists.level,
                      parentBlockRid:
                        displayDetailsLists.level > 1
                          ? displayDetailsLists.currentBlockRid
                          : null,
                    },
                  )
                }
                addActivityFolderHandler={() => handleCreateFolder()}
              />
            )}
            <div className="library-map-parent">
              <MapBox
                usecase="library"
                selectedAdventure={
                  selectedAdventure.length !== 0 ? selectedAdventure[0] : null
                }
                displayTools
                tools={{
                  mapStyleToggle: true,
                  togglePopups: true,
                  backButton: false,
                }}
              />
            </div>
          </DragDropContext>
        </div>
      </div>
    </div>
  ) : (
    <div className="hub-page-wrapper"></div>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Library),
);
