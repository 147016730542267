import DateRangeIcon from '@mui/icons-material/DateRange';
import Co2Icon from '@mui/icons-material/Co2';
import EditNoteIcon from '@mui/icons-material/EditNote';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RecyclingIcon from '@mui/icons-material/Recycling';
import NearMeIcon from '@mui/icons-material/NearMe';
import {
  alpha,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ChipFlexLayout from 'components/chip/chip-flex-layout';
import PaperSection from 'components/section/paper-section';
import ActivityCategoryLayout from 'features/activity/components/activity-category-layout';
import { ActivityIcon } from 'features/activity/components/create-activity-modal-button';
import { FriendsIcon } from 'pages/pwa_hub/adventure/components/friends-card/friends-card';
import React, { useMemo, useState } from 'react';
import useAdventureActivityIcons from '../hooks/use-adventure-activity-icons';
import useAdventureAttendees from '../hooks/use-adventure-attendees';
import useCurrentAdventure from '../hooks/use-current-adventure';
import AdventureActionMenuBase from './adventure-action-menu-base';
import TripPlannerDrawerButtonBase from '../../trip-planner/components/trip-planner-drawer-button-base';
import TripBuilderResponsiveButton from '../../trip-planner/components/trip-builder-responsive-button';
import useCurrentItinerary from 'features/itinerary/hooks/use-current-itinerary';

import svgLibrary from '../../../components/svg_module/svg_library/library/functionLibrary';
import WipeoutCarbonPageButtonBase from 'features/donation/components/wipeout-carbon-page-button';
import { Recycling } from '@mui/icons-material';

const avatarSize = 50;
export function fmtNumber(num) {
  num = Number(num);
  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1) + 'M'; // million
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1) + 'k'; // thousand
  } else {
    return num.toFixed(1); // less than 1000
  }
}

function DistanceCard({ distance }) {
  const formattedDistance = useMemo(() => {
    return `${fmtNumber(distance)} Mi.`;
  }, [distance]);
  return (
    <StatCard sx={{ color: 'text.primary' }}>
      <Stack direction="row">
        <StatCard.Icon>
          <NearMeIcon />
        </StatCard.Icon>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <StatCard.Label>Total Distance</StatCard.Label>
          <StatCard.Value>{formattedDistance}</StatCard.Value>
        </CardContent>
      </Stack>
    </StatCard>
  );
}

function DurationCard({ duration }) {
  return (
    <StatCard sx={{ color: 'text.primary' }}>
      <Stack direction="row">
        <StatCard.Icon>
          <DateRangeIcon />
        </StatCard.Icon>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <StatCard.Label>Duration</StatCard.Label>
          <StatCard.Value>{duration}</StatCard.Value>
        </CardContent>
      </Stack>
    </StatCard>
  );
}

function ImpactCard({ impact }) {
  const { itinerary_info } = useCurrentItinerary();

  const { default_transportation_icon } = useCurrentAdventure();

  const fmtCarbon = `${
    fmtNumber(itinerary_info?.carbon_produced) || '0.0'
  } tons of CO`;

  return (
    <StatCard sx={{ color: 'text.primary' }}>
      <Stack direction="row">
        <StatCard.Icon>
          <SvgIcon>
            {svgLibrary &&
              default_transportation_icon &&
              svgLibrary[default_transportation_icon]('#273A07')}
          </SvgIcon>
        </StatCard.Icon>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <StatCard.Label>Impact</StatCard.Label>
          {itinerary_info?.carbon_produced ? (
            <StatCard.Value>
              {fmtCarbon}
              <sub>2</sub>
            </StatCard.Value>
          ) : (
            <Skeleton width={'100%'}>
              <StatCard.Value>
                1.0k
                <sub>2</sub>
              </StatCard.Value>
            </Skeleton>
          )}
          <WipeoutCarbonPageButtonBase>
            <Button
              startIcon={<Recycling fontSize="large" />}
              variant="contained"
              size="small"
              fullWidth
            >
              WIPE OUT CO<sub>2</sub>
            </Button>
          </WipeoutCarbonPageButtonBase>
        </CardContent>
      </Stack>
    </StatCard>
  );
}

function SummaryCard({ summary }) {
  if (!summary) return <></>;
  return (
    <StatCard sx={{ color: 'text.primary' }}>
      <Stack direction="row">
        <StatCard.Icon>
          <EditNoteIcon />
        </StatCard.Icon>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <StatCard.Label>Summary</StatCard.Label>
          <Typography>{summary}</Typography>
        </CardContent>
      </Stack>
    </StatCard>
  );
}

function ActivitiesCard({ activities }) {
  const { isLoading, data, isError } = useAdventureActivityIcons();

  if (isError) return;
  if (!isLoading && !data?.length) return <></>;

  return (
    <StatCard sx={{ color: 'text.primary' }}>
      <Stack direction="row">
        <StatCard.Icon>
          <ActivityIcon />
        </StatCard.Icon>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            whiteSpace: 'nowrap',
            flexDirection: 'column',
          }}
        >
          <Box mb={0.5}>
            <StatCard.Label>Planned Activities</StatCard.Label>
          </Box>
          {isLoading ? (
            <ChipFlexLayout>
              <Skeleton variant="rounded" width={100} height={45} />
              <Skeleton variant="rounded" width={120} height={45} />
              <Skeleton variant="rounded" width={90} height={45} />
            </ChipFlexLayout>
          ) : (
            <ActivityCategoryLayout categories={data || []} />
          )}
        </CardContent>
      </Stack>
    </StatCard>
  );
}

function FriendsCard({ friendsCount }) {
  const theme = useTheme();
  const maxAvatars = useMediaQuery(theme.breakpoints.down('md')) ? 4 : 10;

  const { isLoading, data } = useAdventureAttendees();
  return (
    <StatCard sx={{ color: 'text.primary' }}>
      <Stack direction="row">
        <StatCard.Icon>
          <FriendsIcon />
        </StatCard.Icon>
        <CardContent
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <StatCard.Label>Attendees</StatCard.Label>
          <AvatarGroup max={maxAvatars} sx={{ justifyContent: 'left' }}>
            {isLoading ? (
              <>
                <Avatar sx={{ width: avatarSize, height: avatarSize }}>
                  <Skeleton variant="circular" />
                </Avatar>
                <Avatar sx={{ width: avatarSize, height: avatarSize }}>
                  <Skeleton variant="circular" />
                </Avatar>
                <Avatar sx={{ width: avatarSize, height: avatarSize }}>
                  <Skeleton variant="circular" />
                </Avatar>
              </>
            ) : (
              data?.map((friend) => {
                return <ClickableTooltipAvatar friend={friend} />;
              })
            )}
          </AvatarGroup>
        </CardContent>
      </Stack>
    </StatCard>
  );
}

function ClickableTooltipAvatar({ friend }) {
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title={friend.friend_name}
        open={open}
        onClose={handleTooltipClose}
        disableHoverListener
        disableFocusListener
        disableTouchListener
      >
        <Avatar
          sx={{ width: avatarSize, height: avatarSize }}
          alt={friend.friend_name}
          src={friend.ui_image_location}
          onClick={handleTooltipOpen}
        />
      </Tooltip>
    </ClickAwayListener>
  );
}
function StatCard({ children, sx = {}, ...rest }) {
  const theme = useTheme();
  return (
    <Card
      {...rest}
      sx={{
        backgroundColor: alpha(theme.palette.background.paper, 1),
        color: 'primary.contrastText',
        borderRadius: 1,
        ...sx,
      }}
    >
      {children}
    </Card>
  );
}

StatCard.Icon = ({ children }) => {
  const clonedChild = React.cloneElement(children, {
    // @ts-ignore
    sx: {
      fontSize: { xs: '25px !important', sm: '50px !important' },
      ...children.props.sx,
    },
  });

  return (
    <Box
      width={{ xs: 100, sm: 150 }}
      minWidth={{ xs: 100, sm: 150 }}
      height={{ xs: 100, sm: 150 }}
      display={{ xs: 'none', md: 'flex' }}
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width={{ xs: 60, sm: 100 }}
        height={{ xs: 60, sm: 100 }}
        sx={{
          backgroundColor: alpha('#000', 0.05),
          borderRadius: '50%',
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {clonedChild}
      </Box>
    </Box>
  );
};

StatCard.Label = ({ children }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        fontWeight: '700',
        color: alpha(theme.palette.text.primary, 0.5),
        width: 1,
        textOverflow: 'ellipses',
      }}
    >
      {children}
    </Typography>
  );
};
StatCard.Value = ({ children }) => {
  const theme = useTheme();
  const variant = useMediaQuery(theme.breakpoints.down('md')) ? 'h5' : 'h4';
  return <Typography variant={variant}>{children}</Typography>;
};

const AdventureOverview = () => {
  const adventure = useCurrentAdventure();

  return (
    <Box my={3}>
      <PaperSection>
        <Stack>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="row"
            mb={3}
            width={1}
          >
            <Box flexGrow={1} overflow="hidden" pr={1}>
              <Typography
                variant="h3"
                whiteSpace={'nowrap'}
                textOverflow={'ellipsis'}
                overflow="hidden"
              >
                {adventure.adventure_name}
              </Typography>
            </Box>
            <Stack direction="row" spacing={1}>
              <TripPlannerDrawerButtonBase>
                <TripBuilderResponsiveButton
                  variant="contained"
                  sx={{ height: '40px !important' }}
                />
              </TripPlannerDrawerButtonBase>
              <AdventureActionMenuBase>
                <IconButton variant="contained">
                  <MoreVertIcon />
                </IconButton>
              </AdventureActionMenuBase>
            </Stack>
          </Box>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <FriendsCard />
              </Grid>
              <Grid item xs={12} md={6}>
                <ImpactCard />
              </Grid>
              <Grid item xs={6} xl={3}>
                <DistanceCard distance={adventure.total_distance} />
              </Grid>
              <Grid item xs={6} xl={3}>
                <DurationCard duration={adventure.duration} />
              </Grid>

              <Grid item xs={12}>
                <ActivitiesCard />
              </Grid>
              <Grid item xs={12}>
                <SummaryCard summary={adventure.adventure_description} />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </PaperSection>
    </Box>
  );
};

export default AdventureOverview;
