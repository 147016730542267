import { makeStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import { CalendarIcon, LocationIcon, svgEdit } from 'components/svg_module';
import svgLibrary from 'components/svg_module/svg_library/library/functionLibrary';
import { styleValues } from '../../../../../../pages/pwa_hub/adventure/components/constants';
import { convertToGeolocationString } from '../../../../../../util/helpers/geolocation-helper';
import MediaSliderWithPreview from '../../../../../common/media-slider-with-preview/media-slider-with-preview';
import HubButton from '../../../../../hub-button';
import { useLibraryLocationBlockCard } from './useLibraryLocationBlockCard';

const useStyles = makeStyles((theme) => ({
  ...styleValues(theme),
  blockItemInfo: {
    fontWeight: '400',
    fontSize: '10px',
    width: '95%',
    overflowWrap: 'break-word',
    color: '#827700',
  },
  iconRoot: {
    minWidth: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blockTypeIcon: {
    width: '15px',
    height: '15px',
    color: '#273A07',
    fill: '#273A07',
  },
  iconText: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  editBtnIcon: {
    width: '10px',
    height: '10px',
    marginRight: '8px',
    color: 'white',
    fill: 'white',
  },
  title: {
    fontWeight: 700,
    fontSize: '14px !important',
    lineHeight: '20px !important',
    color: '#273A07 !important',
  },
  description: {
    fontWeight: 300,
    fontSize: '12px !important',
    lineHeight: '15px !important',
    color: '#827700 !important',
    width: '90%',
    overflowWrap: 'break-word',
  },
  whiteDescription: {
    fontWeight: 300,
    fontSize: '12px !important',
    lineHeight: '15px !important',
    color: 'white !important',
  },
  btnViewDetail: {
    fontWeight: 700,
    fontSize: '12px !important',
    lineHeight: '15px !important',
    padding: '0px !important',
  },
}));

const LibraryLocationBlockCard = ({ block, onEdit, blockMeta }) => {
  const classes = useStyles();

  if (!block) return;
  const { data, loading, errors } = useLibraryLocationBlockCard(
    block.block_rid,
    blockMeta,
  );
  if (loading) return '';
  if (errors.length) return 'An Error has occurred...';

  const { blockDetails, locationDetails } = data;
  if (!blockDetails) return 'No Block Details';

  let images = [
    ...(locationDetails?.images || []),
    ...(blockDetails.block_file || []),
  ];
  return (
    <Box sx={{ padding: '4px' }}>
      <Box>
        {images.length > 0 && (
          <MediaSliderWithPreview
            images={images}
            width={'200px'}
            className={'block-image-carousel'}
            uniqueKey={'normalBlock'}
          />
        )}
      </Box>
      <Box className={classes.title}>{blockDetails?.block_name}</Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'left',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <Box>
          {svgLibrary[blockDetails?.icon_name || ''](
            '',
            [
              classes.svgSize,
              classes.svgColor_237a07,
              classes.cursorSvgDefault,
            ].join(' '),
            [classes.svgSize, classes.cursorSvgDefault].join(' '),
          )}
        </Box>
        <Box className={classes.description}>
          {blockDetails?.block_category_name || ''}
        </Box>
      </Box>
      <Box className={classes.iconText}>
        <Box
          className={[
            classes.staticSvgSize_30_18_withoutColor,
            classes.cursorSvgDefault,
          ].join(' ')}
        >
          <LocationIcon color="#273A07" />
        </Box>
        <Box className={classes.blockItemInfo}>
          {convertToGeolocationString(
            blockDetails?.location_point?.lat,
            blockDetails?.location_point?.lng,
          )}
        </Box>
      </Box>
      {block?.itinerary_item_date_range && (
        <Box className={classes.iconText}>
          <Box
            className={[
              classes.staticSvgSize_30_18_withoutColor,
              classes.cursorSvgDefault,
            ].join(' ')}
          >
            <CalendarIcon color="#273A07" />
          </Box>
          <Box className={classes.blockItemInfo}>
            {block?.itinerary_item_date_range}
          </Box>
        </Box>
      )}
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Box className={classes.description} sx={{ mt: 2 }}>
          {blockDetails?.block_description}
        </Box>
      </Box>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
        <HubButton
          className={classes.whiteDescription}
          onClick={() => onEdit(blockDetails)}
          mode={'red'}
          icon={svgEdit('', '', classes.editBtnIcon)}
          title={'Edit'}
        />
      </Box>
    </Box>
  );
};

export default LibraryLocationBlockCard;
