import React, { useState, useEffect, useCallback, useRef } from 'react';
import { merge } from 'lodash';
import moment from 'moment';
import { setUiState, persistUiState } from '../../../util/helpers/ui_funcs';
import { connect } from 'react-redux';
import MapBox from 'components/modules/map_box';
import Itineraries from './itineraries';
import TripBoard from './trip-board';
import { mapStateToProps, mapDispatchToProps } from './container';
import Fuse from 'fuse.js';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import useMaskingHooks from '../../../util/custom_hooks/maskUrl';
import { useLocation } from 'react-router-dom';
import useCurrentItinerary from 'features/itinerary/hooks/use-current-itinerary';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Skeleton,
} from '@mui/material';
import useTripBoardQuery from 'features/trip-board/hooks/use-trip-board-query';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';
import useInvalidateAdventureData from 'features/adventure/hooks/use-invalidate-adventure-data';
import TripPlannerDrawerButtonBase from 'features/trip-planner/components/trip-planner-drawer-button-base';
import TripPlannerActionMenuBase from 'features/trip-planner/components/trip-planner-action-menu-base';
import DrawerTopBar from 'features/adventure/components/drawer-top-bar';
import { MoreVert } from '@material-ui/icons';
import useTripBuilderDrawerButton from 'features/trip-planner/hooks/use-trip-builder-drawer-button';

const AdventureItinerary = (props) => {
  const { adventure_name } = useCurrentAdventure();
  const { unmaskURL } = useMaskingHooks();
  const location = useLocation();
  const passedState = location.state;

  const { invalidateQueries } = useInvalidateAdventureData();

  // const { data, isLoading, isError, error } = useTripBoardQuery();

  const {
    account_rid,
    itinerary_info,
    explorer_rid,
    deleteItinerary,
    openDeleteModal,
    openBlockModal,
    openItineraryItemModal,
    deleteItineraryItem,
    openItineraryModal,
    userInfo,
    upsertItineraryItem,
    fetchBoardBlocks,
    drillDownIntoAndOutOfItineraryBlock,
    openCheckListModal,
    openCopyModal,
    openFolderModal,
    flushItineraryData,
    flushAdventures,
    fetchAdventureFriend,
  } = props;

  let { adventure_rid = null } = useCurrentAdventure() || {};

  useEffect(() => {
    return () => {
      // flushAdventures();
      // flushItineraryData();
    };
  }, []);

  const { toggleDrawer, isOpen } = useTripBuilderDrawerButton();

  const handleDrawerClose = () => {
    toggleDrawer();
    invalidateQueries();
  };

  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });

  let itinerary_rid;
  console.debug(props.match.params.adventure_rid);

  if (!adventure_rid) {
    adventure_rid = unmaskURL(props.match.params.adventure_rid);
  }
  itinerary_rid = itinerary_info?.itinerary_rid;
  if (!itinerary_rid) {
    itinerary_rid = unmaskURL(props.match.params.itinerary_rid);
  }

  const [currAdvDis, setCurrAdvDis] = useState('Adv-Blocks');
  const [itineraryTab, setItineraryTab] = useState(
    passedState?.data?.itineraryTab || 'Itinerary',
  );

  const [isHoverEnabled] = useState(false);
  const [displayItems, setDisplayItems] = useState({
    summary: false,
    tripBoard: true,
    itinerary: true,
    friends: false,
  });

  const [displayAnchors, setDisplayAnchors] = useState({
    reposition: false,
    currentRepositionAnchor: null,
  });

  const [directionsAndDistances, setDirectionsAndDistances] = useState(null);

  const transitionHandler = () => {
    if (itineraryTab !== 'Itinerary') {
      props.fetchItineraryDetail(itinerary_rid, account_rid, explorer_rid);
    }
  };

  const [displayItineraryTitleList, toggleItineraryTitleList] = useState(false);
  const [loadingTripBoard, setLoadingTripBoard] = useState(true);
  const tabBarIconListRef = useRef();
  const tabBarOuterRef = useRef();

  const handleClickOutSide = useCallback((event) => {
    if (tabBarIconListRef.current && tabBarOuterRef.current) {
      if (
        !tabBarIconListRef.current.contains(event.target) &&
        !tabBarOuterRef.current.contains(event.target)
      ) {
        toggleItineraryTitleList(false);
      }
    }
  });

  useEffect(() => {
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'itinerary' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleClickOutSide);

    return () => {
      window.removeEventListener('click', handleClickOutSide);
    };
  }, []);

  const handleReposition = (name) => {
    setDisplayAnchors({
      ...displayAnchors,
      currentRepositionAnchor: name,
    });

    const element = document.getElementById(name);
    setTimeout(() => {
      if (element) {
        window.scrollTo({
          behavior: element ? 'smooth' : 'auto',
          top: element ? element.offsetTop - 60 : 0,
        });
      }
    }, 300);
  };

  const [pageData, setPageData] = useState({
    libraryBlocks: props.adventureBlocks ? props.adventureBlocks : [],
    itineraryItems: props.itineraryDetails ? props.itineraryDetails : [],
    itineraryItemBlocks: props.itBlocks ? props.itBlocks : {},
  });

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDisplayTransitions = (name) => {
    setDisplayItems({ ...displayItems, [name]: !displayItems[name] });
    let objectToSet = merge({ ...displayItems, [name]: !displayItems[name] });
    setUiState('hub-adventure-selected', objectToSet);
  };

  useEffect(() => {
    function fetchAll() {
      new Promise((resolve, reject) => {
        let adventureQuery = {
          account_rid: userInfo.account_rid,
          inspiration_rid: null,
          page_number: 1,
          page_size: 50,
          image_position_type_rid: 1,
        };
        Promise.all([
          props.fetchItineraryDetail(itinerary_rid, account_rid, explorer_rid),
          // -- this was the original where trip boards were initally fetched
          fetchBoardBlocks(
            userInfo,
            { adventure_rid: adventure_rid },
            { isAdventure: true },
            false,
          ),
          props.fetchUserAdventures(adventureQuery),
          fetchAdventureFriend(adventure_rid),
        ]).then(() => {
          setPageIsLoading(false);
        });

        return resolve('sucesss');
      });
    }
    function fetchItinerary() {
      props.fetchItineraryInformation(adventure_rid, itinerary_rid, userInfo);
      props.fetchItineraryComments(itinerary_rid);
      props.fetchItineraryVotes(itinerary_rid, userInfo);
    }

    fetchItinerary();
    fetchAll();
    scrollTop();
  }, []);

  useEffect(() => {
    let fetchedUi = persistUiState('hub-itineraries-selected  ');
    if (fetchedUi) {
      setDisplayItems(fetchedUi);
    }
  }, []);

  useEffect(() => {
    if (
      (props.adventureBlocks || props.libraryBlocks) &&
      props.itineraryDetails &&
      props.itBlocks
    ) {
      setPageData({
        ...pageData,
        libraryBlocks:
          currAdvDis === 'Adv-Blocks'
            ? props.adventureBlocks
            : props.libraryBlocks,
        itineraryItems: props.itineraryDetails,
        itineraryItemBlocks: props.itBlocks,
      });
      setTimeout(() => setLoadingTripBoard(false), 500);
    } else if (pageData.libraryBlocks === []) setLoadingTripBoard(true);
  }, [
    props.adventureBlocks,
    props.itineraryDetails,
    props.itBlocks,
    props.libraryBlocks,
  ]);

  const [filter, setFilter] = useState('Trip Blocks');

  const filterClickHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    transitionHandler();

    const { value } = e.currentTarget;
    if (itineraryTab !== 'Itinerary') setItineraryTab('Itinerary');
    if (value !== filter) {
      resetDisplayDetails();
    }

    if (
      value === 'Template Blocks' ||
      value === 'Template Lists' ||
      e.currentTarget.innerText === 'add them from your library'
    ) {
      setCurrAdvDis('All-Blocks');
      fetchBoardBlocks(
        userInfo,
        { adventure_rid_exist_flag: adventure_rid },
        { isLibrary: true },
        false,
      );
    } else if (value === 'Trip Blocks' || value === 'Trip Lists') {
      fetchBoardBlocks(
        userInfo,
        { adventure_rid: adventure_rid },
        { isAdventure: true },
        false,
      ).then(() => {
        setCurrAdvDis('Adv-Blocks');
      });
    }
    setFilter(
      e.currentTarget.innerText === 'add them from your library'
        ? 'Template Blocks'
        : value,
    );
  });

  const [displayDetails, setDisplayDetails] = useState({
    level: 1,
    prevLevel: null,
    currentBlockRid: null,
    lastBlockRid: [],
    loading: true,
  });

  const options = { keys: ['list_flag', 'block_level'] };
  let filtered;
  let filterActivated;
  let current_adventure = props.current_adventure;
  if (props.adventureBlocks) {
    filterActivated = new Fuse(props.adventureBlocks, options);
    if (filter === 'Template Lists' || filter === 'Trip Lists') {
      if (filter === 'Trip Lists') {
        filtered = filterActivated.search({
          $and: [{ block_level: `${displayDetails.level}` }],
        });
      } else {
        filtered = filterActivated.search('true');
      }
    } else {
      filtered = filterActivated.search({
        $and: [{ block_level: `${displayDetails.level}` }],
      });
    }
  }

  const resetDisplayDetails = () =>
    setDisplayDetails({
      level: 1,
      prevLevel: null,
      currentBlockRid: null,
      lastBlockRid: [],
      loading: true,
    });

  const handleLevelTraverse = (data) => {
    const displayDetailsWithoutLoading = { ...displayDetails };
    delete displayDetailsWithoutLoading.loading;
    setDisplayDetails({ ...displayDetailsWithoutLoading, ...data });
    setLoadingTripBoard(true);
  };

  const handleTraverseUp = () => {
    setLoadingTripBoard(true);
    if (displayDetails.level === 2) {
      if (currAdvDis === 'Adv-Blocks') {
        props.fetchBoardBlocks(
          userInfo,
          {
            adventure_rid: adventure_rid,
          },
          { isAdventure: true },
          true,
        );
        setDisplayDetails({
          level: 1,
          prevLevel: null,
          currentBlockRid: null,
          lastBlockRid: [],
        });
      } else {
        props.fetchBoardBlocks(
          userInfo,
          { adventure_rid_exist_flag: adventure_rid },
          { isLibrary: true },
          true,
        );
        setDisplayDetails({
          level: 1,
          prevLevel: null,
          currentBlockRid: null,
          lastBlockRid: [],
        });
      }
    } else {
      let lastParentBlockRid =
        displayDetails.lastBlockRid[displayDetails.lastBlockRid.length - 1];

      if (currAdvDis === 'Adv-Blocks') {
        props.fetchBoardBlocks(
          userInfo,
          {
            parent_block_rid: lastParentBlockRid,
            adventure_rid: adventure_rid,
          },
          { isAdventure: true },
          true,
        );
        setDisplayDetails({
          level: displayDetails.level - 1,
          prevLevel: displayDetails.prevLevel - 1,
          currentBlockRid: lastParentBlockRid,
          lastBlockRid: displayDetails.lastBlockRid.slice(
            0,
            displayDetails.lastBlockRid.length - 1,
          ),
        });
      } else {
        props.fetchBoardBlocks(
          userInfo,
          {
            parent_block_rid: lastParentBlockRid,
            adventure_rid_exist_flag: adventure_rid,
          },
          { isLibrary: true },
          true,
        );
        setDisplayDetails({
          level: displayDetails.level - 1,
          prevLevel: displayDetails.prevLevel - 1,
          currentBlockRid: lastParentBlockRid,
          lastBlockRid: displayDetails.lastBlockRid.slice(
            0,
            displayDetails.lastBlockRid.length - 1,
          ),
        });
      }
    }
    props.fetchItineraryDetail(itinerary_rid, account_rid, explorer_rid);
  };

  const openListModalHandler = () => {
    let openType = { isNew: true };
    let blockType = {
      isAdventureBlock: currAdvDis === 'Adv-Blocks' ? true : false,
      isNestedAdventureBlock:
        currAdvDis === 'Adv-Blocks' && displayDetails.level > 1 ? true : false,
      isLibraryBlock: currAdvDis !== 'Adv-Blocks' ? true : false,
      isNestedLibraryBlock:
        currAdvDis !== 'Adv-Blocks' && displayDetails.level > 1 ? true : false,
    };
    let actionPayload = {
      adventureRid:
        currAdvDis === 'Adv-Blocks' ? current_adventure.adventure_rid : null,
      nestedLevel: displayDetails.level,
      parentBlockRid:
        displayDetails.level > 1 ? displayDetails.currentBlockRid : null,
      itineraryInfo: itinerary_info,
      itineraryDetails: props.itineraryDetails,
    };
    openCheckListModal(openType, blockType, actionPayload, null, userInfo);
  };

  const openBlockModalHandler = (itinerary_item_rid) => {
    let openType = { isNew: true };
    let activityModalTypeRid = displayDetails.level > 1 ? '8a' : '2a';
    let uiUpdatePayload = {
      account_rid: userInfo.account_rid,
      adventure_rid: itinerary_info.adventure_rid,
      adventure_rid_exist_flag: null,
      inspiration_rid: null,
      itinerary_item_rid: itinerary_item_rid ? itinerary_item_rid : null,
      parent_block_rid:
        displayDetails.level > 1 ? displayDetails.currentBlockRid : null,
      explorer_rid: userInfo.explorer_rid,
      itineraryInfo: itinerary_info,
    };
    let blockPayload = {
      adventure_rid: itinerary_info.adventure_rid,
      parent_block_rid:
        displayDetails.level > 1 ? displayDetails.currentBlockRid : null,
      itineraryDetails: props.itineraryDetails,
    };
    openBlockModal(
      openType,
      activityModalTypeRid,
      uiUpdatePayload,
      blockPayload,
      userInfo,
    );
  };

  const openFolderModalHandler = (itinerary_item_rid) => {
    openFolderModal(
      {
        isNew: true,
        isFolder: true,
        isListFolder: filter === 'Trip Blocks' ? false : true,
      },
      {
        isAdventureBlock: currAdvDis === 'Adv-Blocks' ? true : false,
        isNestedAdventureBlock:
          currAdvDis === 'Adv-Blocks' && displayDetails.level > 1
            ? true
            : false,
        isLibraryBlock: currAdvDis !== 'Adv-Blocks' ? true : false,
        isNestedLibraryBlock:
          currAdvDis !== 'Adv-Blocks' && displayDetails.level > 1
            ? true
            : false,
      },
      {
        adventureRid:
          currAdvDis === 'Adv-Blocks' ? current_adventure?.adventure_rid : null,
        nestedLevel: displayDetails.level,
        parentBlockRid:
          displayDetails.level > 1 ? displayDetails.currentBlockRid : null,
        itineraryInfo: itinerary_info,
        itineraryDetails: props.itineraryDetails,
      },
    );
  };

  const addDay = () => {
    upsertItineraryItem(
      {
        adventure_rid: itinerary_info.adventure_rid,
        itinerary_item_rid: null,
        parent_itinerary_item_rid: null,
        itinerary_rid: itinerary_info.itinerary_rid,
        explorer_rid: userInfo.explorer_rid,
        item_name: '',
        item_description: '',
        item_start_date: moment(itinerary_info.end_date)
          .utcOffset(2)
          .add(1, 'days')
          .format('dddd D MMM YYYY'),
        item_end_date: moment(itinerary_info.end_date)
          .utcOffset(2)
          .add(1, 'days')
          .format('dddd D MMM YYYY'),
        item_start_time: null,
        item_end_time: null,
        all_day_flag: true,
      },
      userInfo,
    );
  };

  const onBackFromMapView = () => {
    setItineraryTab('Itinerary');
  };

  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;

  return (
    <div className="hub-page-wrapper">
      <DrawerTopBar onClose={handleDrawerClose} title={'Trip Planner'}>
        <TripPlannerActionMenuBase
          openCreateFolder={() => openFolderModalHandler()}
          openCreateActivity={openBlockModalHandler}
          openCreateList={openListModalHandler}
          openEditDates={() =>
            openItineraryModal(itinerary_info, { isEditAndView: true }, null)
          }
          buttonProps={{ disabled: pageIsLoading }}
        >
          {pageIsLoading ? (
            <Skeleton variant="circular" width={30} height={30}></Skeleton>
          ) : (
            <IconButton variant="contrast">
              <MoreVert />
            </IconButton>
          )}
          {/* <Button disabled={pageIsLoading}>OPEN</Button> */}
        </TripPlannerActionMenuBase>
      </DrawerTopBar>
      <Helmet>
        <title>{page_title ? page_title : 'Loading'}</title>
        <meta
          name="description"
          content={
            page_metadata_description ? page_metadata_description : 'Loading'
          }
        />
        <meta
          name="keywords"
          content={page_keywords ? page_keywords : 'Loading'}
        />
      </Helmet>
      {ui_image_location && (
        <div
          className="background-image-behind-content"
          style={{
            backgroundImage: `url(${ui_image_location})`,
          }}
        >
          <div
            className="background-image-opacity-layer"
            style={{
              backgroundColor: `rgba(248,247,216, 0.${
                ui_image_opacity || ui_image_opacity === 0
                  ? ui_image_opacity
                  : 0.4
              })`,
            }}
          />
        </div>
      )}
      <div
        className={`hub-page-container ${
          ui_image_location && 'hub-page-container--no-background-color'
        }`}
        style={{ position: 'relative' }}
      >
        {pageIsLoading ? (
          <Box
            width={1}
            height={1}
            display="flex"
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ background: 'rgb(240 233 214)' }}
          >
            <CircularProgress />
          </Box>
        ) : (
          props.itineraryDetails &&
          itinerary_info && (
            <div
              className="itinerary-page-contents--wrapper"
              id="itinerary-page-contents--wrapper--mobile"
            >
              {props.itineraryDetails ? (
                <>
                  <TripBoard
                    itineraryId={itinerary_rid}
                    displayItems={displayItems}
                    handleDisplayTransitions={handleDisplayTransitions}
                    handleReposition={handleReposition}
                    setCurrAdvDis={setCurrAdvDis}
                    currAdvDis={currAdvDis}
                    pageData={pageData}
                    handleLevelTraverse={handleLevelTraverse}
                    displayDetails={displayDetails}
                    handleTraverseUp={handleTraverseUp}
                    adventure_rid={adventure_rid}
                    isHoverEnabled={isHoverEnabled}
                    resetDisplayDetails={resetDisplayDetails}
                    filtered={filtered}
                    filterClickHandler={filterClickHandler}
                    filter={filter}
                    current_adventure={current_adventure}
                    setItineraryTab={setItineraryTab}
                    itineraryTab={itineraryTab}
                    itinerary_rid={itinerary_rid}
                    openFolderModalHandler={openFolderModalHandler}
                    openItineraryModal={openItineraryModal}
                    openCopyModal={openCopyModal}
                    openDeleteModal={openDeleteModal}
                    deleteItinerary={deleteItinerary}
                    openBlockModalHandler={openBlockModalHandler}
                    openListModalHandler={openListModalHandler}
                    transitionHandler={transitionHandler}
                    addDayHandler={() => {
                      let actionPayload = {
                        itineraryData: itinerary_info,
                      };
                      let openType = {
                        isNew: true,
                      };
                      openItineraryItemModal(openType, actionPayload);
                    }}
                    loadingTripBoard={loadingTripBoard}
                    setLoadingTripBoard={setLoadingTripBoard}
                    {...props}
                  />
                  {!displayItems.itinerary ? (
                    <div
                      className="itinerary-closed-buttons"
                      onClick={() => {
                        handleDisplayTransitions('itinerary');
                        handleReposition('itinerary-contents');
                      }}
                    >
                      <div className="hub-advSel-blocks-titles">TRIP PLAN</div>
                    </div>
                  ) : (
                    <>
                      {itineraryTab === 'Itinerary' ? (
                        <Itineraries
                          addDayHandler={addDay}
                          userInfo={userInfo}
                          pageData={pageData}
                          openBlockModal={openBlockModal}
                          itinerary_info={itinerary_info}
                          openDeleteModal={openDeleteModal}
                          deleteItineraryItem={deleteItineraryItem}
                          adventure_rid={adventure_rid}
                          explorer_rid={explorer_rid}
                          openItineraryItemModal={openItineraryItemModal}
                          drillDownIntoAndOutOfItineraryBlock={
                            drillDownIntoAndOutOfItineraryBlock
                          }
                          isHoverEnabled={isHoverEnabled}
                        />
                      ) : (
                        <div
                          className="itinerary-page-map-wrapper"
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          {/* <MapBox
                          usecase="itinerary"
                          selectedItinerary={itinerary_info}
                          selectedAdventure={current_adventure}
                          displayTools
                          onBack={onBackFromMapView}
                          tools={{
                            filters: true,
                            mapStyleToggle: true,
                            togglePopups: true,
                            backButton: false,
                          }}
                        /> */}
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div />
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdventureItinerary),
);
