import axios from 'axios';

export const fetchUnfriends = (
  account_rid,
  page_number = 1,
  page_size = 12,
  searchString,
) => {
  return axios.get(
    `/api/soc/fetch-unfriended/${account_rid}/${page_number}/${page_size}/${searchString}`,
  );
};

export const fetchFriends = (account_rid, page_number = 1, page_size = 12) => {
  return axios.get(
    `/api/soc/friends/${account_rid}/${page_number}/${page_size}`,
  );
};

export const fetchFriendsForAdventure = (
  account_rid,
  adventure_rid,
  page_number = 1,
  page_size = 12,
) => {
  return axios.get(
    `/api/soc/friends/adventure/${account_rid}/${adventure_rid}/${page_number}/${page_size}`,
  );
};

export const upsertFriends = (data) => {
  return axios.post('/api/soc/friends', data);
};

export const deleteFriend = (account_rid, friend_rid, explorer_rid) => {
  return axios.delete(
    `/api/soc/friends/${account_rid}/${friend_rid}/${explorer_rid}`,
  );
};

export const fetchAccountGroup = (
  account_rid,
  page_number = 1,
  page_size = 12,
) => {
  return axios.patch(
    `/api/soc/account-group/${account_rid}/${page_number}/${page_size}`,
  );
};

export const upsertAccountGroup = (data) => {
  return axios.post('/api/soc/account-group', data);
};

export const deleteAccountGroup = (account_rid, account_group_rid) => {
  return axios.delete(
    `/api/soc/account-group/${account_rid}/${account_group_rid}`,
  );
};

export const fetchAccountGroupFriends = (account_group_rid) => {
  return axios.patch(`/api/soc/account_group_friend/${account_group_rid}`);
};

export const upsertAccountGroupFriend = (data) => {
  return axios.post('/api/soc/account_group_friend', data);
};

export const deleteAccountGroupFriend = (
  account_group_rid,
  account_group_friend_rid,
) => {
  return axios.delete(
    `/api/soc/account_group)friend/${account_group_rid}/${account_group_friend_rid}`,
  );
};


export const fetchAdventureFriend = (
  adventure_rid,
  page_number = 1,
  page_size = 12,
) => {
  return axios.patch(
    `/api/soc/adventure_friend/${adventure_rid}/${page_number}/${page_size}`,
  );
};

export const upsertAdventureFriend = (data) => {
  return axios.post('/api/soc/adventure_friend', data);
};

export const deleteAdventureFriend = (adventure_friend_rid, adventure_rid) => {
  return axios.delete(
    `/api/soc/adventure_friend/${adventure_friend_rid}/${adventure_rid}`,
  );
};

export const fetchAdventureGroup = (
  adventure_rid,
  page_number = 1,
  page_size = 12,
) => {
  return axios.patch(
    `/api/soc/adventure_group/${adventure_rid}/${page_number}/${page_size}`,
  );
};

export const upsertAdventureGroup = (data) => {
  return axios.post('/api/soc/adventure_group', data);
};

export const deleteAdventureGroup = (adventure_rid, adventure_group_rid) => {
  return axios.delete(
    `/api/soc/adventure_group/${adventure_rid}/${adventure_group_rid}`,
  );
};

export const fetchAdventureGroupFriend = (adventure_group_rid) => {
  return axios.patch(`/api/soc/adventure_group_friend/${adventure_group_rid}`);
};

export const upsertAdventureGroupFriend = (data) => {
  return axios.post(`/api/soc/adventure_group_friend`, data);
};

export const deleteAdventureGroupFriend = (
  adventure_group_rid,
  adventure_group_friend_rid,
) => {
  return axios.delete(
    `/api/soc/adventure_group_friend/${adventure_group_rid}/${adventure_group_friend_rid}`,
  );
};

export const fetchUserIncomingNotification = (explorer_rid) => {
  return axios.patch(`/api/soc/notifications_incoming/${explorer_rid}`);
};

export const fetchUserRecentBlockUpdate = (account_rid) => {
  return axios.patch(`/api/soc/recent_block_update/${account_rid}`);
};

export const fetchAccountGroupMembersAndFriends = (
  account_rid,
  account_group_rid,
) => {
  return axios.patch(
    `/api/soc/account-group/members-friends/${account_rid}/${account_group_rid}`,
  );
};

export const postAccountGroupAndAddMembers = (data) => {
  return axios.post('/api/soc/account-group/add-members', data);
};

export const fetchAdventureGroupMembersAndFriends = (
  account_rid,
  adventure_group_rid,
) => {
  return axios.patch(
    `/api/soc/adventure-group/members-friends/${account_rid}/${adventure_group_rid}`,
  );
};

export const updateNotificationAsRead = (data) => {
  return axios.patch('/api/soc/update-notification', data);
};

export const deleteNotification = (data) => {
  return axios.patch('/api/soc/notification', data);
};

export const queryAllNotificationsAsRead = (explorer_rid) => {
  return axios.get(`/api/soc/notification/read-all/${explorer_rid}`);
};

export const fetchUserFriendRequests = (explorer_rid) => {
  return axios.get(`/api/soc/friend_requests/${explorer_rid}`);
};

export const fetchUserAdventureInvites = (explorer_rid) => {
  return axios.get(`/api/soc/adventure_invites/${explorer_rid}`);
};

// used for old adventure datas where adventure attendee wasn't set;
export const updateAdventureAttendee = (data) => {
  return axios.post('/api/soc/adventure_attendee_old', data);
};
