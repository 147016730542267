/**
 * @typedef {"activity" | "list"} BlockType
 */

import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@mui/material';
import ActivityActionMenu from 'features/activity/components/activity-action-menu';
import ListActionMenuBase from 'features/list/components/list-action-menu';
import { BLOCK } from '../constants';
import { getBlockType } from '../util';

/**
 * @param {object} props
 * @param {import("../constants").ActivityBlock | import("../constants").ListBlock  | import('features/list/constants').List} props.block
 */
const BlockActionMenu = ({ block }) => {
  const blockType = getBlockType(block);

  let menu;
  switch (blockType) {
    case BLOCK.TYPE.LIST:
      // @ts-ignore
      menu = (
        <ListActionMenuBase listData={block}>
          <IconButton>
            <MoreHoriz />
          </IconButton>
        </ListActionMenuBase>
      );
      break;
    case BLOCK.TYPE.ACTIVITY:
      // @ts-ignore
      menu = <ActivityActionMenu activityBlock={block} />;
      break;
    default:
      console.warn('Could not infer block type for menu from:', block);
      menu = <></>;
  }
  return menu;
};

export default BlockActionMenu;
