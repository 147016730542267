import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import chroma from 'chroma-js';
import { merge } from 'lodash';
import { Circle } from 'rc-progress';
import {
  svgEditNew,
  svgCopyNew,
  svgDelete,
  svgHierarchy,
  svgComment,
  svgCheckList,
  svgClip,
  svgThumbsDown,
  svgThumbsUp,
  svgInviteAccepted,
  svgListBlock,
  svgUnnest,
  svgCopyToTripBoard,
  svgCompass,
  svgChildCount,
} from 'components/svg_module';
import svgLibrary from 'components/svg_module/svg_library/library/functionLibrary';
import { mapStateToProps, mapDispatchToProps } from './container';
import Activity from './activity';
import Folder from './folder';
import List from './list';
import Thumb from './thumb';
import {
  hasVotes,
  hasComments,
  isTemplate,
  isItinerary,
  isFolder,
  isActivity,
  isList,
  blockType,
  hasFiles,
  hasLocation,
} from './utils';
import { BlockTypes } from './constants';
import { StyledMenu } from 'features/list/components/list-action-menu';
import {
  Box,
  Divider,
  IconButton,
  ListItem,
  MenuItem,
  SvgIcon,
} from '@mui/material';
import {
  CheckCircle,
  Edit,
  MoreHoriz,
  MoreVert,
  SystemUpdateAlt,
} from '@material-ui/icons';
import useToggle from 'hooks/use-toggle';
import { useId } from 'hooks/use-id';
import { ContentCopy, ExpandCircleDown, MoveUp } from '@mui/icons-material';
import { DeleteIcon } from 'features/itinerary/components/delete-itinerary-day-modal-button';
import { FolderIcon } from 'features/folder/components/create-trip-folder-modal-button';
import LikeMenuItem from './like-menu-item';

export const IconWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .watermark-svg-outer,
  svg,
  path {
    fill: ${({ isWhite }) =>
      isWhite ? '#82770044 !important' : '#FFFFFF33 !important'};
  }
`;

export const TitleWrapper = styled.div`
  color: ${({ isWhite }) => isWhite && '#827700 !important'};
  ${({ twoLine }) => twoLine && 'display: -webkit-box;'}
  ${({ twoLine }) => twoLine && 'white-space: break-spaces !important;'}
  ${({ twoLine }) => twoLine && 'word-break: break-word;'}
`;

export const ButtonWrapper = styled.div`
  .mini-blk-hierarchy-outer,
  svg,
  path:not(.vote > svg):not(.in-trip path) {
    fill: ${({ isWhite }) => isWhite && '#827700 !important'};
  }

  .count-spans {
    color: ${({ isWhite }) => isWhite && '#827700 !important'};
  }

  .in-trip {
    color: ${({ isWhite }) => isWhite && '#f00! important'};
  }
`;

const MiniBlocks = ({
  adventureFriends,
  category,
  type,
  selected_flag,
  itinerary_rid,
  adventure_rid,
  name,
  start_date,
  end_date,
  duration,
  backgroundColor,
  openBlockModal,
  block,
  deleteBlock,
  accountRid,
  history,
  isAdventure,
  copyBlock,
  itineraryItemRid,
  voteOnBlock, //function to vote
  removeVote,
  fetchBlocksDynamic,
  handleLevelTraverse,
  currentLevelDisplayDetails,
  blockFile,
  deleteBlockFile,
  currentBlock,
  openBlockFileModal,
  userInfo,
  openDeleteModal,
  fetchBlockList,
  fetchBoardBlocks,
  drillDownIntoAndOutOfItineraryBlock,
  drilledDownItineraries,
  setDrilledDownItineraries,
  voteOnAdventureBlock,
  removeAdventureBlockVote,
  idx,
  itinerary_info,
  openCheckListModal,
  handleTraverseUp,
  upsertBlock,
  styleActive,
  copyToTripBoard,
  openFolderModal,
  copyTemplateBlock,
  fetchTemplateBlocks,
  fetchLocationBlocks,
  ...props
}) => {
  let startingColor;
  let endingColor;
  let style;
  let tipColor;

  const {
    block_name,
    block_rid,
    ui_color,
    block_file_count,
    block_level,
    parent_block_rid,
    child_count,
    folder_flag,
    list_flag,
    template_flag,
    vote_flag,
    total_vote_count_up,
    total_vote_count_down,
    comment_flag,
    comment_count,
    family_count,
    location_flag,
    ui_image_location,
    explorer_vote_down,
    explorer_vote_up,
    adventure_block_rid,
    adventure_block_exist_flag,
    itineriary_block_rid,
    completed_checklist_total,
  } = block;

  if (type === 'block') {
    startingColor = backgroundColor !== 'ffff' ? backgroundColor : '#8bbdd3';
    if (backgroundColor === null) {
      startingColor = '#8bbdd3';
    }

    endingColor = chroma(startingColor).darken(3).hex();
    style = {
      backgroundColor: `linear-gradient(-125deg, ${startingColor}, ${endingColor}),`,
    };
    tipColor = chroma(startingColor).darken(1.5).hex();
  } else {
    startingColor = '#8bbdd3';
    endingColor = chroma(startingColor).darken(3).hex();
    style = {
      backgroundColor: `linear-gradient(-125deg, ${startingColor}, ${endingColor}),`,
    };
    tipColor = chroma(startingColor).darken(1.5).hex();
  }
  function returnActivityModalTypeRid() {
    let uiQuery = {
      account_rid: userInfo.account_rid,
      adventure_rid:
        block.adventure_block_rid || block.itinerary_block_rid
          ? adventure_rid
          : null,
      adventure_rid_exist_flag: adventure_rid ? adventure_rid : null,
      inspiration_rid: null,
      itinerary_item_rid: null,
      parent_block_rid: block.itinerary_item_rid
        ? null
        : block.parent_block_rid,
      explorer_rid: userInfo.explorer_rid,
      itineraryInfo: itinerary_info,
    };
    if (block.itinerary_block_rid) {
      if (block.parent_block_rid) {
        return ['13a', uiQuery];
      }
      return ['14a', uiQuery];
    }
    if (block.adventure_block_rid) {
      if (block.parent_block_rid) {
        return ['12a', uiQuery];
      }
      return ['11a', uiQuery];
    }
    if (block.parent_block_rid) {
      return ['10a', uiQuery];
    }
    return ['9a', uiQuery];
  }

  const unnestHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    // handle unnest for libray blocks
    if (type === 'block' && !block.adventure_block_rid) {
      let passOver = merge({}, block);

      if (currentLevelDisplayDetails.level === 2) {
        passOver.parent_block_rid = null;
        let blockType = { isUnnestingBlock: true };
        upsertBlock(userInfo, passOver, blockType, null).then(() => {
          handleTraverseUp();
        });
      } else {
        passOver.parent_block_rid =
          currentLevelDisplayDetails.lastBlockRid[
            currentLevelDisplayDetails.lastBlockRid.length - 1
          ];
        let blockType = { isUnnestingBlock: true };
        upsertBlock(userInfo, passOver, blockType, null).then(() => {
          handleTraverseUp();
        });
      }
    } else {
      let passOver = merge({}, block);

      if (currentLevelDisplayDetails.level === 2) {
        passOver.parent_block_rid = null;
        let blockType = { isUnnestingBlock: true };
        upsertBlock(userInfo, passOver, blockType, null).then(() => {
          handleTraverseUp();
        });
      } else {
        passOver.parent_block_rid =
          currentLevelDisplayDetails.lastBlockRid[
            currentLevelDisplayDetails.lastBlockRid.length - 1
          ];
        let blockType = { isUnnestingBlock: true };
        upsertBlock(userInfo, passOver, blockType, null).then(() => {
          handleTraverseUp();
        });
      }
    }
  };

  const deleteFuncCaller = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const passedFunc = async (callBack) => {
      if (category === 'it-item') {
        console.log('it-item --------caller');
        let blockType = {
          isItineraryBlock: true,
          isNestedItineraryBlock:
            drilledDownItineraries[block.itinerary_item_rid] &&
            drilledDownItineraries[block.itinerary_item_rid].level > 1 &&
            true,
        };
        let returnActionPayload = {
          itineraryItemRid: block.itinerary_item_rid,
          parentBlockRid:
            drilledDownItineraries[block.itinerary_item_rid] &&
            drilledDownItineraries[block.itinerary_item_rid].level > 1 &&
            block.parent_block_rid,
        };

        deleteBlock(block, userInfo, blockType, returnActionPayload);
      }
      if (type === 'block' && category !== 'it-item') {
        console.log(
          'type = block, not it-item --------caller',
          currentLevelDisplayDetails,
        );
        let blockType = {
          isLibraryBlock: !isAdventure && true,
          isNestedLibraryBlock:
            !isAdventure &&
            true &&
            !!block.parent_block_rid &&
            !block.list_flag,
          isAdventureBlock: isAdventure && true,
          isNestedAdventureBlock:
            isAdventure && true && currentLevelDisplayDetails.level > 1,
          isLibraryAndLists:
            currentLevelDisplayDetails.level === 1 &&
            currentLevelDisplayDetails.list &&
            block.list_flag,
          isNestedLibraryAndLists:
            currentLevelDisplayDetails.level > 1 &&
            currentLevelDisplayDetails.list &&
            block.list_flag,
        };
        let returnActionPayload = {
          adventureRid: isAdventure && adventure_rid,
          parentBlockRid:
            currentLevelDisplayDetails.level > 1 && block.parent_block_rid,
          itineraryRid: itinerary_info ? itinerary_info.itinerary_rid : null,
        };
        let passOver = merge({}, block);
        passOver['adventure_rid_exist_flag'] = adventure_rid;
        deleteBlock(passOver, userInfo, blockType, returnActionPayload).then(
          (res) => {
            fetchLocationBlocks({
              account_rid: userInfo.account_rid,
              adventure_rid: null,
              itinerary_rid: null,
            });
          },
        );
      }
      if (type === 'blockFile') {
        console.log('type = blockFile --------caller');
        deleteBlockFile(blockFile.block_file_rid, currentBlock.block_rid).then(
          (res) => {
            fetchLocationBlocks({
              account_rid: userInfo.account_rid,
              adventure_rid: null,
              itinerary_rid: null,
            });
          },
        );
      }
      callBack();
    };
    openDeleteModal('block', passedFunc);
  };

  const openCommentsHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let openType = {
      isEditWithTab: true,
      tabName: 'COMMENTS',
    };
    if (category === 'it-item') {
      let blockType = {
        isItineraryItemBlock: true,
        isNestedItineraryItemBlock:
          drilledDownItineraries[block.itinerary_item_rid] &&
          drilledDownItineraries[block.itinerary_item_rid].level > 1 &&
          true,
      };
      let actionPayload = {
        itineraryItemRid: block.itinerary_item_rid,
        parentBlockRid:
          blockType.isNestedItineraryItemBlock && block.parent_block_rid,
        adventureRid: adventure_rid,
        itineraryInfo: itinerary_info,
      };
      let passOver = merge({}, block);

      let [
        activityModalTypeRid,
        uiUpdatePayload,
      ] = returnActivityModalTypeRid();
      passOver['idx'] = idx;
      block.list_flag
        ? openCheckListModal(
            openType,
            blockType,
            actionPayload,
            passOver,
            userInfo,
          )
        : openBlockModal(
            openType,
            activityModalTypeRid,
            uiUpdatePayload,
            passOver,
            userInfo,
          );
    }
    if (type === 'block' && category !== 'it-item' && isAdventure) {
      let blockType = {
        isLibraryBlock: !isAdventure && true,
        isNestedLibraryBlock: !isAdventure && true && !!block.parent_block_rid,
        isAdventureBlock: isAdventure && true,
        isNestedAdventureBlock:
          isAdventure && true && currentLevelDisplayDetails.level > 1,
      };
      let actionPayload = {
        adventureRid: isAdventure && adventure_rid,
        parentBlockRid:
          currentLevelDisplayDetails.level > 1 && block.parent_block_rid,
        itineraryInfo: itinerary_info,
      };
      let passOver = merge({}, block);
      let [
        activityModalTypeRid,
        uiUpdatePayload,
      ] = returnActivityModalTypeRid();
      passOver['idx'] = idx;
      block.list_flag
        ? openCheckListModal(
            openType,
            blockType,
            actionPayload,
            passOver,
            userInfo,
          )
        : openBlockModal(
            openType,
            activityModalTypeRid,
            uiUpdatePayload,
            passOver,
            userInfo,
          );
    }
  };

  const openEditHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let openType = {
      isEditAndView: true,
      isFolder: block.folder_flag ? true : false,
      isListFolder: block.list_flag && block.folder_flag ? true : false,
    };
    let [activityModalTypeRid, uiUpdatePayload] = returnActivityModalTypeRid();
    if (category === 'it-item') {
      let blockType = {
        isItineraryItemBlock: true,
        isNestedItineraryItemBlock:
          drilledDownItineraries[block.itinerary_item_rid] &&
          drilledDownItineraries[block.itinerary_item_rid].level > 1 &&
          true,
      };
      let actionPayload = {
        itineraryItemRid: block.itinerary_item_rid,
        parentBlockRid:
          blockType.isNestedItineraryItemBlock && block.parent_block_rid,
        adventureRid: adventure_rid,
        itineraryInfo: itinerary_info,
      };

      block['idx'] = idx;
      if (block.folder_flag)
        return openFolderModal(
          openType,
          blockType,
          actionPayload,
          block,
          userInfo,
        );
      block.list_flag
        ? openCheckListModal(
            openType,
            blockType,
            actionPayload,
            block,
            userInfo,
          )
        : openBlockModal(
            openType,
            activityModalTypeRid,
            uiUpdatePayload,
            block,
            userInfo,
          );
    }
    if (type === 'block' && category !== 'it-item') {
      let blockType = {
        isLibraryBlock: !isAdventure && true,
        isNestedLibraryBlock: !isAdventure && true && !!block.parent_block_rid,
        isAdventureBlock: isAdventure && true,
        isNestedAdventureBlock:
          isAdventure && true && currentLevelDisplayDetails.level > 1,
        itineraryInfo: itinerary_info,
      };
      let actionPayload = {
        adventureRid: adventure_rid,
        parentBlockRid:
          currentLevelDisplayDetails.level > 1 && block.parent_block_rid,
        itineraryInfo: itinerary_info,
      };
      block['idx'] = idx;
      if (block.folder_flag)
        return openFolderModal(
          openType,
          blockType,
          actionPayload,
          block,
          userInfo,
        );
      block.list_flag
        ? openCheckListModal(
            openType,
            blockType,
            actionPayload,
            block,
            userInfo,
          )
        : openBlockModal(
            openType,
            activityModalTypeRid,
            uiUpdatePayload,
            block,
            userInfo,
          );
    }
  };

  const openEntriesHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let openType = {
      isEditWithTab: true,
      tabName: 'ENTRIES',
    };
    if (category === 'it-item') {
      let blockType = {
        isItineraryItemBlock: true,
        isNestedItineraryItemBlock:
          drilledDownItineraries[block.itinerary_item_rid] &&
          drilledDownItineraries[block.itinerary_item_rid].level > 1 &&
          true,
      };
      let actionPayload = {
        itineraryItemRid: block.itinerary_item_rid,
        parentBlockRid:
          blockType.isNestedItineraryItemBlock && block.parent_block_rid,
        adventureRid: adventure_rid,
        itineraryInfo: itinerary_info,
      };
      block['idx'] = idx;

      openCheckListModal(openType, blockType, actionPayload, block, userInfo);
    }
    if (type === 'block' && category !== 'it-item') {
      let blockType = {
        isLibraryBlock: !isAdventure && true,
        isNestedLibraryBlock: !isAdventure && true && !!block.parent_block_rid,
        isAdventureBlock: isAdventure && true,
        isNestedAdventureBlock:
          isAdventure && true && currentLevelDisplayDetails.level > 1,
      };
      let actionPayload = {
        adventureRid: isAdventure && adventure_rid,
        parentBlockRid:
          currentLevelDisplayDetails.level > 1 && block.parent_block_rid,
        itineraryInfo: itinerary_info,
      };
      block['idx'] = idx;

      openCheckListModal(openType, blockType, actionPayload, block, userInfo);
    }
  };

  const openFilesHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let openType = {
      isEditWithTab: true,
      tabName: 'ATTACHMENTS',
    };
    let [activityModalTypeRid, uiUpdatePayload] = returnActivityModalTypeRid();
    if (category === 'it-item') {
      let blockType = {
        isItineraryItemBlock: true,
        isNestedItineraryItemBlock:
          drilledDownItineraries[block.itinerary_item_rid] &&
          drilledDownItineraries[block.itinerary_item_rid].level > 1 &&
          true,
      };
      let actionPayload = {
        itineraryItemRid: block.itinerary_item_rid,
        parentBlockRid:
          blockType.isNestedItineraryItemBlock && block.parent_block_rid,
        adventureRid: adventure_rid,
        itineraryInfo: itinerary_info,
      };
      block['idx'] = idx;

      block.list_flag
        ? openCheckListModal(
            openType,
            blockType,
            actionPayload,
            block,
            userInfo,
          )
        : openBlockModal(
            openType,
            activityModalTypeRid,
            uiUpdatePayload,
            block,
            userInfo,
          );
    }
    if (type === 'block' && category !== 'it-item' && isAdventure) {
      let blockType = {
        isLibraryBlock: !isAdventure && true,
        isNestedLibraryBlock: !isAdventure && true && !!block.parent_block_rid,
        isAdventureBlock: isAdventure && true,
        isNestedAdventureBlock:
          isAdventure && true && currentLevelDisplayDetails.level > 1,
      };
      let actionPayload = {
        adventureRid: isAdventure && adventure_rid,
        parentBlockRid:
          currentLevelDisplayDetails.level > 1 && block.parent_block_rid,
        itineraryInfo: itinerary_info,
      };
      block['idx'] = idx;

      block.list_flag
        ? openCheckListModal(
            openType,
            blockType,
            actionPayload,
            block,
            userInfo,
          )
        : openBlockModal(
            openType,
            activityModalTypeRid,
            uiUpdatePayload,
            block,
            userInfo,
          );
    } else {
      let blockType = {
        isLibraryBlock: !isAdventure && true,
        isNestedLibraryBlock: !isAdventure && true && !!block.parent_block_rid,
      };
      let actionPayload = {
        adventureRid: isAdventure && adventure_rid,
        parentBlockRid:
          currentLevelDisplayDetails &&
          currentLevelDisplayDetails.level > 1 &&
          block.parent_block_rid,
      };
      block['idx'] = idx;

      block.list_flag
        ? openCheckListModal(
            openType,
            blockType,
            actionPayload,
            block,
            userInfo,
          )
        : openBlockModal(
            openType,
            activityModalTypeRid,
            uiUpdatePayload,
            block,
            userInfo,
          );
    }
  };

  const drillDownHandler = () => {
    let queryObject = {
      parent_block_rid: block.block_rid,
      adventure_rid_exist_flag: adventure_rid,
      adventure_rid: isAdventure ? adventure_rid : null,
    };
    let newArr = currentLevelDisplayDetails.lastBlockRid;
    newArr.push(currentLevelDisplayDetails.currentBlockRid);
    let nextLevelDetail = {
      level: currentLevelDisplayDetails.level + 1,
      prevLevel: currentLevelDisplayDetails.level,
      currentBlockRid: block.block_rid,
      lastBlockRid: newArr,
    };
    let originObject = isAdventure
      ? {
          isAdventure: true,
        }
      : {
          isLibrary: true,
          isLibraryListOnly: currentLevelDisplayDetails.list ? true : false,
        };
    if (template_flag && block.account_rid === 1) {
      let type = {
        isTemplateListOnly: block.list_flag ? true : false,
      };
      fetchTemplateBlocks(block.block_rid, type);
    } else {
      fetchBoardBlocks(userInfo, queryObject, originObject, true);
    }
    handleLevelTraverse(nextLevelDetail);
  };

  const drillDownIntoItineraryHandler = () => {
    let nextLevelDetails;
    if (drilledDownItineraries[block.itinerary_item_rid]) {
      const {
        level,
        prevLevel,
        currentBlockRid,
        currentItineraryBlockRid,
        prevItineraryBlockRid,
        lastBlockRid,
      } = drilledDownItineraries[block.itinerary_item_rid];
      let copiedPrevItineraryBlockRid = merge([], prevItineraryBlockRid);
      let copiedLastBlockRid = merge([], lastBlockRid);
      copiedLastBlockRid.push(currentBlockRid);
      copiedPrevItineraryBlockRid.push(currentItineraryBlockRid);
      nextLevelDetails = {
        [block.itinerary_item_rid]: {
          level: level + 1,
          prevLevel: prevLevel + 1,
          currentBlockRid: block.block_rid,
          currentItineraryBlockRid: block.itinerary_block_rid,
          prevItineraryBlockRid: copiedPrevItineraryBlockRid,
          lastBlockRid: copiedLastBlockRid,
        },
      };
    } else {
      nextLevelDetails = {
        [block.itinerary_item_rid]: {
          level: 2,
          prevLevel: 1,
          currentBlockRid: block.block_rid,
          currentItineraryBlockRid: block.itinerary_block_rid,
          prevItineraryBlockRid: [],
          lastBlockRid: [],
        },
      };
    }

    setDrilledDownItineraries({
      ...drilledDownItineraries,
      ...nextLevelDetails,
    });
    let passObject = {
      parent_block_rid: block.block_rid,
      adventure_rid,
    };

    drillDownIntoAndOutOfItineraryBlock(passObject, userInfo, {
      itinerary_item_rid: block.itinerary_item_rid,
    });
  };

  const handleVote = (vote, data) => {
    let voteObject = {
      explorer_rid: userInfo.explorer_rid,
      adventure_block_rid: data.adventure_block_rid,
      parent_block_rid: data.parent_block_rid,
      vote_rid: vote === 'up' ? 1 : 3,
    };
    voteObject['explorer_vote_rid'] = data.explorer_vote_rid;
    if (vote === 'down') {
      return removeVote(
        data.explorer_vote_rid,
        userInfo.explorer_rid,
        data.itineriary_block_rid,
        itinerary_rid,
        accountRid,
        {
          adventure_block_rid: data.adventure_block_rid,
          adventure_block_vote_rid: data.explorer_vote_rid,
          adventure_rid: adventure_rid,
        },
      );
    }
    voteOnBlock(voteObject, itinerary_rid, accountRid, userInfo.explorer_rid, {
      adventure_block_rid: data.adventure_block_rid,
      adventure_rid: adventure_rid,
    });
  };

  const handleAdventureBlockVote = async (vote, data) => {
    let voteObject = {
      explorer_rid: userInfo.explorer_rid,
      adventure_block_rid: data.adventure_block_rid,
      parent_block_rid: data.parent_block_rid,
      vote_rid: vote === 'up' ? 1 : 3,
    };
    let origin = {
      fromBlock: true,
      fromNestedAdventureBlock:
        isAdventure && true && currentLevelDisplayDetails.level > 1,
    };
    let returnActionPayload = {
      adventureRid: adventure_rid,
      parentBlockRid: origin.fromNestedAdventureBlock
        ? data.parent_block_rid
        : null,
    };
    if (vote === 'down') {
      await removeAdventureBlockVote(
        data.adventure_block_rid,
        data.explorer_vote_rid,
        userInfo,
        origin,
        returnActionPayload,
        itinerary_info.itinerary_rid,
      );
    } else {
      await voteOnAdventureBlock(
        voteObject,
        userInfo,
        origin,
        returnActionPayload,
        itinerary_info.itinerary_rid,
      );
    }
  };

  const compassHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let [activityModalTypeRid, uiUpdatePayload] = returnActivityModalTypeRid();

    let openType = {
      isEditWithTab: true,
      tabName: 'MAPS',
    };
    if (category === 'it-item') {
      let blockType = {
        isItineraryItemBlock: true,
        isNestedItineraryItemBlock:
          drilledDownItineraries[block.itinerary_item_rid] &&
          drilledDownItineraries[block.itinerary_item_rid].level > 1 &&
          true,
      };
      let actionPayload = {
        itineraryItemRid: block.itinerary_item_rid,
        parentBlockRid:
          blockType.isNestedItineraryItemBlock && block.parent_block_rid,
        adventureRid: adventure_rid,
        itineraryInfo: itinerary_info,
      };
      let passOver = merge({}, block);
      passOver['idx'] = idx;
      openBlockModal(
        openType,
        activityModalTypeRid,
        uiUpdatePayload,
        passOver,
        userInfo,
      );
    }
    if (type === 'block' && category !== 'it-item') {
      let blockType = {
        isLibraryBlock: !isAdventure && true,
        isNestedLibraryBlock: !isAdventure && true && !!block.parent_block_rid,
        isAdventureBlock: isAdventure && true,
        isNestedAdventureBlock:
          isAdventure && true && currentLevelDisplayDetails.level > 1,
      };
      let actionPayload = {
        adventureRid: isAdventure && adventure_rid,
        parentBlockRid:
          currentLevelDisplayDetails.level > 1 && block.parent_block_rid,
        itineraryInfo: itinerary_info,
      };
      let passOver = merge({}, block);
      passOver['idx'] = idx;
      openBlockModal(
        openType,
        activityModalTypeRid,
        uiUpdatePayload,
        passOver,
        userInfo,
      );
    }
  };

  const openContentsHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    let openType = {
      isEditWithTab: true,
      tabName: 'CONTENTS',
    };
    let blockType = {
      isLibraryBlock: !isAdventure && true,
      isNestedLibraryBlock: !isAdventure && true && !!block.parent_block_rid,
      isAdventureBlock: isAdventure && true,
      isNestedAdventureBlock:
        isAdventure && true && currentLevelDisplayDetails.level > 1,
    };
    let actionPayload = {
      adventureRid: isAdventure && adventure_rid,
      parentBlockRid:
        currentLevelDisplayDetails.level > 1 && block.parent_block_rid,
      itineraryInfo: itinerary_info,
    };
    let passOver = merge({}, block);
    passOver['idx'] = idx;
    openFolderModal(openType, blockType, actionPayload, passOver, userInfo);
  });

  const copyHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (type === 'block') {
      let payload = {
        block_rid: block.block_rid,
        account_rid: block.account_rid,
        adventure_rid: isAdventure ? parseInt(adventure_rid) : null,
        list_flag: block.list_flag,
        adventure_rid_exist_flag: adventure_rid,
        parent_block_rid: block.parent_block_rid
          ? block.parent_block_rid
          : null,
      };
      if (category === 'it-item') {
        payload['itinerary_item_rid'] = itineraryItemRid;
        payload['adventure_rid'] = adventure_rid;
        return copyBlock(true, adventure_rid, accountRid, payload, userInfo);
      }
      isAdventure
        ? copyBlock(true, adventure_rid, accountRid, payload)
        : copyBlock(false, null, null, payload, userInfo);
    }
  };

  const onThumbUp = (event, data) => {
    event.preventDefault();
    event.stopPropagation();

    if (isAdventure) {
      handleAdventureBlockVote('up', data);
    } else {
      handleVote('up', data);
    }
    // } else if (data.itinerary_block_rid) {
    //   handleVote('up', data);
    // } else {
    //   handleAdventureBlockVote('up', data);
    // }
  };

  const onThumbDown = (event, data) => {
    event.preventDefault();
    event.stopPropagation();

    if (isAdventure) {
      handleAdventureBlockVote('down', data);
    } else {
      handleVote('down', data);
    }
    // } else {
    //   handleAdventureBlockVote('up', data);
    // }
  };

  const isWhite = ui_color === '#F9FBFF';

  const Container = useCallback(
    ({ color, children, ...props }) => {
      const type = blockType(block);

      switch (type) {
        case BlockTypes.Folder:
          return (
            <Folder color={color} {...props}>
              {children}
            </Folder>
          );
        case BlockTypes.Activity:
          return (
            <Activity color={color} {...props}>
              {children}
            </Activity>
          );
        case BlockTypes.List:
          return (
            <List color={color} {...props}>
              {children}
            </List>
          );
      }

      return null;
    },
    [block],
  );

  const TopBar = useCallback(() => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
      event.stopPropagation();
      setAnchorEl(null);
    };

    const handleDrillDown = (e) => {
      if (type === 'block' && category !== 'it-item') {
        drillDownHandler();
      } else {
        drillDownIntoItineraryHandler();
      }
      handleClose(e);
    };

    const handleEdit = (e) => {
      openEditHandler(e);
      handleClose(e);
    };

    const handleDelete = (e) => {
      deleteFuncCaller(e);
      handleClose(e);
    };

    const handleCopy = (e) => {
      copyHandler(e);
      handleClose(e);
    };

    const handleUnnest = (e) => {
      unnestHandler(e);
      handleClose(e);
    };

    const handleLike = (e) => {
      handleClose(e);
    };

    if (isTemplate(block)) return null;

    const isRoot = () => {
      return !(currentLevelDisplayDetails?.level > 1);
    };

    const blockType = isFolder(block)
      ? 'Folder'
      : isList(block)
      ? 'List'
      : 'Activity';

    return (
      <>
        <StyledMenu
          MenuListProps={
            {
              // 'aria-labelledby': id,
            }
          }
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {!isAdventure &&
          adventure_block_exist_flag &&
          !adventure_block_rid ? (
            <>
              <MenuItem
                sx={{
                  '& .MuiSvgIcon-root': {
                    color: 'DeepSkyBlue !important',
                  },
                }}
              >
                <CheckCircle />
                {blockType} is in this trip
              </MenuItem>
            </>
          ) : (
            (template_flag || (!adventure_block_rid && adventure_rid)) && (
              <Box>
                <MenuItem
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (template_flag) {
                      return copyTemplateBlock(block, userInfo);
                    }
                    let passOver = merge({}, block);
                    passOver.parent_block_rid = null;
                    passOver.adventure_rid = adventure_rid;
                    let returnType = {
                      isLibraryBlock: true,
                      isNestedLibraryBlock: block_level > 1 ? true : false,
                      isLibraryListOnly:
                        !adventure_block_rid && list_flag ? true : false,
                    };
                    let _actionPayload = {
                      adventureRid: adventure_rid,
                      parentBlockRid: block_level > 1 ? parent_block_rid : null,
                    };
                    copyToTripBoard(
                      userInfo,
                      passOver,
                      returnType,
                      _actionPayload,
                    );
                  }}
                >
                  <SystemUpdateAlt />
                  Add {blockType} to {template_flag ? 'Library' : 'Trip'}
                </MenuItem>
              </Box>
            )
          )}
          {isFolder(block) && [
            <Box>
              <MenuItem onClick={handleDrillDown}>
                <FolderIcon />
                View Folder Contents
              </MenuItem>
            </Box>,
            <Divider />,
          ]}
          <MenuItem onClick={handleEdit}>
            <Edit /> Edit {blockType}
          </MenuItem>
          {!isFolder(block) && <Divider />}
          {!isFolder(block) && (isItinerary(category) || isAdventure) && (
            <LikeMenuItem
              block={block}
              is_white={isWhite}
              isAdventure={isAdventure}
              total_vote_count_up={total_vote_count_up}
              onThumbDown={onThumbDown}
              onThumbUp={onThumbUp}
            />
          )}
          {!isRoot() && !isItinerary(category) && (
            <Box>
              <MenuItem onClick={handleUnnest}>
                <MoveUp />
                Move Up
              </MenuItem>
            </Box>
          )}
          <MenuItem onClick={handleCopy}>
            <ContentCopy />
            Copy {blockType}
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleDelete}>
            <DeleteIcon />
            Delete {blockType}
          </MenuItem>
        </StyledMenu>
        <Box display="flex" flexDirection={'row-reverse'}>
          <ButtonWrapper isWhite={isWhite}>
            <IconButton
              // variant="contained"
              onClick={handleClick}
              sx={{
                p: 0,
              }}
            >
              <MoreVert />
            </IconButton>
          </ButtonWrapper>
        </Box>
      </>
    );
  }, [block, isWhite]);

  const Icon = useCallback(() => {
    const type = blockType(block);
    const SvgIcon = svgLibrary[ui_image_location] || (() => null);

    switch (type) {
      case BlockTypes.Folder:
        return (
          <IconWrapper isWhite={isWhite}>
            {SvgIcon('', 'watermark-svg', 'watermark-svg-outer')}
          </IconWrapper>
        );
      case BlockTypes.Activity:
        return (
          <IconWrapper isWhite={isWhite}>
            {SvgIcon('', 'watermark-svg', 'watermark-svg-outer')}
          </IconWrapper>
        );
      case BlockTypes.List:
        return (
          <IconWrapper isWhite={isWhite}>
            {svgListBlock(
              '',
              'watermark-svg',
              'watermark-svg-outer check-list-svg-outer',
            )}
          </IconWrapper>
        );
    }

    return null;
  }, [block, isWhite]);

  const BottomBar = useCallback(() => {
    return (
      <ButtonWrapper isWhite={isWhite}>
        <div className="bottom-bar">
          {!isAdventure &&
          adventure_block_exist_flag &&
          !adventure_block_rid ? (
            <Box
              className="mini-blk-btm-hierarchy-wrapper in-trip"
              data-text="In trip"
            >
              {svgInviteAccepted(
                '',
                'mini-blk-hierarchy',
                'mini-blk-hierarchy-outer adventure-included-svg-outer ',
              )}
            </Box>
          ) : (
            <></>
            //   (template_flag || (!adventure_block_rid && adventure_rid)) && (
            //     <div
            //       className="mini-blk-btm-hierarchy-wrapper tooltip--bottom--left"
            //       onClick={(e) => {
            //         e.preventDefault();
            //         e.stopPropagation();
            //         if (template_flag) {
            //           return copyTemplateBlock(block, userInfo);
            //         }
            //         let passOver = merge({}, block);
            //         passOver.parent_block_rid = null;
            //         passOver.adventure_rid = adventure_rid;
            //         let returnType = {
            //           isLibraryBlock: true,
            //           isNestedLibraryBlock: block_level > 1 ? true : false,
            //           isLibraryListOnly:
            //             !adventure_block_rid && list_flag ? true : false,
            //         };
            //         let _actionPayload = {
            //           adventureRid: adventure_rid,
            //           parentBlockRid: block_level > 1 ? parent_block_rid : null,
            //         };
            //         copyToTripBoard(
            //           userInfo,
            //           passOver,
            //           returnType,
            //           _actionPayload,
            //         );
            //       }}
            //       data-text={template_flag ? 'Add to library' : 'Add to trip'}
            //     >
            //       {svgCopyToTripBoard(
            //         'color',
            //         'min-blk-svg',
            //         'min-blk-svg-outer',
            //       )}
            //     </div>
            //   )
          )}
          {isFolder(block) && (
            <div className="mini-blk-btm-hierarchy-wrapper" data-text="Items">
              {svgChildCount(
                '',
                'mini-blk-hierarchy',
                'mini-blk-hierarchy-outer',
              )}
              <span className="count-spans count-spans--item-count">
                {child_count}
                {child_count > 1 || child_count === 0 ? ' items' : ' item'}
              </span>
            </div>
          )}
          {hasLocation(block) && (
            <div
              className="mini-blk-btm-hierarchy-wrapper"
              // onClick={compassHandler}
              data-text="Coordinates"
            >
              {svgCompass('', 'mini-blk-hierarchy', 'mini-blk-hierarchy-outer')}
            </div>
          )}
          {isList(block) && (
            <div
              className="mini-blk-btm-hierarchy-wrapper"
              // onClick={openEntriesHandler}
              data-text="Entries"
            >
              {adventure_block_rid || itineriary_block_rid ? (
                <Circle
                  percent={Math.round(
                    (completed_checklist_total / (child_count || 1)) * 100,
                  )}
                  trailWidth="25"
                  trailColor={isWhite ? '#827700' : '#FFFFFF99'}
                  strokeWidth="24"
                  strokeColor="#D18000"
                  className="mini-blk-hierarchy-outer"
                  gapPosition="bottom"
                />
              ) : (
                svgCheckList(
                  '',
                  'mini-blk-hierarchy',
                  'mini-blk-hierarchy-outer',
                )
              )}
              <span className="count-spans count-spans--entries">
                {adventure_block_rid || itineriary_block_rid
                  ? `${completed_checklist_total}/${child_count}`
                  : child_count}
              </span>
            </div>
          )}
          {hasFiles(block) && (
            <div
              className="mini-blk-btm-hierarchy-wrapper"
              // onClick={openFilesHandler}
              data-text="Attachments"
            >
              {svgClip('', 'mini-blk-hierarchy', 'mini-blk-hierarchy-outer')}
              <span className="count-spans count-spans--file-count">
                {block_file_count}
              </span>
            </div>
          )}
          {!isFolder(block) && (isItinerary(category) || isAdventure) && (
            <>
              <Thumb
                block={block}
                is_white={isWhite}
                isAdventure={isAdventure}
                total_vote_count_up={total_vote_count_up}
                onThumbDown={() => {}}
                onThumbUp={() => {}}
              />
              {/* <div
                className="mini-blk-btm-hierarchy-wrapper tooltip--bottom--left"
                onClick={onThumbDown}
                data-text="Vote down"
              >
                {svgThumbsDown('', 'mini-blk-hierarchy', 'mini-blk-hierarchy-outer')}
                <span className="count-spans count-spans--vote-down">
                  {total_vote_count_down}
                </span>
              </div> */}
            </>
          )}
          {/* {(hasComments(block) && (isItinerary(category) || isAdventure)) && (
            <div
              className="mini-blk-btm-hierarchy-wrapper tooltip--bottom--left"
              onClick={openCommentsHandler}
              data-text="Comment"
            >
              {svgComment('', 'mini-blk-hierarchy', 'mini-blk-hierarchy-outer')}
              <span className="count-spans count-spans--comment-count">
                {comment_count}
              </span>
            </div>
          )} */}
          <div style={{ height: '15px' }}></div>
        </div>
      </ButtonWrapper>
    );
  }, [block, adventure_rid]);

  const onBlockClick = useCallback(
    (e) => {
      if (isFolder(block)) {
        // open folder
        if (type === 'block' && category !== 'it-item') {
          drillDownHandler();
        } else {
          drillDownIntoItineraryHandler();
        }
      } else {
        // open block edit modal if not folder
        openEditHandler(e);
      }
    },
    [block],
  );

  const typeID = blockType(block);

  const isTwoLines = (text) => {
    if (text?.length < 20) return false;
    return true;
  };

  return (
    <Container className="block" color={ui_color}>
      <div
        className="wrapper"
        // onClick={onBlockClick}
      >
        <TopBar />
        <div className="center-content">
          <div className="title-wrapper">
            <TitleWrapper
              className="title"
              isWhite={isWhite}
              twoLine={isTwoLines(name)}
            >
              {name}
            </TitleWrapper>
          </div>
          <div className="icon">
            <Icon />
          </div>
        </div>
        <BottomBar />
      </div>
    </Container>
  );
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(MiniBlocks)),
);
