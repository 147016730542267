import { useStore } from 'react-redux';
import drawCustomMarkerHooks from './render_markers_hooks';

const MapHooks = (filters, userInfo) => {
  const store = useStore();
  const markersOnScreen = {};
  const { createLodestarSvg } = drawCustomMarkerHooks(filters, userInfo);

  /*
  rendering marker will be differnet for the usecase of the map,
  on click the marker will display popup for either trip marker or library marker
  trip marker will trigger its necessary modal and libraray marker will trigger its necessary modal
  the markers after being created needs to do two thing, update the marker accordingly for the location needed for the
  sources, where the trip sources will be: "lodestar-location-blocks" and "lodestar-location-blocks-directions" for trips
  and "lodestar-library-location-blocks" and "lodestar-library-location-blocks-directions"

  when triggering the modal, the popup also needs to pass in function to delete itself, under the assumption that when the location block
  is created, it will automatically trigger a refetch of the location block which will automatically trigger an update of the location block
  when this happens the update function in the marker  will automaticlaly render a new popup

  markersOnScreen is made globalized in this hook so for the purpose of being able to address the icon that is clicked if the user were to click on multiple popups
  */
  const renderMarkerOnClick = (
    { lngLat, target, point, ...rest },
    mapboxgl,
    usecase,
    map,
  ) => {
    // markersOnScreen[]);
    const { x, y } = point;
    const width = 25;
    const height = 30;
    let layerBool = map.getLayer('location_block_circles');
    let features;
    let newMarker;
    if (layerBool) {
      features = map.queryRenderedFeatures(
        [
          [x - width / 2, y - height / 2],
          [x + width / 2, y + height / 2],
        ],

        { radius: 100, layers: ['location_block_circles'] },
        function (err, features) {
          console.log(features[0]);
        },
      );
    }
    if ((features && features.length === 0) || !layerBool) {
      const { lng, lat } = lngLat;

      let props = {
        newBlock: true,
        locationPoint: lngLat,
      };
      const el = createLodestarSvg(props, true);
      newMarker = new mapboxgl.Marker({
        element: el,
        draggable: false,
        scale: 1,
        anchor: 'center',
        offset: [0, -16],
      }).setLngLat([lng, lat]);
      markersOnScreen[`newMarker-${lng}-${lat}`] = newMarker;
      newMarker.addTo(target);
    }
    return { newMarker };
  };

  const handleClearAll = (markersOnScreen) => {};
  return {
    renderMarkerOnClick,
  };
};

export default MapHooks;
