import update from 'immutability-helper';
import { useCallback, useState } from 'react';

function useCrudList(initial = [], handlers) {
  const {
    onAddItem,
    onRemoveItem,
    onCombineItem,
    onReorderItem,
  } = handlers || {
    onAddItem: () => {},
    onRemoveItem: () => {},
    onCombineItem: () => {},
    onReorderItem: () => {},
  };

  const [listData, setListData] = useState(initial);

  const addItem = useCallback(
    (item, index) => {
      setListData((prev) =>
        update(prev, {
          $splice: [[index, 0, item.data]],
        }),
      );

      onAddItem(item.data, index);
    },
    [setListData, onAddItem],
  );

  const removeItem = useCallback(
    (index) => {
      setListData((prev) =>
        update(prev, {
          $splice: [[index, 1]],
        }),
      );

      onRemoveItem(index);
    },
    [setListData, onRemoveItem],
  );

  const reorderItem = useCallback(
    (from, to) => {

      setListData((prev) => {
        if (from < 0 || from >= prev.length || to < 0 || to >= prev.length) {
          // issue where this sometimes called with invalid indices, causing undefined values in array
          console.warn(
            `Invalid indices: Splicing from=${from}, to=${to} for array length=${prev.length}`,
          );
          return prev;
        }
        return update(prev, {
          $splice: [
            [from, 1],
            [to, 0, prev[from]],
          ],
        });
      });

      onReorderItem(from, to);
    },
    [setListData, onReorderItem],
  );

  const combineItem = useCallback(
    (from, to) => {
      from.removeItem(from.index);
      onCombineItem(from.data, to.data);
    },
    [onCombineItem],
  );

  return [
    listData,
    setListData,
    { addItem, removeItem, combineItem, reorderItem },
  ];
}

function useMouseWheel() {
  return function (event) {
    if (event.deltaY == 0) return;

    const container = event.currentTarget;
    const scrollLeft = container.scrollLeft + event.deltaY;

    if (container) {
      container.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  };
}

export { useCrudList, useMouseWheel };
