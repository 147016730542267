import React, { createContext, useContext, useState } from 'react';

// Type for the DrawerContext value
interface DrawerContextType {
  openDrawer: DrawerName | null; // The currently open drawer or null
  toggleDrawer: (drawerName: DrawerName) => void; // Function to toggle a drawer
  closeDrawer: () => void; // Function to close all drawers
}

// Create a context with a default value of undefined
const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

/**
 * Provides global drawer control to child components
 */
export const DrawerProvider = ({ children }: { children: React.ReactNode }) => {
  const [openDrawer, setOpenDrawer] = useState<DrawerName | null>(null);

  /**
   * Toggles which drawer is open. If called with the currently open drawer, it will close.
   * @param drawerName - The name of the drawer to toggle
   */
  const toggleDrawer = (drawerName: DrawerName) => {
    setOpenDrawer((current) => (current === drawerName ? null : drawerName));
  };

  /**
   * Closes any open drawer
   */
  const closeDrawer = () => {
    setOpenDrawer(null);
  };

  return (
    <DrawerContext.Provider value={{ openDrawer, toggleDrawer, closeDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};

// Define the drawer names as a constant object
export const DRAWERS = {
  TRIP_BUILDER: 'TRIP_BUILDER',
  LIBRARY: 'LIBRARY',
} as const;

// Extract the type of the drawer names (values of DRAWERS)
type DrawerName = typeof DRAWERS[keyof typeof DRAWERS];

// Custom hook to use the DrawerContext
export const useDrawerContext = (): DrawerContextType => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('DrawerContext must be used within a DrawerProvider');
  }
  return context;
};
