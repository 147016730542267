import fetchRecommendedPartners from 'features/adventure/api/fetchRecommendedPartners';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { selectPartner, setCalculatedCarbon } from 'actions/ui_actions';
import { fetchPartners } from 'util/api_util/ui_api_util';

const SEA_TREES_PARTNER_RID = 41;

function useWipeoutCarbonButton() {
  const { adventure_rid } = useCurrentAdventure();
  const history = useHistory();
  const dispatch = useDispatch();

  const goto = async () => {
    fetchPartners(SEA_TREES_PARTNER_RID, 3, adventure_rid).then(({ data }) => {
      console.log(data);
      dispatch(selectPartner(data.partners[0]));
      dispatch(setCalculatedCarbon());
      history.push('/hub/checkout-calculate');
    });
  };

  return { goto };
}

export default useWipeoutCarbonButton;
