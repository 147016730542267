const DrawCampsites = (map, data) => {
  if(data.length === 0) return;
  const campsiteMarkersGeoJSON = {
    type: 'FeatureCollection',
    features: [],
  };

  // Add special markers to the GeoJSON source
  data.forEach((point) => {
    const { campsite_coordinates } = point;
    campsiteMarkersGeoJSON.features.push({
      type: 'Feature',
      properties: {
        ...point
      },
      geometry: {
        type: 'Point',
        coordinates: [campsite_coordinates.lng, campsite_coordinates.lat],
      },
    });
  });
  
  map.current.loadImage('/campsite_poi.png', (error, image) => {
    if (error) {
      console.error('Failed to load custom marker image:', error);
      return;
    }
    if(map.current.hasImage('campsite-poi', image)) {}
    else
      map.current.addImage('campsite-poi', image);
    map.current.loadImage('/campsite_disabled_poi.png', (error, image) => {
      if (error) {
        console.error('Failed to load custom marker image:', error);
        return;
      }
      if(map.current.hasImage('campsite-disabled-poi', image)) {}
      else
        map.current.addImage('campsite-disabled-poi', image);
      map.current.addSource("rec-campsites", {
        type: "geojson",
        data: campsiteMarkersGeoJSON,
        cluster: true, // Enable clustering
        clusterRadius: 40, // Radius of each cluster in pixels
      });
      initializeCampSites(map);
    });
  });
};

const initializeCampSites = (map) => {
    map.current.addLayer({
      "id": "campsites-unclustered-points",
      "type": "symbol",
      "source": "rec-campsites",
      "filter": ['all', ['!', ['has', 'point_count']], ['>=', ['zoom'], 10]],
      "layout": {
          "icon-image": [
            'case',
            ['get', 'campsite_reservalbe'],
            'campsite-poi',
            'campsite-disabled-poi'
          ],
          "icon-size": 1,
          'text-field': ['get', 'campsite_name'],
          'text-size': 12,
          'text-anchor': 'top',
          'text-offset': [0, 1],
      },
    });

    map.current.addLayer({
      "id": "campsites-cluster",
      "type": "circle",
      "source": "rec-campsites",
      "minzoom": 12,
      "paint": {
          "circle-color": "#827700",
          "circle-radius": 10,
          "circle-opacity": 1
      },
      "filter": ["has", "point_count"],
  });

  // Add a layer for the clusters' count labels
  map.current.addLayer({
      "id": "campsites-cluster-count",
      "type": "symbol",
      "source": "rec-campsites",
      "layout": {
          "text-field": "{point_count}",
          "text-font": [
              "DIN Offc Pro Medium",
              "Arial Unicode MS Bold"
          ],
          "text-size": 8,
      },
      "paint": {
        "text-color": 'white',
      },
      "minzoom": 12,
  });
}

export default DrawCampsites;

