export function isVideoFile(url) {
  var extension = url.split('.').pop().toLowerCase();
  var videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'wmv', 'flv', 'webm', 'm4v', '3gp', 'mpeg', 'mpg']; // Add more video extensions as needed
  
  return videoExtensions.includes(extension);
}

export function isImageFile(url) {
  var extension = url.split('.').pop().toLowerCase();
  var imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'tiff', 'ico', 'webp']; // Add more image extensions as needed
  
  return imageExtensions.includes(extension);
}

export function isMediaFile(url) {
  return isImageFile(url) || isVideoFile(url);
}