import { Box, Button, Drawer, styled, SvgIcon } from '@mui/material';
import ResponsiveIconButton from 'features/itinerary/components/responsive-icon-button';
import useCurrentItinerary from 'features/itinerary/hooks/use-current-itinerary';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useMaskingHooks from 'util/custom_hooks/maskUrl';

import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '95%',
    height: '93%',
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    overflow: 'hidden',

    left: '2.5%',
    transform: 'translate(-50%, -50%)', // Centers it
    opacity: 0.95,
  },
}));

const TripPlannerButton = ({ sx, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { maskURL } = useMaskingHooks();

  const { itinerary_info: { itinerary_rid } = {} } = useCurrentItinerary();
  const { adventure_rid } = useCurrentAdventure();

  const handleClick = (e) => {
    setLoading(true); // prevent button spam
    e.preventDefault();
    e.stopPropagation();
    history.push(
      `/hub/itinerary/${maskURL(`${adventure_rid}`)}/${maskURL(
        `${itinerary_rid}`,
      )}`,
    );
  };

  return (
    <ResponsiveIconButton
      onClick={handleClick}
      text={'Trip Planner'}
      icon={<VanIcon sx={{ width: 30, height: 30 }} />}
      disabled={loading}
      sx={{ whiteSpace: 'nowrap', ...sx }}
      {...rest}
    />
  );
};

export function VanIcon(props) {
  return (
    <SvgIcon
      {...props} // Spread additional props if needed
      viewBox="0 0 31 20"
    >
      <path d="M6.84732 16.8203C6.84732 17.4265 7.32568 17.919 7.91767 17.919C8.50964 17.919 8.98803 17.4265 8.98803 16.8203C8.98803 16.7351 8.97856 16.6497 8.95963 16.5692H6.87583C6.85679 16.6497 6.84732 16.7351 6.84732 16.8203Z" />
      <path d="M25.043 16.8202C25.043 17.4297 24.5637 17.9238 23.9726 17.9238C23.3815 17.9238 22.9023 17.4296 22.9023 16.8202C22.9023 16.2108 23.3815 15.7168 23.9726 15.7168C24.5637 15.7168 25.043 16.2108 25.043 16.8202Z" />
      <path d="M29.9553 19.2897H25.3136C26.1661 18.8082 26.7439 17.8829 26.7439 16.8201C26.7439 15.2573 25.503 13.9929 23.9733 13.9929C22.4436 13.9929 21.2027 15.2574 21.2027 16.8201C21.2027 17.8829 21.7805 18.8082 22.6329 19.2897H9.24539C9.99778 18.8689 10.539 18.1096 10.6603 17.2085L10.2862 16.6923C10.2246 16.6071 10.1488 16.5692 10.0635 16.5692H9.67993C9.69419 16.6497 9.69887 16.735 9.69887 16.8203C9.69887 17.8148 8.89849 18.6294 7.91814 18.6294C6.93779 18.6294 6.13741 17.8148 6.13741 16.8203C6.13741 16.7351 6.1422 16.6497 6.15635 16.5692H5.8438C5.7586 16.5692 5.68275 16.6071 5.63063 16.6828L5.19023 17.2891C5.32841 18.1547 5.85976 18.8816 6.58832 19.2898L0.355187 19.2897C0.159138 19.2897 0 19.4488 0 19.6449C0 19.8409 0.159138 20 0.355187 20H29.9564C30.1524 20 30.3116 19.8409 30.3116 19.6449C30.3116 19.4488 30.1515 19.2897 29.9553 19.2897ZM23.9733 18.6341C22.9929 18.6341 22.1926 17.8195 22.1926 16.8202C22.1926 15.8209 22.9929 15.0063 23.9733 15.0063C24.9584 15.0063 25.754 15.8209 25.754 16.8202C25.754 17.8195 24.9583 18.6341 23.9733 18.6341Z" />
      <path d="M0.581565 15.3094C0.628902 15.5037 0.681024 15.693 0.733149 15.8778C0.955793 16.6403 1.67086 17.1707 2.47131 17.1707H4.21416C4.33256 17.1707 4.4367 17.1186 4.50307 17.0239L5.04771 16.2756C5.23237 16.0104 5.52596 15.8588 5.8434 15.8588H10.0633C10.3806 15.8588 10.679 16.0103 10.859 16.2756L11.4036 17.0239C11.4699 17.1186 11.5741 17.1707 11.6877 17.1707H20.5109C20.4968 17.0571 20.492 16.9386 20.492 16.8202C20.492 14.869 22.0549 13.2825 23.9728 13.2825C25.8956 13.2825 27.4539 14.8691 27.4539 16.8202C27.4539 16.9386 27.4491 17.057 27.4349 17.1707H28.4863C29.4951 17.1707 30.3097 16.3466 30.3097 15.3284V11.5821C30.3097 11.189 30.267 10.8007 30.1724 10.4218H0.00473022C0.0141977 12.122 0.203652 13.7795 0.558847 15.2051C0.558847 15.2288 0.568315 15.2525 0.582569 15.3093L0.581565 15.3094ZM27.7567 13.6992C27.7567 13.1925 28.1593 12.7852 28.6518 12.7852H29.599V14.9874H28.6518C28.1593 14.9874 27.7567 14.5755 27.7567 14.0687V13.6992Z" />
      <path d="M29.8546 9.48418L27.6856 4.69624C27.2688 3.78217 26.3548 3.19018 25.3602 3.19018H17.14V2.30546H17.9332C18.1293 2.30546 18.2884 2.14632 18.2884 1.95028C18.2884 1.75423 18.1293 1.59509 17.9332 1.59509H17.14V0.710374H17.9332C18.1293 0.710374 18.2884 0.551236 18.2884 0.355187C18.2884 0.159138 18.1293 0 17.9332 0H4.05688C3.86083 0 3.70169 0.159138 3.70169 0.355187C3.70169 0.551236 3.86083 0.710374 4.05688 0.710374H4.84979V1.59487H4.05688C3.86083 1.59487 3.70169 1.75401 3.70169 1.95006C3.70169 2.14611 3.86083 2.30524 4.05688 2.30524H4.84979V3.19018H2.49942C1.6896 3.19018 0.964953 3.74429 0.742332 4.53519C0.301935 6.09807 0.0555674 7.88364 0.013031 9.71173H29.9492C29.9208 9.6312 29.8924 9.55546 29.8544 9.4844L29.8546 9.48418ZM5.56036 0.710293H16.4297V1.59501L5.56036 1.59479V0.710293ZM5.56036 2.30516L16.4297 2.30538V3.19009H5.56036V2.30516ZM8.02167 8.64587H2.35278C2.44745 7.68923 2.60383 5.81864 2.82637 4.966L8.0217 4.9659L8.02167 8.64587ZM19.5207 8.64587H9.62237V4.966H19.5207V8.64587ZM21.1214 8.64587V4.966H25.3554C25.4786 4.966 25.5875 5.03706 25.6396 5.16014L26.7904 8.64584L21.1214 8.64587Z" />
    </SvgIcon>
  );
}
export default TripPlannerButton;
