import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import { logout } from 'actions/session_actions';
import {
  svgHamburgerMenu,
  svgLibrary,
  svgLibrary2,
  svgTrip,
  svgTripThisYear,
  svgBottomNavRefresh,
  svgMyHub,
  svgBottomNavHamburgerMenu,
  svgDashboardImpact,
  SvgFriendIcon,
  svgTripGreen,
} from 'components/svg_module';

import MenuDrawer from './MenuDrawer';
import {
  openBlockModal,
  openFolderModal,
  openAdventureModal,
  openCheckListModal,
} from 'actions/modal_actions';
import {
  fetchUserIncomingNotification,
  updateNotifications,
  triggerNewNotificationBadge,
} from 'actions/social_actions';
import { triggerMenuDrawerWithTab } from 'actions/ui_actions';
import { svgHubGreen, svgLibraryGreen, svgMenuGreen } from '../../svg_module';
// import {} from "../../library_v2";
const mapStateToProps = (state) => {
  let signedIn = state.session.isAuthenticated
    ? state.session.isAuthenticated
    : null;
  let errors = state.session.erorrs ? state.session.errors : null;
  let user = state.session.user ? state.session.user : null;
  let session = state.session ? state.session : null;
  let userInfo =
    state.session.user && state.session.user.userInfo
      ? state.session.user.userInfo[0]
      : null;
  let userNotifications =
    state.entities.social.userIncomingNotifications &&
    state.entities.social.userIncomingNotifications;
  let openDrawerWithTab = state.ui.components.openDrawerWithTab
    ? state.ui.components.openDrawerWithTab
    : null;
  return {
    signedIn,
    errors,
    userInfo,
    user,
    session,
    userNotifications,
    openDrawerWithTab,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    openBlockModal: (
      openType,
      activityModalTypeRid,
      uiUpdatePayload,
      blockPayload,
      userInfo,
    ) =>
      dispatch(
        openBlockModal(
          openType,
          activityModalTypeRid,
          uiUpdatePayload,
          blockPayload,
          userInfo,
        ),
      ),
    openFolderModal: () =>
      dispatch(
        openFolderModal(
          {
            isNew: true,
            isFolder: true,
            isListFolder: false,
          },
          {
            isAdventureBlock: false,
            isNestedAdventureBlock: false,
            isLibraryBlock: true,
            isNestedLibraryBlock: false,
          },
          {
            adventureRid: null,
            nestedLevel: null,
            parentBlockRid: null,
          },
        ),
      ),
    openCheckListModal: (openType, blockType, actionPayload, data, userInfo) =>
      dispatch(
        openCheckListModal(openType, blockType, actionPayload, data, userInfo),
      ),
    openAdventureModal: () => dispatch(openAdventureModal()),
    fetchUserIncomingNotification: (explorer_rid) =>
      dispatch(fetchUserIncomingNotification(explorer_rid)),
    triggerNewNotificationBadge: (data) =>
      dispatch(triggerNewNotificationBadge(data)),
    triggerMenuDrawerWithTab: (tab) => dispatch(triggerMenuDrawerWithTab(tab)),
  };
};

const BottomNav = (props) => {
  const { openDrawerWithTab, triggerMenuDrawerWithTab } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const menuOpenStyle = {
    transform: `translateX(${menuOpen ? 0 : -101}%)`,
  };

  // document.body.style.position = menuOpen ? "fixed" : "";

  //console.log(document.body.style);
  const handleOpen = () => {
    // setMenuOpen(!menuOpen);
    document
      .getElementById('menu-scroller')
      .scrollTo({ top: 0, behavior: 'smooth' });
    if (menuOpen) {
      return setMenuOpen(!menuOpen);
    }

    triggerMenuDrawerWithTab('menu');
  };

  useEffect(() => {
    if (openDrawerWithTab === 'menu' || openDrawerWithTab === 'notification') {
      setMenuOpen(true);
    }
  }, [openDrawerWithTab]);
  useEffect(() => {
    props.fetchUserIncomingNotification(props.userInfo.explorer_rid);

    return () => {
      document.body.style.position = '';
    };
  }, []);
  const retunActiveTripClassName = () => {
    let flag = props.location.pathname.split('/')[2];

    if (
      flag === 'adventure' ||
      flag === 'itinerary' ||
      flag === 'impact_partner' ||
      flag === 'checkout-calculate'
    ) {
      return 'bottom-nav-item--active';
    }
  };
  //add a scroll event listener to this component that runs every time the user scrolls
  //if the user scrolls down, hide the bottom nav
  //if the user scrolls up, show the bottom nav
  //if the user scrolls up and the bottom nav is already showing, do nothing
  //if the user scrolls down and the bottom nav is already hidden, do nothing

  return (
    <div className="bottom-nav-wrapper" id="bottom-nav-wrapper-mobile">
      <div className="bottom-nav">
        <div className="bottom-nav-button bottom-nav-menu">
          <div
            className={
              menuOpen
                ? 'bottom-nav-item bottom-nav-item--active'
                : 'bottom-nav-item'
            }
          >
            <a className="bottom-nav-menu item-container" onClick={handleOpen}>
              {props.userNotifications && props.userNotifications.length > 0 && (
                <div className="bottom-menu-notification-count-wrapper">
                  <span>{props.userNotifications.length}</span>
                </div>
              )}
              {svgMenuGreen('', 'bottom-nav-svg', 'bottom-nav-svg-outerlayer ')}

              <div>MENU</div>
            </a>
          </div>
        </div>
        <div className="bottom-nav-button">
          <NavLink
            onClick={() => setMenuOpen(false)}
            className={`bottom-nav-item${
              menuOpen ? '' : ''
            } ${retunActiveTripClassName()}`}
            to="/hub/my_adventures"
            activeClassName="bottom-nav-item--active"
          >
            <div className="item-container">
              {/* {svgTripGreen(
                '',
                'bottom-nav-svg',
                'bottom-nav-svg-outerlayer bottom-nav-svg-outerlayer--svgTrip',
              )} */}
              {svgTrip(
                '',
                'bottom-nav-svg',
                'bottom-nav-svg-outerlayer bottom-nav-svg-outerlayer--svgTrip',
                70,
                40,
                '-4 0 100 60',
              )}
              <div>TRIPS</div>
            </div>
          </NavLink>
        </div>
        <div className="bottom-nav-button bottom-nav-hub">
          <NavLink
            onClick={() => setMenuOpen(false)}
            className={
              menuOpen
                ? 'bottom-nav-item bottom-nav-item--notActive'
                : 'bottom-nav-item'
            }
            activeClassName="bottom-nav-item--active"
            to="/hub/friends"
          >
            <div className="item-container">
              {SvgFriendIcon(
                '',
                'bottom-nav-svg',
                'bottom-nav-svg-outerlayer bottom-nav-svg-outerlayer--svgFriend',
              )}
              <div>FRIENDS</div>
            </div>
          </NavLink>
        </div>
        {/* <div className="bottom-nav-button">
          <NavLink
            onClick={() => setMenuOpen(false)}
            className={
              menuOpen
                ? 'bottom-nav-item bottom-nav-item--notActive'
                : 'bottom-nav-item'
            }
            activeStyle={{
              border: '1px solid #f7b84',
            }}
            to="/hub/library"
            activeClassName="bottom-nav-item--active"
          >
            <div className="item-container">
              {svgLibraryGreen(
                '',
                'bottom-nav-svg',
                'bottom-nav-svg-outerlayer bottom-nav-svg-outerlayer--svgLibrary',
              )}
              <div>LIBRARY</div>
            </div>
          </NavLink> */}
        {/* </div> */}
        <div className="bottom-nav-button">
          <NavLink
            onClick={() => setMenuOpen(false)}
            className={
              menuOpen
                ? 'bottom-nav-item bottom-nav-item--notActive'
                : 'bottom-nav-item'
            }
            activeStyle={{
              border: '1px solid #f7b84',
            }}
            to="/hub/my-impact-summary"
            activeClassName="bottom-nav-item--active"
          >
            <div className="item-container">
              {svgDashboardImpact(
                '',
                'bottom-nav-svg',
                'bottom-nav-svg-outerlayer bottom-nav-svg-outerlayer--svgImpact',
              )}
              <div>IMPACT</div>
            </div>
          </NavLink>
        </div>
      </div>

      <MenuDrawer
        sendDataToParent={setMenuOpen}
        style={menuOpenStyle}
        name={props.user ? props.user.full_name : null}
        email={props.user ? props.user.email : null}
        imageSrc={
          props.user &&
          props.user.userInfo &&
          props.user.userInfo[0].ui_image_location
            ? props.user.userInfo[0].ui_image_location
            : '/svg_assets/person_light_orange.svg'
        }
        // props={props}
        menuOpen={menuOpen}
        onClick={() => setMenuOpen(false)}
        userNotifications={props.userNotifications}
        {...props}
      ></MenuDrawer>
      {menuOpen ? (
        <div
          className="dashboard-cover"
          onClick={() => setMenuOpen(false)}
        ></div>
      ) : null}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomNav);
