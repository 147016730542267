import { useDragLayer } from 'react-dnd'
import { DragTypes } from '../../../components/drag-drop/item-types.js'
import BlockPreview from '../../../components/modules/blocks/block-preview.jsx'
import ItinerariesSingleItemPreview from './itineraries-singleitem-preview.jsx'

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

function getItemStyles(offset) {
  if (!offset) {
    return {
      display: 'none',
    }
  }

  let { x, y } = offset
  const transform = `translate(${x}px, ${y}px)`
  return {
    opacity: 0.1,
    transform,
    WebkitTransform: transform,
  }
}
const DragPreview = () => {
  const { itemType, isDragging, item, offset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      offset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }))

  function renderItem() {
    switch (itemType) {
      case DragTypes.Activity:
        return <BlockPreview block={item.data}/>
      case DragTypes.Library:
        return <BlockPreview block={item.data}/>
      case DragTypes.List:
        return <BlockPreview block={item.data}/>
      case DragTypes.Folder:
        return <BlockPreview block={item.data}/>
      case DragTypes.Day:
        return <ItinerariesSingleItemPreview item={item.data}/>
      default:
        return null
    }
  }

  if (!isDragging) {
    return null
  }

  return (
    <div style={layerStyles}>
      <div
        style={getItemStyles(offset)}
      >
        {renderItem()}
      </div>
    </div>
  )
}

export default DragPreview
