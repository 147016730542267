import fetchRecommendedPartners from 'features/adventure/api/fetchRecommendedPartners';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { selectPartner } from 'actions/ui_actions';

function useDonationPageButton() {
  const { adventure_rid } = useCurrentAdventure();
  const history = useHistory();
  const dispatch = useDispatch();

  const goto = async () => {
    fetchRecommendedPartners(adventure_rid).then((res) => {
      let selectedPartners = getLowestRanked(res.data);
      dispatch(selectPartner(selectedPartners));
      history.push('/hub/checkout-calculate');
    });
  };

  return { goto };
}

/**
 * Reduces an array to the three objects with the lowest rank_order.
 * @param {Array<Object>} array - The input array of objects.
 * @param {number} n - The number of elements to return (default is 3).
 * @returns {Array<Object>} - An array of the objects with the lowest rank_order.
 */
function getLowestRanked(array, n = 3) {
  return (
    array
      // .sort((a, b) => a.rank_order - b.rank_order) // Sort by rank_order (ascending)
      .slice(0, n)
  );
}

export default useDonationPageButton;
