import { useQuery } from '@tanstack/react-query';
import { receiveAdventureBlocks } from 'actions/adventure_actions';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';
import { ADVENTURE, TRIP_BOARD, USER } from 'features/query-keys';
import useUserInfo from 'features/user/hooks/use-user-info';
import { useDispatch } from 'react-redux';
import { fetchBlocksDynamic } from 'util/api_util/block_api_util';

function useTripBoardQuery(parent_block_rid?: number) {
  const userInfo = useUserInfo();
  const dispatch = useDispatch();
  const { explorer_rid, account_rid } = userInfo;
  const { adventure_rid } = useCurrentAdventure();

  return useQuery({
    queryKey: [
      USER,
      explorer_rid,
      ADVENTURE,
      adventure_rid,
      TRIP_BOARD,
      parent_block_rid,
    ],
    queryFn: () =>
      fetchBlocksDynamic(account_rid, { adventure_rid, parent_block_rid }).then(
        (res) => {
          const blocks = res.data.userBlocks;
          dispatch(receiveAdventureBlocks(blocks));
          return blocks;
        },
      ),
  });
}

export default useTripBoardQuery;
