import { alpha, Box, Drawer, styled } from '@mui/material';
import HubItinerary from '../../../pages/pwa_hub/itineraries';
import React from 'react';

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '95%',
    height: '93%',
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    // opacity: 0.95,
    left: '2.5%',
    '& .hub-page-wrapper': {
      height: '100% !important',
      width: '100%',
    },
    '& .hub-page-container': {
      height: '100% !important',
    },
  },
}));

function TripPlannerDrawer({ isOpen, handleClose }) {
  return (
    <CustomDrawer
      BackdropProps={{ style: { backgroundColor: alpha('#fff', 0.5) } }}
      anchor={'bottom'}
      open={isOpen}
      onClose={handleClose}
      container={document.body}
      sx={{
        zIndex: 1060,
      }}
    >
      <Box width={1} height={1} position="relative">
        {/* <DrawerTopBar onClose={handleClose} title={'Trip Planner'} /> */}
        <HubItinerary />
      </Box>
    </CustomDrawer>
  );
}

export default TripPlannerDrawer;
