import {
  removeAdventureBlockVote,
  removeVote,
  voteOnAdventureBlock,
  voteOnBlock,
} from 'actions/block_actions';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';
import useInvalidateAdventureData from 'features/adventure/hooks/use-invalidate-adventure-data';
import useCurrentItinerary from 'features/itinerary/hooks/use-current-itinerary';
import useUserInfo from 'features/user/hooks/use-user-info';
import React from 'react';
import { useDispatch } from 'react-redux';

function useBlockVote({ isAdventure }) {
  const userInfo = useUserInfo();
  const dispatch = useDispatch();
  const { invalidateQueries } = useInvalidateAdventureData();
  const { adventure_rid } = useCurrentAdventure();
  const { itinerary_info } = useCurrentItinerary();
  const { itinerary_rid = null } = itinerary_info || {};
  const { account_rid: accountRid } = userInfo;

  const handleAdventureBlockVote = async (vote, data) => {
    console.debug('handling block vote', vote, data);
    let voteObject = {
      explorer_rid: userInfo.explorer_rid,
      adventure_block_rid: data.adventure_block_rid,
      parent_block_rid: data.parent_block_rid,
      vote_rid: vote === 'up' ? 1 : 3,
    };
    let origin = {
      fromBlock: true,
      fromNestedAdventureBlock: false,
    };
    let returnActionPayload = {
      adventureRid: adventure_rid,
      parentBlockRid: origin.fromNestedAdventureBlock
        ? data.parent_block_rid
        : null,
    };
    if (vote === 'down') {
      await dispatch(
        removeAdventureBlockVote(
          data.adventure_block_rid,
          data.explorer_vote_rid,
          userInfo,
          origin,
          returnActionPayload,
          itinerary_info.itinerary_rid,
        ),
      );
    } else {
      await dispatch(
        voteOnAdventureBlock(
          voteObject,
          userInfo,
          origin,
          returnActionPayload,
          itinerary_info.itinerary_rid,
        ),
      );
    }
  };

  const handleVote = (vote, data) => {
    let voteObject = {
      explorer_rid: userInfo.explorer_rid,
      adventure_block_rid: data.adventure_block_rid,
      parent_block_rid: data.parent_block_rid,
      vote_rid: vote === 'up' ? 1 : 3,
    };
    voteObject['explorer_vote_rid'] = data.explorer_vote_rid;
    if (vote === 'down') {
      return dispatch(
        removeVote(
          data.explorer_vote_rid,
          userInfo.explorer_rid,
          data.itineriary_block_rid,
          itinerary_rid,
          accountRid,
          {
            adventure_block_rid: data.adventure_block_rid,
            adventure_block_vote_rid: data.explorer_vote_rid,
            adventure_rid: adventure_rid,
          },
        ),
      );
    }
    dispatch(
      voteOnBlock(
        voteObject,
        itinerary_rid,
        accountRid,
        userInfo.explorer_rid,
        {
          adventure_block_rid: data.adventure_block_rid,
          adventure_rid: adventure_rid,
        },
      ),
    );
  };

  const onThumbDown = (event, data) => {
    event.preventDefault();
    event.stopPropagation();

    if (isAdventure) {
      handleAdventureBlockVote('down', data);
    } else {
      handleVote('down', data);
    }
    invalidateQueries();
  };

  const onThumbUp = (event, data) => {
    event.preventDefault();
    event.stopPropagation();

    if (isAdventure) {
      handleAdventureBlockVote('up', data);
    } else {
      handleVote('up', data);
    }
    invalidateQueries();
  };

  return { onThumbUp, onThumbDown };
}

export default useBlockVote;
