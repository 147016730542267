import { useEffect, useState } from 'react';
import {
  fetchRecGovCampgrounds,
  fetchRecGovCampsites,
} from '../api_util/linked_app_api_util';

export const useCampgroundData = (facilityId) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [data, setCampgroundData] = useState();
  const errCtx = 'Error fetching campsite data:';
  useEffect(() => {
    setLoading(true);
    fetchRecGovCampgrounds({
      facility_id: facilityId,
      page_number: 1,
      page_size: 1,
    })
      .then((res) => {
        if (res.status != 200) throw new Error(errorCtx, `${res.status}`);
        if (!res.data.campgrounds.length)
          throw new Error(errCtx, 'no campsite data');
        // if (res.data.campgrounds[0].campgroud_id != facilityId)
        //   throw new Error(errCtx, 'Received wrong campground');
        setCampgroundData(res.data.campgrounds[0]);
      })
      .catch((err) => {
        setErrors((errors) => [...errors, err]);
      })
      .finally(setLoading(false));
  }, [facilityId]);
  return { data, loading, errors };
};

export const useCampsiteData = (facilityId, campsiteId) => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [data, setCampsiteData] = useState();

  useEffect(() => {
    setLoading(true);
    fetchRecGovCampsites({
      facility_id: facilityId,
      campsite_id: campsiteId,
      page_number: 1,
      page_size: 1,
    })
      .then((res) => {
        if (res.status != 200)
          throw new Error(`Fetching Block Data Error: ${res.status}`);
        //TODO: Parse Data
        if (!res.data.campsites.length)
          throw new Error('Error getting campsite');
        if (res.data.campsites[0].campsite_id != campsiteId)
          throw new Error('Error getting campsite: Received wrong campsite');
        setCampsiteData(res.data.campsites[0]);
      })
      .catch((err) => {
        setErrors((errors) => [...errors, 'Error fetching block details']);
      })
      .finally(setLoading(false));
  }, [campsiteId]);
  return { data, loading, errors };
};
