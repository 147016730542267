import SlideButton from 'components/modules/block_slider_no_button/button';
import SliderClone from 'components/modules/block_slider_no_button/index_clone';
import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import HubCircularProgress from '../../../components/common/circular-progress/circular-progress';
import { Box, Button } from '@mui/material';
import { ExpandLess } from '@material-ui/icons';
const TripBoard = ({
  displayItems,
  handleDisplayTransitions,
  handleReposition,
  currAdvDis,
  handleLevelTraverse,
  displayDetails,
  handleTraverseUp,
  userInfo,
  adventure_rid,
  itineraryId,
  libraryBlocks,
  filterClickHandler,
  filtered,
  filter,
  itinerary_info,
  openBlockModal,
  openCheckListModal,
  openItineraryModal,
  openCopyModal,
  openDeleteModal,
  deleteItinerary,
  current_adventure,
  itineraryDetails,
  setItineraryTab,
  itineraryTab,
  fetchItineraryDetail,
  itinerary_rid,
  openFolderModal,
  openFolderModalHandler,
  openListModalHandler,
  openBlockModalHandler,
  addDayHandler,
  transitionHandler,
  loadingTripBoard,
  setLoadingTripBoard,
  ...props
}) => {
  const settingsButtonInnerRef = useRef();
  const settingsButtonOuterRef = useRef();
  const tabBarIconListRef = useRef();
  const tabBarOuterRef = useRef();
  const handleClickOutSide = useCallback((event) => {
    if (tabBarIconListRef.current && tabBarOuterRef.current) {
      if (
        !tabBarIconListRef.current.contains(event.target) &&
        !tabBarOuterRef.current.contains(event.target)
      ) {
        toggleItineraryIconList(false);
      }
    }
    if (settingsButtonInnerRef.current && settingsButtonOuterRef.current) {
      if (
        !settingsButtonInnerRef.current.contains(event.target) &&
        !settingsButtonOuterRef.current.contains(event.target)
      ) {
        toggleSettingsDropdown(false);
      }
    }
  });

  useEffect(() => {
    window.addEventListener('click', handleClickOutSide);

    return () => {
      window.removeEventListener('click', handleClickOutSide);
    };
  }, [handleClickOutSide]);

  return !displayItems.tripBoard ? (
    <div
      className="itinerary-closed-buttons"
      onClick={
        !displayItems.tripBoard
          ? () => {
              handleDisplayTransitions('tripBoard');
              handleReposition('block-library');
            }
          : null
      }
    >
      <div className="hub-advSel-blocks-titles">Library</div>
    </div>
  ) : (
    <>
      <div
        id="trip_board"
        className="itinerary_adventure_block_wrapper itinerary_adventure_block_wrapper-itinerary backgound-bg-mild"
      >
        <div>
          {itineraryTab === 'Itinerary' && (
            <Box display="flex" minHeight={'38px'}>
              {displayDetails && displayDetails.level > 1 && (
                <Button
                  variant="contained"
                  endIcon={<ExpandLess />}
                  onClick={handleTraverseUp}
                  sx={{ mt: 1, ml: 1, maxHeight: '30px' }}
                >
                  Go Up
                </Button>
              )}
            </Box>
          )}
        </div>
        {itineraryTab === 'Itinerary' && (
          <div className="itinerary_adventure_block_bottom">
            {loadingTripBoard ? (
              <div style={{ margin: 'auto' }}>
                <HubCircularProgress isLoading />
              </div>
            ) : currAdvDis === 'Adv-Blocks' && filtered?.length === 0 ? (
              <div className="placeholder-wrapper">
                <div className="empty-block-text-wrapper itinerary-item-action-guidance">
                  <span className="empty-block-text-high-lighter">
                    Add new folders, activities, and lists
                  </span>
                </div>
              </div>
            ) : (
              <SliderClone
                itineraryId={itineraryId}
                adventure_rid={adventure_rid}
                handleLevelTraverse={handleLevelTraverse}
                displayDetails={displayDetails}
                currAdvDis={currAdvDis}
                itinerary_info={itinerary_info}
                handleTraverseUp={handleTraverseUp}
                filtered={filtered}
                setLoadingTripBoard={setLoadingTripBoard}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TripBoard;
