import { Edit, LibraryAdd } from '@material-ui/icons';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { ButtonBase, Divider, ListItem } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { DeleteIcon } from 'features/itinerary/components/delete-itinerary-day-modal-button';
import { StyledMenu } from 'features/list/components/list-action-menu';
import { useState } from 'react';
import useCopyAdventureModalButton from '../hooks/use-copy-adventure-modal-button';
import useDeleteAdventureModalButton from '../hooks/use-delete-adventure-modal-button';
import useEditAdventureModal from '../hooks/use-edit-adventure-modal';
import TripPlannerDrawerButtonBase from '../../trip-planner/components/trip-planner-drawer-button-base';
import { VanIcon } from 'features/trip-planner/components/trip-builder-page-button';
import useAdventurePermissions from '../hooks/use-adventure-permissions';
import EditAdventureButtonBase from './edit-adventure-button-base';
import DeleteAdventureButtonBase from './delete-adventure-button-base';
import DuplicateAdventureButtonBase from './duplicate-adventure-button-base';
import LibraryDrawerButtonBase from 'features/library/components/library-drawer-button-base';
import LibraryDrawer from 'features/library/components/library-drawer';
import useGlobalLibraryDrawerButton from 'features/library/hooks/use-global-library-drawer-button';

const AdventureActionMenuBase = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {
    toggleDrawer: toggleLibraryDrawer,
    isOpen: libraryDrawerIsOpen,
  } = useGlobalLibraryDrawerButton();

  const { canEditTrip } = useAdventurePermissions();

  const { openModal: openEditAdventureModal } = useEditAdventureModal();
  const {
    openModal: openDeleteAdventureModal,
  } = useDeleteAdventureModalButton();

  const { openModal: openCopyAdvnetureModal } = useCopyAdventureModalButton();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTripPlanner = (e) => {
    // handleTripPlannerClick(e);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = async () => {
    // await openEditAdventureModal();
    handleClose();
  };

  const handleDelete = async () => {
    handleClose();
    // await openDeleteAdventureModal();
  };

  const handleCopy = () => {
    // openCopyAdvnetureModal();
    handleClose();
  };

  const handleLibrary = async () => {
    toggleLibraryDrawer();
    handleClose();
  };

  const id = 'adventure-action-menu';

  return (
    <>
      <ButtonBase
        onClick={handleClick}
        id={id}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          height: 'min-content',
        }}
      >
        {children}
      </ButtonBase>
      <StyledMenu
        id={id}
        MenuListProps={{
          'aria-labelledby': id,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <ListItem disableGutters disablePadding>
          <EditAdventureButtonBase>
            <MenuItem onClick={handleEdit} disableRipple sx={{ width: 1 }}>
              <Edit />
              Edit Trip Summary & Pics
            </MenuItem>
          </EditAdventureButtonBase>
        </ListItem>

        <ListItem disableGutters disablePadding>
          <DuplicateAdventureButtonBase>
            <MenuItem onClick={handleCopy} disableRipple sx={{ width: 1 }}>
              <ContentCopy fontSize="small" />
              Duplicate Trip
            </MenuItem>
          </DuplicateAdventureButtonBase>
        </ListItem>

        <Divider />

        <TripPlannerDrawerButtonBase>
          <MenuItem onClick={handleTripPlanner} disableRipple sx={{ width: 1 }}>
            <VanIcon />
            Open Trip Planner
          </MenuItem>
        </TripPlannerDrawerButtonBase>

        <MenuItem onClick={handleLibrary} disableRipple sx={{ width: 1 }}>
          <LibraryAdd />
          Open Library
        </MenuItem>

        <Divider />

        <DeleteAdventureButtonBase>
          <MenuItem onClick={handleDelete} disableRipple sx={{ width: 1 }}>
            <DeleteIcon />
            Delete Trip
          </MenuItem>
        </DeleteAdventureButtonBase>
      </StyledMenu>
      <LibraryDrawer
        isOpen={libraryDrawerIsOpen}
        handleClose={() => {
          toggleLibraryDrawer();
        }}
      />
    </>
  );
};

export default AdventureActionMenuBase;
