import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@material-ui/core';

const Container = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 160px;
  min-width: 120px;
  max-height: 250px;
  overflow: auto;
  padding: 5px;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 3px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 3px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const useStyles = makeStyles({
  btnClose: {
    fontSize: '10px',
    color: '#273A07',
  }
});

const PopupContainer = ({ children, footer, onClose }) => {
  const classes = useStyles();

  return (
    <Container>
      <Header>
        <IconButton
          className={classes.btnClose}
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon fontSize="10px"/>
        </IconButton>
      </Header>
      <Body>{children}</Body>
      <Footer>{footer}</Footer>
    </Container>
  )
};

PopupContainer.defaultProps = {
  onClose: () => {},
}

export default PopupContainer;