import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import debounce from 'lodash.debounce';

const SearchBox = ({ placeholder, onChange, debounceTime, ...props }) => {
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = debounce(onChange, debounceTime || 300);

  const handleInputChange = (event) => {
    const searchText = event.target.value;
    setSearchText(searchText);
    debouncedSearch(searchText);
  };

  const handleClearClick = () => {
    setSearchText('');
  };

  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchText ? (
              <ClearIcon onClick={handleClearClick} />
            ) : (
              <SearchIcon />
            )}
          </InputAdornment>
        ),
      }}
      onChange={handleInputChange}
      value={searchText}
      {...props}
    />
  );
};

SearchBox.defaultProps = {
  onChange: () => {},
};

export default SearchBox;