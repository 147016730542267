import styled from "@emotion/styled"
import chroma from "chroma-js"

const Container = styled.div`
  width: 110px;
  height: 105px;
  border-radius: 10px;
  background: ${({ color }) => color};
  box-sizing: border-box;
  padding: 8px;
  // box-shadow: 0 1.7px 1.1px -10px rgba(19,10,43,.093),
  //             0 4.3px 3px -10px rgba(19,10,43,.133),
  //             0 8.9px 6.6px -10px rgba(19,10,43,.167),
  //             0 12.3px 14.4px -10px rgba(19,10,43,.207),
  //             0 11px 17px -10px rgba(19,10,43,.3);
`

function List({ color, children, ...props }) {
  const startColor = color || List.defaultProps.color
  const endColor = chroma(startColor).darken(3).hex()
  const mainColor = chroma(startColor).darken(1.5).hex()

  return (
    <Container color={startColor} startColor={startColor} endColor={endColor} {...props}>
      {children}
    </Container>
  )
}

List.defaultProps = {
  color: '#ffd480',
}

export default List