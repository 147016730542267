import React, { useEffet, useCallback } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import Popup from './ShowLibraryLocationPointPopup';

const MovedLibraryBlockPopup = (props) => {
  const {
    markerData,
    updateBlockLocationPoint,
    itineraryInfo,
    userInfo,
    mapFilter,
    fetchLocationBlocks,
    openBlockModal,
    libraryAdventureSelected,
  } = props;
  const { marker, coords, block, store, idx } = markerData;

  const handleCancel = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    let popup = marker.getPopup();
    let domElementPlaceHolder = document.createElement('div');
    ReactDOM.render(
      <Popup type="libraryLocation" block={block} {...props} />,
      domElementPlaceHolder,
    );
    popup.setDOMContent(domElementPlaceHolder);
    marker.setLngLat(coords);
  });

  const updateCoordinatesHandler = useCallback((event) => {
    let newLocations = marker.getLngLat();
    const { lat, lng } = newLocations;
    let blockToUpdate = {
      block_rid: block.block_rid,
      location_point: { lat: `${lat}`, lng: `${lng}` },
    };

    updateBlockLocationPoint(blockToUpdate);
    let popup = marker.getPopup();
    let domElementPlaceHolder = document.createElement('div');
    ReactDOM.render(
      <Popup type="libraryLocation" block={block} {...props} />,
      domElementPlaceHolder,
    );
    popup.setDOMContent(domElementPlaceHolder);
  });

  const callBackAfterInsertingBlock = async (marker) => {
    await fetchLocationBlocks({
      account_rid: userInfo.account_rid,
      adventure_rid: null,
      itinerary_rid: null,
    });
    let popup = marker.getPopup();
    let domElementPlaceHolder = document.createElement('div');
    ReactDOM.render(
      <Popup type="libraryLocation" block={block} {...props} />,
      domElementPlaceHolder,
    );
    popup.setDOMContent(domElementPlaceHolder);
  };

  const editHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    let openType = {
      isEditAndView: true,
    };
    let blockType = {
      isLibraryBlock: true,

      itineraryInfo: null,
    };
    let actionPayload = {
      adventureRid: libraryAdventureSelected,
      // itineraryInfo: itineraryInfo,
      func: () => callBackAfterInsertingBlock(marker),
    };

    block['idx'] = 0;
    let passOver;
    axios.get(`/api/b/block-details/${block.block_rid}`).then((res) => {
      passOver = res.data.blockDetails[0];
      let newLocations = marker.getLngLat();
      const { lat, lng } = newLocations;
      actionPayload.location_point = { lat, lng };

      passOver.location_point = { lat: `${lat}`, lng: `${lng}` };
      openBlockModal(openType, blockType, actionPayload, passOver, userInfo);
    });
  });
  return (
    <div className="moved-location-block-wrapper">
      <button
        className="moved-location-block-button moved-location-block-button--update"
        onClick={updateCoordinatesHandler}
      >
        Update coordinates only
      </button>
      <button
        onClick={editHandler}
        className="moved-location-block-button moved-location-block-button--edit"
      >
        Edit details
      </button>
      <button
        onClick={handleCancel}
        className="moved-location-block-button moved-location-block-button--cancel"
      >
        Cancel
      </button>
    </div>
  );
};

export default MovedLibraryBlockPopup;
