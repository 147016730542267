import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Tooltip } from '@mui/material';
import { styleValues } from '../../../pages/pwa_hub/adventure/components/constants';

const useStyles = makeStyles((theme) => ({
  ...styleValues(theme),
  tanBarItemContainer: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      padding: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      padding: '8px',
    },
  },
  tanBarIcon: {
    [theme.breakpoints.up('sm')]: {
      width: '74px',
      padding: '0px 8px',
      '& svg': {
        width: '58px',
        height: '50px',
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '46px',
      padding: '0px 8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        width: '30px',
        height: '25px',
      },
    },
  },
  tanBarContent: {
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 74px)',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 46px)',
    },
  },
  ellipsis: {
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

const TanBarItem = ({ icon, title, content }) => {
  const classes = useStyles();
  return (
    <Box className={classes.tanBarItemContainer}>
      <Box className={classes.tanBarIcon}>{icon}</Box>
      <Box className={classes.tanBarContent}>
        <Tooltip
          title={
            <Box
              dangerouslySetInnerHTML={{
                __html: (title || '').replaceAll('CO2', 'CO<sub>2</sub>') ?? '',
              }}
            />
          }
          arrow
        >
          <Box
            className={[
              classes.contentFontSize,
              classes.bold,
              classes.ellipsis,
            ].join(' ')}
            dangerouslySetInnerHTML={{
              __html: (title || '').replaceAll('CO2', 'CO<sub>2</sub>') ?? '',
            }}
          ></Box>
        </Tooltip>
        <Tooltip title={content || ''} arrow>
          <Box className={classes.contentFontSize}>{content}</Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default TanBarItem;
