import React, { useState, useEffect } from 'react';
import { svgThumbsUp } from 'components/svg_module';
import styled from 'styled-components';
import { MenuItem, tableSortLabelClasses } from '@mui/material';
import { useSelector } from 'react-redux';
import { LikeIcon } from 'pages/pwa_hub/adventure/components/svgs';
import { ThumbDown, ThumbUp } from '@material-ui/icons';

const ThumbContainer = styled.div`
svg, path { 
  fill: ${({ vote_up, is_white }) =>
    vote_up
      ? '#D18000 !important'
      : is_white
      ? '#827700 !important'
      : 'rgba(255,255,255,0.6)'}
`;

export default function LikeMenuItem({
  is_white,
  block,
  isAdventure,
  total_vote_count_up,
  onThumbDown,
  onThumbUp,
  onClick,
}) {
  const adventureBlock = useSelector(
    (state) =>
      state.entities.itinerary.adventure_blocks?.filter(
        (e) => e.block_rid === block.block_rid,
      )[0],
  );

  const data = isAdventure ? { ...adventureBlock } : { ...block };
  const totalCount = isAdventure
    ? data.total_vote_count_up
    : total_vote_count_up;
  const voteUp = data.explorer_vote_up === true;

  //console.log(adventureBlock, block);

  return (
    <MenuItem
      onClick={(e) => {
        onClick();
        if (voteUp) {
          onThumbDown(e, data);
        } else {
          onThumbUp(e, data);
        }
      }}
    >
      {voteUp ? <ThumbDown /> : <ThumbUp />}
      {voteUp ? 'Remove Like' : 'Like'}
    </MenuItem>
  );
}
