import { createTheme } from '@mui/material';
import { orange } from '@mui/material/colors';

const { palette } = createTheme();
const { augmentColor } = palette;

const createColor = (mainColor) => augmentColor({ color: { main: mainColor } });

const lightPalette = {
  primary: { main: '#DD603F' },
  secondary: orange,
  background: {
    default: '#ffffff', // White background
    primary: '#ffffff', // White background
    secondary: '#fcedd6',
    dark: '#273a07', // green background
    paper: '#faf7f1', // beige background for paper-like elements
  },
  text: {
    primary: '#273a07', // Dark Green text color
    secondary: '#526138', // Medium text color
    light: '#827700',
    disabled: '#999999', // Light text color for disabled elements
    contrast: '#ffffff', // Light text color for dark background
  },
  red: createColor('#e74c3c'),
  orange: createColor('#e67e22'),
  yellow: createColor('#f1c40f'),
  green: createColor('#2ecc71'),
  blue: createColor('#3498db'),
  indigo: createColor('#9b59b6'),
  gray: createColor('#7f8c8d'),
};

export default lightPalette;
