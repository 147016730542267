import { alpha, Box, ButtonBase, Drawer, styled } from '@mui/material';
import React from 'react';
import DrawerTopBar from '../../adventure/components/drawer-top-bar';
import useTripBuilderDrawerButton from '../hooks/use-trip-builder-drawer-button';
import { Padding } from '@mui/icons-material';
import useInvalidateAdventureData from 'features/adventure/hooks/use-invalidate-adventure-data';
import TripPlannerDrawer from './trip-planner-drawer';

interface TripPlannerDrawerButtonProps {
  children: React.ReactNode;
}

function TripPlannerDrawerButtonBase({
  children,
}: TripPlannerDrawerButtonProps) {
  const { invalidateQueries } = useInvalidateAdventureData();
  const { toggleDrawer, isOpen } = useTripBuilderDrawerButton();

  const handleClose = () => {
    toggleDrawer();
    invalidateQueries();
  };

  return (
    <>
      <ButtonBase onClick={toggleDrawer} sx={{ width: 1 }}>
        {children}
      </ButtonBase>
      <TripPlannerDrawer isOpen={isOpen} handleClose={handleClose} />
    </>
  );
}

export default TripPlannerDrawerButtonBase;
