import { useEffect, useRef } from 'react';
import { useId } from 'hooks/use-id';
import { useTheme } from '@mui/material';
import { update } from 'lodash';

type options = {
  sourceId?: string;
  active?: boolean;
};

const useDrawRoute = (
  mapRef,
  coordinates,
  options?: options = { active: true },
) => {
  const randomId = useId();
  const theme = useTheme();
  const isRouteDrawn = useRef(false);

  let { active, sourceId = undefined } = options;
  if (!sourceId) sourceId = randomId;

  useEffect(() => {
    console.debug('STATE UPDATE');
    if (!mapRef.current) {
      console.debug('NO MAP REF');
      return;
    }

    if (!Array.isArray(coordinates)) {
      console.warn('MALFORMED OR UNDEFINED COORDINATE ARRAY');
      return;
    }

    if (coordinates.length === 0) {
      console.debug('NO COORDINATES TO SHOW');
      return;
    }

    const map = mapRef.current;
    console.debug('PASSED PRE-FILIGHT CHECKS', active);

    const updateSource = () => {
      console.debug('DRAWING/UPDATING ROUTE SOURCE');
      if (map.getSource(sourceId)) {
        // Update the existing source
        map.getSource(sourceId).setData({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coordinates,
          },
        });
      } else {
        // Add the source and layer
        map.addSource(sourceId, {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: coordinates,
            },
          },
        });

        map.addLayer({
          id: sourceId,
          type: 'line',
          source: sourceId,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': theme.palette.primary.main,
            'line-width': 4,
          },
        });
        isRouteDrawn.current = true;
      }
    };

    onMapLoaded(mapRef, updateSource);
  }, [mapRef?.current, coordinates]);

  useEffect(() => {
    if (
      !mapRef.current ||
      !Array.isArray(coordinates) ||
      coordinates.length === 0
    )
      return;

    const map = mapRef.current;
    const layer = map.getLayer(sourceId);
    if (layer) {
      if (active) {
        map.setLayoutProperty(sourceId, 'visibility', 'visible');
      } else {
        map.setLayoutProperty(sourceId, 'visibility', 'none');
      }
    }
    if (!active) {
      return;
    }
  }, [active]);
};

const onMapLoaded = (mapRef, cb) => {
  if (mapRef.current.isStyleLoaded()) {
    console.debug('STYLE IS LOADED');
    cb();
  } else {
    console.debug('STYLE IS NOT LOADED');
    mapRef.current.on('load', cb);
  }
};
export default useDrawRoute;
