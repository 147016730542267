/* global MapboxGeocoder,  */
import { makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { Box, CircularProgress } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {
  useEffect,
  // useCallback,
  // createRef,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import {
  fetchRecGovCampgrounds,
  fetchRecGovCampsites,
} from '../../../util/api_util/linked_app_api_util';
import CampGroundCard from '../../common/campground-card';
import LocationCard from '../../common/location-card';
import OutlineButton from '../../common/outline-button';
import ScrollPagination from '../../common/scroll-pagination';
import SearchBox from '../../common/search-box';
import CampGround from '../../global_modal/hub_modal_pages/campground';
import CampSite from '../../global_modal/hub_modal_pages/campsite';
//import SearchSectionHeader from '../../common/search-section-header';
import mapboxgl from '!mapbox-gl';
import { svgBackButton } from 'components/svg_module';
import { merge } from 'lodash';
import {
  MAP_OPTION_ITINERARY_ONLY,
  //MAP_STYLE_SATELLITE,
  MAP_STYLE_TERRAIN,
} from '../../../constants';
import { upsertRecGovMetaData } from '../../../util/api_util/linked_app_api_util';
import HubButton from '../../hub-button';
import { Tools } from './components';
import DetailedPopup from './components/detailed-popup';
import { mapDispatchToProps, mapStateToProps } from './container';
import {
  DrawCampgrounds,
  DrawCampsites,
  useCustomMarkers,
  useDirectionsHooks,
  useGetCampsites,
  //useDrawControlHooks,
  //useCustomDrawModeHooks,
  //useClusterHooks,
  useGetFacilities,
  useMapHooks,
} from './custom_hooks';

import axios from 'axios';
import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';
import MapboxErrorAlert from './errors/MapboxErrorAlert';
import './index.scss';
import useDrawRoute from './custom_hooks/use-itinerary-route';
import useItineraryInfoQuery from 'features/itinerary/hooks/use-itinerary-info-query';
mapboxgl.accessToken =
  'pk.eyJ1IjoibG9kZXN0YXJodWJkZXYiLCJhIjoiY2tzYXd3aHNuMDE1ZDMwcDRjZmVrMDRrcyJ9.EccewqzpbeugVzO2ARAAiA';
// mapboxgl.workerClass = MapboxWorker;

const useStyles = makeStyles({
  root: {
    position: 'absolute !important',
    right: 'unset !important',
    '& .MuiModal-backdrop': {
      position: 'absolute !important',
      display: 'none',
    },
    '& .MuiDrawer-paper': {
      position: 'absolute !important',
      width: '250px',
    },
  },
  campgroundsRoot: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    height: '100%',
    boxSizing: 'border-box',
    padding: '5px',
    overflow: 'auto',
  },
  btnShowDrawer: {
    position: 'absolute',
    left: '40px',
    top: '10px',
    zIndex: 50,
    padding: '2px',
    minWidth: '0px',
    borderWidth: '0px',
    color: 'rgba(0, 0, 0, 0.54)',
    boxShadow: '0 0 0 2px rgba(0,0,0,.1)',
  },
  searchBox: {
    '& .MuiInputBase-root': {
      fontSize: '12px',
      paddingRight: '5px',
      borderRadius: '0px',
    },
    '& .MuiInputBase-input': {
      padding: '5px 0px 5px 10px',
      fontSize: '14px',
      color: '#827700',
    },
    '& .MuiSvgIcon-root': {
      fill: '#273A07',
      width: '18px',
      height: '18px',
    },
  },
  scrollPagination: {
    height: '100%',
    overflow: 'auto',
  },
  blockTypeIcon: {
    width: '15px',
    height: '15px',
    color: '#273A07',
    fill: '#273A07',
  },
  btnBack: {
    justifyContent: 'left !important',
    padding: '0 !important',
  },
  btnEdit: {
    fontSize: '10px',
    margin: 'inherit',
  },
  tabs: {
    minHeight: '48px !important',
    '& .MuiTabs-indicator': {
      backgroundColor: '#273A07 !important',
    },
  },
  tab: {
    fontSize: '12px !important',
    padding: '7px 15px !important',
    minWidth: '0px !important',
    minHeight: '48px !important',
    '&.Mui-selected': {
      color: '#273A07 !important',
    },
  },
});

// this module is designed so that you can control the size of the map by sizing the parent div tag or parent dom element of this module
const MapBoxMaps = ({
  //props passed down

  selectedAdventure, //used for both library and itinerary, passes adventure information
  selectedItinerary, // used for itinerary only, passes itinerary information
  usecase, // used to declare, either itinerary or library ** required
  libraryAdventureSelected,
  //states from container
  userInfo,
  libraryBlocks, // required for library & itinerary
  itineraryDetails, // required for itinerary
  adventureBlocks, // required for itinerary
  itineraryInfo, // required for itinerary
  locationBlocks, // required for library & itinerary
  tripBoardLocationBlocks,
  mapFilter,
  campsites,
  campgrounds,
  mapUiDetailShow,
  //displatches from container
  setLoading,
  openBlockModal,
  fetchLocationBlocks,
  fetchTripBoardLocationBlocks,
  upsertLocationBlock,
  upsertBlock,
  copyToTripBoard,
  displayTools, // boolean to check tools to display
  tools, // JSON for which tool to display {filters: true, }
  setMapUiDetailShow,

  fetchAdventureFriend, //
  fetchAdventureGroup, //

  //this is for keeping  the filters on the redux
  sendItineraryMapFilters,

  //for flushing location Blocks
  flushLocationBlocks,
  setActiveMapLayers,
  onBack,
  layers,
  ...props
}) => {
  const locationBlocksRef = useRef();
  const selectedAdventureRef = useRef(null);
  const filterRef = useRef();
  const mapStyleRef = useRef();
  const map = useRef(null);
  const mapContainer = useRef(null);
  //const mapClickFlag = useRef('');
  const showRouteRef = useRef(false);
  const itineraryInfoRef = useRef(null);
  const itineraryDetailsRef = useRef(null);
  const [mapStyle, setMapStyle] = useState(MAP_STYLE_TERRAIN);
  const [filters, setFilters] = useState(MAP_OPTION_ITINERARY_ONLY);
  const [showRoute, setShowRoute] = useState(usecase !== 'library');
  const { loading } = useGetFacilities();
  const { campsitesLoading } = useGetCampsites();
  const [loadingModal, setLoadingModal] = useState(false);
  const [campsite, setCampsite] = useState(true);
  const [campground, setCampground] = useState(true);
  const [firstLoad, setFirstLoad] = useState(false);

  let locationBlockSourceId;
  let locationBlockDirectionSourceId;

  if (usecase === 'itinerary') {
    locationBlockSourceId = `lodestar-location-blocks`;
    locationBlockDirectionSourceId = `lodestar-library-location-blocks-directions`;
  }

  if (usecase === `library`) {
    locationBlockSourceId = `lodestar-library-location-blocks`;
    locationBlockDirectionSourceId = `lodestar-library-location-blocks-directions`;
  }
  const {
    //drawDataAsPieChart,
    drawLocationPoints,
    updateSourceAndDraw,
  } = useCustomMarkers(filters, userInfo, mapStyle);

  const { data } = useItineraryInfoQuery({ refetchInterval: 3000 });

  if (usecase !== 'library') {
    useDrawRoute(map, data?.itinerary_route?.coordinates, {
      active: showRoute,
    });
  }

  const { renderMarkerOnClick } = useMapHooks(filters, userInfo, usecase);
  const styleObject = {
    MAP_STYLE_NAVIGATION:
      'mapbox://styles/lodestarhubdev/cktdekbuf19dg18pclo506154',
    MAP_STYLE_SATELLITE:
      'mapbox://styles/lodestarhubdev/cktdem0rq19fy18luf27zwigj',
    MAP_STYLE_TERRAIN:
      'mapbox://styles/lodestarhubdev/cksaxb7zxcgzj17lyzl0spl1a',
  };

  useEffect(() => {
    itineraryDetailsRef.current = itineraryDetails;
    itineraryInfoRef.current = itineraryInfo;
    selectedAdventureRef.current = selectedAdventure;
  }, [itineraryDetails, itineraryInfo, selectedAdventure]);

  // remove previous blocks when selected adventure changes
  useEffect(() => {
    flushLocationBlocks();
  }, [selectedAdventure]);

  useEffect(() => {
    if (usecase === `library`) {
      fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: null,
        itinerary_rid: null,
      });
    }
    if (usecase === `itinerary` && !locationBlocks) {
      fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: selectedItinerary.adventure_rid,
        itinerary_rid: selectedItinerary.itinerary_rid,
      });
    }
  }, []);

  useEffect(() => {
    if (map.current && mapStyleRef.current !== mapStyle) {
      mapStyleRef.current = mapStyle;
      const style = styleObject[mapStyle];
      map.current.setStyle(style);
    }
  }, [mapStyle]);

  useEffect(() => {
    if (
      !loading &&
      !campsitesLoading &&
      campsites.length > 0 &&
      campgrounds.length > 0 &&
      map.current
    ) {
      if (campground) {
        DrawCampgrounds(map, campgrounds, locationBlockSourceId);
        map.current.on('click', 'unclustered-points', (e) => {
          // Handle the click event for unclustered icons here
          const { properties } = e.features[0];
          setMapUiDetailShow({
            ...properties,
            location_type: 'campground',
            event: e,
          });
        });
      } else {
        map.current.getLayer('unclustered-points') &&
          map.current.removeLayer('unclustered-points');
        map.current.getLayer('cluster-0') &&
          map.current.removeLayer('cluster-0');
        map.current.getLayer('cluster-1') &&
          map.current.removeLayer('cluster-1');
        map.current.getLayer('cluster-2') &&
          map.current.removeLayer('cluster-2');
        map.current.getLayer('cluster-count') &&
          map.current.removeLayer('cluster-count');
        map.current.getSource('rec-campgrounds') &&
          map.current.removeSource('rec-campgrounds');
      }
      if (campsite) {
        DrawCampsites(map, campsites, locationBlockSourceId);
        // Add click event handler for unclustered icons
        map.current.on('click', 'campsites-unclustered-points', (e) => {
          // Handle the click event for unclustered icons here
          const { properties } = e.features[0];
          setMapUiDetailShow({
            ...properties,
            location_type: 'campsite',
            event: e,
          });
        });
      } else {
        map.current.getLayer('campsites-unclustered-points') &&
          map.current.removeLayer('campsites-unclustered-points');
        map.current.getLayer('campsites-cluster') &&
          map.current.removeLayer('campsites-cluster');
        map.current.getLayer('campsites-cluster-count') &&
          map.current.removeLayer('campsites-cluster-count');
        map.current.getSource('rec-campsites') &&
          map.current.removeSource('rec-campsites');
      }
      map.current.on('click', async (event) => {
        // Get the clicked point
        var clickedPoint = event.point;

        // Perform a query to get the rendered features at the clicked point
        var features = map.current.queryRenderedFeatures(clickedPoint);

        // Access the layers of the features
        var isRecGovIconClicked = features.some(function (feature) {
          return (
            feature.layer.id === 'unclustered-points' ||
            feature.layer.id === 'campsites-unclustered-points'
          );
        });

        if (!isRecGovIconClicked) onClickHandler(event);
      });
    }
  }, [
    loading,
    locationBlockSourceId,
    campsitesLoading,
    campsites,
    campgrounds,
    mapStyleRef.current,
    filters,
    usecase,
    campsite,
    campground,
    map.current,
  ]);

  useEffect(() => {
    if (map.current) {
      return;
    }
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: styleObject[mapStyle],
      center: [-93.6619069687375, 38.58038526633845],
      zoom: 4,
    });
    const geolocationControl = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      trackUserLocation: true,
      showUserHeading: true,
      showUserLocation: true,
    });

    const scaleControl = new mapboxgl.ScaleControl({ unit: 'imperial' });
    const coordinatesGeocoder = function (query) {
      const matches = query.match(
        /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i,
      );
      if (!matches) {
        return null;
      }

      function coordinateFeature(lng, lat) {
        return {
          center: [lng, lat],
          geometry: {
            type: 'Point',
            coordinates: [lng, lat],
          },
          place_name: 'Lat: ' + lat + ' Lng: ' + lng,
          place_type: ['coordinate'],
          properties: {},
          type: 'Feature',
        };
      }

      const coord1 = Number(matches[1]);
      const coord2 = Number(matches[2]);
      const geocodes = [];

      if (coord1 < -90 || coord1 > 90) {
        // must be lng, lat
        geocodes.push(coordinateFeature(coord1, coord2));
      }

      if (coord2 < -90 || coord2 > 90) {
        // must be lat, lng
        geocodes.push(coordinateFeature(coord2, coord1));
      }

      if (geocodes.length === 0) {
        // else could be either lng, lat or lat, lng
        geocodes.push(coordinateFeature(coord1, coord2));
        geocodes.push(coordinateFeature(coord2, coord1));
      }

      return geocodes;
    };
    const geoCoderControl = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      localGeocoder: coordinatesGeocoder,
      reverseGeocode: true,
    });

    if (props.interactive === true || props.interactive === undefined) {
      map.current.addControl(
        new mapboxgl.NavigationControl({
          showCompass: true,
          visualizePitch: true,
        }),
      );
      map.current.addControl(scaleControl);
      map.current.addControl(new mapboxgl.FullscreenControl());
      map.current.addControl(geolocationControl);
      // map.current.addControl(geoCoderControl, 'top-left');
    }

    return () => {
      //locationBlocksRef.current = null;
      flushLocationBlocks();
    };
  }, [selectedItinerary, userInfo, usecase]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success, error);
  }, []);

  const returnBool = (ref, locationBlocks) => {
    if (!ref && !locationBlocks) {
      return false;
    }
    if (!ref && locationBlocks) {
      return true;
    }
    if (Array.isArray(ref) && Array.isArray(locationBlocks)) {
      if (ref.length !== locationBlocks.length) {
        return true;
      }
      if (ref.length > 0 && locationBlocks.length > 0) {
        if (ref.length !== locationBlocks.length) {
          return true;
        }
        if (ref[0].location_type !== locationBlocks[0].location_type) {
          return true;
        }
        if (
          ref[0].location_type === locationBlocks[0].location_type &&
          ref.length === locationBlocks.length
        ) {
          for (let i = 0; i < ref.length; i++) {
            let oldBlock = ref[i];
            let newBlock = locationBlocks[i];
            if (
              parseFloat(oldBlock?.location_point?.lat) !==
              parseFloat(newBlock?.location_point?.lat)
            ) {
              return true;
            }
            if (
              parseFloat(oldBlock?.location_point?.lng) !==
              parseFloat(newBlock?.location_point?.lng)
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  };

  const [boundSet, setBoundSet] = useState(false);

  const resetMapBounds = () => {
    if (map.current && map.current.loaded()) {
      const locations =
        (filters === MAP_OPTION_ITINERARY_ONLY
          ? locationBlocks
          : tripBoardLocationBlocks) || [];
      if (locations.length === 0) return;
      const bounds = locations
        .map((a) => [
          Number(a.location_point.lng) || 0,
          Number(a.location_point.lat) || 0,
        ])
        .reduce(function (bounds, coord) {
          return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(
          [locations[0].location_point.lng, locations[0].location_point.lat],
          [locations[0].location_point.lng, locations[0].location_point.lat],
        ));
      map.current.fitBounds(bounds, {
        maxZoom: 10,
        padding: 50,
      });
    }
  };

  const setBoundsForAllMarkers = (map, coords) => {
    if (!map || !coords || coords.length === 0 || boundSet) return;
    setBoundSet(true);
    const bounds = coords
      .map((a) => [Number(a[0]) || 0, Number(a[1]) || 0])
      .reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(coords[0], coords[0]));

    map.fitBounds(bounds, {
      maxZoom: 10,
      padding: 50,
    });
  };

  useEffect(() => {
    if (map.current && map.current.loaded()) {
      const locations =
        (filters === MAP_OPTION_ITINERARY_ONLY
          ? locationBlocks
          : tripBoardLocationBlocks) || [];
      if (map.current && !firstLoad && locations.length > 0) {
        setBoundsForAllMarkers(
          map.current,
          locations.map((ele) => [
            ele.location_point.lng,
            ele.location_point.lat,
          ]),
        );
        setFirstLoad(true);
      }
    } else {
      map.current.on('load', (event) => {
        const locations =
          (filters === MAP_OPTION_ITINERARY_ONLY
            ? locationBlocks
            : tripBoardLocationBlocks) || [];
        if (map.current && !firstLoad && locations.length > 0) {
          setFirstLoad(true);
          setBoundsForAllMarkers(
            map.current,
            locations.map((ele) => [
              ele.location_point.lng,
              ele.location_point.lat,
            ]),
          );
        }
      });
    }
  }, [locationBlocks, tripBoardLocationBlocks, map, filters, firstLoad]);

  useEffect(() => {
    const locations =
      (filters === MAP_OPTION_ITINERARY_ONLY
        ? locationBlocks
        : tripBoardLocationBlocks) || [];
    if (returnBool(locationBlocksRef.current, locations)) {
      mapStyleRef.current = mapStyle;
      locationBlocksRef.current = locations;
      if (usecase === 'itinerary') filterRef.current = filters;
      const loadMapWithLocationBlocks = async (flag) => {
        const blockLocationType = locationBlocksRef?.current
          ? locationBlocksRef?.current[0]?.location_type
          : null;
        if (!flag) {
          const locations =
            (filters === MAP_OPTION_ITINERARY_ONLY
              ? locationBlocks
              : tripBoardLocationBlocks) || [];
          const { updateLocationPointMarkers } = drawLocationPoints(
            map.current,
            mapboxgl,
            locationBlockSourceId,
            locations,
            userInfo,
            usecase,
            mapStyle,
          );
          //this is when all routes are clicked
          if (showRoute) {
            // drawDirections(
            //   map.current,
            //   mapboxgl,
            //   locationBlockDirectionSourceId,
            //   locationBlocksRef.current,
            //   userInfo,
            //   usecase,
            // );
          }

          //this is when my routes are clicked
          if (showRoute && blockLocationType === 'tripRoutes') {
            // drawMyRoutesDirections(
            //   map.current,
            //   mapboxgl,
            //   locationBlockDirectionSourceId,
            //   locationBlocksRef.current,
            //   userInfo,
            //   usecase,
            // );
          }
          // this is when the map listens to source data changes
          const listenerFunc = (e) => {
            let isSource = map.current.getSource(locationBlockSourceId);
            if (isSource) {
              if (!map.current.isSourceLoaded(locationBlockSourceId)) return;
              updateLocationPointMarkers();
            }
          };
          map.current.on('render', (e) => {
            listenerFunc(e);
          });
        } else {
          const locations =
            (filters === MAP_OPTION_ITINERARY_ONLY
              ? locationBlocks
              : tripBoardLocationBlocks) || [];

          const { updateUpdatedLocationPointMarkers } = updateSourceAndDraw(
            map.current,
            mapboxgl,
            locationBlockSourceId,
            locations,
            userInfo,
            usecase,
          );

          if (showRoute) {
            // drawDirections(
            //   map.current,
            //   mapboxgl,
            //   locationBlockDirectionSourceId,
            //   locationBlocksRef.current,
            //   userInfo,
            //   usecase,
            // );
          }
          if (showRoute && blockLocationType === 'tripRoutes') {
            // drawMyRoutesDirections(
            //   map.current,
            //   mapboxgl,
            //   locationBlockDirectionSourceId,
            //   locationBlocksRef.current,
            //   userInfo,
            //   usecase,
            // );
          }
        }
      };
      let source = map.current.getSource(locationBlockSourceId);
      if (
        source &&
        (locationBlocks?.length === 0 ||
          locationBlocks === undefined ||
          locationBlocks === null)
      ) {
        // when switching from all pois back to other itinerary related location blocks while itinerary related location blocks are 0
        loadMapWithLocationBlocks(true);
        return;
      }
      if (locationBlocks && locationBlocks.length > 0) {
        if (!source) {
          let loaded = map.current.loaded();

          if (loaded && locationBlocks.length >= 1) {
            loadMapWithLocationBlocks(false);
            return;
          }

          map.current.on('load', (event) => {
            loadMapWithLocationBlocks(false);
          });
          map.current.on('style.load', (event) => {
            loadMapWithLocationBlocks(false);
          });
        } else {
          loadMapWithLocationBlocks(true);
        }
        return;
      }
    }
    if (showRouteRef.current !== showRoute) {
      showRouteRef.current = showRoute;
      if (!showRoute) {
        // removeDirections(map.current, null);
        // removeDirections(map.current, locationBlockDirectionSourceId);
      } else {
        const blockLocationType = locationBlocksRef?.current
          ? locationBlocksRef?.current[0]?.location_type
          : null;
        if (blockLocationType === 'tripRoutes') {
          // drawMyRoutesDirections(
          //   map.current,
          //   mapboxgl,
          //   locationBlockDirectionSourceId,
          //   locationBlocksRef.current,
          //   userInfo,
          //   usecase,
          // );
        } else {
          // drawDirections(
          //   map.current,
          //   mapboxgl,
          //   locationBlockDirectionSourceId,
          //   locationBlocksRef.current,
          //   userInfo,
          //   usecase,
          // );
        }
      }
    }
  }, [
    locationBlocksRef,
    locationBlocks,
    tripBoardLocationBlocks,
    mapStyle,
    filters,
    showRoute,
    selectedItinerary,
    userInfo,
    usecase,
  ]);

  //this is for when the users location service is not enabled
  //*error
  const error = (error) => {
    const listener = async (e) => {
      const { sourceId, isSourceLoaded } = e;
      if (sourceId === locationBlockSourceId && isSourceLoaded) {
        map.current.off('sourcedata', listener);
        map.current.flyTo({
          center: [
            locationBlocks[0].location_point.lng,
            locationBlocks[0].location_point.lat,
          ],
          essential: true,
          zoom: 4, // this animation is considered essential with respect to prefers-reduced-motion
        });

        setLoading(false);
      }
    };
    //using source data here because the fly to has to happen after the markers and layers and rendered

    if (selectedItinerary) {
      let query = {
        account_rid: userInfo.account_rid,
        adventure_rid: selectedItinerary.adventure_rid,
        itinerary_rid: selectedItinerary.itinerary_rid,
        direction_flag: true,
      };
      axios.patch(`/api/it/itinerary/location_blocks`, query).then((res) => {
        if (res.status === 200) {
          let focusBlock = res.data.locationBlocks.filter(
            (ele) => ele.map_focus_flag,
          );
          if (focusBlock.length > 0) {
            map.current.flyTo({
              center: [
                focusBlock[0].location_point.lng,
                focusBlock[0].location_point.lat,
              ],
              essential: true,
              zoom: 6, // this animation is considered essential with respect to prefers-reduced-motion
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(false);
    }
  };
  //*success
  const success = (position) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };

    const listener = async (e) => {
      const { sourceId, isSourceLoaded } = e;
      if (sourceId === locationBlockSourceId && isSourceLoaded) {
        map.current.off('sourcedata', listener);
        map.current.flyTo({
          center: [
            locationBlocks && locationBlocks.length > 0
              ? locationBlocks[0].location_point.lng
              : position.coords.longitude,
            locationBlocks && locationBlocks.length > 0
              ? locationBlocks[0].location_point.lat
              : position.coords.latitude,
          ],
          essential: true,
          zoom: 4, // this animation is considered essential with respect to prefers-reduced-motion
        });
      }
    };
    if (selectedItinerary) {
      let query = {
        account_rid: userInfo.account_rid,
        adventure_rid: selectedItinerary.adventure_rid,
        itinerary_rid: selectedItinerary.itinerary_rid,
        direction_flag: true,
      };
      axios.patch(`/api/it/itinerary/location_blocks`, query).then((res) => {
        if (res.status === 200) {
          let focusBlock = res.data.locationBlocks.filter(
            (ele) => ele.map_focus_flag,
          );
          if (focusBlock.length > 0) {
            map.current.flyTo({
              center: [
                focusBlock[0].location_point.lng,
                focusBlock[0].location_point.lat,
              ],
              essential: true,
              zoom: 10, // this animation is considered essential with respect to prefers-reduced-motion
            });
            setLoading(false);
          } else {
            map.current.flyTo({
              center: [position.coords.longitude, position.coords.latitude],
              essential: true,
              zoom: 10, // this animation is considered essential with respect to prefers-reduced-motion
            });
            setLoading(false);
          }
        }
      });
    } else {
      setLoading(false);
    }
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setLocationPoints([]);
    setIsDrawerOpen(false);
  };

  // const handleBoundaryChange = () => {
  //   useDrawCampgroundsOnMaps(map, facilities);
  // };

  const createActivity = (event) => {
    const { newMarker } = renderMarkerOnClick(
      event,
      mapboxgl,
      usecase,
      map.current,
    );
    if (usecase === 'library') {
      let openType = { isNew: true, isLibraryBlock: true };
      let location_point = newMarker.getLngLat();
      let blockPayload = {
        location_point: location_point,
        location_flag: true,
      };
      blockPayload['adventureRid'] = libraryAdventureSelected;
      let uiUpdatePayload = () => callBackAfterInsertingBlock();
      openBlockModal(openType, '3a', uiUpdatePayload, blockPayload, userInfo);
    } else if (usecase === 'itinerary') {
      let openType = { isNew: true };
      let location_point = newMarker.getLngLat();
      let blockPayload = {
        location_point: location_point,
        location_flag: true,
        itineraryDetails: itineraryDetailsRef.current,
        adventure_rid: itineraryInfoRef.current?.adventure_rid,
      };
      let uiUpdatePayload = {
        uiUpdateFunction: () => callBackAfterInsertingBlockForTrip(),
        itineraryInfo: itineraryInfoRef.current,
        explorer_rid: userInfo.explorer_rid,
      };
      blockPayload['adventureRid'] = itineraryInfoRef.current?.adventure_rid;
      openBlockModal(openType, '4a', uiUpdatePayload, blockPayload, userInfo);
    }
    newMarker.remove();
  };

  const onClickHandler = (event) => {
    setLoadingModal(true);
    setTimeout(() => setLoadingModal(false), 1000);
    setTimeout(() => createActivity(event), 0);
  };

  const callBackAfterInsertingBlock = async (newBlock) => {
    await fetchLocationBlocks({
      account_rid: userInfo.account_rid,
      adventure_rid: null,
      itinerary_rid: null,
    });
    if (newBlock) {
      upsertRecGovMetaData({
        block_outside_data_rid: null,
        block_rid: newBlock?.block_rid,
        facility_id: mapUiDetailShow?.facility_id || null,
        campsite_id: mapUiDetailShow?.campsite_id || null,
      });
    }
  };

  const callBackAfterInsertingBlockAndAddTrip = async (newBlock) => {
    await fetchLocationBlocks({
      account_rid: userInfo.account_rid,
      adventure_rid: null,
      itinerary_rid: null,
    });
    importBlockToTrip(newBlock);
    upsertRecGovMetaData({
      block_outside_data_rid: null,
      block_rid: newBlock?.block_rid,
      facility_id: mapUiDetailShow?.facility_id || null,
      campsite_id: mapUiDetailShow?.campsite_id || null,
    });
  };

  const callBackAfterInsertingBlockForTrip = async (newBlock) => {
    let returnedData;
    if (mapFilter === 'itineraryLocationBlocks') {
      returnedData = await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfoRef.current.adventure_rid,
        itinerary_rid: itineraryInfoRef.current.itinerary_rid,
      });
    } else if (mapFilter === 'allTripBlocks') {
      returnedData = await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfoRef.current.adventure_rid,
        itinerary_rid: null,
      });
    } else {
      returnedData = await fetchLocationBlocks({
        account_rid: userInfo.account_rid,
        adventure_rid: itineraryInfoRef.current.adventure_rid,
        itinerary_rid: itineraryInfoRef.current.itinerary_rid,
        direction_flag: true,
      });
    }
    if (newBlock) {
      importBlockToTrip(newBlock);
      upsertRecGovMetaData({
        block_outside_data_rid: null,
        block_rid: newBlock?.block_rid,
        facility_id: mapUiDetailShow?.facility_id || null,
        campsite_id: mapUiDetailShow?.campsite_id || null,
      });
    }
  };
  const loadCampgrounds = async (pageNumber) => {
    const res = await fetchRecGovCampgrounds({
      search: keyWord,
      facility_type_description: null,
      search_point_lat: null,
      search_point_lng: null,
      radius_mile: null,
      page_number: pageNumber,
      page_size: 12,
    });

    const campgrounds = res.data.campgrounds?.map((v) => {
      const { facility, campsite_count } = v;
      const {
        name,
        description,
        media,
        url,
        facility_id,
        reservation_url,
        lat,
        lng,
      } = facility;
      const sites = `${campsite_count} site${campsite_count !== 1 ? 's' : ''}`;

      return {
        title: name,
        description,
        images: media.map((item) => item['URL']),
        url,
        sites,
        facilityId: facility_id,
        reservationURL: reservation_url,
        location: { lat, lng },
      };
    });
    return campgrounds;
  };

  const [initialSearchData, setInitialSearchData] = useState([]);
  const [locationPoints, setLocationPoints] = useState([]);

  const [keyWord, setKeyWord] = useState(null);

  const searchLocation = async (query) => {
    try {
      const apiKey =
        'pk.eyJ1IjoibG9kZXN0YXJodWJkZXYiLCJhIjoiY2tzYXd3aHNuMDE1ZDMwcDRjZmVrMDRrcyJ9.EccewqzpbeugVzO2ARAAiA'; // Replace with your Mapbox API key

      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${apiKey}`,
      );

      const data = await response.json();
      setLocationPoints(
        data.features.map((v) => ({
          title: v.text,
          description: v.place_name,
          location: { lng: v.center[0], lat: v.center[1] },
        })),
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const onSearch = (value) => {
    if (value === '') setKeyWord(null);
    else setKeyWord(value);
    searchLocation(value);
    setInitialSearchData([]);
  };

  const [showCampgroundDetail, setShowCampgroundDetail] = useState(false);
  const [campgroundId, setCampgroundId] = useState(0);

  const viewCampgroundDetail = (id) => {
    setShowCampgroundDetail(true);
    setCampgroundId(id);
  };

  const onCampgroundClose = () => {
    setShowCampgroundDetail(false);
    setCampgroundId(0);
  };

  const [showCampsiteDetail, setShowCampsiteDetail] = useState(false);
  const [campsiteId, setCampsiteId] = useState(0);

  const viewCampsiteDetail = (campgroundId, campsiteId) => {
    setShowCampsiteDetail(true);
    setCampgroundId(campgroundId);
    setCampsiteId(campsiteId);
  };

  const onCampsiteClose = () => {
    setShowCampsiteDetail(false);
    setCampsiteId(0);
  };

  const hasLocation = (location) => {
    if (!location) return false;
    const { lat, lng } = location;
    if (!lat && !lng) return false;

    return true;
  };

  const onGotoMap = (location) => {
    // set zoom to 10, or whatever the largest value is to not zoom out when already zoomed in
    const zoom = Math.max(10, map?.current?.getZoom());
    if (!hasLocation(location)) return;
    map?.current?.flyTo({
      center: [location?.lng, location?.lat],
      zoom: zoom,
      speed: 2,
    });
  };

  const addToLibrary = (blockPayload) => {
    let openType = { isNew: true, isLibraryBlock: true };
    let activityModalTypeRid = '20a';
    let uiUpdatePayload = {
      uiUpdateFunction: (block) => callBackAfterInsertingBlock(block),
      account_rid: userInfo.account_rid,
      explorer_rid: userInfo.explorer_rid,
    };
    openBlockModal(
      openType,
      activityModalTypeRid,
      uiUpdatePayload,
      blockPayload,
      userInfo,
    );
  };

  const addToTrip = (blockPayload) => {
    let openType = { isNew: true, isLibraryBlock: true };
    let activityModalTypeRid = '20a';
    let uiUpdatePayload = {
      uiUpdateFunction: (block) => callBackAfterInsertingBlockAndAddTrip(block),
      account_rid: userInfo.account_rid,
      adventure_rid: null,
      adventure_rid_exist_flag: itineraryInfoRef.current?.adventure_rid
        ? itineraryInfoRef.current?.adventure_rid
        : null,
      inspiration_rid: null,
      itinerary_item_rid: null,
      parent_block_rid: null,
      explorer_rid: userInfo.explorer_rid,
    };
    openBlockModal(
      openType,
      activityModalTypeRid,
      uiUpdatePayload,
      blockPayload,
      userInfo,
    );
  };

  const importBlockToTrip = (block) => {
    let passOver = merge({}, block);
    passOver.parent_block_rid = null;
    passOver.adventure_rid = selectedAdventure?.adventure_rid;
    let returnType = {
      isLibraryBlock: true,
      isNestedLibraryBlock: false,
      isLibraryListOnly: false,
    };
    let _actionPayload = {
      adventureRid: selectedAdventure?.adventure_rid,
      parentBlockRid: null,
    };
    copyToTripBoard(userInfo, passOver, returnType, _actionPayload);
  };

  const addToItinerary = (blockPayload) => {
    const openType = { isNew: true };
    const activityModalTypeRid = '19a';
    let uiUpdatePayload = {
      uiUpdateFunction: (block) => callBackAfterInsertingBlockForTrip(block),
      itineraryInfo: itineraryInfoRef.current,
      explorer_rid: userInfo.explorer_rid,
    };
    openBlockModal(
      openType,
      activityModalTypeRid,
      uiUpdatePayload,
      blockPayload,
      userInfo,
    );
  };

  const onAddCampground = async (campgroundId) => {
    setLoadingModal(true);
    setTimeout(() => setLoadingModal(false), 1000);
    setTimeout(async () => {
      const res = await fetchRecGovCampgrounds({
        facility_id: campgroundId,
        page_number: 1,
        page_size: 12,
      });
      const campgrounds = res.data.campgrounds;
      if (campgrounds.length === 0) return;
      const campground = campgrounds[0];
      const { facility, org_name } = campground;
      const { name, description, lat, lng } = facility;
      const blockPayload = {
        block_name: `${name}`,
        block_description: description,
        block_category_rid: 12,
        location_point: { lat, lng },
        location_flag: true,
        adventure_rid: itineraryInfoRef.current?.adventure_rid,
      };
      if (btnAddTitle === 'Add to itinerary') {
        addToItinerary(blockPayload);
      } else if (btnAddTitle === 'Add to library') {
        addToLibrary(blockPayload);
      } else {
        addToTrip(blockPayload);
      }
    }, 0);
  };

  const onAddCampsite = async (campgroundId, campsiteId) => {
    setLoadingModal(true);
    setTimeout(() => setLoadingModal(false), 1000);
    setTimeout(async () => {
      const res1 = await fetchRecGovCampgrounds({
        facility_id: campgroundId,
        page_number: 1,
        page_size: 12,
      });
      const campgrounds = res1.data.campgrounds;
      if (campgrounds.length === 0) return;

      const campground = campgrounds[0];
      const { facility } = campground;
      const { name } = facility;

      const res2 = await fetchRecGovCampsites({
        facility_id: campgroundId,
        campsite_id: campsiteId,
        page_number: 1,
        page_size: 1000,
      });
      const campsites = res2.data.campsites;
      if (campsites.length === 0) return;

      const campsite = campsites[0];
      const { campsite_name, facility_loop, campsite_coordinates } = campsite;
      const blockPayload = {
        block_name: `${name} - ${campsite_name}`,
        block_description: facility_loop,
        block_category_rid: 12,
        location_point: {
          lat:
            (campsite_coordinates?.lat || 0) === 0
              ? facility.lat
              : campsite_coordinates?.lat,
          lng:
            (campsite_coordinates?.lng || 0) === 0
              ? facility.lng
              : campsite_coordinates?.lng,
        },
        location_flag: true,
        adventure_rid: itineraryInfoRef.current?.adventure_rid,
      };
      if (btnAddTitle === 'Add to itinerary') {
        addToItinerary(blockPayload);
      } else if (btnAddTitle === 'Add to library') {
        addToLibrary(blockPayload);
      } else {
        addToTrip(blockPayload);
      }
    }, 0);
  };

  const [tab, setTab] = useState(0);

  const changeTab = (event, newValue) => {
    setTab(newValue);
  };

  const classes = useStyles();
  const btnAddTitle =
    usecase === 'itinerary'
      ? 'Add to itinerary'
      : selectedAdventure
      ? 'Add to trip'
      : 'Add to library';
  return (
    <>
      {/* {directionsHookErrors.directionsError && (
        <MapboxErrorAlert error_code={directionsHookErrors.directionsError} />
      )} */}
      <div
        className={[
          'map-box-module-wrapper',
          !tools.backButton && 'no-backbutton',
        ].join(' ')}
      >
        {loadingModal && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 100000,
              backgroundColor: 'rgba(255,255,255, 0.6)',
            }}
          >
            <CircularProgress sx={{ color: '#273a07' }} size={40} />
          </Box>
        )}

        {(loading || campsitesLoading) && (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 999999,
              backgroundColor: 'rgba(255,255,255, 0.6)',
            }}
          >
            <CircularProgress sx={{ color: '#273a07' }} size={40} />
          </Box>
        )}
        <DetailedPopup
          onGotoMap={onGotoMap}
          usecase={usecase}
          onViewCampsite={viewCampsiteDetail}
          onAddCampground={onAddCampground}
          onAddCampsite={onAddCampsite}
          selectedAdventure={selectedAdventure}
          callBackAfterInsertingBlockForTrip={
            callBackAfterInsertingBlockForTrip
          }
          callBackAfterInsertingBlock={callBackAfterInsertingBlock}
        />
        <OutlineButton
          className={classes.btnShowDrawer}
          icon={<SearchIcon />}
          onClick={handleDrawerOpen}
        />
        <Drawer
          className={classes.root}
          open={isDrawerOpen}
          container={document.getElementById('campground-list-container')}
        >
          <Box className={classes.campgroundsRoot}>
            <div>
              <IconButton
                className={classes.btnBack}
                onClick={handleDrawerClose}
              >
                <ArrowBackIcon
                  className={classes.blockTypeIcon}
                  fontSize="small"
                  color="#273A07"
                />
              </IconButton>
            </div>
            <SearchBox
              className={classes.searchBox}
              placeholder="Search"
              onChange={onSearch}
            />
            <Tabs className={classes.tabs} value={tab} onChange={changeTab}>
              <Tab className={classes.tab} label="POI" />
              {campground && (
                <Tab className={classes.tab} label="Campgrounds" />
              )}
            </Tabs>
            {tab == 0 && (
              <Box>
                {locationPoints.map((v) => (
                  <LocationCard {...v} onGotoMap={onGotoMap} />
                ))}
              </Box>
            )}
            {tab == 1 && (
              <ScrollPagination
                initialData={initialSearchData}
                className={classes.scrollPagination}
                loadData={loadCampgrounds}
                itemRenderer={(item, index) => (
                  <CampGroundCard
                    onViewDetail={(id) => {
                      onGotoMap(item?.location);
                      viewCampgroundDetail(id);
                    }}
                    uniqueKey={`campgroundList-${index}`}
                    {...item}
                  />
                )}
              ></ScrollPagination>
            )}
          </Box>
        </Drawer>
        <Drawer
          className={classes.root}
          open={showCampgroundDetail}
          container={document.getElementById('campground-container')}
        >
          <CampGround
            id={campgroundId}
            usecase={usecase}
            onClose={onCampgroundClose}
            onViewCampsite={viewCampsiteDetail}
            onGotoMap={onGotoMap}
            addButton={
              <HubButton
                className={classes.btnEdit}
                onClick={() => onAddCampground(campgroundId)}
                title={btnAddTitle}
                mode="red"
              />
            }
          />
        </Drawer>
        <Drawer
          className={classes.root}
          open={showCampsiteDetail}
          container={document.getElementById('campsite-container')}
        >
          <CampSite
            id={campsiteId}
            campgroundId={campgroundId}
            usecase={usecase}
            onClose={onCampsiteClose}
            onGotoMap={onGotoMap}
            addButton={
              <HubButton
                className={classes.btnEdit}
                onClick={() => onAddCampsite(campgroundId, campsiteId)}
                title={btnAddTitle}
                mode="red"
              />
            }
          />
        </Drawer>
        <div id="block-detail-container"></div>
        <div id="campground-list-container"></div>
        <div id="campground-container"></div>
        <div id="campsite-container"></div>
        {tools.backButton && (
          <div
            onClick={() => {
              setLocationPoints([]);
              onBack();
            }}
            style={{
              position: 'absolute',
              left: '10px',
              top: '13px',
              zIndex: 1000,
            }}
          >
            {svgBackButton('', 'svg-back-button')}
          </div>
        )}
        {displayTools && (
          <Tools
            tools={tools} // gets passed as the object from the parent component that uses this module.
            fetchLocationBlocks={fetchLocationBlocks}
            fetchTripBoardLocationBlocks={fetchTripBoardLocationBlocks}
            selectedItinerary={selectedItinerary}
            userInfo={userInfo}
            map={map.current}
            filters={filters}
            setFilters={setFilters}
            showRoute={showRoute}
            setShowRoute={setShowRoute}
            sendItineraryMapFilters={sendItineraryMapFilters}
            mapStyle={mapStyle}
            setMapStyle={setMapStyle}
            campGround={campground}
            setCampGround={setCampground}
            campSite={campsite}
            setCampSite={setCampsite}
            usecase={usecase}
            onBack={() => {
              setLocationPoints([]);
              onBack();
            }}
            onGoMyTrip={() => {
              // console.log('test point 1', firstLoad);
              // setFirstLoad(false);
              // setBoundSet(false);
              resetMapBounds();
            }}
            {...props}
          />
        )}
        <div
          ref={mapContainer}
          onScroll={(e) => e.stopPropagation()}
          onWheel={(e) => e.stopPropagation()}
          className="map-container"
        />
      </div>
    </>
  );
};

MapBoxMaps.defaultProps = {
  interactive: true,
};

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(MapBoxMaps),
);
