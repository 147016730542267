import { makeStyles } from '@material-ui/core';
import { Button } from '@mui/material';

const useStyles = makeStyles({
  root: {
    background: 'white',
    borderRadius: '5px',
    color: '#273A07',
    padding: '5px 20px',
    zIndex: 300,
  },
});

const OutlineButton = ({ title, icon, className, ...props }) => {
  const classes = useStyles();
  return (
    <Button
      className={[classes.root, className].join(' ')}
      size="small"
      {...props}
    >
      {icon}
      {title}
    </Button>
  );
};

export default OutlineButton;
