import React from 'react';
import {
  Typography,
  Box,
} from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
    width: '100%',
    boxSizing: 'border-box',
    border: '1px solid #E9EBEB',
    cursor: 'pointer',
  },
  information: {
    display: 'flex',
    background: 'white',
    flexGrow: 1,
  },
  box: {
    padding: '10px',
  },
  title: {
    fontWeight: 700,
    fontSize: '14px !important',
    lineHeight: '20px !important',
    color: '#273A07 !important',
  },
  description: {
    fontWeight: 300,
    fontSize: '12px !important',
    lineHeight: '15px !important',
    color: '#827700 !important',
  },
});

const LocationCard = ({ title, description, location, onGotoMap }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container} onClick={() => onGotoMap(location)}>
      <Box className={classes.information}>
        <Box className={classes.box}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.description}>{description}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

LocationCard.defaultProps = {
  onGotoMap: () => { },
}

export default LocationCard;