import { Box } from '@mui/material';
import TanBar from 'components/common/tan-bar';
import TanBarItem from 'components/common/tan-bar/tan-bar-item';
import SectionHeader from 'components/section/header';
import useAdventureDonations from 'features/adventure/hooks/use-adventure-donations';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';

import {
  GreenEarthIcon,
  HandShakeIcon,
} from 'pages/pwa_hub/adventure/components/footprint/svgs';

function AdventureDonationsList() {
  const { adventure_rid } = useCurrentAdventure();

  const {
    data: tripDonations,
    isLoading,
    isError,
    error,
  } = useAdventureDonations(adventure_rid);

  if (isLoading) return <></>;
  if (isError) {
    console.error('donation-list error:', error);
    return <></>;
  }

  if (!isLoading && !tripDonations?.length) return <></>;

  return (
    <Box py={2}>
      <SectionHeader>Donations</SectionHeader>
      {tripDonations.map((donation, index) => (
        <Box style={{ margin: '12px', width: '100%' }} key={index}>
          <TanBar
            headerContent={donation.donation_amount}
            bodyContent={
              <TanBarItem
                icon={
                  donation.partner_donation_type_rid === 1
                    ? HandShakeIcon
                    : GreenEarthIcon
                }
                title={donation.explorer_name}
                content={`${donation.donation_date} - ${donation.partner_name}`}
              />
            }
            colorMode="dark"
          />
        </Box>
      ))}
    </Box>
  );
}

export default AdventureDonationsList;
