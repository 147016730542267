import { openBlockModal, setLoading } from 'actions/modal_actions';
import {
  fetchLocationBlocks,
  fetchTripBoardLocationBlocks,
  sendItineraryMapFilters,
} from 'actions/itinerary_actions';
import {
  upsertLocationBlock,
  upsertBlock,
  flushLocationBlocks,
  copyToTripBoard,
} from 'actions/block_actions';
import {
  fetchAdventureFriend,
  fetchAdventureGroup,
} from 'actions/social_actions';

import { setMapUiPopup, setActiveMapLayers, setMapUiDetailShow } from '../../../actions/ui_actions';
export const mapStateToProps = (state) => {
  let userInfo = state.session.user ? state.session.user.userInfo[0] : null;
  let libraryBlocks = state.entities.blocks.library_blocks
    ? state.entities.blocks.library_blocks
    : null;
  let itineraryDetails = state.entities.itinerary.itineraryDetails
    ? state.entities.itinerary.itineraryDetails
    : null;
  let adventureBlocks = state.entities.itinerary.adventure_blocks
    ? state.entities.itinerary.adventure_blocks
    : null;
  let itineraryInfo = state.entities.itinerary.itinerary_info
    ? state.entities.itinerary.itinerary_info
    : null;
  let locationBlocks =
    state.entities.itinerary.locationBlocks &&
    state.entities.itinerary.locationBlocks;
  const tripBoardLocationBlocks = state.entities.itinerary?.tripBoardLocationBlocks || [];
  let user_adventure_friend = state.entities.social.adventureFriends
    ? state.entities.social.adventureFriends
    : null;
  let libraryAdventureSelected = state.entities.blocks?.libraryAdventureSelected
    ? state.entities.blocks?.libraryAdventureSelected
    : null;
  let user_adventure_friend_rid =
    user_adventure_friend && user_adventure_friend.length > 0
      ? user_adventure_friend.filter(
          (ele) => ele.explorer_rid === userInfo.explorer_rid,
        )[0]?.adventure_friend_rid
      : null;
  let popupState = state.ui?.map?.displayMapPopups;
  let mapFilter = state.entities.itinerary?.mapFilter
  ? state.entities.itinerary?.mapFilter
  : null;
  let campsites = state.dataCache?.recGov?.recGovCampSites?.data || [];
  let campgrounds = state.dataCache?.recGov?.recGovCampGrounds?.data || [];
  let layers = state.ui?.map?.activeMapLayers || null;
  let mapUiDetailShow = state?.ui?.map?.displayMapDetailPopups || null;
  return {
    userInfo,
    libraryBlocks,
    itineraryDetails,
    adventureBlocks,
    itineraryInfo,
    locationBlocks,
    tripBoardLocationBlocks,
    user_adventure_friend,
    user_adventure_friend_rid,
    popupState,
    libraryAdventureSelected,
    mapFilter,
    campsites,
    campgrounds,
    layers,
    mapUiDetailShow
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    openBlockModal: async (openType, blockType, actionPayload, data, userInfo) =>
      dispatch(
        openBlockModal(openType, blockType, actionPayload, data, userInfo),
      ),
    fetchLocationBlocks: (data) => dispatch(fetchLocationBlocks(data)),
    fetchTripBoardLocationBlocks: (data) => dispatch(fetchTripBoardLocationBlocks(data)),
    upsertLocationBlock: (data, userInfo, returnType, returnActionPayload) =>
      dispatch(
        upsertLocationBlock(data, userInfo, returnType, returnActionPayload),
      ),
    setLoading: (boolean) => dispatch(setLoading(boolean)),
    upsertBlock: (userInfo, data, returnType, updateActionPayload) =>
      dispatch(upsertBlock(userInfo, data, returnType, updateActionPayload)),
    fetchAdventureFriend: (adventure_rid) =>
      dispatch(fetchAdventureFriend(adventure_rid)),
    fetchAdventureGroup: (adventure_rid) =>
      dispatch(fetchAdventureGroup(adventure_rid)),
    sendItineraryMapFilters: (data) => dispatch(sendItineraryMapFilters(data)),
    flushLocationBlocks: () => dispatch(flushLocationBlocks()),
    setMapUiPopup: (data) => dispatch(setMapUiPopup(data)),
    setActiveMapLayers: (data) => dispatch(setActiveMapLayers(data)),
    setMapUiDetailShow: (data) => dispatch(setMapUiDetailShow(data)),
    copyToTripBoard: (data, userInfo, origin, returnActionPayload) =>
      dispatch(copyToTripBoard(data, userInfo, origin, returnActionPayload)),
  };
};
