import React from 'react';
import MiniBlocks from '../blocks';
import { Draggable } from 'react-beautiful-dnd';
import { merge } from 'lodash';
const Item = ({
  block,
  blockIndex,
  adventure_rid,
  handleLevelTraverse,
  displayDetails,
  currAdvDis,
  itinerary_item,
  itinerary_info,
  drilledDownItineraries,
  setDrilledDownItineraries,
  handleTraverseUp,
  type,
}) => {
  function getStyle(style, snapshot) {
    if (snapshot.isDragging) {
      let updatedStyle = {
        ...style,
        zIndex: 10000,
      };
      return updatedStyle;
    }
  }

  //console.log(block, type);

  return type === 'itinerary-item-block' ? (
    <Draggable
      key={`${itinerary_item.itinerary_item_rid}-${block.itineriary_block_rid}-${block.block_rid}`}
      draggableId={`${itinerary_item.itinerary_item_rid}-${
        block.itineriary_block_rid
      }-${block.block_rid}${block.folder_flag ? '-isFolder' : ''}${
        block.folder_flag && block.list_flag ? '-isFolderList' : ''
      }`}
      index={blockIndex}
      isCombineEnabled={block.folder_flag}
      type="blocks"
    >
      {(provided4, snapshot) => {
        //this block is for hard patch on giving itinerary_item_rid
        let modifiedBlock = merge({}, block);
        modifiedBlock['itinerary_item_rid'] = itinerary_item.itinerary_item_rid;
        return (
          <div
            ref={provided4.innerRef}
            className="itinerary-adventure-block-children"
            {...provided4.draggableProps}
            {...provided4.dragHandleProps}
          >
            <div>{blockIndex}</div>
            <MiniBlocks
              category="it-item"
              vote_flag={block.vote_flag}
              type="block"
              name={block.block_name}
              description={block.block_description}
              backgroundColor={block.ui_color}
              grabber={`grabber-${blockIndex}`}
              block_rid={block.block_rid}
              block={block.itinerary_item_rid ? block : modifiedBlock}
              itineraryItemRid={itinerary_item.itinerary_item_rid}
              itinerary_rid={itinerary_info.itinerary_rid}
              setDrilledDownItineraries={setDrilledDownItineraries}
              drilledDownItineraries={drilledDownItineraries}
              adventure_rid={adventure_rid}
              idx={blockIndex}
              itinerary_info={itinerary_info}
              handleTraverseUp={handleTraverseUp}
            />
          </div>
        );
      }}
    </Draggable>
  ) : (
    <Draggable
      // key={key}
      block={block}
      type="blocks"
      draggableId={`adventureBlock-${block.block_rid}-${currAdvDis}${
        block.folder_flag ? '-isFolder' : ''
      }${block.folder_flag && block.list_flag ? '-isFolderList' : ''}`}
      index={blockIndex}
      isCombineEnabled={block.folder_flag}
    >
      {(provided) => (
        <div
          ref={provided.innerRef}
          className="itinerary-adventure-block-children"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <MiniBlocks
            category="block_cache"
            type="block"
            name={block.block_name}
            description={block.block_description}
            backgroundColor={block.ui_color}
            grabber={`grabber-${blockIndex}`}
            adventure_rid={adventure_rid}
            vote_flag={block.vote_flag}
            block_rid={block.block_rid}
            block={block}
            itinerary_info={itinerary_info}
            isAdventure={currAdvDis === 'Adv-Blocks'}
            handleLevelTraverse={handleLevelTraverse}
            currentLevelDisplayDetails={displayDetails}
            adventureBlock={currAdvDis === 'Adv-Blocks' ? true : false}
            idx={blockIndex}
            handleTraverseUp={handleTraverseUp}
          />
        </div>
      )}
    </Draggable>
  );
};

export default Item;
