import { ButtonBase, Divider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { ActivityIcon } from 'features/activity/components/create-activity-modal-button';
import useCreateAdventureActivityModalButton from 'features/activity/hooks/use-create-adventure-activity-modal-button';
import { StyledMenu } from 'features/list/components/list-action-menu';
import { useState } from 'react';
import useAppendItineraryDayButton from '../hooks/use-append-itinerary-day-button';
import useEditItineraryModalButton from '../hooks/use-edit-itinerary-modal-button';
import { DayIcon } from './append-itinerary-day-button';
import { CalendarIcon } from './edit-itinerary-modal-button';
import TripPlannerDrawerButtonBase from 'features/trip-planner/components/trip-planner-drawer-button-base';
import useTripPlannerButton from 'features/trip-planner/hooks/use-trip-planner-button';
import { VanIcon } from 'features/trip-planner/components/trip-builder-page-button';

const ItineraryActionMenu = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const { openModal: openEditItineraryModal } = useEditItineraryModalButton();
  const { addDay: appendItineraryDay } = useAppendItineraryDayButton();
  const {
    openModal: openCreateActivityModal,
  } = useCreateAdventureActivityModalButton();

  const {
    handleClick: handleTripBuilderButtonClick,
    loading,
  } = useTripPlannerButton();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateActivity = () => {
    openCreateActivityModal();
    handleClose();
  };

  const handleAddDay = () => {
    appendItineraryDay();
    handleClose();
  };

  const handleEdit = () => {
    openEditItineraryModal();
    handleClose();
  };

  const handleTripPlanner = (e) => {
    // handleTripBuilderButtonClick(e);
    handleClose();
  };

  const id = 'itinerary-action-menu';

  return (
    <>
      <ButtonBase
        onClick={handleClick}
        id={id}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          height: 'min-content',
        }}
      >
        {children}
      </ButtonBase>
      <StyledMenu
        id={id}
        MenuListProps={{
          'aria-labelledby': id,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCreateActivity} disableRipple>
          <ActivityIcon />
          Create New Activity
        </MenuItem>
        <MenuItem onClick={handleAddDay} disableRipple>
          <DayIcon />
          Add Another Day
        </MenuItem>
        <MenuItem onClick={handleEdit} disableRipple>
          <CalendarIcon />
          Edit Start Date
        </MenuItem>
        <Divider />
        <TripPlannerDrawerButtonBase>
          <MenuItem onClick={handleTripPlanner} disableRipple sx={{ width: 1 }}>
            <VanIcon />
            Open Trip Planner
          </MenuItem>
        </TripPlannerDrawerButtonBase>
      </StyledMenu>
    </>
  );
};

export default ItineraryActionMenu;
