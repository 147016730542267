import { Chip } from '@mui/material';
import { FolderIcon } from 'features/folder/components/create-trip-folder-modal-button';

/**
 * FolderTag component renders a styled MUI `Chip` with an icon and custom text.
 * It automatically adjusts the text and icon color based on the background color contrast.
 *
 * @component
 *
 * @param {Object} props - The props for the FolderTag component.
 * @param {string} [props.backgroundColor='#fff'] - The background color of the chip. Default is white.
 * @param {string} props.text - The label text for the chip.
 *
 * @returns {JSX.Element} A MUI Chip component with a folder icon and custom styles.
 *
 * @example
 * // Usage example:
 * <FolderTag backgroundColor="#000" text="My Folder" />
 */
const FolderTag = ({ backgroundColor: color = '#f00', text }) => {
  return (
    <Chip
      icon={<FolderIcon sx={{ pl: 0.5 }} />}
      variant="outlined"
      label={text}
      size="small"
      sx={{
        //   whiteSpace: 'nowrap',
        //   overflow: 'hidden',
        //   textOverflow: 'ellipsis',
        //   borderRadius: 0.5,
        //   // backgroundColor: backgroundColor,
        borderColor: color,
        color: color,
        '& .MuiChip-icon': {
          fill: color,
          color: color, // Ensure the icon inherits the text color
        },
      }}
    />
  );
};

export default FolderTag;
