import styled from "@emotion/styled"
import chroma from "chroma-js"

const Container = styled.div`
  width: 130px;
  height: 120px;
  padding-top: 15px;
  box-sizing: border-box;
  position: relative;
  background: ${({ color }) => color};
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 20px;
  // -webkit-perspective: 200px;
  // -webkit-perspective-origin: top center;
  // perspective: 300px;
  // perspective-origin: top center;
  &::before {
    width: 33px;
    height: 7px;
    content: '';
    background: ${({ color }) => color};
    position: absolute;
    top: -6px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .inner {
    width: 130px;
    height: 105px;
    padding: 8px;
    box-sizing: border-box;
    background: ${({ color }) => color};
    // box-shadow: 0 1.7px 1.1px -10px rgba(19,10,43,.093),
    //             0 4.3px 3px -10px rgba(19,10,43,.133),
    //             0 8.9px 6.6px -10px rgba(19,10,43,.167),
    //             0 12.3px 14.4px -10px rgba(19,10,43,.207),
    //             0 11px 17px -10px rgba(19,10,43,.3);
    border-radius: 10px;
    -webkit-transform-origin: bottom center;
    -webkit-transform: rotateX(-10deg);
    transform-origin: bottom center;
    transform: rotateX(-10deg);
  }
`

const InnerFile = styled.div`
  width: 115px;
  height: 60px;
  position: absolute;
  background: #F9FBFF;
  top: 8px;
  left: 6px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  -webkit-transform: rotate(-2deg);
  transform: rotate(-2deg);
  border: 1px solid #ddd;
  border-radius: 5px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  &::before {
    content: '';
    /* we're going to use linear gradients to create the lines */
    background: -webkit-repeating-linear-gradient(145deg, #ffffff, #ffffff 5px, #333333 5px, #333333 10px);
    background: repeating-linear-gradient(90deg, #ffffff, #ffffff 5px, #333333 5px, #333333 10px);
    position: absolute;
    top: 2px;
    left: 2px;
    width: 111px;
    height: 50px;
    color: #343434;
    font-size: 60px;
    line-height: 30px;
    opacity: 0.15;
    display: none;
  }
`

function Folder({ color, children, ...props }) {
  const startColor = color || Folder.defaultProps.color
  const endColor = chroma(startColor).darken(3).hex()
  const mainColor = chroma(startColor).darken(1.5).hex()

  return (
    <Container
      color={startColor}
      startColor={startColor}
      endColor={endColor}
      {...props}
    >
      <InnerFile/>
      <div className="inner">
        {children}
      </div>
    </Container>
  )
}

Folder.defaultProps = {
  color: '#ffd480',
}

export default Folder