import { svgBackButton, svgBottomNavRefresh } from 'components/svg_module';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { fetchAccountExplorer } from '../../../actions/user_actions';
import {
  HeaderContainer,
  LogoBar,
  StyledImg,
  SubTitleBar,
  UserIconBar,
} from './components/styled-components';
import './header.scss';

const Header = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.session.user?.userInfo[0]);
  const explorerInfo = useSelector(
    (state) => state.entities?.users?.currentExplorerDetails,
  );
  const parentRef = useRef(null);
  const childRef = useRef(null);
  const backBtnRef = useRef(null);
  const spanRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src =
      explorerInfo && explorerInfo.ui_image_location
        ? explorerInfo.ui_image_location
        : '/svg_assets/person_light_orange.svg';
    img.onload = () => {
      setImageLoaded(true);
    };
  }, [explorerInfo]);

  useEffect(() => {
    dispatch(fetchAccountExplorer(userInfo.account_rid, userInfo.explorer_rid));
  }, []);
  const selectedAdvetureFromItinerary = useSelector((state) =>
    state.entities?.adventure?.user_adventures?.filter(
      (e) =>
        e.adventure_rid ===
        state.entities?.itinerary?.itinerary_info?.adventure_rid,
    ),
  );
  const selectedAdventure = useSelector(
    (state) => state.entities?.adventure?.selectedAdventure?.adventure_name,
  );

  const adventureName = selectedAdventure
    ? selectedAdventure
    : selectedAdvetureFromItinerary?.length > 0
    ? selectedAdvetureFromItinerary[0].adventure_name
    : undefined;

  const history = useHistory();
  const [subTitle, setSubTitle] = useState('');

  // useEffect(() => {
  //   const titleObserver = new MutationObserver((mutations) => {
  //     mutations.forEach((mutation) => {
  //       if (mutation.type === 'childList' && document.title !== 'Loading') {
  //         setSubTitle(
  //           document.title.startsWith('Impact') ? 'Impact' : document.title,
  //         );
  //       }
  //     });
  //   });
  //   titleObserver.observe(document.querySelector('head > title'), {
  //     childList: true,
  //   });
  //   return () => titleObserver.disconnect();
  // });

  useEffect(() => {
    const titleElement = document.querySelector('head > title');

    if (!titleElement) return; // Exit if titleElement is not found
    const titleObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && document.title !== 'Loading') {
          setSubTitle(
            document.title.startsWith('Impact') ? 'Impact' : document.title,
          );
        }
      });
    });

    titleObserver.observe(titleElement, {
      childList: true,
    });

    return () => titleObserver.disconnect();
  }, []); // Dependency array to ensure effect runs only once

  const isHubPage = window.location.pathname.includes('/hub');
  return (
    <HeaderContainer>
      <LogoBar onClick={() => (window.location.href = '/hub/my_adventures')}>
        <StyledImg
          alt="pwa-nav-bar"
          src="/svg_logos/app_lodestar_logo_icon.svg"
          width={40}
          height={40}
        />
      </LogoBar>
      <SubTitleBar ref={parentRef}>
        {isHubPage && (
          <a
            ref={backBtnRef}
            className="back-button"
            onClick={() => {
              if (history.length > 1) history.goBack();
            }}
          >
            {svgBackButton(
              '',
              'sub-nav-svg-back-button',
              'svg-outer-layer',
              25,
              25,
            )}
          </a>
        )}
        <div ref={childRef} className="hub-sv-cent-inf-container">
          <span ref={spanRef}>
            {subTitle === 'Itinerary' || subTitle === 'Selected Trip'
              ? adventureName
              : subTitle}
          </span>
        </div>
      </SubTitleBar>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        {/* <div
          style={{ height: '25px', cursor: 'pointer' }}
          onClick={() => window.location.reload()}
        >
          {svgBottomNavRefresh(
            '',
            'sub-nav-svg-back-button',
            'svg-outer-layer',
            25,
            25,
          )}
        </div> */}
        <UserIconBar>
          <div className="hub-nav-profile-pic">
            {!isHubPage ? (
              <NavLink to="/hub/dashboard" className="top-nav-login-button">
                MY HUB
              </NavLink>
            ) : (
              <NavLink
                to={isMobile ? '/hub/account' : '/hub/account/profile'}
                style={{ cursor: 'pointer' }}
              >
                {imageLoaded ? (
                  <StyledImg
                    alt="pwa-nav-bar"
                    src={
                      explorerInfo && explorerInfo.ui_image_location
                        ? explorerInfo.ui_image_location
                        : '/svg_assets/person_light_orange.svg'
                    }
                    className="hub-nav-profile-pic-img"
                    loading="lazy"
                  />
                ) : (
                  <StyledImg
                    alt="pwa-nav-bar"
                    src={'/svg_assets/person_light_orange.svg'}
                    className="hub-nav-profile-pic-img"
                  />
                )}
              </NavLink>
            )}
          </div>
        </UserIconBar>
      </div>
    </HeaderContainer>
  );
};

export default Header;
