import { useSelector } from 'react-redux';

/**
 * This might not be fully accurate I just had chatgpt do it quickly
 *
 * @typedef {Object} SelectedAdventure
 * @property {number} account_rid - The unique identifier for the account.
 * @property {?string} adventure_description - A description of the adventure, if available.
 * @property {Array.<Object>} adventure_file - An array of file objects associated with the adventure.
 * @property {string} adventure_name - The name of the adventure.
 * @property {number} adventure_rid - The unique identifier for the adventure.
 * @property {number} adventure_year - The year the adventure took place.
 * @property {Object} budget_range - The budget range for the adventure.
 * @property {number} budget_range.to - The upper limit of the budget.
 * @property {number} budget_range.from - The lower limit of the budget.
 * @property {?boolean} calendar_sync_flag - Flag indicating whether the adventure is synced with the calendar.
 * @property {number} count_block - The number of blocks related to the adventure.
 * @property {number} count_comment - The number of comments on the adventure.
 * @property {number} count_friend - The number of friends involved in the adventure.
 * @property {number} count_invite - The number of invites sent for the adventure.
 * @property {number} count_itinerary - The number of itineraries for the adventure.
 * @property {number} count_vote_down - The number of downvotes the adventure received.
 * @property {number} count_vote_up - The number of upvotes the adventure received.
 * @property {string} default_transportation_icon - The icon representing the default mode of transportation.
 * @property {number} default_transportation_type_rid - The unique identifier for the default transportation type.
 * @property {string} duration - The duration of the adventure, e.g., "3 Days".
 * @property {number} duration_day - The duration of the adventure in days.
 * @property {string} end_date - The end date of the adventure in ISO 8601 format.
 * @property {?string} image_position_type - The type of positioning used for the image, if applicable.
 * @property {?string} inspiration_name - The name of the inspiration for the adventure, if applicable.
 * @property {?number} inspiration_rid - The unique identifier for the inspiration, if applicable.
 * @property {string} northern_hemisphere_season - The season of the adventure in the northern hemisphere.
 * @property {?number} position_x - The X position of the adventure image or element, if applicable.
 * @property {?number} position_y - The Y position of the adventure image or element, if applicable.
 * @property {?number} position_z - The Z position of the adventure image or element, if applicable.
 * @property {string} southern_hemisphere_season - The season of the adventure in the southern hemisphere.
 * @property {string} start_date - The start date of the adventure in ISO 8601 format.
 * @property {string} status - The status of the adventure, e.g., "past", "upcoming".
 * @property {string} status_detail - The detailed status of the adventure, e.g., "completed".
 * @property {number} thumb_up - The number of thumbs-up reactions the adventure received.
 * @property {string} total_distance - The total distance covered in the adventure.
 * @property {?string} ui_image_location - The location of the UI image for the adventure, if applicable.
 * @property {?number} vote_count_down - The total number of downvotes.
 * @property {?number} vote_count_up - The total number of upvotes.
 * @property {boolean} vote_flag - Flag indicating whether the user has voted on the adventure.
 */

/**
 * Custom hook to select the currently selected adventure from the Redux store.
 *
 * @returns {SelectedAdventure} The currently selected adventure object or `null` if no adventure is selected.
 */
const useCurrentAdventure = () => {
  return useSelector((state) => state?.entities?.adventure?.selectedAdventure);
};

export default useCurrentAdventure;
