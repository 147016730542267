import EditIcon from '@mui/icons-material/Edit';
import SlideButton from 'components//modules/block_slider_no_button/button';
import ItemStandAloneClone from 'components//modules/block_slider_no_button/item_stand_alone_clone';
import DNDList from 'components/drag-drop/dnd-list/dnd-list';
import {
  SvgAddToList,
  svgDayAdd,
  svgDelete,
  svgNote,
} from 'components/svg_module';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import Collapsible from 'react-collapsible';
import {
  DragDirections,
  DragTypes,
} from '../../../components/drag-drop/item-types';
import TooltipButton from '../../../components/hub-button/tooltip-button';
import { convertTime } from '../../../util/helpers/time_funcs';
import ItineraryNotes from './itinerary_notes';
import {
  setBlockSortOrder,
  useAddBlockFromBoard,
  useMoveBlockAroundItems,
  useRefreshItinerary,
  useReorderBlock,
} from './utils';
import { MoreVert } from '@material-ui/icons';
import { StyledMenu } from 'features/list/components/list-action-menu';
import useId from '@mui/material/utils/useId';
import { Divider, IconButton, MenuItem } from '@mui/material';
import { JournalIcon } from 'features/itinerary/components/edit-itinerary-day-journal-modal-button';
import { DayIcon } from 'features/itinerary/components/append-itinerary-day-button';
import { DeleteIcon } from 'features/itinerary/components/delete-itinerary-day-modal-button';

const ItinerariesSingleItem = ({
  ele,
  idx,
  pageData,
  itinerary_info,
  openDeleteModal,
  deleteItineraryItem,
  adventure_rid,
  explorer_rid,
  openItineraryItemModal,
  isHoverEnabled,
  drilledDownItineraries,
  setDrilledDownItineraries,
  itineraryBlockTraverseUpHanlder,
  addDayHandler,
  onWheel,
}) => {
  const id = useId();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [displayItineraryDropdown, toggleItineraryDropdown] = useState(false);
  const singleItineraryOuterRef = useRef();
  const singleItineraryInnerRef = useRef();
  const handleClickOutSideItinerary = useCallback((event) => {
    if (singleItineraryInnerRef.current && singleItineraryOuterRef.current) {
      if (
        !singleItineraryInnerRef.current.contains(event.target) &&
        !singleItineraryOuterRef.current.contains(event.target)
      ) {
        toggleItineraryDropdown(false);
      }
    }
  });
  useEffect(() => {
    window.addEventListener('click', handleClickOutSideItinerary);

    return () => {
      window.removeEventListener('click', handleClickOutSideItinerary);
    };
  }, [handleClickOutSideItinerary]);

  const blocks = pageData.itineraryItemBlocks[ele.itinerary_item_rid];

  blocks?.sort(function (a, b) {
    if (a.sort_order === null) return 1;
    if (b.sort_order === null) return -1;
    if (a.sort_order > b.sort_order) return 1;
    if (a.sort_order < b.sort_order) return -1;
    return 0;
  });

  const setBlockOrder = setBlockSortOrder();
  useEffect(() => {
    blocks?.sort(function (a, b) {
      if (a.sort_order === null) return 1;
      if (b.sort_order === null) return -1;
      if (a.sort_order > b.sort_order) return 1;
      if (a.sort_order < b.sort_order) return -1;
      return 0;
    });
    blocks?.forEach((v, index) => {
      if (v.sort_order != index + 1) {
        // setBlockOrder(v, index + 1);
      }
    });

    // blocks.forEach((v, index) => (v.sort_order = index + 1));
  }, []);

  const BlockRenderer = useCallback(
    ({ data, index }) => {
      return (
        <ItemStandAloneClone
          type="itinerary-item-block"
          block={data}
          key={data.block_rid}
          itinerary_item={ele}
          itinerary_info={itinerary_info}
          setDrilledDownItineraries={setDrilledDownItineraries}
          drilledDownItineraries={drilledDownItineraries}
          adventure_rid={adventure_rid}
          blockIndex={index}
        ></ItemStandAloneClone>
      );
    },
    [
      ele,
      itinerary_info,
      setDrilledDownItineraries,
      drilledDownItineraries,
      adventure_rid,
    ],
  );

  const DropPlaceholder = useCallback(() => {
    return (
      <div className="itinerary-item-action-guidance">
        <div className="create-modal-svg-wrapper" style={{ cursor: 'inherit' }}>
          {SvgAddToList(
            '',
            'itinerary-trip-board-svg',
            'itinerary-trip-board-svg-outer',
            30,
            30,
          )}
        </div>
        <span
          className="empty-block-text-high-lighter"
          style={{ cursor: 'inherit' }}
        >
          Drag & drop items from your Trip Board folders here
        </span>
      </div>
    );
  }, []);

  const reorderBlock = useReorderBlock();
  const moveBlockAroundItems = useMoveBlockAroundItems();
  const addBlockFromBoard = useAddBlockFromBoard();
  const refreshItinerary = useRefreshItinerary();

  const blockReorder = useCallback(
    (a, b) => {
      const from = blocks[a];
      const to = blocks[b];
      console.log(from, to, 'reorder');
      reorderBlock(from, to, a, b);
    },
    [pageData.itineraryItemBlocks, ele.itinerary_item_rid],
  );

  const blockAdd = useCallback(
    (item, index) => {
      const itItemId = item.itinerary_item_rid;
      if (itItemId) {
        moveBlockAroundItems(item, ele.itinerary_item_rid, index + 1).catch(
          () => {
            refreshItinerary();
          },
        );
      } else {
        addBlockFromBoard(item, ele.itinerary_item_rid, index + 1);
      }
    },
    [ele.itinerary_item_rid],
  );

  const {
    item_header,
    item_level,
    item_start_date,
    item_start_time,
    item_end_time,
    itinerary_item_rid,
  } = ele;

  return (
    <div className="itinerary-day">
      <div className="header">
        <div className="up-button">
          {drilledDownItineraries[itinerary_item_rid] &&
            drilledDownItineraries[itinerary_item_rid].level > 1 && (
              <SlideButton
                type="up"
                displayDetails={drilledDownItineraries[itinerary_item_rid]}
                handleTraverseUp={() =>
                  itineraryBlockTraverseUpHanlder(itinerary_item_rid)
                }
                customClassName="itinerary-item-up-button"
              />
            )}
        </div>
        <div className="info">
          <div className="hr-bar" />
          <div className="day-title">
            <span className="text">{`${item_header} - `}</span>
            <span className="date-time">
              {item_level === 1
                ? `${moment.utc(item_start_date).format('ddd, MMM D, YYYY')}`
                : `${convertTime(item_start_time)} - ${convertTime(
                    item_end_time,
                  )}`}
            </span>
            <div
              className="itineraryBottomDropdown"
              ref={singleItineraryOuterRef}
            >
              <div className="itinerary_adventure_block_bottom_icon_dropdown">
                <IconButton onClick={handleClick}>
                  <MoreVert />
                </IconButton>
                <StyledMenu
                  id={id}
                  MenuListProps={{
                    'aria-labelledby': id,
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      addDayHandler();
                      handleClose();
                    }}
                    disableRipple
                  >
                    <DayIcon />
                    Add Another Day
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      let actionPayload = {
                        itineraryItemData: ele,
                        itineraryData: itinerary_info,
                      };
                      let openType = {
                        isEdit: true,
                      };
                      openItineraryItemModal(openType, actionPayload);
                      handleClose();
                    }}
                    disableRipple
                  >
                    <JournalIcon />
                    Edit Journal Entry
                  </MenuItem>
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      const passedFunc = (callBack) => {
                        deleteItineraryItem(
                          adventure_rid,
                          explorer_rid,
                          ele.itinerary_item_rid,
                          ele.itinerary_rid,
                        ).then();
                        callBack();
                      };

                      openDeleteModal(
                        {
                          content: 'Remove from itinerary?',
                          following: (
                            <span>
                              Items on your Trip Board will not be affected.
                            </span>
                          ),
                        },
                        passedFunc,
                      );
                      handleClose();
                    }}
                    disableRipple
                  >
                    <DeleteIcon />
                    Delete Day
                  </MenuItem>
                </StyledMenu>
                {/* <Collapsible
                  trigger={
                    // <div
                    //   className="trip-board-list-collapsible-icon-plus-container"
                    //   data-tip
                    //   data-for="dailySetup"
                    // >
                    //   {svgAddIcon(
                    //     '',
                    //     'itinerary-trip-board-svg',
                    //     'itinerary-trip-board-svg-outer',
                    //   )}
                    // </div>
                    <TooltipButton icon={<MoreVert />} label="Edit" size="sm" />
                  }
                  contentOuterClassName="trip-board-box-menu-single-closed"
                  contentInnerClassName="trip-board-box-menu-opened"
                  open={displayItineraryDropdown}
                  handleTriggerClick={() => {
                    toggleItineraryDropdown(!displayItineraryDropdown);
                  }}
                  transitionTime={10}
                ></Collapsible> */}
              </div>
            </div>
          </div>
          <div className="hr-bar" />
        </div>
      </div>
      <div className="blocks">
        <div className="itinerary-item-sections itinerary-itinerary-item-blocks-wrapper">
          <DNDList
            type={DragTypes.Activity}
            accept={[DragTypes.Activity, DragTypes.Library, DragTypes.List]}
            direction={DragDirections.Horizontal}
            gap={10}
            keyField="itineriary_block_rid"
            data={blocks}
            ItemRenderer={BlockRenderer}
            Placeholder={DropPlaceholder}
            handlers={{ onReorderItem: blockReorder, onAddItem: blockAdd }}
          />
        </div>
      </div>
      <div className="itineraryBottomDropdown">
        <ItineraryNotes itineraryItemNotes={ele.item_description} />
      </div>
    </div>
  );
};

export default ItinerariesSingleItem;
