import { MoreVert } from '@material-ui/icons';
import {
  Box,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useResize } from 'components/carousel/card-carousel';
import SectionHeader from 'components/section/header';
import PaperSection from 'components/section/paper-section';
import SwiperCarousel from 'components/swiper-carousel';
import CreateListModalButton from 'features/list/components/create-list-modal-button';
import ListBlockCard from 'features/list/components/list-block-card';
import useMutateListItemInAdventureLists from 'features/list/hooks/use-mutate-list-item-in-adventure-lists';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useMaskingHooks from 'util/custom_hooks/maskUrl';
import useAdventureLists from '../hooks/use-adventure-lists';
import useCurrentAdventure from '../hooks/use-current-adventure';
import useRecommendedPartners from '../hooks/use-recommended-partners';
import AdventureListsActionMenu from './adventure-lists-action-menu';

// Skeleton for ListBlockCard
export const SkeletonListBlockCard = () => (
  <Box width={1} sx={{ mb: 4 }}>
    <Skeleton variant="rounded" width={'100%'} height={400} />
  </Box>
);

// Custom Hook for Progressive Rendering

// Custom Hook for Progressive Rendering with Delay

const AdventureLists = () => {
  const { adventure_rid: adventureRid } = useCurrentAdventure();
  const { data } = useRecommendedPartners(adventureRid);

  const { adventure_rid: paramsRid } = useParams();
  const { unmaskURL } = useMaskingHooks();

  const { data: initialData, isLoading, isError } = useAdventureLists(
    adventureRid,
  );
  const { deviceWidth } = useResize();
  const { mutate } = useMutateListItemInAdventureLists();

  // Use the custom hook for progressive rendering

  const perViewCounts = getPerViewCounts(deviceWidth);

  const handleCheck = (listId, listItem, completed) => {
    const updatedListItem = { ...listItem, completed };
    mutate({ ...updatedListItem, listId });
  };

  const renderedSlides = useMemo(() => {
    if (!initialData) return [];
    return initialData.map((list) => (
      <ListBlockCard list={list} key={list.block_rid} onCheck={handleCheck} />
    ));
  }, [initialData, handleCheck]);

  return (
    <Paper
      elevation={0}
      sx={{
        mt: 3,
        background: 'white',
        wordBreak: 'break-word',
        '& .swiper-slide': {
          background: 'transparent',
        },
      }}
    >
      <PaperSection>
        <Box
          display="flex"
          alignItems={'center'}
          justifyContent={'left'}
          flexDirection={'row'}
        >
          <Box flexGrow={1}>
            <SectionHeader>LISTS</SectionHeader>
          </Box>
          <Stack direction="row" spacing={1} display="flex">
            <CreateListModalButton
              variant="contained"
              sx={{ height: '40px !important' }} // force adjustment to align with icon button...
            />
            <AdventureListsActionMenu>
              <IconButton variant="contained">
                <MoreVert />
              </IconButton>
            </AdventureListsActionMenu>
          </Stack>
        </Box>
        <Box my={'30px'}>
          <SwiperCarousel
            className={`card-carousel checklist`}
            prev={'swiper-checklist-prev'}
            next={'swiper-checklist-next'}
            navigation={{
              prevEl: '.swiper-checklist-prev',
              nextEl: '.swiper-checklist-next',
            }}
            spaceBetween={10}
            slidesPerView={perViewCounts}
          >
            {isLoading
              ? Array.from({ length: perViewCounts + 2 }).map((_, index) => (
                  <SkeletonListBlockCard key={index} />
                ))
              : renderedSlides}
          </SwiperCarousel>
        </Box>
      </PaperSection>
    </Paper>
  );
};

const getPerViewCounts = (deviceWidth) => {
  let perViewCounts = 4;
  if (deviceWidth < 450) {
    perViewCounts = 1;
  } else if (deviceWidth < 600) {
    perViewCounts = 1;
  } else if (deviceWidth < 750) {
    perViewCounts = 1;
  } else if (deviceWidth < 900) {
    perViewCounts = 2;
  } else if (deviceWidth < 1050) {
    perViewCounts = 3;
  } else if (deviceWidth < 1200) {
    perViewCounts = 3;
  } else if (deviceWidth < 1500) {
    perViewCounts = 4;
  } else if (deviceWidth < 1900) {
    perViewCounts = 5;
  } else {
    perViewCounts = 6;
  }

  return perViewCounts;
};

export const CenteredSectionHeader = ({ title = '', subtitle = '' }) => {
  return (
    <Box>
      <Typography
        variant="h5"
        textAlign="left"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        gutterBottom={false}
      >
        {title}
      </Typography>
      <Typography
        textAlign="left"
        variant="body1"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};
export default AdventureLists;
