import { useQueryClient } from '@tanstack/react-query';
import useCurrentAdventure from './use-current-adventure';
import { useDispatch } from 'react-redux';
import {
  fetchItineraryInformation,
  fetchUserAdventures,
} from 'actions/adventure_actions';
import useUserInfo from 'features/user/hooks/use-user-info';
import { fetchBoardBlocks } from 'actions/block_actions';
import useCurrentItinerary from 'features/itinerary/hooks/use-current-itinerary';
import { fetchLocationBlocks } from 'actions/itinerary_actions';

const useInvalidateAdventureData = () => {
  const { adventure_rid = null } = useCurrentAdventure() || {}; // sometimes this may null
  const {
    itinerary_info: { itinerary_rid = null } = { itinerary_rid: null },
  } = useCurrentItinerary();
  const userInfo = useUserInfo();
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const invalidateQueries = () => {
    if (!adventure_rid)
      console.warn('Invalidating adventure data when no adventure is set.');

    queryClient.invalidateQueries({
      queryKey: ['adventure', adventure_rid],
    });
    queryClient.invalidateQueries({
      queryKey: ['itinerary'],
    });
    refreshStore();
  };

  function refreshStore() {
    let adventureQuery = {
      account_rid: userInfo.account_rid,
      inspiration_rid: null,
      page_number: 1,
      page_size: 50,
      image_position_type_rid: 1,
    };

    if (itinerary_rid) {
      dispatch(
        fetchItineraryInformation(adventure_rid, itinerary_rid, userInfo),
      );
      dispatch(
        fetchLocationBlocks({
          account_rid: userInfo.account_rid,
          adventure_rid,
          itinerary_rid,
        }),
      );
    }

    dispatch(
      fetchBoardBlocks(
        userInfo,
        { adventure_rid: adventure_rid },
        { isAdventure: true },
        false,
      ),
    );

    dispatch(fetchUserAdventures(adventureQuery));
  }

  return { invalidateQueries };
};

export default useInvalidateAdventureData;
