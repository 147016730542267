import {
  createTheme,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  SvgIcon,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React from 'react';
import { VanIcon } from './trip-planner/components/trip-builder-page-button';
import muiTheme from 'stylesheets/mui/themes/defaultTheme';
import { Edit } from '@material-ui/icons';
import LibraryDrawer from './library/components/library-drawer';
import useToggle from 'hooks/use-toggle';
import useCurrentAdventure from './adventure/hooks/use-current-adventure';
import useTripPlannerButton from './trip-planner/hooks/use-trip-planner-button';
import useTripBuilderDrawerButton from './trip-planner/hooks/use-trip-builder-drawer-button';
import TripPlannerDrawerButtonBase from './trip-planner/components/trip-planner-drawer-button-base';
import { DrawerProvider } from './adventure/hooks/use-drawer-context';
import { svgLibraryGreen } from 'components/svg_module';
import TripPlannerDrawer from './trip-planner/components/trip-planner-drawer';
import { useLocation } from 'react-router-dom';

const resetBorderRadiusTheme = createTheme({
  components: {},
});

function PlannerDialer() {
  const location = useLocation();
  const [libraryDrawerIsOpen, toggleLibraryDrawer] = useToggle(false);

  const {
    toggleDrawer: toggleTripPlannerDrawer,
    isOpen: tripPlannerIsOpen,
  } = useTripBuilderDrawerButton();

  // Split the path into segments
  const pathSegments = location.pathname.split('/');
  const inAdventure = pathSegments[2] === 'adventure';

  return (
    <>
      <SpeedDial
        color="primary"
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 100, right: 20 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          key={'library'}
          icon={svgLibraryGreen()}
          tooltipOpen
          tooltipTitle={<Typography noWrap>Library</Typography>}
          onClick={toggleLibraryDrawer}
          sx={{
            borderRadius: 1,
            '& svg': { fontSize: '1rem !important', width: 30, height: 30 },
          }}
        />
        {inAdventure && (
          <SpeedDialAction
            key={'trip-planner'}
            icon={<VanIcon />}
            tooltipOpen
            tooltipTitle={<Typography noWrap>Trip Planner</Typography>}
            onClick={toggleTripPlannerDrawer}
          />
        )}
      </SpeedDial>
      {/* <TripPlannerDrawerButtonBase /> */}
      <TripPlannerDrawer
        isOpen={tripPlannerIsOpen}
        handleClose={toggleTripPlannerDrawer}
      />
      <LibraryDrawer
        isOpen={libraryDrawerIsOpen}
        handleClose={toggleLibraryDrawer}
      />
    </>
  );
}

// I made this because I needed to wrap the drawer in context
const PlannersSpeedDialer = () => {
  return (
    <>
      <ThemeProvider theme={muiTheme}>
        <DrawerProvider>
          <PlannerDialer />
        </DrawerProvider>
      </ThemeProvider>
    </>
  );
};

export default PlannersSpeedDialer;
