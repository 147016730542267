import { useSelector, useDispatch } from 'react-redux';
import ItemStandAloneClone from './item_stand_alone_clone';
import MultiBackendProvider from 'components/drag-drop/multi-backend-provider/multi-backend-provider';
import DNDList from 'components/drag-drop/dnd-list/dnd-list';
import { useCallback } from 'react';
import { DragTypes, DragDirections } from '../../drag-drop/item-types';
import { useCombineBlock } from '../../../pages/pwa_hub/itineraries/utils';
import { fetchItineraryDetails } from '../../../actions/itinerary_actions';
import { fetchBoardBlocks } from '../../../actions/block_actions';
import useInvalidateAdventureData from 'features/adventure/hooks/use-invalidate-adventure-data';

const RenderBlockSlider = ({
  itineraryId,
  adventure_rid,
  handleLevelTraverse,
  displayDetails,
  currAdvDis,
  itinerary_info,
  handleTraverseUp,
  filtered,
  provided,
  filter,
}) => {
  const BlockRenderer = useCallback(({ data, index }) => {
    return (
      <ItemStandAloneClone
        block={data}
        blockIndex={index}
        adventure_rid={adventure_rid}
        handleLevelTraverse={handleLevelTraverse}
        displayDetails={displayDetails}
        currAdvDis={currAdvDis}
        provided={provided}
        itinerary_info={itinerary_info}
        handleTraverseUp={handleTraverseUp}
      />
    );
  });

  const combineBlock = useCombineBlock();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.session.user?.userInfo[0]);
  const accountId = useSelector((state) => state.session.user?.account_rid);
  const explorerId = useSelector((state) => state.session.user?.explorer_rid);

  const { invalidateQueries } = useInvalidateAdventureData();
  const onCombineBlock = useCallback(
    (from, to) => {
      if (!to.folder_flag) return;
      combineBlock(from, to).then(() => {
        invalidateQueries();
        dispatch(
          fetchBoardBlocks(
            userInfo,
            {
              adventure_rid: adventure_rid,
              parent_block_rid: to.parent_block_rid,
            },
            { isLibrary: true },
            false,
          ),
        );
        dispatch(
          fetchBoardBlocks(
            userInfo,
            {
              adventure_rid: adventure_rid,
              adventure_rid_exist_flag: true,
              parent_block_rid: to.parent_block_rid,
            },
            { isAdventure: true },
            false,
          ),
        );
        dispatch(fetchItineraryDetails(itineraryId, accountId, explorerId));
      });
    },
    [combineBlock, userInfo, accountId, explorerId, itineraryId],
  );

  return (
    <MultiBackendProvider>
      <DNDList
        type={DragTypes.Library}
        accept={[
          DragTypes.Library,
          DragTypes.Folder,
          DragTypes.List,
          DragTypes.Activity,
        ]}
        direction={DragDirections.Horizontal}
        gap={10}
        keyField="block_rid"
        clonable
        canCombine
        data={filtered?.map((v) => {
          const item = v.item;
          if (item.folder_flag) {
            item.type = DragTypes.Folder;
          } else if (item.list_flag) {
            item.type = DragTypes.List;
          } else {
            item.type = DragTypes.Activity;
          }
          return v.item;
        })}
        ItemRenderer={BlockRenderer}
        handlers={{ onCombineItem: onCombineBlock }}
      />
    </MultiBackendProvider>
  );
};

export default RenderBlockSlider;
