import {
  DRAWERS,
  useDrawerContext,
} from 'features/adventure/hooks/use-drawer-context';

function useTripBuilderDrawerButton() {
  const { toggleDrawer: toggleGlobalDrawer, openDrawer } = useDrawerContext();

  const toggleDrawer = () => {
    toggleGlobalDrawer(DRAWERS.TRIP_BUILDER);
  };

  const isOpen = openDrawer === DRAWERS.TRIP_BUILDER;

  return { toggleDrawer, isOpen };
}

export default useTripBuilderDrawerButton;
