import { useEffect, useRef, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Fab,
  ThemeProvider,
} from '@mui/material';
import axios from 'axios';
import AdventureLists from 'features/adventure/components/adventure-lists';
import AdventureOverview from 'features/adventure/components/adventure-overview';
import PartnersCarousel from 'features/partners/partners-carousel';
import { Helmet } from 'react-helmet';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import muiTheme from 'stylesheets/mui/themes/defaultTheme';
import { fetchTripDonations } from 'util/api_util/user_api_util';
import useMaskingHooks from '../../../util/custom_hooks/maskUrl';
import FriendsCard from './components/friends-card';
import ItineraryCard from './components/itinerary-card';
import TripOverview from './components/trip-overview';

import FootPrint from './components/footprint';
import { mapDispatchToProps, mapStateToProps } from './container';
import { DrawerProvider } from 'features/adventure/hooks/use-drawer-context';

const AdventureSelected = ({
  match,
  history,
  // location,
  account_rid,
  fetchUserAdventureInformation,
  // fetchUserAdventures,
  fetchItineraryInformation,
  fetchItineraryDetail,
  current_adventure_data,
  current_adventure,
  deleteAdventure,
  openDeleteModal,
  //openBlockModal,
  //openItineraryModal,
  openAdventureModal,
  userInfo,
  fetchAdventureFriend,
  //fetchAdventureGroup,
  fetchLocationBlocks,
  user_adventure_friend,
  //user_adventure_group,
  openFriendsModal,
  //openAdventureGroupModal,
  openAdventureCopyModal,
  //itinerary_info,
  //userTokenParsed,
  updateToken,
  fetchSingleAdventure,
  flushSelectedPartners,
  fetchTransportationTypes,
  fetchSocialCostOfCarbon,
  transportationTypes,
  calculateImpactForTrip,
  updateAdventureAttendee,
  //setLoading,
  deleteAdventureFriend,
  flushCurrentAdventure,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { unmaskURL } = useMaskingHooks();
  let adventure_rid = unmaskURL(match.params.adventure_rid);
  let userAdventureFriendRid = useRef(null);
  if (user_adventure_friend && !userAdventureFriendRid.current) {
    user_adventure_friend.forEach((ele) => {
      if (ele.explorer_rid === userInfo.explorer_rid) {
        userAdventureFriendRid.current = ele.adventure_friend_rid;
      }
    });
  }

  useEffect(() => {
    return () => {
      // if you flush here the donation page won't have adventure context
      // flushCurrentAdventure();
    };
  }, []);

  const [pageInfos, setPageInfos] = useState({
    page_title: '',
    page_keyword: '',
    page_metadata_description: '',
    ui_image_location: '',
    ui_image_opacity: null,
  });

  const [carbonData, setCarbonData] = useState(undefined);
  const [tripDonations, setTripDonations] = useState([]);

  const curItineraries = useSelector(
    (state) =>
      state.entities?.adventure?.current_adventure?.adventure_itineraries,
  );

  const curItinerary = curItineraries ? curItineraries[0] : null;

  useEffect(() => {
    if (curItinerary && current_adventure_data) {
      fetchItineraryInformation(
        current_adventure_data.adventure_rid,
        curItinerary.itinerary_rid,
        userInfo,
      );
      fetchItineraryDetail(
        curItinerary.itinerary_rid,
        userInfo.account_rid,
        userInfo.explorer_rid,
      );
    }
  }, [current_adventure_data]);

  useEffect(() => {
    if (current_adventure_data) {
      fetchTripDonations(current_adventure_data?.adventure_rid).then((res) => {
        setTripDonations(res.data?.adventurePartnerDonations || []);
        calcaulateTotalDistanceForTrip().then((res) => setCarbonData(res.data));
      });
    }
  }, [current_adventure_data]);

  useEffect(() => {
    axios
      .patch('/api/ui/fetch_meta_data_new', { pageName: 'selected trip' })
      .then((res) => {
        const {
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        } = res.data.metaData;
        setPageInfos({
          page_title,
          page_keyword,
          page_metadata_description,
          ui_image_location,
          ui_image_opacity,
        });
      });
    let promises = [
      fetchUserAdventureInformation(account_rid, adventure_rid, userInfo),
      fetchSingleAdventure(adventure_rid),
      fetchAdventureFriend(adventure_rid),
      flushSelectedPartners(),
      fetchSocialCostOfCarbon(),
      fetchTransportationTypes(),
    ];
    Promise.all(promises).catch((error) => console.log(error));

    if (userAdventureFriendRid.current === null && current_adventure_data) {
      let queryData = {
        adventure_rid: current_adventure_data.adventure_rid,
        explorer_rid: userInfo.explorer_rid,
        account_rid: userInfo.account_rid,
      };
      updateAdventureAttendee(queryData);
    }
  }, []);

  useEffect(() => {
    if (curItinerary)
      fetchItineraryInformation(
        adventure_rid,
        curItinerary?.itinerary_rid,
        userInfo,
      ).then(() => {
        fetchLocationBlocks({
          account_rid: userInfo.account_rid,
          adventure_rid,
          itinerary_rid: curItinerary.itinerary_rid,
        });
      });
  }, [curItinerary]);

  useEffect(() => {
    if (match.params.token) {
      updateToken(match.params.token);
    }
  }, []);

  const {
    page_title,
    page_metadata_description,
    page_keywords,
    ui_image_location,
    ui_image_opacity,
  } = pageInfos;

  const calcaulateTotalDistanceForTrip = () =>
    new Promise((resolve, reject) => {
      let adventureTransportationType;
      for (let i = 0; i < transportationTypes.length; i++) {
        let transportation = transportationTypes[i];
        if (
          transportation.transportation_type_rid ===
          current_adventure_data.default_transportation_type_rid
        ) {
          adventureTransportationType = transportation;
          i += 100;
        }
      }
      let query = {
        adventureItineraries: current_adventure?.adventure_itineraries || [],
        adventureData: current_adventure_data,
        account_rid: userInfo.account_rid,
        adventureFriendRid: userAdventureFriendRid.current,
        adventureTransportationType,
      };

      return resolve(calculateImpactForTrip(query));
    });

  return current_adventure_data ? (
    <>
      <ThemeProvider theme={muiTheme}>
        <DrawerProvider>
          <div className="hub-page-wrapper">
            <Helmet>
              <title>{page_title ? page_title : 'Loading'}</title>
              <meta
                name="description"
                content={
                  page_metadata_description
                    ? page_metadata_description
                    : 'Loading'
                }
              />
              <meta
                name="keywords"
                content={page_keywords ? page_keywords : 'Loading'}
              />
            </Helmet>
            {(current_adventure_data.ui_image_location ||
              ui_image_location) && (
              <div
                className="background-image-behind-content"
                style={{
                  backgroundImage: `url(${ui_image_location})`,
                }}
              >
                <div
                  className="background-image-opacity-layer"
                  style={{
                    backgroundColor: `rgba(248,247,216, 0.${
                      ui_image_opacity || ui_image_opacity === 0
                        ? ui_image_opacity
                        : 0.4
                    })`,
                  }}
                />
              </div>
            )}
            <div
              className="hub-page-container selected-adventure"
              id="hub-trip-selected-container"
            >
              <div
                style={{ width: '90%', margin: 'auto', padding: '24px 0px' }}
              >
                <TripOverview data={current_adventure_data} />
                <AdventureOverview />
                <AdventureLists />
                <ItineraryCard />
                <FriendsCard
                  openFriendsModal={openFriendsModal}
                  deleteAdventureFriend={deleteAdventureFriend}
                />
                <PartnersCarousel />
              </div>
            </div>
          </div>
        </DrawerProvider>
      </ThemeProvider>
    </>
  ) : (
    <div className="hub-page-wrapper">
      <Box
        width={1}
        height={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: '#f0e9d5',
        }}
      >
        <CircularProgress />
      </Box>
    </div>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdventureSelected),
);
