import { deleteAdventure } from 'actions/adventure_actions';
import { openDeleteModal } from 'actions/modal_actions';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useCurrentAdventure from './use-current-adventure';

const useDeleteAdventureModalButton = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const adventure = useCurrentAdventure();

  const openModal = async () => {
    const onConfirm = async () => {
      await dispatch(await deleteAdventure(adventure));
      await history.push('/hub/my_adventures');
    };

    dispatch(
      openDeleteModal(
        {
          content: 'Delete Adventure?',
          following: <span>All local items in adventure will be deleted.</span>,
        },
        onConfirm,
      ),
    );
  };

  return { openModal };
};

export default useDeleteAdventureModalButton;
