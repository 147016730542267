import { alpha, Box, ButtonBase, Drawer, Portal, styled } from '@mui/material';
import HubItinerary from '../../../pages/pwa_hub/itineraries';
import React, { useEffect, useState } from 'react';
import useGlobalLibraryDrawerButton from '../hooks/use-library-drawer-button';
import useInvalidateAdventureData from 'features/adventure/hooks/use-invalidate-adventure-data';
import ReactDOM from 'react-dom';
import Library from './library';
import useCurrentAdventure from 'features/adventure/hooks/use-current-adventure';

interface LibraryDrawerProps {
  isOpen: boolean;
  handleClose: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
}

const CustomDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '95%',
    height: '89%',
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    // opacity: 0.95,
    left: '2.5%',
    '& .hub-page-wrapper': {
      height: '100% !important',
      width: '100%',
    },
    '& .hub-page-container': {
      height: '100% !important',
    },
  },
}));

function LibraryDrawer({ isOpen, handleClose }: LibraryDrawerProps) {
  const { adventure_rid = null } = useCurrentAdventure() || {};
  return (
    <>
      {ReactDOM.createPortal(
        <CustomDrawer
          BackdropProps={{ style: { backgroundColor: alpha('#fff', 0.5) } }}
          anchor="bottom"
          open={isOpen}
          onClose={handleClose}
          container={document.body}
        >
          <Box width={1} height={1} position="relative">
            {/* Drawer content */}
            <Library
              sourceAdventureRid={adventure_rid}
              closeDrawer={handleClose}
            />
          </Box>
        </CustomDrawer>,
        document.body,
      )}
    </>
  );
}

export default LibraryDrawer;
